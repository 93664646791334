import React from "react"
import { ButtonGroup, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";


class LookingMinMax extends React.Component<any>{
    public state = {
        min: this.props.sendprop.lookingminmax.min,
        min_error: '',
        max: this.props.sendprop.lookingminmax.max,
        max_error: '',
        go: false,
        page: '',
    }

    // constructor(props:any) {
    //     super(props)
    // }


    public setProp = () => {
        console.log('this.state.min', this.state.min.length);


        if (this.state.min.length <= 0) {
            this.setState({ min_error: 'This field is required.' });
        } else {
            this.setState({ min_error: '' });
        }
        if (this.state.max.length <= 0) {
            this.setState({ max_error: 'This field is required.' });
        } else {
            this.setState({ max_error: '' });
        }


        setTimeout(() => {
            if (this.state.min_error === '' && this.state.max_error === '') {
                this.props.returnHandle({
                    lookingminmax: {
                        min: this.state.min,
                        max: this.state.max
                    },
                    current_q: 3,
                    questiongroup: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    prevNext: ['lookingForMinMaxAge', 'occupation'],
                    page: 'questions'
                });
            }
        }, 100);

    }

    public setvalues = (r: any, type: any) => {
        if (type === 'min') {
            this.setState({ min: r.target.value });
            if (r.target.value.length <= 0) {
                this.setState({ min_error: 'This field is required.' });
            } else {
                this.setState({ min_error: '' });
            }

        }
        if (type === 'max') {
            this.setState({ max: r.target.value });
            if (r.target.value.length <= 0) {
                this.setState({ max_error: 'This field is required.' });
            } else {
                this.setState({ max_error: '' });
            }
        }


        setTimeout(() => {
            this.props.returnHandle({ lookingminmax: { min: this.state.min, max: this.state.max } });
        }, 100);

    }

    render(): React.ReactNode {

        let Age: any = [];
        for (let index = 18; index <= 100; index++) {
            Age.push(index);
        }

        return (
            <div className="container main" >
                {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
                <div className={this.props.from === undefined ? 'd-block m_auto  col-md-5' : 'd-block m_auto  col-md-12'} >
                    {this.props.from === undefined ? <h3 className="d-block text-center" >I am looking for someone between: </h3>
                        : ''}
                    <FormControl fullWidth style={{ marginTop: '10px' }}>
                        <InputLabel id="demo-simple-select-label">Looking For Minimum Age</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={this.props.sendprop.lookingminmax.min}
                            label="Looking For Minimum Age"
                            onChange={(e) => this.setvalues(e, 'min')}
                            error={this.state.min_error !== '' ? true : false}
                        >
                            {Age.map((val: any) => (<MenuItem key={val} value={val}>{val}</MenuItem>))}
                        </Select>
                        <span className="text-light muierror">{this.state.min_error}</span>
                    </FormControl>

                    <FormControl fullWidth style={{ marginTop: '15px' }}>
                        <InputLabel id="demo-simple-select-label">Looking For Maximum Age</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={this.props.sendprop.lookingminmax.max}
                            label="Looking For Maximum Age"
                            onChange={(e) => this.setvalues(e, 'max')}
                            error={this.state.max_error !== '' ? true : false}
                        >
                            {Age.map((val: any) => (<MenuItem key={val} value={val}>{val}</MenuItem>))}
                        </Select>
                        <span className="text-light muierror">{this.state.max_error}</span>
                    </FormControl>


                    <ButtonGroup style={{ width: '100%' }}>
                        {this.props.from === undefined ? <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns login-next-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.props.returnHandle({ page: 'location' })}
                            variant="contained">
                            Back
                        </LoadingButton> : ''}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns  login-back-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.setProp()}
                            variant="contained">
                            {this.props.from === undefined ? 'Next' : 'Save'}
                        </LoadingButton>

                    </ButtonGroup>

                </div>
            </div>
        );
    }


}

export default LookingMinMax;