import { Button, Backdrop, CircularProgress, Grid, Slider, Typography, Chip, Stack, ButtonGroup, Box, Tooltip } from '@mui/material';
import axios from 'axios';
import React from 'react';
import variables from '../../global/global';
import TopHeader from '../includes/header';
import Breadcrumb from '../includes/breadcrumb';
import logo from '../../assets/img/logo-pitch.png'
import logoWithout from '../../assets/img/logo-without.png'
import Sendpitch from '../../components/lovediamond/sendpitch';
import { LoadingButton } from '@mui/lab';
import Base64e from '../../components/encodedecode/encode';
import { Navigate } from 'react-router-dom';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BlockIcon from '@mui/icons-material/Block';
import Dialogs from '../../components/dialog/dialog';
import Massages from './Massages';
import Swal from 'sweetalert2';
import HomeIcon from '@mui/icons-material/Home';
import SportsBaseballOutlinedIcon from '@mui/icons-material/SportsBaseballOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import { useLocation } from 'react-router-dom';
class ChatList extends React.Component<any, any>{
  public state = {
    status: 1,
    dialog: false,
    confirm: false,
    deleteId: '',
    priority: 0,

    id: '',
    SendUId: '',
    user: {
      id: '',
    },
    rows: [],
    search: '',
    total: 0,
    limit: 15,
    totalPages: 0,
    curentPage: 0,
    loading: true,
    sendpitch: {
      open: false,
      value: '',
      loading: false,
      id: '',
      column: '',
    },
    blockUser: {
      id: '',
      req_id: '',
    },
    bool: false,
    path: '',
    viewUser: [],
    requestRow: [],
    chatPage: false,
    smallScreen: false,
    breadcrumb: [{
      link: '',
      link_name: 'Messages'
    }]
  }

  public goto = (path: any) => {
    this.setState({
      bool: true,
      path: path,
    });
  }

  public resize = (e: any) => {

    if (e !== null && e.getBoundingClientRect().width <= 820) {
      this.setState({ smallScreen: true });
    } else {
      this.setState({ smallScreen: false });
    }
  }

  public getMassageList = (val: any) => {
    const Page = val;

    let rows: any = this.state.rows;
    axios.post(variables.url + 'ChatList', {
      id: Base64e.encode(this.state.id + ''),
      page: this.state.limit * Page,
      limit: this.state.limit,
      search: this.state.search,
      status: 1,
    }).then((res) => {
      if (res.status === 200) {
        res.data.rows.map((v: any) => {
          v.loading = false;
          v.from = parseInt(v.from);
          v.to = parseInt(v.to);

          rows.push(v);
          return v;
        })

        this.setState({
          rows: rows,
          total: res.data.count,
          totalPages: Math.ceil(res.data.count / this.state.limit),
          curentPage: Page,
          loading: false,
        });

        window.screenTop = 300;
      }
    });
  }

  public removeElement = (index: any) => {
    const rows: any = this.state.rows.filter((_, i) => i !== index);
    this.setState({ rows: rows });
  };

  componentDidMount() {
    
    this.setState({ id: this.props.user().rows[0].id, user: this.props.user().rows[0] });
    const oneChat = window.location.href;
    const url = new URL(oneChat);
    const paramValue = url.searchParams.get("chat");


    setTimeout(() => {
      this.getOneChat(paramValue);
      this.getMassageList(0);
    }, 100);
  }

  componentWillMount(): void {
 

    window.addEventListener('scroll', () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        if (this.state.curentPage <= this.state.totalPages) {
          this.setState({ loading: true });
          this.getMassageList(this.state.curentPage + 1);
        }
      }
    });
  }

  // when use come from notification 
  public getOneChat = (id: any) => {
    if (id !== null && id !== '') {

      this.setState({ loading: true });
      axios.post(variables.url + 'getOneChat', {
        id: id,
        uId: Base64e.encode(this.state.user.id + ''),
      })
        .then((res) => {
          if (res.status === 200 && res.data !== null) {
            res.data.loading = false;
            res.data.from = parseInt(res.data.from);
            res.data.to = parseInt(res.data.to);
            this.openChat(res.data);
          } else {
            this.goto('ChatList');
          }
          this.setState({ loading: false });
        }).catch((e: any) => {
          console.log(e);
          this.setState({ loading: false });
        });

    }
  }


  public openIntrestBox = (value: any) => {
    this.setState({ priority: value.priority, sendpitch: value });

  }


  public blockUser = (id: any, req_id: any) => {

    Swal.fire({
      title: 'Are you sure you want to block this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(variables.url + 'blockUser', {
          user_id: Base64e.encode(this.state.id + ''),
          blocked: Base64e.encode(id + ''),
          req_id: Base64e.encode(req_id + ''),
        })
          .then((res) => {
            if (res.status === 200) {

              this.setState({ rows: [], loading: true });
              setTimeout(() => { this.getMassageList(0); }, 100);





            }
          }).catch((e: any) => {
            console.log(e);
          });


      }

    })
  }
  public getAge = (dateString: any) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }


  public showTooltip = (value: any, classs: any) => {


    let i = 0;
    value.messages.map((mess: any, index: any) => {

      let to: string = mess.to
      if (mess.status == 0 && parseInt(this.state.id) == parseInt(to)) {

        i++
      }
    });
    let title = i > 1 ? i + " new messages received" : i + " new message received";
    return <Tooltip className='messagetootip' color="primary" open={i > 0 ? true : false} title={title} placement="top" arrow >
      <Chip onClick={() => this.openChat(value)} label="View Messages" className={classs} />
    </Tooltip>
  }
  public render() {

    function valuetext(value: number) {
      if (value >= 1 && value <= 24) {
        return "Headed  towards first base!";
      } else if (value >= 25 && value <= 49) {
        return "First base!";

      } else if (value >= 50 && value <= 74) {
        return "Second base!";
      } else if (value >= 75 && value <= 99) {
        return "Third base!";

      } else if (value == 100) {
        return "Home run!";

      }
    }

    function trans(id: any, from: any, priority: any, to_priority: any) {
     
      return id == from ? 100 - priority : 100 - (to_priority ?? 100)
    }



    return (
      <div className='App position-relative' ref={this.resize}>
        {this.state.bool === true ? <Navigate state={this.state} to={"/" + this.state.path} replace={true} /> : ''}
        <TopHeader title="Messages" logo={''} />
        <Breadcrumb breadcrumb={this.state.breadcrumb} chatPage={this.state.chatPage} returnHandler={(e: any) => this.setState({ chatPage: e })} />
        <h5 className='page-title'>Messages</h5>







        <div className='mb-6'>
          {!this.state.chatPage ?
            <div style={{ width: this.state.smallScreen ? '100%' : '94%', margin: 'auto', padding: '10px 0px' }} >
              {this.state.rows[0] !== undefined ? this.state.rows.map((value: any, index) => (
                <div className='row'>

                  {this.state.smallScreen ?
                    <SwipeableList>
                      <SwipeableListItem
                        swipeLeft={{
                          content: <div className="basic-swipeable-list__item-content-left"><span>Remove</span></div>,
                          action: () => this.removeElement(index)
                        }}
                        // swipeRight={{
                        //   content: <div className="basic-swipeable-list__item-content-right"><span>Interested</span></div>,
                        //   action: () => this.openIntrestBox({open:false, value:value})
                        // }}
                        onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}

                      >
                        <div className='col-md-12 msg-list-box mb-2' style={{ background: (index % 2) == 0 ? '#e39c14' : '#e39c14' }}>
                          <div className='row smallscreen'>
                            <div className='col-12 col-md-12 '>
                            <div className="row">
                              <div className='col-7 impadd' onClick={() => this.viewProfile(this.state.id === value.from ? value.user_to : value.user, value.id)}>
                                <div className='row'>
                                  <div className='col-4 p-0'>
                                    <img className='border-rounded' width={'100%'} alt={`uvatar n°${index + 1}`} src={
                                    value !== null && this.state.id === value.from ?
                                      value.user_to.pic :
                                      value !== null && this.state.id === value.to ?
                                        value.user.pic : ''
                                  }  />

                                  </div>
                                  <div className='col-8   name-box pl-1'>
                                  <Typography variant={'h5'}><b>{value.from !== undefined && value.from == this.state.id ? value.user_to.first_name : value.user.first_name}</b></Typography>
                                  <p className='ms-tx '><b>{value.messages[0] != undefined && value.messages[0].message != '' ? <>{this.state.id == value.messages[0].from && value.user.id==this.state.id ?  value.user.first_name+': '  :  value.user_to.first_name+': '}</>:'No messages in chat'}</b>
                                  
                                  {value.messages[0] !== undefined ? value.messages[0].message.substr(0, 80) : ''}</p>
                                  </div>
                                </div>
                              
                              </div>
                              <div className='col-5 name-box pl-1'>
                              <div className='d-flex justify-cotent-center align-items-center'>
                                  <div>


                                    <div className='pos-re-mess'>
                                      <img className='fade-img' src={logoWithout} width={95} alt="logo" />
                                      <img className='heart-img heart-img-mssg' src={logo} width={100} alt="logo" style={{
                                        width: '64px',
                                          WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.to_priority, value.priority) + '%, black 0%, black 90%, transparent 100%)',
                                      maskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.to_priority, value.priority) + '%, black 0%, black 90%, transparent 100%)'
                                      }} />
                                    </div>
                                    <Typography sx={{textAlign:'center', fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} className='nowrap'> Their Interest</Typography>
                                  </div>
                                  <div className='percet'>{this.state.id == value.from ? value.to_priority ?? 0 : value.priority ?? 0}%</div>
                                </div>
                              </div>
                            </div>


                            <div className="row mt-3">
                            <div className='col-7 d-flex align-items-end justify-content-center'>
                           
                            <div className='d-flex flex-column justify-content-between hh-100'>

                              {this.showTooltip(value, 'redbtn redbtn-blue')}
                              <LoadingButton style={{ fontSize: '12px' }} className='redbtn redbtn-blue' loading={this.state.loading} onClick={() => this.openIntrestBox({
                                priority: this.state.id === value.from ? value.priority : value.to_priority,
                                open: true,
                                value: this.state.id === value.from ? value.user_to : value.user,
                                id: value.id,
                                column: this.state.id === value.from ? 'from' : 'to',
                                status: value.status,
                                buttontext:value.status == 2 ? 'Send Pitch' : 'Update Interest'
                              })} variant='contained'>


                                {this.state.id === value.from ?
                                  <>
                                    {value.priority == 0 && value.status == 3 ? 'Hit Back' :

                                      <>
                                        {value.status == 2 ? 'Send Pitch' : 'Update Interest'}
                                      </>
                                    }
                                  </>
                                  :
                                  <>
                                    {value.to_priority == null && value.status == 3 ? 'Hit Back' :

                                      <>
                                        {value.status == 2 ? 'Send Pitch' : 'Update Interest'}
                                      </>
                                    }
                                  </>

                                }



                              </LoadingButton>
                              </div>
                           
                            </div>
                            <div className='col-5 name-box pl-1'>
                   
                            
                                  <div className='d-flex justify-cotent-center align-items-end'>
                                    <div>
                                      

                                      <div className='pos-re-mess'>
                                        <img className='fade-img' src={logoWithout} width={95} alt="logo" />
                                        <img className='heart-img heart-img-mssg' src={logo} alt="logo" style={{
                                          width: '64px',
                                          WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.priority, value.to_priority) + '%, black 0%, black 90%, transparent 100%)',
                                          maskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.priority, value.to_priority) + '%, black 0%, black 90%, transparent 100%)'
                                        }} />
                                      </div>

                                      <Typography sx={{ textAlign:'center', fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} >Your Interest</Typography>

                                    </div>
                                    <div className='percet'>{value.priority}%</div>
                                  </div>
                         
                            </div>
                            
                            </div>         













                            
                            </div>
                          

                          </div>
                        </div>
                      </SwipeableListItem>
                    </SwipeableList>
                    :
                    <div className='col-md-12 msg-list-box mb-2' style={{ background: (index % 2) == 0 ? '#e39c14' : '#e39c14' }}>
                      <div className='row'>
                        <div className='col-md-3 d-flex'>
                          <Grid container>
                            <Grid item className='pl-0' xs={2} md={3}>
                              <img className='border-rounded' width={'100%'} alt={`uvatar n°${index + 1}`} src={
                                value !== null && this.state.id === value.from ?
                                  value.user_to.pic :
                                  value !== null && this.state.id === value.to ?
                                    value.user.pic : ''
                              } />
                            </Grid>

                            <Grid item xs={10} md={9} className='name-box' title="open chat"  >
                              <div>
                                <Typography variant={'h5'} style={{ textAlign: 'left' }}><b>{value.from !== undefined && value.from == this.state.id ? value.user_to.first_name + ', ' + this.getAge(value.user_to.dob.toString()) + ', ' + value.user_to.city : value.user.first_name + ', ' + this.getAge(value.user.dob.toString()) + ', ' + value.user.city}</b></Typography>
                                <p className='ms-tx mt-2'><b>{value.messages[0] != undefined && value.messages[0].message != '' ? <>{this.state.id == value.messages[0].from && value.user.id==this.state.id ?  value.user.first_name+': '  :  value.user_to.first_name+': '}</>:'No messages in chat'}</b>
                                  
                                  {value.messages[0] !== undefined ? value.messages[0].message.substr(0, 80) : ''}</p>
                              </div>
                              {this.showTooltip(value, 'graybtn')}
                              {/* <Chip onClick={()=>this.openChat(value)} label="View Messages" className='graybtn' /> */}
                            </Grid>
                          </Grid>
                        </div>
                        <div className='col-md-9 position-relative'>
                          <Grid container className='align-items-end'>
                            <Grid xs={3} sx={{ display: { xs: "none", md: "block" } }} title="View Profile" className={'font-60'} md={2} onClick={() => this.viewProfile(this.state.id === value.from ? value.user_to : value.user, value.id)}><AccountCircleOutlinedIcon /><br /><Chip label="View Profile" className='graybtn mt-2' /></Grid>
                            {value.status == 1 ?
                            <Grid xs={3} sx={{ display: { xs: "none", md: "block" } }} title="Unmatch" className={'font-60'} md={2} onClick={() => this.unmatchUser(this.state.id === value.from ? value.user_to.id : value.user.id, value.id)}><ClearIcon color='error' /><br /><Chip label="Unmatch" color="primary" className='graybtn mt-2' /></Grid>
                            :''}
                            <Grid xs={3} sx={{ display: { xs: "none", md: "block" } }} title="Block" className={'font-60'} md={2} onClick={() => this.blockUser(this.state.id === value.from ? value.user_to.id : value.user.id, value.id)}><BlockIcon color='error' /><br /><Chip label="Block" color="primary" className='graybtn mt-2' /></Grid>
                            <Grid xs={4} className={'font-40'} md={3}>
                              <div className='d-flex justify-cotent-center align-items-center'>
                                <div>
                                  <Typography sx={{ textAlign:'center', fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} >Your Interest</Typography>

                                  <div className='pos-re-mess'>
                                    <img className='fade-img' src={logoWithout} width={120} alt="logo" />
                                    <img className='heart-img heart-img-mssg' src={logo} alt="logo" style={{
                                      width: '75px',
                                      WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.priority, value.to_priority) + '%, black 0%, black 90%, transparent 100%)',
                                      maskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.priority, value.to_priority) + '%, black 0%, black 90%, transparent 100%)'
                                    }} />
                                  </div>
                                  <LoadingButton style={{ fontSize: '12px' }} className='redbtn' loading={this.state.loading} onClick={() => this.openIntrestBox({
                                    priority: this.state.id === value.from ? value.priority : value.to_priority,
                                    open: true,
                                    value: this.state.id === value.from ? value.user_to : value.user,
                                    id: value.id,
                                    column: this.state.id === value.from ? 'from' : 'to',
                                    status: value.status,
                                    buttontext:value.status == 2 ? 'Send Pitch' : 'Update Interest'
                                  })} variant='contained'>


                                    {this.state.id === value.from ?
                                      <>
                                        {value.priority == 0 && value.status == 3 ? 'Hit Back' :

                                          <>
                                            
                                            {value.status == 2 ? 'Send Pitch' : 'Update Interest'}
                                          </>
                                        }
                                      </>
                                      :
                                      <>
                                        {value.to_priority == null && value.status == 3 ? 'Hit Back' :

                                          <>
                                            {value.status == 2 ? 'Send Pitch' : 'Update Interest'}
                                          </>
                                        }
                                      </>

                                    }



                                  </LoadingButton>


                                </div>
                                <div className='percet mb-5'>{this.state.id === value.from ? value.priority ?? 0 : value.to_priority ?? 0}%</div>
                              </div>



                              {/* <Typography sx={{  fontSize: { xs: "14px", md: "22px" }}}   variant={'h6'} >{this.state.id===value.from ? valuetext(value.priority) : valuetext(value.to_priority)}&nbsp;</Typography>  */}







                            </Grid>
                            <Grid item xs={4} md={3} className='h-235 h-235-mssg'>
                              <div className='d-flex justify-cotent-center align-items-center'>
                                <div>
                                  <Typography sx={{textAlign:'center', fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} > Their Interest</Typography>

                                  <div className='pos-re-mess'>
                                    <img className='fade-img' src={logoWithout} width={120} alt="logo" />
                                    <img className='heart-img heart-img-mssg' src={logo} width={100} alt="logo" style={{
                                      width: '75px',
                                      WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.to_priority, value.priority) + '%, black 0%, black 90%, transparent 100%)',
                                      maskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.to_priority, value.priority) + '%, black 0%, black 90%, transparent 100%)'
                                    }} />
                                  </div>
                                </div>
                                <div className='percet'>{this.state.id == value.from ? value.to_priority ?? 0 : value.priority ?? 0}%</div>
                              </div>
                            </Grid>

                          </Grid>
                          <ClearIcon className='clear-ic' onClick={()=>this.removeElement(index)}/>
                        </div>

                      </div>
                    </div>
                  }

                </div>
              )) : !this.state.loading ?
                <>
                  <div className='py-5 px-2'>
                    <Box className='msspopup msspopup2' style={{ pointerEvents: 'all' }}>
                      <div>
                        <div className="row position-relative">
                          <div className="col-md-12"> <Typography className='text-center color-pink' variant='h4'>Messages</Typography></div>
                          <div className="col-md-12 iocdiv"> </div>
                        </div>

                        <Typography className='text-center' variant='h5'>You have no messages</Typography>
                        <Typography className='text-center' variant='h6' >Update your <a href="/profile"><span className="color-pink">profile</span></a> to stand out or go to  <a href="/matches"><span className="color-pink">send pitches</span></a> to meet new singles</Typography>
                      </div>
                    </Box>

                  </div>
                </>
                : ''}
            </div> : <Massages user={this.props.user} state={this.state} returnHandler={(e: any) => this.setState(e)} />}

          {this.state.sendpitch.open ? <Sendpitch from={'massages-list'} removeRequestedUser={(e: any) => this.removeuser(e)} returnhandle={(e: any) => this.setState(e)} data={this.state} /> : ''}

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </div>
    )

  }



  public openChat = (val: any) => {
    console.log('vvvv', val);

    this.setState({ chatPage: true, requestRow: val });
  }



  public viewProfile = (v: any, row_id: any) => {
    const Eid = Base64e.encode(v.id + '');
    const Rid = Base64e.encode(row_id + '');
    this.setState({ path: 'MatchesProfile/' + Eid + '/' + Rid, bool: true });

  }

  public unmatchUser = (v: any, row_id: any) => {

    Swal.fire({
      title: 'Are you sure you want to unmatch this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios.post(variables.url + 'unmatchUser', {
          req_id: Base64e.encode(row_id + ''),
        })
          .then((res) => {
            if (res.status === 200) {

              window.location.reload();



            }
          }).catch((e: any) => {
            console.log(e);
          });
      }

    });

  }
  public removeuser = (i: any) => {
    const sendpitch = { open: false };
    this.setState({ sendpitch: sendpitch, rows: [] });
    setTimeout(() => { this.getMassageList(0); }, 100);

  }

  public strikeout = (id: any, strikeout: any) => {

    this.loading(strikeout, true);
    axios.post(variables.url + 'strikeout', {
      strike: strikeout,
      id: Base64e.encode(id + '')
    })
      .then((res) => {
        if (res.status === 200) {
          this.loading(strikeout, false);
          this.setState({ rows: [], loading: true });
          setTimeout(() => { this.getMassageList(0); }, 100);
        }
      }).catch((e: any) => {
        console.log(e);
      });

  }


  public loading = (id: any, bool: boolean) => {

    const final = this.state.rows.map((v: any) => {
      if (v.id === id) {
        v.loading = bool;
      }
      return v;
    });
    this.setState({ rows: final });
  }

}
function ChatListWithLocation(props: any) {
  const location = useLocation();

  // Render the class-based component passing location as props
  return <ChatList {...props} location={location} />;
}

export default ChatListWithLocation;
