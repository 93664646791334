import React from "react"
import { ButtonGroup, Backdrop, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Footer from "../footer/Footer";
import Base64e from "../encodedecode/encode";
import axios from "axios";
import variables from "../../global/global";
import parse from 'html-react-parser';

class TermConditions extends React.Component<any>{
  public state = {
    agree: this.props.sendprop.termcondition,
    submit: this.props.sendprop.termcondition ? false : true,
    content: '',
    content2: '',
    loading: true
  }

  // constructor(props:any) {
  //     super(props)


  // }

  public checked = (e: any) => {
    if (e.target.checked) {
      this.setState({ submit: false });
      this.props.returnHandle({ termcondition: true });
    } else {
      this.setState({ submit: true });
      this.props.returnHandle({ termcondition: false });
    }

  }

  public signupFinish = () => {

    this.props.returnHandle({ register: true, loading: true });
  }
  public open = (num: any) => {
    const x = Base64e.encode(num + '');
    window.open('https://www.slammmm.com/page/' + x);
  }
  public openurl = (num: any) => {

    window.open('https://www.slammmm.com/' + num);
  }

  componentDidMount(): void {

    axios.post(variables.url + 'getPage', { id: Base64e.encode(2 + '') })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ content: res.data.content });
          this.setState({ loading: false });
        }
      }).catch((e: any) => {

      });

    axios.post(variables.url + 'getPage', { id: Base64e.encode(1 + '') })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ content2: res.data.content });
        }
      }).catch((e: any) => {
        console.log(e);

      });
  }


  render(): React.ReactNode {
    return (
      <div className="container main">
        <h3 className="d-block text-center" ><span style={{ cursor: 'pointer' }} onClick={() => this.openurl('privacy-policy')}>T<span className=" ">e</span>rms & C<span className=" ">o</span>ndit<span className=" ">i</span>ons</span> / <span style={{ cursor: 'pointer' }} onClick={() => this.openurl('terms-and-conditions')}>Privacy Policy</span></h3>
        <div className="d-block m_auto col-md-12 text-center" >
          <div className="terms-conditions" style={{ height: '250px', width: '100%', overflowY: 'scroll' }} >
            {parse(this.state.content)}
            {parse(this.state.content2)}
          </div>


          <label className="term-check" style={{ fontSize: '17px' }}>
            <input type='checkbox' required={true} onChange={(e) => this.checked(e)} checked={this.props.sendprop.termcondition} />
            &nbsp;&nbsp;&nbsp;I agree to the terms & conditions / privacy policy
          </label>
        </div>
        <ButtonGroup style={{ width: '100%' }}>
          <LoadingButton
            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
            type="submit"
            size="large"
            disabled={false}
            className="btns login-next-button "
            loading={false}
            loadingPosition="center"
            onClick={() => this.props.returnHandle({ page: 'password' })}
            variant="contained">
            Back
          </LoadingButton>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <LoadingButton
            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
            type="submit"
            size="large"
            disabled={this.state.submit}
            className="btns login-back-button"
            loading={this.props.sendprop.loading}
            loadingPosition="center"
            onClick={() => this.signupFinish()}
            variant="contained">
            Submit
          </LoadingButton>

        </ButtonGroup>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }


}

export default TermConditions;