import { Backdrop, Box, Button, CircularProgress, Divider, Fade, Grid, Modal, Typography } from '@mui/material';
import React from 'react';
import TopHeader from '../includes/header';
import Breadcrumb from '../includes/breadcrumb';
import { Navigate } from 'react-router-dom';
import ProfileUpdate from '../../components/profile/updateField';
import { LoadingButton } from '@mui/lab';
import Base64e from '../../components/encodedecode/encode';
import variables from '../../global/global';
import axios from 'axios';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSignIn } from 'react-auth-kit';
import Cardupdate from '../../components/profile/cardupdate';
import { useLocation } from 'react-router-dom';


class Subscription extends React.Component<any>{

  // constructor(props:any) {
  //     super(props)
  // }

  public state = {
    trialStatus: false,
    user: this.props.user().rows[0],
    customer: { card: { last4: 'N/A' } },
    subscription_details: { paymentSchedule: { startDate: '' } },
    subscription: {
      creditCard: { cardNumber: 'N/A' },
      current_period_end: '',
      cancel_at_period_end: false,
      canceled_at: '',
    },
    activepic: 0,
    bool: false,
    path: '',
    loading: false,
    load: true,
    open: false,
    editCard: false,
    editField: false,
    success: '',
    msg: '',
    modal: {
      title: '',
      sub: '',
      sub1: '',
      ok: '',
      cancle: '',
    },
    modalAfterResponse: {
      title: '',
      sub: '',
      sub1: '',
    },
    edit: {
      type: '',
      value: '',
      id: '',
    },
    breadcrumb: [{
      link: '/settings',
      link_name: 'Settings'
    },
    {
      link: '',
      link_name: 'Subscription'
    },
    ]
  }


  componentDidMount(): void {

    const queryParams = new URLSearchParams(this.props.location.search);
    const openParam = queryParams.get('open');
    if (openParam == '1') {
      this.openModal(2);
    }
    const currentDateCheck = new Date();
    const targetDateCheck = new Date(this.state.user.payments[0].start_date);

    if (targetDateCheck <= currentDateCheck) {
      console.log('Target date is not greater than or equal to the current date.');
      this.setState({ trialStatus: true });
    } else {
      console.log('Target date is greater than the current date.');
      this.setState({ trialStatus: false });
    }


    this.setState({ loading: true });
    const idx = Base64e.encode(this.state.user.id + '');
    const trans = (this.state.user.payments[0].transaction_id != null) ? Base64e.encode(this.state.user.payments[0].transaction_id) : '';
    const cus = (this.state.user.payments[0].s_customer_id != null) ? Base64e.encode(this.state.user.payments[0].s_customer_id) : '';
    const pm = (this.state.user.payments[0].cardId != null) ? Base64e.encode(this.state.user.payments[0].cardId) : '';
    axios.post(variables.url + 'getSubscription', { id: idx, sub: trans, cus: cus, pm: pm })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);

          this.setState({ loading: false, load: false });

          console.log(res.data.subscription);
          console.log(res.data.details);
          if (res.data.subscription != undefined) {
            // this.setState({customer:res.data.customer});
            this.setState({ subscription: res.data.subscription });


          }
          if (res.data.details != undefined && res.data.details != '') {

            this.setState({ subscription_details: res.data.details });
          }

        }
      }).catch((e: any) => {
        console.log(e);
        this.setState({ msg: 'server error', loading: false, load: false });

      });

    if (localStorage.getItem('open_planpopup') == 'true') {
      this.setState({
        edit: {
          type: 'plan',
          value: 'ok',
          id: 'ok',
        }, editField: true
      });
    }

  }
  public setData = (r: any) => {

    this.setState(r);
    window.history.pushState('test', 'subscription', r.page);

  }


  public render() {
    console.log(this.state);

    return (
      <div className='App' >
        {this.state.bool === true ? <Navigate state={this.state} to={"/" + this.state.path} replace={true} /> : ''}
        <TopHeader title="Subscription Details" logo={''} />
        <Breadcrumb breadcrumb={this.state.breadcrumb} />
        <br />
        <div className='setting_ m-4'>
          <Grid container >

            <Grid item xs={12} md={5}>
              <Grid container spacing={2} style={{ textAlign: 'left' }}>

                <Grid item xs={6} md={6}>
                  <Typography variant='h6'  ><b>Current Subscription  </b></Typography>
                  <Typography variant='h6'  ><b> Next Billing Date </b></Typography>
                  <Typography variant='h6'  ><b>Billing Amount  </b></Typography>
                  <Typography variant='h6'  ><b>Credit Card on File </b> </Typography>
                </Grid>
                <Grid item xs={6} md={6} className='susdetails'>
                  <Typography variant='h6'  > {this.state.user.payments[0].plane.title}</Typography>
                  <Typography variant='h6'  >
                    {this.state.user.payments[0].plane.id == 4 ? <>{!this.state.trialStatus? new Date(this.state.user.payments[0].start_date).toLocaleDateString() : 'N/A'}</> :
                      <>{this.state.user.payments[0].start_date != '' ?
                          new Date(this.state.user.payments[0].start_date).toLocaleDateString()
                          : <> {this.state.user.payments[0].trial_end ? new Date(this.state.user.payments[0].trial_end).toLocaleDateString() : 'N/A'} </>
                        } </>

                      
                      
                    }
                  </Typography>
                  <Typography variant='h6'  >{this.state.user.payments[0].transaction_id == null || this.state.user.payments[0].transaction_id == '' ? '$' + this.state.user.payments[0].plane.amount : '$' + this.state.user.payments[0].plane.amount}</Typography>
                  <Typography variant='h6'  >{Object.keys(this.state.subscription).length > 0 ? this.state.subscription.creditCard.cardNumber : 'N/A'} <Button className='w_40  m-0' color='secondary' size='medium' variant='text' onClick={() => this.setState({ editCard: true })}><i className='fas iconEdit blue'>&#xf044;</i></Button></Typography>
                </Grid>

                <Grid xs={6} md={6} className='setting-btns pb-3'> <br /><LoadingButton onClick={() => this.setState({ bool: true, path: 'settings' })} className='w_40 text-lowercase ml-2 btns purple-btn' color='secondary' size='medium' variant='contained'><i className='fas iconEdit'>&#xf085;</i> Settings</LoadingButton></Grid>
                <Grid xs={6} md={6} className='setting-btns pb-3'> <br /><LoadingButton className='w_40 text-lowercase mr-2 btns purple-btn' onClick={() => this.openModal(2)} color='secondary' size='medium' variant='contained'><i className='fas iconEdit'>&#xf362;</i> Change Subscription </LoadingButton></Grid>
                {/* <Grid  xs={4} md={4}><br/>
        {this.state.subscription.cancel_at_period_end ?
       <LoadingButton  className='w_40 text-lowercase' disabled  color='error' size='medium'variant='contained'> Canceled </LoadingButton>
        :
        <LoadingButton  className='w_40 text-lowercase' onClick={()=>this.openModal(3)}  color='error' size='medium'variant='contained'><i  className='fas iconEdit'>&#xf2f5;</i> Cancel </LoadingButton>
        }
        </Grid> */}

              </Grid>
            </Grid>

          </Grid>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.load}>
            <CircularProgress color="inherit" />
          </Backdrop>

          {/*card */}
          <Elements stripe={loadStripe(variables.stripKey)}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <Cardupdate stripe={stripe} sign={useSignIn()} elements={elements} returnHandle={(r: any) => this.setData(r)} sendprop={this.state} />
              )}
            </ElementsConsumer>
          </Elements>
          {/* end card */}

          {this.state.editField ? <ProfileUpdate data={this.state} returnhandle={(e: any) => this.setState(e)} /> : ''}

          <Modal

            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{ pointerEvents: 'none' }}
          >
            <Fade in={this.state.open} >
              <Box className='modal_confirmbox' style={{ pointerEvents: 'all' }}>
                {this.state.success === '' ? <div><Typography className='text-center' variant='h4'>{this.state.modal.title}</Typography>
                  <Typography className='text-center' variant='h6'>{this.state.modal.sub}</Typography>
                  <Typography className='text-center' variant='h6'>{this.state.modal.sub1}</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <LoadingButton
                        style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                        type="submit"
                        size="large"
                        disabled={false}
                        className="login-button"
                        color='success'
                        loading={this.state.loading}
                        loadingPosition="center"
                        onClick={() => this.cancledAction()}
                        variant="contained">
                        {this.state.modal.ok}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <LoadingButton
                        style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                        type="submit"
                        size="large"
                        disabled={false}
                        className="login-button"
                        color='error'
                        loading={false}
                        loadingPosition="center"
                        onClick={() => this.setState({ open: false })}
                        variant="contained">
                        {this.state.modal.cancle}
                      </LoadingButton>
                    </Grid>
                  </Grid></div>
                  :
                  <div style={{ textAlign: 'center' }}>
                    <Typography className='text-center' variant='h4'>{this.state.modalAfterResponse.title}</Typography>
                    <Typography className='text-center' variant='h6'>{this.state.modalAfterResponse.sub}</Typography>
                    <Typography className='text-center color-pink' variant='h6'>{this.state.modalAfterResponse.sub1}</Typography>
                    {

                      <LoadingButton
                        style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                        type="submit"
                        size="large"
                        disabled={false}
                        className="login-button"
                        color='secondary'
                        loading={false}
                        loadingPosition="center"
                        onClick={() => { window.location.reload() }}
                        variant="contained">
                        Login
                      </LoadingButton>

                    }
                  </div>
                }
              </Box>
            </Fade>
          </Modal>

        </div>
      </div>

    )
  }




  public openModal = (i: number) => {
    if (i === 3) {
      const modal = {
        title: 'Are you sure you want to cancel your subscription? ',
        sub: 'This cannot be undone',
        sub1: '',
        ok: 'Yes, confirm cancellation',
        cancle: 'No, I want to keep my subscription',
      }
      this.setState({ modal: modal, open: true, deleteType: i });
    } else {

      this.setState({
        edit: {
          type: 'plan',
          value: 'ok',
          id: 'ok',
        }, editField: true
      });
    }

  }

  public cancledAction = () => {
    let modal: any = {};
    this.setState({ loading: true });
    const idx = Base64e.encode(this.state.user.id + '');
    const trans = Base64e.encode(this.state.user.payments[0].transaction_id);
    axios.post(variables.url + 'cancleSubscription', { id: idx, sub: trans })
      .then((res) => {
        if (res.status === 200) {
          modal = {
            title: 'Your paid subscription has been cancelled. ',
            sub: 'You still have access to paid features until your next billing date.',
            sub1: 'After next billing date, you will only be able to access free site features.',
          }
          this.setState({ success: 'Subscription Cancled', loading: false, modalAfterResponse: modal });
        }
      }).catch((e: any) => {
        console.log(e);
        this.setState({ msg: 'server error', loading: false });

      });

  }



}

function SubscriptionWithLocation(props: any) {
  const location = useLocation();

  // Render the class-based component passing location as props
  return <Subscription {...props} location={location} />;
}

export default SubscriptionWithLocation;