import { LoadingButton } from "@mui/lab";
import axios from "axios";
import React from "react"
import variables from "../global/global";
import DoneIcon from '@mui/icons-material/Done';
import { Backdrop, CircularProgress, Dialog } from "@mui/material";
import successImg from '../assets/img/load.gif';
import { log } from "console";
import { withSignIn } from 'react-auth-kit';
import Swal from 'sweetalert2';
import { format } from "date-fns";
import Cardupdate from '../components/profile/cardupdate';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSignIn } from 'react-auth-kit';
import ClearIcon from '@mui/icons-material/Clear';

class Plans extends React.Component<any> {
    public state = {
        user: this.props.sendprop.register_res,
        go: false,
        page: '#',
        loading: false,
        plans: [],
        modal: false,
        payerror: '',
        success: false,
        formData: { email: this.props.sendprop.email, password: this.props.sendprop.password },
        error: '',
        editCard: false,
        selected_plan: '',
        daysLeft: 0,
    }
    // constructor(props:any) {
    //     super(props);

    // }

    componentDidMount(): void {
        if (this.props.sendprop.page_c == 'signup') {
            this.autoLogin();
        } else {
            this.getPlans();
            console.log(this.props.sendprop);
            localStorage.setItem("open_planpopup", '');
        }

        if (Object.keys(this.props.sendprop.register_res).length > 0 && this.props.sendprop.register_res.payments[0].trial_end != null && this.props.sendprop.register_res.payments[0].trial_end != '') {
            const currentDate = new Date();
            const daysLeft = this.getDayDifference(this.props.sendprop.register_res.payments[0].trial_end, currentDate);
            this.setState({ daysLeft: daysLeft });
        }




    }
    // Function to calculate the difference in days between two dates
    public getDayDifference = (date1: any, date2: any) => {
        // Convert both dates to milliseconds
        const date1Ms = new Date(date1).getTime();
        const date2Ms = new Date(date2).getTime();

        // Calculate the difference in milliseconds
        const differenceMs = date1Ms - date2Ms;

        // Convert milliseconds to days
        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

        return differenceDays;
    };
    public goto = (page: any) => {

        this.setState({ go: true, page: page });

    }


    public getPlans = () => {
        console.log(this.props.sendprop);
        axios.get(variables.url + 'getUserPlans')
            .then((res) => {
                if (res.status === 200) {
                    if (this.props.from === 'edit') {

                        const rw = res.data.rows;

                        this.setState({ plans: rw });

                    } else {
                        this.setState({ plans: res.data.rows });
                    }

                }
            });
    }

    public makepayment = async (v: any) => {

        console.log('this.props.sendprop', this.props.sendprop);
        if (Object.keys(this.props.sendprop.register_res).length > 0 && this.props.sendprop.page_c != "signup") {
            let trial_end = this.props.sendprop.register_res.payments[0].trial_end;
            let card = this.props.sendprop.register_res.payments[0].cardId;



            const date1 = new Date();
            const date2 = new Date(trial_end);
            const diffTime = date2.valueOf() - date1.valueOf();
            console.log(diffTime);
            var monthcount = v.title == 'MVP' ? '6 months' : 'month';
            let title;
            if (diffTime <= 0) {
                title = "Are you sure you want to upgrade your subscription to " + v.title + " ($" + v.amount + "/" + monthcount + ") starting  " + format(new Date(), 'MM/dd/yyyy') + "?"
            } else {
                title = "Are you sure you want to upgrade your subscription to " + v.title + " ($" + v.amount + "/" + monthcount + ") starting  " + format(new Date(trial_end), 'MM/dd/yyyy') + "?";
            }

            Swal.fire({
                title: title,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    if (card != null && card != '') {


                        if (this.props.from === 'edit') {
                            this.setState({ loading: true });
                            await axios.post(variables.url + 'authrizepayment', {
                                cardData: {},
                                plan: {
                                    selected_plan: v,
                                    user_id: this.props.sendprop.register_res.id
                                },
                                from: this.props.from
                            }).then((res) => {
                                if (res.status === 200 && res.data.statusCode === undefined) {
                                    console.log('makepayment', res.data);
                                    localStorage.setItem('_auth_state', JSON.stringify(res.data.user));
                                    this.setState({ loading: false, success: true });
                                    setTimeout(() => {
                                        //  window.location.reload();
                                        const currentUrl = window.location.href;
                                        window.location.href = currentUrl.replace('?open=1', "");
                                    }, 1000);

                                } else {
                                    this.setState({ payerror: 'Payment failed. check your update your card details and try again.', loading: false });
                                }
                            }).catch((e: any) => {
                                console.log(e);


                                this.setState({ load: false });
                                alert('Error -> ' + e.response.data);
                            });

                        } else {

                            this.props.returnHandle({ page: 'checkout', selected_plan: v });
                        }

                    } else {
                        this.setState({ editCard: true, selected_plan: v })
                    }
                }

            });

        } else {
            this.props.returnHandle({ page: 'checkout', selected_plan: v });
        }

    }


    public saveFreePlan = async (v: any) => {


        if (Object.keys(this.props.sendprop.register_res).length > 0 && this.props.sendprop.page_c != "signup") {
            console.log(this.props.sendprop.register_res.payments[0].trial_end);
            let trial_end = this.props.sendprop.register_res.payments[0].trial_end;
            const date1 = new Date();
            const date2 = new Date(trial_end);
            const diffTime = date2.valueOf() - date1.valueOf();
            const diffDays = diffTime / (1000 * 60 * 60 * 24);
            var title;


            if (diffTime <= 0) {
                title = "you want to change your subscription to Minor League ($0/month)."
            } else {
                title = "you want to change your subscription to Minor League ($0/month) after the free trial which ends " + format(new Date(this.props.sendprop.register_res.payments[0].trial_end), 'MM/dd/yyyy');
            }

            Swal.fire({
                title: 'Are you sure ' + title,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    this.setState({ loading: true });
                    await axios.post(variables.url + 'authrizepayment', {
                        cardData: {},
                        plan: {
                            selected_plan: v,
                            user_id: this.props.sendprop.register_res.id
                        },
                        from: this.props.from
                    }).then((res) => {
                        if (res.status === 200 && res.data.statusCode === undefined) {
                            console.log('data', res.data);
                            if (this.props.sendprop.page_c == 'signup') {
                                this.autoLogin();
                            } else {
                                localStorage.setItem('_auth_state', JSON.stringify(res.data.user));
                                this.setState({ loading: false, success: true });
                                setTimeout(() => { 
                                    
                                    //window.location.reload();

                                    const currentUrl = window.location.href;
                                    window.location.href = currentUrl.replace('?open=1', "");
                                
                                
                                }, 1000);
                            }

                            //this.props.returnHandle({go:true, page:'signin'})

                        } else {
                            this.setState({ payerror: 'Payment failed. check your update your card details and try again.', loading: false });
                        }
                    }).catch((e: any) => {
                        console.log(e);

                        alert('failed');
                        this.setState({ load: false });
                    });


                }
            });
        } else {


            this.setState({ loading: true });
            await axios.post(variables.url + 'authrizepayment', {
                cardData: {},
                plan: {
                    selected_plan: v,
                    user_id: this.props.sendprop.register_res.id
                },
                from: this.props.from
            }).then((res) => {
                if (res.status === 200 && res.data.statusCode === undefined) {

                    if (this.props.sendprop.page_c == 'signup') {
                        this.autoLogin();
                    } else {
                        localStorage.setItem('_auth_state', JSON.stringify(res.data.user));
                        this.setState({ loading: false, success: true });
                        setTimeout(() => { window.location.reload(); }, 1000);
                    }

                    //this.props.returnHandle({go:true, page:'signin'})

                } else {
                    this.setState({ payerror: 'Payment failed. check your update your card details and try again.', loading: false });
                }
            }).catch((e: any) => {
                console.log(e);

                alert('faild');
                this.setState({ load: false });
            });
        }

    }


    public autoLogin = () => {

        this.setState({ load: true });

        axios.post(variables.url + 'Userlogin', this.state.formData)
            .then((res) => {
                this.setState({ load: false });
                if (res.status === 200 && res.data.error === '') {

                    if (this.props.signIn({
                        token: res.data.token,
                        expiresIn: res.data.expiresIn,
                        tokenType: "Bearer",
                        authState: res.data.authUserState,
                        refreshToken: res.data.refreshToken,                    // Only if you are using refreshToken feature
                        refreshTokenExpireIn: res.data.refreshTokenExpireIn
                    })) { // Only if you are using refreshToken feature
                        this.props.returnHandle({ go: true, page: 'Dashboard' });
                    }
                } else if (res.data.error !== '') {

                    Swal.fire({
                        icon: "error",
                        title: '',
                        text: res.data.error || 'server error.',
                        confirmButtonColor: '#7b1fa2',
                    }).then(() => {

                        this.props.returnHandle({ go: true, page: 'signin' });
                    });

                } else {
                    alert('something goes wrong!');
                }
            }).catch(() => {
                this.setState({ load: false });
                Swal.fire({
                    icon: "error",
                    title: '',
                    text: 'server error.',
                    confirmButtonColor: '#7b1fa2',
                }).then(() => {

                    this.props.returnHandle({ go: true, page: 'signin' });
                });


            });
    }

    public setData = (r: any) => {

        this.setState(r);
        window.history.pushState('test', 'subscription', r.page);

    }
    public render() {

        return (
            <>{this.props.sendprop.page_c == 'signup' ? ''
                :
                <div className="container main pb-5">
                    {this.props.from === 'edit' ?
                        <div className="row position-relative">
                            <div className="col-md-12"><h3 className="heading f-35" style={{ textAlign: 'center' }}>{this.props.sendprop.title}</h3></div>
                            <div className="col-md-12 iocdiv"> <ClearIcon onClick={() => this.props.returnHandle({ editField: false, open: false })} className='clearicon' /></div>
                        </div>
                        : ''}

                    <h6 style={{ textAlign: 'center', color: 'red' }}>{this.state.payerror}</h6>
                    <div className="row scrollable">
                        {this.state.plans.map((v: any, index: any) => (
                            <div key={v.title + 'm'} className="col-md-4 ">
                                <div className={'card plan-box plan-box-' + index} >
                                    <h4 className="first-heading">{v.title != 'Minor League' ? 'Full' : ''} Access to</h4>
                                    <ul className="planeUl">
                                        {v.features !== null ? JSON.parse(v.features).map((j: any, i: any) => (
                                            j.text !== '-' ? <li key={i + 'v'} className=""><h5>&nbsp;{j.text}</h5></li> : <li>&nbsp;</li>
                                        )) : ''}
                                    </ul>
                                    {(Object.keys(this.props.sendprop.register_res).length > 0 && this.props.sendprop.page_c != "signup" && this.props.sendprop.register_res.payments[0].plan_id == v.id && this.state.daysLeft <= 0) ?

                                        <LoadingButton
                                            style={{ marginTop: '10px' }}
                                            type="submit"
                                            size='large'
                                            disabled={false}
                                            className="btns plan-button-success plan-button-4"
                                            loading={v.loading}
                                            loadingPosition="center"
                                            variant="contained"
                                            color="success"
                                        >
                                            Your  Active Plan
                                        </LoadingButton>

                                        : <LoadingButton
                                            style={{ marginTop: '10px' }}
                                            type="submit"
                                            size='large'
                                            disabled={false}
                                            className={'btns plan-button plan-button-' + index}
                                            loading={v.loading}
                                            loadingPosition="center"
                                            onClick={v.id == 4 ? () => this.saveFreePlan(v) : () => this.makepayment(v)}
                                            variant="contained">
                                            {v.title}
                                        </LoadingButton>
                                    }
                                </div>
                            </div>))}


                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute' }} open={this.state.loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>

                        <Dialog open={this.state.success}>
                            <img alt="img" src={successImg} width='100%' />
                        </Dialog>
                    </div>
                    {/*card */}
                    <Elements stripe={loadStripe(variables.stripKey)}>
                        <ElementsConsumer>
                            {({ stripe, elements }) => (
                                <Cardupdate stripe={stripe} sign={useSignIn()} elements={elements} returnHandle={(r: any) => this.setData(r)} sendprop={this.state} />
                            )}
                        </ElementsConsumer>
                    </Elements>
                    {/* end card */}


                </div>
            }
            </>

        )
    }

}


export default withSignIn(Plans);