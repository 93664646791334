import React from 'react';
import { useState, useEffect } from "react";
import './App.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/css/main.css'
import './assets/css/main_new.css'
import lineimg from './assets/img/Line_for_home_page.png'
import ball from './assets/img/Baseball-1.png'
import hit from './assets/img/Hits-in-white-o.png'
import message from './assets/img/Message-in-white.png'
import profile from './assets/img/Profile-in-white.png'
import setting from './assets/img/Setting-in-white.png'
import { Button, ButtonGroup, Typography, Badge, Modal, Box, Fade } from '@mui/material';
import TopHeader from './pages/includes/header';
import { useNavigate } from 'react-router-dom';
import { useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import variables from './global/global';
import Base64e from './components/encodedecode/encode';
import Titles from './global/hometitle';
import SportsBaseballOutlinedIcon from '@mui/icons-material/SportsBaseballOutlined';
import SportsCricketOutlinedIcon from '@mui/icons-material/SportsCricketOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import Swal from 'sweetalert2';



const App = (data: any) => {
  const [msg, setMsg] = React.useState({ message_count: '0', text: 'messages' });
  const [request, setRequest] = React.useState({ request_count: '0', text: 'pitches' });
  const [update_interest_count, setUpdateInterestCount] = React.useState({ count: 0, text: 'interests' });
  const [match_accept_count, setMatchAcceptCount] = React.useState({ count: 0, text: 'interests' });
  const [profileCompleted, setprofileCompleted] = React.useState(0);
  const [greet, setgreet] = React.useState('');
  const [open, setopen] = React.useState(false);
  const [title, settitle] = React.useState('Messages');
  const [sub, setsub] = React.useState('You have no messages');
  const [sub1, setsub1] = React.useState('Update your <a href="/profile"><span class="color-pink">profile</span></a> to stand out or go to  <a href="/matches"><span class="color-pink">send pitches</span></a> to meet new singles');
  const navigate = useNavigate();
  const user: any = useAuthUser();

  const openModal = (i: number) => {
    if (i === 1) {
      if (msg.message_count != '0') {
        navigate('/ChatList')
      } else {
        settitle('Messages');
        setsub('You have no messages');
        setsub1('Update your <a href="/profile"><span class="color-pink">profile</span></a> to stand out or go to  <a href="/matches"><span class="color-pink">send pitches</span></a> to meet new singles');
        setopen(true);
      }

    }
    if (i === 2) {
      if (request.request_count != '0') {
        navigate('/activepitches')
      } else {
        
        settitle('Active Hits');
        setsub('You have no new hits');
        setsub1('Update your <a href="/profile"><span class="color-pink">profile</span></a> to stand out or go to  <a href="/matches"><span class="color-pink">send pitches</span></a> to meet new singles');
        setopen(true);
      }

    }

  }




  const Greetings = () => {


    let myDate = new Date();
    let hours = myDate.getHours();
    let greet;

    if (hours < 12)
      greet = "Morning";
    else if (hours >= 12 && hours <= 17)
      greet = "Afternoon";
    else if (hours >= 17 && hours <= 24)
      greet = "Evening";

    return <span>Good {greet}, {user().rows[0].first_name}</span>


  }




  const getUnreadMessage = async () => {

    axios.post(variables.url + 'getUnreadMessage', {
      uId: Base64e.encode(user().rows[0].id + ''),
    })
      .then((res:any) => {
        if (res.status === 200 && res.data !== null) {
          if (res.data.count == 1) {
            setMsg({ message_count: res.data.count, text: 'message' })
          } else {
            setMsg({ message_count: res.data.count, text: 'messages' })
          }

        } else {
          setMsg({ message_count: '0', text: 'messages' })
        }

      }).catch((e: any) => {
        console.log(e);
      });
  }

  const getnewRequests = async () => {

    axios.post(variables.url + 'getnewRequests', {
      uId: Base64e.encode(user().rows[0].id + ''),
    })
      .then((res) => {

        if (res.status === 200 && res.data !== null) {
          console.log('count', res.data.count);

          if (res.data.count > 0) {
            if (res.data.count == 1) {
              setRequest({ request_count: res.data.count, text: 'pitch' })
            } else {
              setRequest({ request_count: res.data.count, text: 'pitches' })
            }

          } else {
            setRequest({ request_count: '0', text: 'pitches' })
          }

          if (res.data.requests.count > 0) {
            let count = 0;
            let m_count = 0;
            let user_id = user().rows[0].id;
            res.data.requests.rows.map(function (val: any) {
              if (user_id == val.from && val.to_priority_update_notification == 1) {
                count = count + 1;
              } else if (user_id == val.to && val.priority_update_notification == 1) {
                count = count + 1;
              }

              if (user_id == val.from && val.hitback_to_noti == 1) {
                m_count = m_count + 1;
              } else if (user_id == val.to && val.hitback_from_noti == 1) {
                m_count = m_count + 1;
              }


            })
            console.log(count);
            if (m_count == 1) {
              setMatchAcceptCount({ count: m_count, text: 'interest' })
            } else {
              setMatchAcceptCount({ count: m_count, text: 'interests' })
            }

          } else {
            setMatchAcceptCount({ count: 0, text: 'interests' })
            setUpdateInterestCount({ count: 0, text: 'interests' })
          }


        } else {
          setRequest({ request_count: '0', text: 'pitches' })
        }

      }).catch((e: any) => {
        console.log(e);
      });
  }
  // Function to calculate the difference in days between two dates
  const getDayDifference = (date1: any, date2: any) => {
    // Convert both dates to milliseconds
    const date1Ms = new Date(date1).getTime();
    const date2Ms = new Date(date2).getTime();

    // Calculate the difference in milliseconds
    const differenceMs = date1Ms - date2Ms;

    // Convert milliseconds to days
    const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

    return differenceDays;
  };
  // Function to check if a date is not today
  const isNotToday = (date: any) => {
    const today = new Date();
    const inputDate = new Date(date);

    // Compare the date parts (year, month, day) of both dates
    return (
      today.getFullYear() !== inputDate.getFullYear() ||
      today.getMonth() !== inputDate.getMonth() ||
      today.getDate() !== inputDate.getDate()
    );
  };
  const check7daytrial = async (yourDate: any) => {
    const idx = Base64e.encode(user().rows[0].id + '');
    await axios.post(variables.url + 'updatetrialpopup', { id: idx, update: false })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          const dateToCheck = res.data.popupStatus; // Replace with your date
          const notToday = isNotToday(dateToCheck);
          console.log('notToday', notToday);
          if (notToday) {
            yourDate = new Date(res.data.trial_end);
            const currentDate = new Date();
            const daysLeft = getDayDifference(yourDate, currentDate);
            console.log('isSevenDaysOrLessLeft', daysLeft);
            console.log('isSevenDaysOrLessLeft', yourDate);
            console.log('isSevenDaysOrLessLeft', currentDate.getDate() + 7);
            if (daysLeft > 0 && daysLeft <= 7) {

              axios.post(variables.url + 'updatetrialpopup', { id: idx, update: true })
                .then((res) => {
                  if (res.status === 200) {
                    console.log(res);


                    Swal.fire({
                      title: '',
                      text: 'Your free trial, with unrestricted access to all features, is expiring in ' + daysLeft + ' days. Please choose a plan within this period to continue enjoying our services.',
                      icon: 'warning',
                      showCancelButton: true,
                      allowOutsideClick: false, // Disable closing by clicking outside
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Choose Plan Now',
                      cancelButtonText: 'Decide Later'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate('/subscription?open=1'); // Assuming navigate is properly imported and works as intended

                      } else {
                        // Handle cancellation
                      }
                    });
                  }

                }).catch((e: any) => {
                  console.log(e);
                });
            } else if (yourDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0) && res.data.status == 1) {
              navigate('/subscription?open=1'); // Assuming navigate is properly imported and works as intended

            }
          }
        }

      }).catch((e: any) => {
        console.log(e);
      });

  }



  useEffect(() => {
   
    if (user().rows[0].payments.length > 0 && user().rows[0].payments[0].trial_end != null && (user().rows[0].payments[0].transaction_id == '' || user().rows[0].payments[0].transaction_id == null)) {

      check7daytrial(user().rows[0].payments[0].trial_end);
    }

    const name: any = Greetings();
    setgreet(name);
    getUnreadMessage();
    getnewRequests();
    setInterval(getUnreadMessage, 10000);
    setInterval(getnewRequests, 10000);
    const id = Base64e.encode(user().rows[0].id + "");

    axios.post(variables.url + 'getSingleUser', {
      id: id,
      Rid: '',
      lat: user().rows[0].lat,
      lang: user().rows[0].lang,
      uId: ''
    }).then((res) => {
      if (res.status === 200 && res.data !== '') {
        const row = res.data.rows.rows[0];

        console.log(row.user_photos);


        let profileCompleted = 0;
        if (row.first_name !== '') {
          profileCompleted = profileCompleted + 2;
        }
        if (row.height !== '') {
          profileCompleted = profileCompleted + 2;
        }
        if (row.dob !== '') {
          profileCompleted = profileCompleted + 2;
        }

        if (row.city !== '' && row.state !== '' && row.zip !== '') {
          profileCompleted = profileCompleted + 2;
        }
        if (row.looking_min_age !== '' && row.looking_max_age !== '') {
          profileCompleted = profileCompleted + 2;
        }

        if (row.occupation !== '') {
          profileCompleted = profileCompleted + 2;
        }
        if (row.about !== null && row.about !== '') {
          profileCompleted = profileCompleted + 22;
        }
        if (row.genres !== null && row.genres !== undefined && row.genres !== '') {
          profileCompleted = profileCompleted + 12;
        }
        // if(user().rows[0].genres_partner !== null && user().rows[0].genres_partner !== undefined && user().rows[0].genres_partner !== ''){
        //     profileCompleted= profileCompleted+6;
        // }
        console.log(row);
        var t = 0;
        row.user_answers.map(function (val: any, i: any) {

          if (val.answer_id !== '') {
            profileCompleted = profileCompleted + 2 - 0.55;
          }
        })
        profileCompleted = Math.floor(profileCompleted);
        if (row.user_photos.length >= 3) {
          profileCompleted = profileCompleted + 28;
        } else if (row.user_photos.length == 2) {
          profileCompleted = profileCompleted + 21;
        } else if (row.user_photos.length == 1) {
          profileCompleted = profileCompleted + 14;
        } else if (row.user_photos.length == 0) {
          profileCompleted = profileCompleted + 7;

        }

        setprofileCompleted(profileCompleted)



      }
    });






  }, []);



  return (
    <>
      <div className="App">
        <header className="App-header">
          <TopHeader logo={''} />
        </header>
        <div className='row mb-md-50'>

          <div className='col-md-7 text-center  gray-box'>
            <h1 className="mt-4 gd">{greet}
              <img src={lineimg} />
            </h1>
            <h3 className='mt-3 dash-title'>{Titles}</h3>

            <a href='#' onClick={() => navigate('/profile')} ><Typography className='mt-3 pc-text' variant='h5'>Your profile is currently
              {profileCompleted < 100 && profileCompleted > 0 ? <span className='percent'> <>{profileCompleted}% </></span> : ''}
              complete. we encourage you to finish the remaining fields by clicking the profile tab. </Typography></a>

            <a  className='btn btn-gray' href='#' id="installApp" style={{ visibility: 'hidden' }}>Add bookmark to home screen</a>

          </div>

        </div>

        <div className='row desktop-btns'>
          <div className='col-md-10 m-auto'>
            <div className='btns-group'>
              <button className='btn btn-gray' onClick={() => navigate('/matches')}><img src={ball} />Send Pitch </button>
              <Badge badgeContent={request.request_count} color="secondary"> <button className='btn btn-gray' onClick={() => openModal(2)}><img src={hit} />Hits</button></Badge>
              <Badge badgeContent={msg.message_count} color="secondary"><button className='btn btn-gray' onClick={() => navigate('/ChatList')}><img src={message} />Messages</button></Badge>
              <button className='btn btn-gray' onClick={() => navigate('/profile')}> <img src={profile} /> Profile</button>
              {(user().rows[0].email != 'test_male@gmail.com' && user().rows[0].email != 'test_female@gmail.com') ?
                <button className='btn btn-gray' onClick={() => navigate('/settings')}><img src={setting} /> Settings</button>
                : ""
              }
            </div>
          </div>


        </div>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={() => setopen(false)}
          closeAfterTransition
          style={{ pointerEvents: 'none' }}
        >
          <Fade in={open} >
            <Box className='msspopup' style={{ pointerEvents: 'all' }}>
              <div>
                <div className="row position-relative">
                  <div className="col-md-12"> <Typography className='text-center color-pink' variant='h4'>{title}</Typography></div>
                  <div className="col-md-12 iocdiv"> <ClearIcon onClick={() => setopen(false)} className='clearicon' /></div>
                </div>

                <Typography className='text-center' variant='h5'>{sub}</Typography>
                <Typography className='text-center' variant='h6' ><div dangerouslySetInnerHTML={{
                  __html: sub1
                }}></div></Typography>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

export default App;

