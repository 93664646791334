import React from "react"
import axios from 'axios'
import { ButtonGroup, TextField, Backdrop, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";
import variables from "../../global/global";
// import Base64 from "../encodedecode/decode";
import Base64e from "../encodedecode/encode";

class Email extends React.Component<any>{
    public state = {
        email: this.props.sendprop.email,
        email_error: '',
        go: false,
        page: '',
        otp: '',
        otp_entered: '',
        otp_error: '',
        loading: false,
    }

    constructor(props: any) {
        super(props)
    }


    public setProp = () => {
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (this.state.email.length > 5 && this.state.email.trim().length > 5 && filter.test(this.state.email) && this.state.otp === '') {
            const sixnum = this.generateOTP() + '';
            const otp = Base64e.encode('Your email verification one-time password is: ' + sixnum);
            this.setState({ loading: true });
            var user_id = this.props.from === 'edit' ? this.props.sendprop.register_res.id : '';
            axios.post(variables.url + 'sendotp', { otp: otp, email: this.state.email, user_id: user_id })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.status === 200) {
                            // this.setState({otp: sixnum,loading:false});
                            this.props.returnHandle({ email: this.state.email.trim(), confirm: 0, page: 'password' });

                        } else {
                            this.setState({ loading: false });
                            this.setState({ email_error: res.data.msg });
                        }
                    }
                }).catch((e: any) => {
                    this.setState({ loading: false });
                    this.setState({ email_error: 'server error.Please try again.' });
                });

        } else if (this.state.otp !== '') {
            if (this.state.otp_entered === this.state.otp) {
                this.props.returnHandle({ email: this.state.email.trim(), confirm: 0, page: 'password' });
            } else {
                this.setState({ otp_error: 'Entered Wrong OTP.' });
            }
        } else {
            this.setState({ email_error: 'Please enter a valid email address.' });
        }

    }

    public generateOTP = () => {
        // Declare a digits variable 
        // which stores all digits
        var digits = '0123456789';
        let OTP = '';
        for (let i = 0; i < 6; i++) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        console.log(OTP);

        return OTP;
    }

    public setvalues = (r: any, type: any) => {
        if (type === 'email') {
            this.props.returnHandle({ email: r.target.value });
            this.setState({ email: r.target.value });
        } else {
            this.setState({ otp_entered: r.target.value });
        }
    }

    render(): React.ReactNode {
        // console.log(Base64e.encode('virenderminhas'));
        // console.log(Base64.decode('dmlyZW5kZXJtaW5oYXM='));
        return (
            <>
                <div className="container main py-2" >
                    {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
                    <div className={this.props.from === undefined ? 'd-block m_auto  col-md-5' : 'd-block m_auto  col-md-12'}>
                        {this.props.from !== undefined && this.props.from !== null ? '' : <h3 className="d-block text-center" >What is your email?</h3>}


                        {this.state.otp !== '' ? <span className="text-success">Please check your email and enter the One-Time Password to continue.</span> : ''}
                        {this.state.otp !== '' ?
                            <TextField required={true} style={{ fontSize: '20px' }} className='d-flex' type={'number'} id="outlined-basic" label="Enter One-Time Password" onChange={(e) => this.setvalues(e, 'otp')} error={this.state.otp_error !== '' ? true : false} helperText={this.state.otp_error} variant="outlined" />
                            : <TextField required={true} autoComplete="one-time-code" className='d-flex' type={'email'} id="outlined-basic" label="Enter your email" onChange={(e) => this.setvalues(e, 'email')} error={this.state.email_error !== '' ? true : false} helperText={this.state.email_error} variant="outlined" value={this.props.sendprop.email} />

                        }

                        <ButtonGroup style={{ width: '100%' }}>
                            {this.props.from === undefined ? <LoadingButton
                                style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                                type="submit"
                                size="large"
                                disabled={false}
                                className="btns login-next-button"
                                loading={false}
                                loadingPosition="center"
                                onClick={() => this.props.returnHandle({ page: 'verifypic' })}
                                variant="contained">
                                Back
                            </LoadingButton> : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <LoadingButton
                                style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                                type="submit"
                                size="large"
                                disabled={false}
                                className="btns  login-back-button"
                                loading={this.state.loading}
                                loadingPosition="center"
                                onClick={() => this.setProp()}
                                variant="contained">
                                {this.props.from === undefined ? 'Next' : 'Save'}
                            </LoadingButton>

                        </ButtonGroup>

                    </div>

                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.loading}>
                    <CircularProgress color="inherit" />

                </Backdrop>
            </>
        );
    }


}

export default Email;