import {   Button,   Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import * as React from 'react';
class Dialogs extends React.Component<any,any> {
    constructor(props: any) {
        super(props);
    
        this.state={
          open:this.props.open,
          title:this.props.title,
          subtitle:this.props.subtitle,
        }
      }

      componentWillReceiveProps(nextProps:any) {       
     this.setState({
        open:nextProps.open,
      })
       }
    
      public handleClickAgree = () => {
        this.props.onreturn({dialog:false,confirm:true});
      };
    
      public handleClose = () => {
        this.props.onreturn({dialog:false,confirm:false});
      };
    
    public render(){    
     return (
        <Dialog
open={this.state.open}
onClose={this.handleClose}
aria-labelledby="responsive-dialog-title"
>
<DialogTitle style={{background:'#eee',textAlign:'center'}} id="responsive-dialog-title" >
  {this.state.title}
</DialogTitle>
<DialogContent style={{background:'#eee'}}>
  <DialogContentText>
{this.state.subtitle}
  </DialogContentText>
</DialogContent>
<DialogActions style={{background:'#eee'}}>
  <Button variant='contained' color='primary' autoFocus onClick={this.handleClose}>
    No
  </Button>
  <Button  variant='contained'color='primary' onClick={this.handleClickAgree} autoFocus>
    Yes
  </Button>
</DialogActions>
</Dialog>
     )
    }


    
    }
export default Dialogs;