import { Badge, Button, ButtonGroup, Divider, Grid, Typography, Chip, Box, FormHelperText, FormControl, InputLabel, MenuItem, Checkbox, ListItemText, OutlinedInput,IconButton } from '@mui/material';

import React from 'react';
import variables from '../../global/global';
import TopHeader from '../includes/header';
import Breadcrumb from '../includes/breadcrumb';
import { Navigate } from 'react-router-dom';
import ProfileUpdate from '../../components/profile/updateField';
import Genres from '../../components/profile/genres';
import Personality from '../../components/profile/personality';
import HelpIcon from '@mui/icons-material/Help';
import GenresPotentailPartner from '../../components/profile/genresPotentialPartner';
import About from '../../components/profile/about';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import searching_for from '../../assets/img/searching_for.png';
import Occupation from '../../assets/img/Occupation.png';
import profileline from '../../assets/img/Profile-line.png';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import CreateIcon from '@mui/icons-material/Create';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import axios from 'axios';
import Base64e from '../../components/encodedecode/encode';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';





const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


class Profile extends React.Component<any>{

    // constructor(props:any) {
    //     super(props)
    // }

    public state = {
        pics: this.props.user().rows[0].user_photos,
        user: this.props.user().rows[0],
        activepic: 0,
        bool: false,
        personalities: [],
        add_personalities: [],
        selected_per: [''],
        selected_per_add: [''],
        per_error: '',

        path: '',
        edit: {
            type: '',
            value: '',
            id: '',
        },
        profileCompleted: 0,
        imageUploaded: 0,
        editField: false,
        fresh: '',
        loading: false,
        breadcrumb: [{
            link: '/',
            link_name: 'Profile'
        }]
    }


    componentWillMount = async () => {

        const id = Base64e.encode(this.props.user().rows[0].id + "");
        this.addProfilepic();
        await axios.post(variables.url + 'getSingleUser', {
            id: id,
            Rid: '',
            lat: this.props.user().rows[0].lat,
            lang: this.props.user().rows[0].lang,
            uId: ''
        }).then((res) => {
            if (res.status === 200 && res.data !== '') {
                const row = res.data.rows.rows[0];

                this.setState({
                    user: row,
                    pics: row.user_photos
                }, () => {
                    console.log(row.user_photos);


                    this.addProfilepic();
                    console.log(this.state.pics);
                    let profileCompleted = 0;
                    if (this.state.user.first_name !== '') {
                        profileCompleted = profileCompleted + 2;
                    }
                    if (this.state.user.height !== '') {
                        profileCompleted = profileCompleted + 2;
                    }
                    if (this.state.user.dob !== '') {
                        profileCompleted = profileCompleted + 2;
                    }

                    if (this.state.user.city !== '' && this.state.user.state !== '' && this.state.user.zip !== '') {
                        profileCompleted = profileCompleted + 2;
                    }
                    if (this.state.user.looking_min_age !== '' && this.state.user.looking_max_age !== '') {
                        profileCompleted = profileCompleted + 2;
                    }

                    if (this.state.user.occupation !== '') {
                        profileCompleted = profileCompleted + 2;
                    }
                    if (this.state.user.about !== null && this.state.user.about !== '') {
                        profileCompleted = profileCompleted + 22;
                    }
                    if (this.state.user.genres !== null && this.state.user.genres !== undefined && this.state.user.genres !== '') {
                        profileCompleted = profileCompleted + 12;
                    }
                    // if(this.state.user.genres_partner !== null && this.state.user.genres_partner !== undefined && this.state.user.genres_partner !== ''){
                    //     profileCompleted= profileCompleted+6;
                    // }



                    const personalities: any = [];
                    const add_personalities: any = [];
                    const a: any = [];
                    let answer_id: any = [];
                    console.log('qqqqq', this.state.user.user_answers);
                    this.state.user.user_answers.map(function (val: any, i: any) {

                        if (val.answer_id !== '') {
                            profileCompleted = profileCompleted + 2;
                        }


                        console.log('qqqqq', JSON.parse(val.question_id));
                        console.log('vvvvvvv', JSON.parse(val.answer_id));
                        if (val.question != null && val.question_id == 9 && val.answer_id != null && JSON.parse(val.answer_id).length > 0) {
                            answer_id = JSON.parse(val.answer_id);
                            console.log('val.id', val.question.questions_options);
                            val.question.questions_options.map((row: any) => {
                                personalities.push(row.option);
                                if (row.required == 1) {
                                    // personalities.push(row.option);
                                } else {
                                    add_personalities.push(row.option);
                                }


                            })

                        }
                    })
                    this.setState({ selected_per: answer_id, personalities: personalities, add_personalities: add_personalities });

                    console.log("userpics", this.state.user.user_photos.length);

                    if (this.state.user.user_photos.length >= 3) {
                        profileCompleted = profileCompleted + 28;
                        this.setState({ imageUploaded: 0 });
                    } else if (this.state.user.user_photos.length == 2) {
                        profileCompleted = profileCompleted + 21;
                        this.setState({ imageUploaded: 7 });
                    } else if (this.state.user.user_photos.length == 1) {
                        profileCompleted = profileCompleted + 14;
                        this.setState({ imageUploaded: 14 });
                    } else if (this.state.user.user_photos.length == 0) {
                        profileCompleted = profileCompleted + 7;
                        this.setState({ imageUploaded: 21 });
                    }

                    this.setState({ profileCompleted: profileCompleted })


                    console.log(this.state.user);




                });
            }
        });


















    }


    public addProfilepic = async () => {
        var a = [{
            id: 0,
            picture: this.state.user.pic,
            type: 'image',
            user_id: '0',
            createdAt: ''
        }];
        var b = this.state.pics;

        await this.setState({ pics: a.concat(this.state.pics) });

    }

    public goto = (path: any) => {
        this.setState({
            bool: true,
            path: path,
        });
    }
    public handleChange = (event: SelectChangeEvent<typeof this.state.selected_per>) => {
        const {
            target: { value },
        } = event;
        console.log('value', value);

        this.setState({ selected_per: value });
        this.props.returnHandle({ selected_per: value });
        if (this.state.selected_per.length > 0) {
            this.setState({ per_error: '' });
        }
        console.log('ssdfd', this.state.selected_per);
    };


    public render() {
        let question = this.state.user.user_answers;
        question.sort((a: any, b: any) => a.question_id.localeCompare(b.question_id) || b.question_id - a.question_id);
        return (
            <div className='App' >
                {this.state.bool === true ? <Navigate state={this.state.pics} to={"/" + this.state.path} replace={true} /> : ''}

                <TopHeader title="&nbsp;" logo={''} />
                <Breadcrumb breadcrumb={this.state.breadcrumb} />
                <div className="profile_">
                    <div className='container-fluid px-md-4 px-xs-2'>
                        <div className='row'>
                            <div className='col-md-4 position-relative  ing'>
                                <div className='profilegrid'>
                                    <div className='location'>
                                        <Typography className='m-1' variant='h6'><span className='firstname'>{this.state.user.first_name},  </span> <span className='address'>{this.getAge(this.state.user.dob.toString())} 
                      {this.state.user.city}, {this.state.user.state} ({this.state.user.zip})</span> 
                      {this.state.user.confirm==0 ? <> 
                                        <IconButton className="verifiedicon" color="inherit" sx={{ flexDirection: 'row', alignItems:'center' }}>
                                            <VerifiedOutlinedIcon  />
                                            <Typography className="red-text p-0" variant="caption">Photo Verified</Typography>
                                        </IconButton></> : '' }
                       <Button onClick={() => this.updateFeild('location', { state: this.state.user.state, city: this.state.user.city, zip: this.state.user.zip }, this.state.user.id)} className='w_40' color='secondary' size='small' variant='text'><CreateIcon />{(this.state.user.city == '' || this.state.user.state == '' || this.state.user.zip == '') ? <sup>+2%</sup> : ''}</Button></Typography>

                                       
                                    </div>

                                    {this.state.pics.length > 0 ?
                                        <> {this.state.pics[this.state.activepic].picture != undefined && this.state.pics[this.state.activepic].picture != ''
                                            ? <img className='sliderImg' width={'100%'} alt={'img'} src={this.state.pics[this.state.activepic].picture} />
                                            : <img className='sliderImg' width={'100%'} alt={'img'} src={variables.BaseUrl + 'thumbnails/' + 'nopic.jpg'} />}</>
                                        : <img className='sliderImg' width={'100%'} alt={'img'} src={variables.BaseUrl + 'thumbnails/' + 'nopic.jpg'} />}
                                </div>
                                <Grid container style={{ marginTop: '5px' }} className='tols'>
                                    <Grid item xs={2} md={2} style={{ textAlign: 'initial', color: '#000' }} color='black'>
                                        {this.state.activepic + 1} of {this.state.pics.length}
                                    </Grid>

                                    <Grid item xs={10} md={10} className='text_right d-flex align-items-center justify-content-between'>
                                        <span style={{ color: '#000' }} className='mb-1 c_pointer' onClick={() => this.goto('images')} ><i style={{ color: '#000' }} className='fas iconEdit'>&#xf044;</i> {this.state.pics.length > 0 ? 'See all  photos' : 'Add Photos'} {(this.state.user.user_photos.length < 3) ? <sup>+{this.state.imageUploaded}%</sup> : ''}</span>&nbsp;&nbsp;
                                        <ButtonGroup color="primary" className='gridOnSmall gridOnSmall11 ' aria-label="outlined primary button group">
                                            <Button className='mr_2' size='small' onClick={() => this.setState({ activepic: this.state.activepic - 1 })} variant='contained' disabled={this.state.activepic <= 0 ? true : false}><ArrowLeftIcon /></Button>
                                            <Button className='mr_2' size='small' onClick={() => this.setState({ activepic: this.state.activepic >= this.state.pics.length - 1 ? 0 : this.state.activepic + 1 })} variant='contained' disabled={this.state.activepic >= this.state.pics.length - 1 ? false : false}><ArrowRightIcon /></Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                                <Divider className='m-1' />
                            </div>
                            <div className='col-md-8'>
                                <div className='d-block text-left'>
                                    <div className='profile-question'>
                                        <img src={searching_for} />
                                        {this.state.user.looking_min_age} - {this.state.user.looking_max_age}
                                        <Button onClick={() => this.updateFeild('looking-min-max', { min: this.state.user.looking_min_age, max: this.state.user.looking_max_age }, this.state.user.id)} className='w_40' color='secondary' size='small' variant='text'><CreateIcon />{(this.state.user.looking_min_age == '' || this.state.user.looking_max_age == '') ? <sup>+2%</sup> : ''}</Button>
                                    </div>
                                    <div className='profile-question'>
                                        <img src={Occupation} />
                                        {this.state.user.occupation}
                                        <Button onClick={() => this.updateFeild('occupation', this.state.user.occupation, this.state.user.id)} className='w_40' color='secondary' size='small' variant='text'><CreateIcon />{(this.state.user.occupation == '') ? <sup>+2%</sup> : ''}</Button>

                                    </div>
                                    <div className='profile-question'>
                                        <img src={require(`./../../assets/img/Height.png`)} />
                                        {this.state.user.height}

                                        <Button onClick={() => this.updateFeild('height', this.state.user.height, this.state.user.id)} className='w_40' color='secondary' size='small' variant='text'><CreateIcon />{(this.state.user.height == '') ? <sup>+2%</sup> : ''}</Button>
                                    </div>
                                    {
                                        question.map((val: any, index: any) => (

                                            <>
                                                {val.question != null ?


                                                    <>

                                                        {val.question != null && val.question_id == 9 && val.answer_id != null && JSON.parse(val.answer_id).length > 0 ?
                                                            <>

                                                                <img src={profileline} className='profileline' />
                                                                <Personality data={this.state} questions={val.question} edit={true} returnhandle={(e: any) => this.setState(e)} />

                                                            </>
                                                            :
                                                            <>
                                                                {val.question != null && val.question_id != 1 ?
                                                                    <div className='profile-question'>
                                                                        {val.question.sub_title != null && val.question.sub_title != '' ?
                                                                            <img src={require(`./../../assets/img/${val.question.sub_title}.png`)} />
                                                                            : ''}

                                                                        {val.question != null ? val.question.questions_options.map((ans: any) => (
                                                                            ans.id === parseInt(val.answer_id) ? ans.option : '')) : ''}
                                                                        <Button onClick={() => this.updateQuestion('question', val.question, val, this.state.user.id)} className='w_40' color='secondary' size='small' variant='text'><CreateIcon />

                                                                            {(val.answer_id === '') ? <sup>+2%</sup> : ''}
                                                                        </Button>
                                                                    </div>
                                                                    : ""}
                                                            </>
                                                        }
                                                    </>
                                                    : ''}
                                            </>


                                        ))
                                    }

                                </div>

                            </div>
                        </div>





                        <div className='row'>
                            <div className='col-md-12 profile-fields'>
                                <Genres data={this.state} edit={true} returnhandle={(e: any) => this.setState(e)} />
                                <Divider className='m-1' />
                                <About state={this.state} edit={true} />
                            </div>

                        </div>

                    </div>
                </div>
                {this.state.editField ? <ProfileUpdate data={this.state} returnhandle={(e: any) => this.setState(e)} /> : ''}
            </div>

        )
    }



    public getAge = (dateString: any) => {
        let today = new Date();
        let birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }


    public updateFeild = (type: string, value: any, id: any) => {

        this.setState({
            edit: {
                type: type,
                value: value,
                id: id,
            }, editField: true
        });

    }
    public isNumber = (n: any) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }
    public updateQuestion = (type: string, value: any, val: any, id: any) => {
        if (val.question_id == 9) {

            value.selected_per = this.isNumber(val.answer_id) ? [] : JSON.parse(val.answer_id);
            value.selected_per_add = this.isNumber(val.answer_id_add) ? [] : JSON.parse(val.answer_id_add);
        }
        value.selected = parseInt(val.answer_id);
        console.log('vvvvvvvvvvdddddddddd',value);
        this.setState({
            edit: {
                type: type,
                value: value,
                val,
                id: id,
            }, editField: true
        });

    }

}

export default Profile;