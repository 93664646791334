import React, { useState, useEffect } from "react";
import {  Slider } from '@mui/material';
import logopitch from '../assets/img/logo-pitch.png'
import logoWithout from '../assets/img/logo-without.png'
const Sliderhome = ({ delay = 1000 }) => {
  const [priority, setPriority] = useState(0);
  const [runIt, setRunIt] = useState(false)
  const marks = [
    {
      value: 0,
      label: '0%',
     
    },
    {
      value: 25,
      label: '25%',
     
    },
    {
      value: 50,
      label: '50%',
     
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];
  function trans1(priority:any){
      
        
        
    return 100-parseInt(priority)
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setPriority(priority => priority < 100 ? priority + 1 : 0)
     
    }, 100)

    return () => {
       // clearInterval(interval);
    }

   
  },[])

  return (
    <>
    <div className='pos-re-pro'>
    <img className='fade-img slider-logo' src={logoWithout} width={100} alt="logo"/>
    
    <img className='heart-img heart-img-pro slider-logo'  src={logopitch}  alt="logo" style={{
        WebkitMaskImage : 'linear-gradient(to bottom, transparent '+trans1(priority)+'%, black 0%, black 90%, transparent 100%)',
        maskImage : 'linear-gradient(to bottom, transparent '+trans1(priority)+'%, black 0%, black 90%, transparent 100%)'}}/>
    </div>
  <Slider
                 
  sx={{
   color: '#ffffff',
   }}
   aria-label="Interest"
   //valueLabelFormat={valuetext}
   value={priority}
 valueLabelDisplay='auto'
 marks={marks}
   step={1}
  // onChange={(_:any, newValue:any)=>setPriority(newValue)}
   min={0}
   max={100}
 />
 
 </>);
};

export default Sliderhome;