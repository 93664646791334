import React from "react"
import axios from 'axios'
import logo from '../assets/img/logo.png'
import logo1 from '../assets/img/logo-1.png'
import logo2 from '../assets/img/logo-2.png'
import FirstName from '../components/signup/firstName'
import Dob from '../components/signup/dob'
import Height from '../components/signup/height'
// import Uploadphotos from '../components/signup/uploadphotos'
import Email from '../components/signup/email'
import Location from '../components/signup/location'
import LookingMinMax from '../components/signup/lookingMinMax'
import Question from '../components/signup/quetions'
import TermConditions from '../components/signup/termConditions'
import PhoneNumber from '../components/signup/phoneNumber'
import { Navigate } from "react-router-dom"
import variables from "../global/global"
import Profilepic from "../components/signup/profilepic"
import Password from "../components/signup/password"
import Occupation from "../components/signup/occupation"
import Verifypic from "../components/signup/verifypic"
import Plans from "./plans"
import CheckoutForm from "./checkout"
import { loadStripe } from "@stripe/stripe-js"
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"
import DoneIcon from '@mui/icons-material/Done';
import { Backdrop, CircularProgress } from '@mui/material';

import { useSignIn } from "react-auth-kit"
import Swal from 'sweetalert2';
import { AnyAaaaRecord } from "dns"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { withSignIn } from 'react-auth-kit';
class Signup extends React.Component<any>{

  public state = {
    progress: 0,
    go: false,
    page: window.location.href.split('/')[4],
    demo: false,
    id: '',
    page_c: 'signup',
    name: '',
    dob: '',
    day: '',
    month: '',
    year: '',
    height: '',
    email: '',
    password: '',
    occupation: '',
    location: { city: '', state: '', statefull: { full: '', short: '' }, zip: '' },
    lat: '',
    lang: '',
    lookingminmax: { min: '', max: '' },
    questions: [],
    current_q: 1,
    selected_per: [],
    selected_per_add: [],
    questiongroup: [1, 2],
    prevNext: ['', 'dob'],
    profilepic: {},
    pic: '',
    confirm: 1,
    verifypic: {},
    vpic: '',
    termcondition: false,
    number: '',
    photos: [],
    register: false,
    loading: false,
    register_res: null,
    selected_plan: {},
    clientSecret: '',
    title: 'You will get a free introductory 60 day MVP Membership. Pick your plan to start on day 61',
  }

  // constructor(props:any) {
  //     super(props)
  // }

  public getQuestions = () => {
    // this.setState({  loading:true})  
    axios.get(variables.url + 'getRegisterQuestions')
      .then((res) => {

        if (res.status === 200) {

          const rowx = res.data.rows.map((val: any) => {
            val.selected = '';
            val.active = val.id === 1 ? true : false;
            val.questions_options.sort((a: any, b: any) => {
              if (a.option < b.option) { return -1; }
              if (a.option > b.option) { return 1; }
              return 0;
            });
            return val;
          });

          this.setState({ questions: rowx, loading: false, });

        }
      });
  }

  public goto = (page: any) => {
    this.setState({ go: true, page: page });
  }

  public setData = (r: any) => {
    console.log('statteeee', r);
    this.setState(r);
    window.history.pushState('test', 'Signup', r.page);
  }

  componentDidMount(): void {

    this.getQuestions();
  }
  componentWillMount() {
    document.body.classList.add('bg-smoke');
    document.body.classList.remove('bg-without-smoke');
  }
  componentDidUpdate(): void {
    if (this.state.register) {
      //this.setState({  loading:true})
      this.finalRegister();
    }
  }

  public finalRegister = () => {
    this.setState({ register: false })


    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent: any) => {
        let progress: any;
        progress = ((progressEvent.loaded / progressEvent.total) * 100).toString();
        let pro = Math.round(progress)
        this.setState({ progress: pro });
      }
    }
    axios.post(variables.url + 'registeruser', this.state, config
    )
      .then((res) => {
        this.setState({ loading: false })
        if (res.status === 200) {
          this.setState({ register: false, register_res: res.data });
          setTimeout(() => {
            this.autoLogin();

            // this.setState({page:'plans'});   

          }, 100);
        } else {
          Swal.fire({
            icon: "error",
            title: 'Oops...',
            allowOutsideClick: false,
            text: ' Something went wrong!',
            confirmButtonColor: '#7b1fa2',
          }).then((res) => {
            this.goto('');
          })
        }
      }).catch(function (error) {

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser 
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }

      });

  }

  public autoLogin = () => {
    this.setState({ load: true });

    axios.post(variables.url + 'Userlogin', {
      email: this.state.email,
      password: this.state.password
    })
      .then((res) => {
        this.setState({ load: false });
        console.log(res.status);
        console.log(res.data);

        if (res.status === 200 && !res.data.error) {
          const { token, expiresIn, authUserState, refreshToken, refreshTokenExpireIn } = res.data;

          if (this.props.signIn({
            token,
            expiresIn,
            tokenType: "Bearer",
            authState: authUserState,
            refreshToken, // Only if you are using refreshToken feature
            refreshTokenExpireIn // Only if you are using refreshToken feature
          })) {
            this.setState({ go: true, page: 'Dashboard' });
          }
        } else {
          let errorMessage = res.data.error || 'Server error.';
          Swal.fire({
            icon: "error",
            title: '',
            text: errorMessage,
            confirmButtonColor: '#7b1fa2',
          }).then(() => {
            this.setState({ go: true, page: 'signin' });
          });
        }
      })
      .catch((error) => {
        this.setState({ load: false });
        console.error("API Error:", error);

        Swal.fire({
          icon: "error",
          title: '',
          text: 'Server error.',
          confirmButtonColor: '#7b1fa2',
        }).then(() => {
          this.setState({ go: true, page: 'signin' });
        });
      });
  }




  public render() {

    return (
      <>
        <section className="header pt-2 ">
          <div className="container-fluid">
            <div className="row show-md" data-aos="fade-in">
              <div className="col-lg-2 col-md-2 position-relative">
                <a href="javascript::void();" onClick={() => this.goto('')} className="logo1 align-items-center">
                  <img src={logo1} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                  <span className="header-tm-text logotm2">TM</span>
                </a>
              </div>
              <div className="col-lg-10  col-md-10 position-relative d-flex align-items-center">
                <a href="javascript::void();" onClick={() => this.goto('')} className="logo align-items-center m-left ">
                  <img src={logo2} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                  <span className="header-tm-text">TM</span>
                </a>
                <hr className="logo-hr" />

              </div>
              <div className="gold-line"> &nbsp; </div>
            </div>
            <div className="row show-sm" data-aos="fade-in">

              <div className="col-lg-12  position-relative">
                <a href="javascript::void();" onClick={() => this.goto('')} className="logo align-items-center">
                  <img src={logo} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                  {/* <span className="header-tm-text-moblie">TM</span> */}
                </a>
                <hr className="logo-hr" />

              </div>
              <div className="gold-line"> &nbsp; </div>
            </div>

          </div>

        </section>


        <div className="container" style={{ display: 'flex', margin: 'auto' }}>
          {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={true} /> : ''}

          <div className="signupform" >
            <div className="form-group t-center">

              {this.state.page === 'plans' ?
                <>
                  <h4 className="text-account-created">{this.state.page === 'plans' ? 'Your Account has been successfully created! ' : ''} {this.state.page === 'plans' ? <DoneIcon /> : ''} </h4>
                  <h3 className="pla-tex">{this.state.title}</h3>
                  <h4 className="text-account-created">(You must choose a Plan)</h4>
                </>
                : ""}
            </div>
            {
              this.state.page === 'name' ? <FirstName returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                this.state.page === 'dob' ? <Dob returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                  this.state.page === 'height' ? <Height returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                    this.state.page === 'profilepic' ? <Profilepic returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                      this.state.page === 'location' ? <Location returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                        this.state.page === 'lookingForMinMaxAge' ? <LookingMinMax returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                          this.state.page === 'number' ? <PhoneNumber returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                            this.state.page === 'occupation' ? <Occupation returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                              this.state.page === 'verifypic' ? <Verifypic returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                                this.state.page === 'questions' && this.state.questions[0] !== undefined ? <Question returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                                  this.state.page === 'email' ? <Email returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                                    this.state.page === 'password' ? <Password returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                                      this.state.page === 'termAndconditions' ? <TermConditions returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> :
                                        this.state.page === 'checkout' ?
                                          <CheckoutForm from='signup' returnHandle={(r: any) => this.setData(r)} sendprop={this.state} />

                                          :
                                          this.state.page === 'plans' ? <Plans from='signup' returnHandle={(r: any) => this.setData(r)} sendprop={this.state} /> : ''
            }
          </div>
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loading}>
          {this.state.progress > 0 ?
            <div className="progressdiv">

              <CircularProgressbar
                background
                styles={buildStyles({
                  pathColor: "#df1515",
                  textColor: "#df1515",
                  backgroundColor: "#fff",


                })}

                strokeWidth={8} value={this.state.progress} text={`${this.state.progress}%`} />
              <h3>&nbsp; Please wait..</h3>
            </div>
            :
            <CircularProgress color="inherit" />
          }
        </Backdrop>

      </>
    );
  }


}

export default withSignIn(Signup);