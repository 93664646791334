import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Routesx from './routes/routex';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-auth-kit'
import { Notifications } from 'react-push-notification';
import { Provider } from "react-redux";
import configureStore from "../src/store/store";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  // <React.StrictMode>
  <Provider store={configureStore()}>
     <AuthProvider authType = {'localstorage'}
                  authName={'_auth'}
                  cookieDomain={window.location.hostname}
                  cookieSecure={window.location.protocol === "https:"}>
  <div>
    <Routesx />
    <Notifications/>

  </div>  
    </AuthProvider>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
