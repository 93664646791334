import React from "react"
import { ButtonGroup, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";


class FirstName extends React.Component<any>{
    public state = {
        name: this.props.sendprop.name,
        name_error: '',
        go: false,
        page: '',
    }

    // constructor(props:any) {
    //     super(props)
    // }


    public setProp = () => {
        if (this.state.name.length > 0 && this.state.name.trim().length > 0) {
            this.props.returnHandle({ name: this.state.name.trim(), page: 'height' });

        } else {
            this.setState({ name_error: 'Name field is required.' });
        }

    }

    public setvalues = (r: any) => {
        this.props.returnHandle({ name: r.target.value });
        this.setState({ name: r.target.value });
    }

    render(): React.ReactNode {

        return (
            <div className="container main" >
                {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
                <div className={this.props.from === undefined ? 'd-block m_auto  col-md-5' : 'd-block m_auto  col-md-12'}>
                    <h3 className="d-block text-center" >What is your first name?</h3>
                    {/* {this.props.from===undefined  ?
             <h3 className="d-block text-center" >Hi, let's get started.</h3> 
             : ''} */}
                    <TextField required={true} className='d-flex' id="outlined-basic" label="What is your first name" autoComplete="one-time-code" onChange={(e) => this.setvalues(e)} error={this.state.name_error !== '' ? true : false} helperText={this.state.name_error} variant="outlined" value={this.props.sendprop.name} />




                    <ButtonGroup style={{ width: '100%' }}>
                        {this.props.from === undefined ? <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns login-next-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.props.returnHandle({ page: 'dob' })}
                            variant="contained">
                            Back
                        </LoadingButton> : ''}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns  login-back-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.setProp()}
                            variant="contained">
                            {this.props.from === undefined ? 'Next' : 'Save'}
                        </LoadingButton>

                    </ButtonGroup>


                </div>
            </div>
        );
    }


}

export default FirstName;