import React, { useState, useRef} from "react";
import { Button,Grid, Dialog,Slide, Snackbar } from '@mui/material';
import Cropper from "react-easy-crop";
import { TransitionProps } from '@mui/material/transitions';
import test from '../../assets/test2.png'
import Swal from 'sweetalert2';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ImageCropper(data:any) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [open, setOpen] = useState(false);
  const [snack_open, setsnackOpen] = useState({open: false, msg: ''});
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(4 / 3);

  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState(data.pic);
console.log(data);

  const onCropComplete = (croppedAreaPercentage:any, croppedAreaPixels:any) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onAspectRatioChange = (event:any) => {
    setAspectRatio(event.target.value);
  };

    // Invoked when new image file is selected
    const onImageSelected = (selectedImg:any) => {
      setOpen(true);
      setImage(selectedImg);
      setCurrentPage("crop-img");
    };
  
    // Generating Cropped Image When Done Button Clicked
    const onCropDone = (imgCroppedArea:any) => {
      const canvasEle = document.createElement("canvas");
      canvasEle.width = imgCroppedArea.width;
      canvasEle.height = imgCroppedArea.height;
  
      const context = canvasEle.getContext("2d");
  if(context !== null){
      let imageObj1 = new Image();
      imageObj1.src = image;
      imageObj1.onload = function () {
        context.drawImage(
          imageObj1,
          imgCroppedArea.x,
          imgCroppedArea.y,
          imgCroppedArea.width,
          imgCroppedArea.height,
          0,
          0,
          imgCroppedArea.width,
          imgCroppedArea.height
        );
  
        const dataURL = canvasEle.toDataURL("image/jpeg");
        setOpen(false);
        setImgAfterCrop(dataURL);
       let file= dataURLtoFile(dataURL,getRandomFileName());
        data.returnHandler({file:file,dataURL:dataURL});
        setCurrentPage("img-cropped");
      };
    }
    };
    function dataURLtoFile(dataurl:any, filename:any) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
  }
  function getRandomFileName() {
    var timestamp = new Date().toISOString().replace(/[-:.]/g,"");  
    var random = ("" + Math.random()).substring(2, 8); 
    var random_number = timestamp+random;  
    return random_number+'.png';
    }
  
    // Handle Cancel Button Click
    const onCropCancel = () => {
      setOpen(false);
      setCurrentPage("choose-img");
      setImage("");
    };



    const handleOnChange = (event:any) => {
      console.log(data.images.length);
      
      if(data.images !== undefined && data.images.length < 10){

      
            const file    = event.target.files[0];
            const type = file.type.split('/');  
            if (file.size>7340032 || type[0]!=='image') {
              setsnackOpen({open:true, msg: 'Please insert valid Image and Size should be less than 7Mb'})
            

              }else{ 
            if (event.target.files && event.target.files.length > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(event.target.files[0]);
              reader.onload = function (e) {
                onImageSelected(reader.result);
              };
            }
          }
    }else{
      event.target.value = null;
      Swal.fire({  
        icon: "error",
        title: '',  
        text: "You can't upload more than 10 images.",
        confirmButtonColor: '#7b1fa2',  
      });
    }
    };
    const inputRef = useRef();
    const onChooseImg = () => {
      if(inputRef !== undefined){
       // inputRef.current.click();
      }
  
    };
    const cropSize = {width:400, height:450};
  return (
    <>
    <div className="container pl-0">

         <div>
 
  {data.page=='user_photos' ?
     <Button  variant="outlined" color='secondary' component="label">
    <img width={'100%'} src={'/images/addpiclogo.gif'}  alt="img" />
    <input type="file"    onChange={handleOnChange}  hidden  />
    </Button>
    :
    <div className="profile-pic">
		    <label className="-label" htmlFor="outlined-basic">
				<span className=" fa fa-camera"></span>
				  <span>Change Image</span>
			</label>
                <input  accept=".png,.jpg,.gif,.jpeg" required={true} type={'file'}  className='d-none' id="outlined-basic"  onChange={handleOnChange}  />
                <div className="profile-pic-img" >
                     <img alt="img" className="profilepic" src={imgAfterCrop!=='' ? imgAfterCrop : test} />
                </div>
        </div>
      }
  
        
       </div>
   
   

      <div>
        <div>
          {/* <img src={imgAfterCrop} className="cropped-img" /> */}
        </div>

      </div>
    
  </div>





    <Dialog
    open={open}
    fullWidth={true}
    TransitionComponent={Transition}
    keepMounted
    aria-describedby="alert-dialog-slide-description"
    className="cropperDialog"
    >
    <div className="cropper" style={{height:'100%'}}>
      <div style={{height:'80%'}}>
        <Cropper
          image={image}
         // aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          cropSize={cropSize}
          style={{
            containerStyle: {
              width: "100%",
              height: "80%",
              backgroundColor: "#fff",
            },
          }}
        />
      </div>

      <div className="d-flex justify-content-between action-btn px-2 py-4">
        {/* <div className="aspect-ratios" onChange={onAspectRatioChange}>
          <input type="radio" value={1 / 1} name="ratio" /> 1:1
          <input type="radio" value={5 / 4} name="ratio" /> 5:4
          <input type="radio" value={4 / 3} name="ratio" /> 4:3
          <input type="radio" value={3 / 2} name="ratio" /> 3:2
          <input type="radio" value={5 / 3} name="ratio" /> 5:3
          <input type="radio" value={16 / 9} name="ratio" /> 16:9
          <input type="radio" value={3 / 1} name="ratio" /> 3:1
        </div> */}
      
        <Button variant="outlined" color="error" onClick={onCropCancel}>
        Cancel
        </Button>
        <Button className="ml-3" variant="contained" color="primary" onClick={() => {
            onCropDone(croppedArea);
          }}>
          Save
        </Button>
       
      </div>
    </div>
    </Dialog>
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={snack_open.open}
      autoHideDuration={1}
      message={snack_open.msg}
      key={'snackbar'}
    />
    </>
  );
}

export default ImageCropper;