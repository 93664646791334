import * as React from 'react';
import { useState, useEffect } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  Backdrop, Button, Grid, Typography, Link, Slide,
  AppBar, Toolbar, IconButton, Divider, Chip, Box, FormHelperText, FormControl, InputLabel, MenuItem, Checkbox, ListItemText, OutlinedInput
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import variables from '../../global/global';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';
import Swal from 'sweetalert2';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';




const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function Personality(dat: any) {
  console.log(dat.data);
  let selected: any = [''];
  let per_selected: any = [];
  let selected_ids: any = [];
  const [smallScreen, setsmallScreen] = useState(false);
  const navigate = useNavigate();
  const [editgenres, setEditgenres] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [max, setMax] = React.useState({ val: 10, indicator: '' });
  const [load, setload] = React.useState(false);
  const [genres, setGenres]: any = React.useState([]);
  const [addgenres, setAddgenres]: any = React.useState('');


  const handleClickOpen = () => {
    console.log('dsfs');

    setOpen(true);
    setMax({ val: 99, indicator: 'rotate(180deg)' })
  };

  const handleClose = () => {
    console.log('dfs');
    setOpen(false);
    console.log('smallScreen', window.innerWidth);
    if (window.innerWidth <= 820) {
      setMax({ val: 3, indicator: '' });
    } else {
      setMax({ val: 10, indicator: '' })
    }

  };


  const handleResize = () => {
    console.log('width', window.innerWidth);
    if (window.innerWidth <= 820) {
      setsmallScreen(true);
      setMax({ val: 4, indicator: '' });
    } else {
      setsmallScreen(false);

    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()



  }, []);

  const addOrRemove = (event: any, name: any) => {

    per_selected = dat.data.selected_per;


    const index = per_selected.indexOf(name);
    console.log(name)
    console.log(per_selected)
    if (index === -1) {
      per_selected.push(name);
    } else {
      per_selected.splice(index, 1);
    }

    setAddgenres(per_selected);
    console.log(per_selected)
  }


  const setva = () => {
    setload(true);
    // let ids:any = [];
    // e.map((v:any)=>{
    //     ids.push(v.id);
    //     return v;
    // });


    axios.post(variables.url + 'updateAnswer', { id: dat.data.user.id, data: { selected_per: per_selected, selected_per_add: [], questions: [dat.questions] } })
      .then((res) => {
        if (res.status === 200) {
          setload(false);
          setOpen(false);
          localStorage.setItem('_auth_state', JSON.stringify(res.data));
          window.location.reload();
          // Swal.fire({  
          //   icon: "success",
          //   title: 'Successfully Updated!',  
          //   confirmButtonColor: '#7b1fa2',  
          // }).then(()=>{

          //   window.location.reload();
          // });

        }
      }).catch((e: any) => {
        setload(false);
        console.log(e);
      });
  }

  const selectedData = () => {
    if (dat.data.selected_per !== null) {
      dat.data.selected_per.map((v: any, index: any) => {
        if (index <= max.val) {

          per_selected.push(v)

        }
        return v;
      });


      console.log(per_selected);
    }
  }

  selectedData();
  return (
    <Grid item xs={12} md={12} style={{ textAlign: 'left', position: 'relative' }}>
      <div className='personality'>
        {dat.edit ?
          <img onClick={() => handleClickOpen()} src={require(`./../../assets/img/Personality.png`)} />
          :
          <img src={require(`./../../assets/img/Personality.png`)} />
        }


        <FormControl variant="standard" sx={{ width: '90%' }} >
          <Autocomplete

            onOpen={() => {
              setMax({ val: 99, indicator: 'rotate(180deg)' })
            }}
            onClose={handleClose}
            sx={{
              width: "100%",
              "& .MuiAutocomplete-popupIndicator": { transform: max.indicator },
            }}
            multiple
            id="size-small-standard-multi"
            size='medium'
            readOnly={true}
            options={dat.data.personalities}
            getOptionLabel={(option) => option}
            onChange={(e: any, detail: any) => setva()}
            value={per_selected}
            defaultValue={per_selected}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label=""
                placeholder={editgenres ? 'Type...' : ''}
              />
            )}
          />



          {/* <Select
                onOpen={() => {
                    handleClickOpen()
                }}
              readOnly={dat.edit}
               open={open}
                labelId="demo-multiple-checkbox-label"
                 id="demo-multiple-checkbox"
                 multiple
                 value={per_selected ?? []}
                 input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                 renderValue={(selected) => (
                   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value:any) => (
                     <Chip key={value} label={value} />
                       ))}
                  </Box>
                   )}
                    >
                 
                </Select> */}

        </FormControl>
        {/* <Typography className='m-1 gen-tt' variant='h4'>
    <Button  onClick={()=>handleClickOpen()} title='Edit' className='w_40' color='secondary' size='small'variant='text'><CreateIcon /></Button>
       
    </Typography>  */}
      </div>

      <Dialog
        fullScreen
        hideBackdrop={true}
        disableAutoFocus
        open={open}
        onClose={handleClose}
        transitionDuration={200}
        TransitionComponent={Transition}
        PaperProps={{ style: { backgroundColor: 'rgb(255 175 13)' } }}
      >
        <AppBar sx={{ position: 'fixed', top: '0px', backgroundColor: '#7b1fa2' }}>
          <Toolbar>
            <IconButton
              className='back'
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Personality Traits
            </Typography>
            <Button autoFocus color="inherit" onClick={setva}>
              Save
            </Button>
          </Toolbar>
        </AppBar>






        {dat.data.personalities !== '' ?



          <>

            <Grid container rowSpacing={2} direction="row"
              justifyContent="flex-start"
              alignItems="center" className="p-md-5 genres-container">

              {dat.data.personalities !== '' ? dat.data.personalities.map((value: any) => (
                <>

                  <Grid xs={6} md={3}>
                    <label> <input type="checkbox" name="generes" onClick={(e) => addOrRemove(e, value)} defaultChecked={dat.data.selected_per.includes(value)} />  {value}</label>
                  </Grid>

                </>
              )) : ''}
            </Grid>


          </>
          : ''}


      </Dialog>

    </Grid>
  );
}



