import React from 'react';
import { CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Backdrop, Box, ButtonGroup, Dialog, Fade, Modal, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import variables from '../../global/global';
import { LoadingButton } from '@mui/lab';
import DoneIcon from '@mui/icons-material/Done';
import { Navigate } from 'react-router-dom';
import PaymentForm from '../paymentform/PaymentForm';
import ClearIcon from '@mui/icons-material/Clear';

class Cardupdate extends React.Component<any> {

  public state = {
    load: false,
    dashboard: false,
    success: false,
    error: '',
    er: false,
    state_error: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    zipcode: '',
    address_line1_error: '',
    city_error: '',
    zipcode_error: '',
    name: '',
    name_error: ''
  }

  public stateAbbreviations = [
    { short: "AL", full: "Alabama" },
    { short: "AK", full: "Alaska" },
    { short: "AZ", full: "Arizona" },
    { short: "AR", full: "Arkansas" },
    { short: "CA", full: "California" },
    { short: "CO", full: "Colorado" },
    { short: "CT", full: "Connecticut" },
    { short: "DE", full: "Delaware" },
    { short: "FL", full: "Florida" },
    { short: "GA", full: "Georgia" },
    { short: "HI", full: "Hawaii" },
    { short: "ID", full: "Idaho" },
    { short: "IL", full: "Illinois" },
    { short: "IN", full: "Indiana" },
    { short: "IA", full: "Iowa" },
    { short: "KS", full: "Kansas" },
    { short: "KY", full: "Kentucky" },
    { short: "LA", full: "Louisiana" },
    { short: "ME", full: "Maine" },
    { short: "MD", full: "Maryland" },
    { short: "MA", full: "Massachusetts" },
    { short: "MI", full: "Michigan" },
    { short: "MN", full: "Minnesota" },
    { short: "MS", full: "Mississippi" },
    { short: "MO", full: "Missouri" },
    { short: "MT", full: "Montana" },
    { short: "NE", full: "Nebraska" },
    { short: "NV", full: "Nevada" },
    { short: "NH", full: "New Hampshire" },
    { short: "NJ", full: "New Jersey" },
    { short: "NM", full: "New Mexico" },
    { short: "NY", full: "New York" },
    { short: "NC", full: "North Carolina" },
    { short: "ND", full: "North Dakota" },
    { short: "OH", full: "Ohio" },
    { short: "OK", full: "Oklahoma" },
    { short: "OR", full: "Oregon" },
    { short: "PA", full: "Pennsylvania" },
    { short: "RI", full: "Rhode Island" },
    { short: "SC", full: "South Carolina" },
    { short: "SD", full: "South Dakota" },
    { short: "TN", full: "Tennessee" },
    { short: "TX", full: "Texas" },
    { short: "UT", full: "Utah" },
    { short: "VT", full: "Vermont" },
    { short: "VA", full: "Virginia" },
    { short: "WA", full: "Washington" },
    { short: "WV", full: "West Virginia" },
    { short: "WI", full: "Wisconsin" },
    { short: "WY", full: "Wyoming" }
  ];

  public setvalues = (r: any, type: any) => {
    if (type === 'name') {
      this.setState({ name: r.target.value });
    }
    if (type === 'address_line1') {
      this.setState({ address_line1: r.target.value });
    }
    if (type === 'city') {
      this.setState({ city: r.target.value });
    }
    if (type === 'state') {
      this.setState({ state: r.target.value });
    }
    if (type === 'zipcode') {
      this.setState({ zipcode: r.target.value });

    }


  }

  validatefields = (e: any) => {
    e.preventDefault();
    const isValid: any = [];
    if (this.state.name == '') {
      this.setState({ name_error: 'This field is required' })
      isValid.push(1);
    } else {
      this.setState({ name_error: '' })
    }
    if (this.state.address_line1 == '') {
      this.setState({ address_line1_error: 'This field is required' })
      isValid.push(1);
    } else {
      this.setState({ address_line1_error: '' })
    }

    if (this.state.city == '') {
      this.setState({ city_error: 'This field is required' })
      isValid.push(1);
    } else {
      this.setState({ city_error: '' })
    }
    if (this.state.state == '') {
      this.setState({ state_error: 'This field is required' })
      isValid.push(1);
    } else {
      this.setState({ state_error: '' })
    }
    if (this.state.zipcode == '') {
      this.setState({ zipcode_error: 'This field is required' })
      isValid.push(1);
    } else {
      this.setState({ zipcode_error: '' })
    }

    if (isValid.length == 0) {
      this.handleSubmit(e)
    }
  }



  handleSubmit = async (event: any) => {
    event.preventDefault();
    this.setState({ load: true });
    console.log(this.props);

    const { stripe, elements, sendprop } = this.props;

    if (elements == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address: {
          city: this.state.city,
          country: 'US',
          line1: this.state.address_line1,
          line2: this.state.address_line2,
          postal_code: this.state.zipcode,
          state: this.state.state,
        },
        name: this.state.name,
        phone: sendprop.user.number,
      },
    });

    if (error === undefined) {

      const pm = sendprop.user.payments[0].cardId ?? '';
      const c = sendprop.user.payments[0].s_customer_id;
      const customer_name = sendprop.user.first_name;
      const customer_email = sendprop.user.email;
      const Uid = sendprop.user.id;
      await axios.post(variables.url + 'updateCard', {
        card: paymentMethod,
        pm: pm,
        customer_name: customer_name,
        c: c,
        Uid: Uid,
        customer_email: customer_email,
        selected_plan: sendprop.selected_plan
      })
        .then((res) => {

          if (res.status === 200) {
            this.setState({ load: false, success: true });
            localStorage.setItem('_auth_state', JSON.stringify(res.data));

            setTimeout(() => {
              this.props.returnHandle({ editCard: false });
              localStorage.setItem("open_planpopup", 'true');
              window.location.reload();
            }, 2000);

          } else {

            this.setState({ error: res.data.code, load: false, er: true });
          }
        }).catch((e: any) => {
          console.log(e);

          alert('faild');
          this.setState({ load: false });
        });
    } else {
      this.setState({ load: false })
    }
  };

  render() {
    // const {stripe} = this.props;

    const OPTIONS = {
      showIcon: true,
    };

    return (

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={this.props.sendprop.editCard}
        onClose={() => this.props.returnHandle({ editCard: false })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      // style={{pointerEvents: 'none'}}
      >
        <Fade in={this.props.sendprop.editCard} >
          <Box className='modal_confirmbox updateModal'>
            <Typography variant='h5' style={{ textAlign: 'center', color: 'black' }}><b>Update Card</b></Typography><br />
            <div className="col-md-12 iocdiv iocdiv2"> <ClearIcon onClick={()=>this.props.returnHandle({ editCard: false })} className='clearicon'/></div>
            <PaymentForm from="" sendprop={this.props.sendprop}  returnHandle={(r:any)=>this.props.returnHandle({ editCard: false })}/> 
          </Box>
        </Fade>
      </Modal>
    );
  }
}





export default Cardupdate;
