import React from "react"
import { ButtonGroup, FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";


class Password extends React.Component<any>{
  public state = {
    password: this.props.sendprop.password,
    password_error: '',
    go: false,
    page: '',
    showPassword: false,
    minimum: 'Passwords must be  minimum 8 characters long ',
  }

  // constructor(props:any) {
  //     super(props)
  // }


  public handleClickShowPassword = () => {
    if (this.state.showPassword) {
      this.setState({ showPassword: false });
    } else {
      this.setState({ showPassword: true });
    }
  };

  public handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  public checkPassword = (r: any) => {

    const re = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
    if (r.target.value.length >= 8) {

      this.setState({ password_error: '', minimum: '' });

    } else {
      this.setState({ minimum: 'Passwords must be  minimum 8 characters long ', password_error: ' and contain at least one uppercase and special character.' });
    }

  }
  public setProp = () => {

    const re = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/;
    if (this.state.password.length >= 8) {
      this.props.returnHandle({ password: this.state.password.trim(), page: 'termAndconditions' });
      this.setState({ password_error: '', minimum: '' });

    } else {
      this.setState({ minimum: 'Passwords must be  minimum 8 characters long ', password_error: ' and contain at least one uppercase and special character.' });
    }

  }

  public setvalues = (r: any) => {
    this.props.returnHandle({ password: r.target.value });
    this.setState({ password: r.target.value });
  }

  render(): React.ReactNode {

    return (
      <div className="container main" >
        {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
        <div className="d-block m_auto col-md-5" >
          <h3 className="d-block" >Create your Password<span className="text-danger">*</span></h3>
          <FormControl sx={{ width: '100%' }} variant="filled">
            <InputLabel htmlFor="filled-adornment-password"></InputLabel>
            <FilledInput
              id="filled-adornment-password"
              type={this.state.showPassword ? 'text' : 'password'}
              defaultValue={this.props.sendprop.password}
              // value={this.props.sendprop.password}
              onBlur={(e) => this.setvalues(e)}
              onKeyUp={(e) => this.checkPassword(e)}
              error={this.state.password_error !== '' ? true : false}
              autoComplete="new-password"

              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <span className={this.state.password_error !== '' ? "text-black" : "text-black"}>{this.state.minimum} {this.state.password_error !== '' ? this.state.password_error : ''}</span>
          </FormControl>

          <ButtonGroup style={{ width: '100%' }}>
            <LoadingButton
              style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
              type="submit"
              size="large"
              disabled={false}
              className="btns login-next-button"
              loading={false}
              loadingPosition="center"
              onClick={() => this.props.returnHandle({ page: 'email' })}
              variant="contained">
              Back
            </LoadingButton>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <LoadingButton
              style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
              type="submit"
              size="large"
              disabled={false}
              className="btns  login-back-button"
              loading={false}
              loadingPosition="center"
              onClick={() => this.setProp()}
              variant="contained">
              Next
            </LoadingButton>

          </ButtonGroup>

        </div>
      </div>
    );
  }




}

export default Password;