import axios from "axios";
import TopHeader from './includes/header';
import Breadcrumb from './includes/breadcrumb';
import React from "react";
import { Navigate } from 'react-router-dom';
import variables from "../global/global";
import {Grid } from '@mui/material';
import { format } from "date-fns";
import Base64e from '../components/encodedecode/encode';
import { ClassNames } from "@emotion/react";

class  Blog extends React.Component<any, any>{
    public state = {
        
        go:false,
        page:'#',
        loading:false,
        blogs:[],
        breadcrumb:[{
            link:'',
            link_name:'Blogs'
          }
        ]
        
    }
componentDidMount(): void {
    axios.get(variables.url+'getBlogs')
    .then((res)=>{    
    if(res.status === 200){     
        this.setState({blogs:res.data.rows});
            }
    }).catch((e:any)=>{  
         
    });

}
public goto = (page:any)=>{
        
    this.setState({go:true,page:page});

}

render(): React.ReactNode {

return (

  <div className='App'>

{this.state.go===true ? <Navigate  state={this.state} to={"/"+this.state.page} replace={true}/>  : ''}

<TopHeader title="&nbsp;"  logo={''}/>
<Breadcrumb breadcrumb={this.state.breadcrumb} />
<div className="container blog">
<div className="row">
<div className="col-md-10 m-auto">
{ this.state.blogs.length > 0 ? 

this.state.blogs.map((post:any,index) => {
  var title= post.title.split('|');
    return (<>
<div className="card" >
    {
      post.image &&
      
        <a href="#" onClick={()=>this.goto('post/'+Base64e.encode(post.id+''))} className="blog-post-hero blog-post-hero--short" style={{ backgroundImage: `url(${variables.BaseUrl+'files/'+post.image}?w=1400&auto=format)`}}></a>
     
    }
    <div className="card-padding">
      <h2 className="blog__title blog__title--small blog-title">
       
          <a href="#" onClick={()=>this.goto('post/'+Base64e.encode(post.id+''))}>{title[0]} <br className="responsive"/> {title[1]}</a>
     
      </h2>
      <div className="blog__author">

          <a>
            <div className="blog__author-image" style={{ backgroundImage: `url(${variables.BaseUrl+'files/'+post.image}?w=100&auto=format)`}}></div>
          </a>
        
        <div className="blog__author-title">by <a>SLAMMMM.com</a> on {format(new Date(), 'MM/dd/yyyy')}</div>
        <div className="clearfix"></div>
      </div>
      <div className="blog__teaser droid" dangerouslySetInnerHTML={{__html: post.teaser}}></div>
      <div className="blog__read-more">
    
          <a href="#" onClick={()=>this.goto('post/'+Base64e.encode(post.id+''))}>Read more...</a>
       
      </div>
    </div>
  </div>
  </>)
}) : '' }
 </div>
 </div>
 </div>
 </div>

 
)
}

}



export default Blog;