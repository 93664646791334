import React from "react"
import { Backdrop, Box, Button, Fade, Grid, Modal, Slider, Typography, Snackbar, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";
import logo from '../../assets/img/logo-pitch.png'
import logoWithout from '../../assets/img/logo-without.png'
import variables from "../../global/global";
import axios from "axios";
import Base64e from "../encodedecode/encode";
import Swal from 'sweetalert2';
import ClearIcon from '@mui/icons-material/Clear';



class Sendpitch extends React.Component<any, any>{
  public state = {
    dob_error: '',
    go: false,
    page: '',
    text: '',
    open: this.props.data.sendpitch.open,
    priority: this.props.data.priority,
    transparent: 100 - this.props.data.priority,
    msg: '',
    loading: false,
    permission: true,
    snack_open: false,
    snackmsg: '',
    insterst_msg: this.props.data.insterst_msg,

  }
  public marks = [
    {
      value: 25,
      label: '25%',

    },
    {
      value: 50,
      label: '50%',

    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];
  // constructor(props:any) {
  //     super(props)


  // }

  componentDidMount(): void {

    if (this.props.data.user.payments[0].plan_id === '4') {
      let trial_end = this.props.data.user.payments[0].start_date;

      const date1 = new Date();
      const date2 = new Date(trial_end);
      console.log(trial_end);

      const diffTime = date2.valueOf() - date1.valueOf();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      console.log(diffTime + " milliseconds");
      console.log(diffDays + " days");

      if (diffTime <= 0) {

        this.setState({ permission: false }, () => {

          Swal.fire({
            icon: "info",
            title: '',
            allowOutsideClick: false,
            text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
            confirmButtonColor: '#7b1fa2',
          }).then((res) => {
                
            window.location.href = '/subscription?open=1';
            //window.location.reload();
          })



        })

      } else {
        this.setState({ permission: true });
      }
    }
    this.setInterest(this.state.priority);
  }

  public close = () => {
    const close = { open: false };
    this.props.returnhandle({ sendpitch: close });
  }

  public msgSet = (e: any) => {
    this.setState({ msg: e.target.value })
  }

  public setInterest = (value: any) => {
    this.setState({ priority: value });

    const vv = 100 - value;
    console.log('trans', vv);

    this.setState({ transparent: vv });
    console.log(value);

    if (value >= 1 && value <= 24) {

      // this.setState({insterst_msg: "Your interest is heading towards first base!"});

    } else if (value >= 25 && value <= 49) {
      // this.setState({insterst_msg: "Your interest is first base!"});



    } else if (value >= 50 && value <= 74) {
      // this.setState({insterst_msg: "Your interest is second base!"});



    } else if (value >= 75 && value <= 99) {
      // this.setState({insterst_msg: "Your interest is third base!"});



    } else if (value == 100) {
      // this.setState({insterst_msg: "Your interest is a home run!"});




    }
  }
  render(): React.ReactNode {


    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '95%',
      margin: 'auto',
      textAlign: 'center',
      bgcolor: '#8700c7',
      color: '#fff',
      border: '2px solid #000',
      boxShadow: 24,
      p: 2,
    };

    function valuetext(value: number) {
      if (value >= 1 && value <= 24) {
        return "Headed  towards first base!";
      } else if (value >= 25 && value <= 49) {
        return "First base!";

      } else if (value >= 50 && value <= 74) {
        return "second base!";
      } else if (value >= 75 && value <= 99) {
        return "Third base!";

      } else if (value == 100) {
        return "Home run!";

      }
    }

    return (
      <>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={this.state.open}
          onClose={this.close}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.open}>
            <Box className='updateModal interestbox' sx={style}>
              <Typography variant="h5">{this.props.from === 'massages' || this.props.from === 'massages-list' ? 'Change your interest in ' : 'Rate your interest in'} {this.props.data.sendpitch.value.first_name}</Typography>
              <Button className="close" variant="text" onClick={this.close}><ClearIcon className='backarrow' /></Button>
              <div className="container main" >
                {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
                <Grid container md={12}>

                  <Grid item md={12} xs={12}>
                    <br />
                  </Grid>
                  <Grid item md={6} xs={6}>
                    <img src={this.props.data.sendpitch.value !== null ? this.props.data.sendpitch.value.pic : ''} width={'50%'} alt="logo" />
                  </Grid>
                  <Grid item md={1} xs={1}>
                    <div className='percet'>{this.state.priority}%</div>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <div className='pos-re'>
                      <img className='fade-img' src={logoWithout} width={'93%'} alt="logo" />
                      <img className='heart-img' src={logo} width={'60%'} alt="logo" style={{
                        WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + this.state.transparent + '%, black 0%, black 90%, transparent 100%)',
                        maskImage: 'linear-gradient(to bottom, transparent ' + this.state.transparent + '%, black 0%, black 90%, transparent 100%)'
                      }} />
                    </div>
                  </Grid>

                  <Grid item md={12} xs={12} ><br />
                    <div className='px-3'>
                      <Slider
                        sx={{
                          color: '#ffffff',
                        }}
                        aria-label="Temperature"
                        defaultValue={this.state.priority}
                        value={this.state.priority}
                        //valueLabelFormat={valuetext}
                        valueLabelDisplay="auto"
                        step={1}
                        onChange={(_, newValue) => this.setInterest(newValue)}
                        marks={this.marks}
                        min={0}
                        max={100}
                      />
                    </div>
                    {this.props.from !== 'massages' && this.props.from !== 'massages-list' ? <div>

                      <Typography variant="h6" className="mb-1">{this.state.insterst_msg}</Typography>
                      <Typography variant="h6" className="mb-1">Add a message</Typography>
                      <textarea style={{ width: '100%' }} placeholder='Write your message here..........' rows={3} onKeyUp={(e) => this.msgSet(e)} defaultValue={this.state.text} /></div> :
                      ''
                    }
                    {/* <Typography variant="h5" className="mb-1">{this.state.insterst_msg}</Typography> */}
                    <LoadingButton
                      style={{  marginTop: '5px', }}
                      type="submit"
                      size="large"
                      color='info'
                      disabled={false}
                      className="btns text-lowercase sendpitchbtn"
                      loading={this.state.loading}
                      onClick={() => this.handleSubmit(this.props.data.sendpitch.value.id)}
                      loadingPosition="center"
                      variant="contained">
                      {this.props.from === 'match' || this.props.data.sendpitch.buttontext=='Send Pitch' ? 'Send pitch' : this.props.from === 'massages' || this.props.from === 'massages-list' ? 'Update Interest' : 'Match'}
                    </LoadingButton>
                  </Grid>

                </Grid>



              </div>
            </Box>
          </Fade>
        </Modal>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.snack_open}
          autoHideDuration={3000}
          onClose={() => this.setState({ snack_open: false })}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {this.state.snackmsg}
          </Alert>
        </Snackbar>
      </>
    );
  }





  public handleSubmit = (id: any) => {
    console.log('dssdds', this.props.from);


    if (this.state.permission) {
      this.setState({ loading: true });
      let data: any = [];
      if (this.props.from == 'hitRequest' || this.props.from == 'hit_back_from_profile') {
        data = {
          from: Base64e.encode(id + ''),
          to: Base64e.encode(this.props.data.id + ''),
          priority: this.state.priority,
          msg: this.state.msg,
          id: this.props.data.sendpitch.id,
          column: this.props.data.sendpitch.column,
        };
        console.log('dattcccc',data);
        
      } else if (this.props.data.sendpitch.status == 2) {
        console.log('prop', this.props.data);

        data = {
          from: Base64e.encode(this.props.data.user.id + ''),
          to: Base64e.encode(id + ''),
          priority: this.state.priority,
          msg: this.state.msg,
          id: this.props.data.sendpitch.id,
          status: 2
          //column:this.props.data.sendpitch.column,
        };
      } else if (this.props.from === 'massages' || this.props.from === 'massages-list') {

        data = {
          from: Base64e.encode(this.props.data.id + ''),
          to: Base64e.encode(id + ''),
          priority: this.state.priority,
          msg: this.state.msg,
          id: this.props.data.sendpitch.id,
          column: this.props.data.sendpitch.column,
        };

      } else {

        data = {
          from: Base64e.encode(this.props.data.id + ''),
          to: Base64e.encode(id + ''),
          priority: this.state.priority,
          msg: this.state.msg,
          id: '',
        };

      }



      console.log('dssddssss', data);
      axios.post(variables.url + 'sendUserRequests', data).then((res) => {
        if (res.status === 200) {
          this.setState({ loading: false, open: false });
          //this.props.removeRequestedUser(id)
          console.log('dssddssssvvv', res.data);
          this.setState(
            {
              loading: false,
              snack_open: true,
              snackmsg: 'Request sent successfully!'
            });
          console.log(this.props.from);
          if (this.props.from == 'hit_back_from_profile') {
            this.setState({ go: true, page: 'ChatList' });
          } else if (this.props.from == 'hitRequest') {  
             this.props.openChat(res.data);
          } else if (this.props.from != 'massages') {
            window.location.reload();
          } else {
            this.props.setpriority(this.state.priority)
          }
        }
      }).catch((e: any) => {
        console.log(e);

      });
    } else {
      alert('access denied')
    }
  }


}

export default Sendpitch;