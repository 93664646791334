import { Backdrop, Box, Button, ButtonGroup, Divider, Fade, Grid, Modal, Typography } from '@mui/material';
import React from 'react';
import TopHeader from '../includes/header';
import Breadcrumb from '../includes/breadcrumb';
import { Navigate } from 'react-router-dom';
import ProfileUpdate from '../../components/profile/updateField';
import { LoadingButton } from '@mui/lab';
import Base64e from '../../components/encodedecode/encode';
import variables from '../../global/global';
import axios from 'axios';
import { withSignOut } from 'react-auth-kit'
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Swal from 'sweetalert2';
import { ToggleSlider } from "react-toggle-slider";



class Settings extends React.Component<any>{

  // constructor(props:any) {
  //     super(props)
  // }

  public state = {
    user: this.props.user().rows[0],
    clickchange: this.props.user().rows[0].hide_profile,
    hide_profile: this.props.user().rows[0].hide_profile,
    paused_on:0,
    paused_status:0,
    activepic: 0,
    bool: false,
    path: '',
    edit: {
      type: '',
      value: '',
      id: '',
    },
    editField: false,
    loading: false,
    open: false,
    success: '',
    msg: '',
    deleteType: 0,
    modal: {
      title: '',
      sub: '',
      sub1: '',
      ok: '',
      cancle: '',
    },
    modalAfterResponse: {
      title: '',
      sub: '',
      sub1: '',
    },
    breadcrumb: [{
      link: '',
      link_name: 'Settings'
    }],
    checked: false
  }

  componentDidMount(): void {
    console.log('ddd', this.state.hide_profile);
    console.log('paused_status', this.state.paused_status);
    console.log('ddd', this.props.user().rows[0]);
    
  }

  public handleChange = (event: any) => {
    if (event != this.state.hide_profile) {
      this.setState({ hide_profile: event == true ? 1 : 0 }, () =>
        this.openModal(this.state.hide_profile == 0 ? 4 : 2)
      );
    }
  };
  // public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   this.setState({hide_profile: event.target.checked==true ? 1 :0});
  // }; 
  public handleChange2 = (event: any) => {
    if (event != this.state.paused_status) {
      this.setState({ paused_status: event == true ? 1 : 0 }, () =>
        this.checksubscription()
      );
    }


  };

  public render() {
    return (
      <div className='App' >
        {this.state.bool === true ? <Navigate state={this.state} to={"/" + this.state.path} replace={true} /> : ''}
        <TopHeader title="Settings" logo={''} />
        <Breadcrumb breadcrumb={this.state.breadcrumb} />
        <h5 className='page-title'>Settings</h5>
        <div className='setting_ '>
          <Grid container spacing={2}>

            <Grid item xs={12} md={12}>
              <Typography variant='h5'  >Mobile: {this.state.user.number} <Button className='w_40' onClick={() => this.updateFeild('number', this.state.user.number, this.state.user.id)} color='secondary' size='small' variant='text'><i className='fas iconEdit  blue'>&#xf044;</i></Button></Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant='h5'  >{this.state.user.email} <Button className='w_40' onClick={() => this.updateFeild('email', this.state.user.email, this.state.user.id)} color='secondary' size='small' variant='text'><i className='fas iconEdit blue'>&#xf044;</i></Button></Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant='h5'  >Blocked Users <Button className='w_40' color='secondary' onClick={() => this.setState({ bool: true, path: 'blockedUsers' })} size='small' variant='text'><i className='fas iconEdit blue'>&#xf044;</i></Button></Typography>
            </Grid>

            <Grid item xs={12} md={10}>
              <Grid container className="setting-btns pb-5" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant='h5'  ><Button className='btns purple-btn' color='secondary' size='medium' onClick={() => this.setState({ bool: true, path: 'subscription' })} variant='contained'>Subscription Details</Button></Typography>

                </Grid>
                 
                <Grid item xs={12} md={6}>
                  <Typography variant='h5'  ><Button className='btns purple-btn' color='secondary' size='medium' onClick={() => this.setState({ bool: true, path: 'support' })} variant='contained'>Support</Button></Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='h5'  ><Button className='btns purple-btn' color='secondary' size='medium' variant='contained' onClick={() => this.openModal(3)}>Cancel Subscription</Button></Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography variant='h5'  >
                    <Button className='btns purple-btn' color='secondary' size='medium' variant='contained' >Hide Profile &nbsp; &nbsp;

                      <ToggleSlider key={this.state.hide_profile} draggable={false} active={this.state.hide_profile == 1 ? true : false}

                        onToggle={(active) => this.handleChange(active)}
                        barStyles={{ opacity: 0.38, position: 'relative', zIndex: 0 }}
                        barStylesActive={{ opacity: 0.38 }}
                        handleStyles={{ position: 'relative', zIndex: 1 }}
                        padding={0}
                        barHeight={15}
                        barWidth={74}
                        handleSize={22}
                        barBackgroundColor={'#000'}
                        barBackgroundColorActive={'#15e920'}

                      />

                      {/* <Switch  draggable={true} checked={this.state.hide_profile==1 ? true : false}
              onChange={this.handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              color="success" /> */}
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant='h5'  ><Button className='btns red-btn' color='secondary' size='medium' variant='contained' onClick={() => this.openModal(1)}>Delete Account</Button></Typography>

                </Grid>
                <Grid item xs={12} md={6}>
                  {/* <Typography variant='h5'  >

                    <Button className='btns purple-btn' color='secondary' size='medium' variant='contained' >Freeze Subscription &nbsp; &nbsp;
                      <ToggleSlider key={this.state.paused_status} draggable={false} active={this.state.paused_status == 1 ? true : false}

                        onToggle={(active) => this.handleChange2(active)}
                        barStyles={{ opacity: 0.38, position: 'relative', zIndex: 0 }}
                        barStylesActive={{ opacity: 0.38 }}
                        handleStyles={{ position: 'relative', zIndex: 1 }}
                        padding={0}
                        barHeight={15}
                        barWidth={74}
                        handleSize={22}
                        barBackgroundColor={'#000'}
                        barBackgroundColorActive={'#15e920'}

                      />


                      {/* <Switch   draggable={true}
           checked={this.state.paused_status==1 ? true : false}
           onChange={this.handleChange2}
           inputProps={{ 'aria-label': 'controlled' }}
           color="success" /> 
                    </Button>

                  </Typography>*/}
                </Grid>
              </Grid>




            </Grid>


          </Grid>

          {this.state.editField ? <ProfileUpdate data={this.state} returnhandle={(e: any) => this.setState(e)} /> : ''}


          <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{ pointerEvents: 'none' }}
          >
            <Fade in={this.state.open} >
              <Box className='modal_confirmbox' style={{ pointerEvents: 'all' }}>
                {this.state.success === '' ? <div><Typography className='text-center' variant='h4'>{this.state.modal.title}</Typography>
                  <Typography className='text-center' variant='h6'>{this.state.modal.sub}</Typography>
                  <Typography className='text-center color-pink' variant='h6'>{this.state.modal.sub1}</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <LoadingButton
                        style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                        type="submit"
                        size="large"
                        disabled={false}
                        className="login-button"
                        color='success'
                        loading={this.state.loading}
                        loadingPosition="center"
                        onClick={() => this.accountAction()}
                        variant="contained">
                        {this.state.modal.ok}
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <LoadingButton
                        style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                        type="submit"
                        size="large"
                        disabled={false}
                        className="login-button back-pink"
                        color='error'
                        loading={false}
                        loadingPosition="center"
                        onClick={() => this.cancelpopup()}
                        variant="contained">
                        {this.state.modal.cancle}
                      </LoadingButton>
                    </Grid>
                  </Grid></div>
                  :
                  <div style={{ textAlign: 'center' }}>
                    <Typography className='text-center' variant='h4'>{this.state.modalAfterResponse.title}</Typography>
                    <Typography className='text-center' variant='h6'>{this.state.modalAfterResponse.sub}</Typography>
                    <Typography className='text-center color-pink' variant='h6'>{this.state.modalAfterResponse.sub1}</Typography>
                    {
                      this.state.deleteType === 1 ? <ButtonGroup >

                        <LoadingButton
                          style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                          type="submit"
                          size="large"
                          disabled={false}
                          className="login-button"
                          color='secondary'
                          loading={false}
                          loadingPosition="center"
                          onClick={() => this.props.signOut()}
                          variant="contained">
                          Home
                        </LoadingButton>&nbsp;&nbsp;&nbsp;
                        {/* <LoadingButton
          style={{display:'flex',margin:'auto',marginTop:'10px'}}
          type="submit"
          size="large"
          disabled={false}
          className="login-button"
          color='secondary'
  loading={false}
  loadingPosition="center"
  onClick={()=>this.setState({bool:true,path:'support'})}
  variant="contained">
Support
</LoadingButton> */}

                      </ButtonGroup>
                        :

                        <LoadingButton
                          style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                          type="submit"
                          size="large"
                          disabled={false}
                          className="login-button"
                          color='error'
                          loading={false}
                          loadingPosition="center"
                          onClick={() => { window.location.reload() }}
                          variant="contained">
                          Ok
                        </LoadingButton>

                    }
                  </div>
                }
              </Box>
            </Fade>
          </Modal>

        </div>
      </div>

    )
  }

  public cancelpopup = () => {


    this.setState({ open: false })
    if (this.state.deleteType == 2 || this.state.deleteType == 4) {
      this.setState({ hide_profile: this.state.hide_profile == 1 ? 0 : 1 })
    } else if (this.state.deleteType == 5 || this.state.deleteType == 6) {
      this.setState({ paused_status: this.state.paused_status == 1 ? 0 : 1 })
    }
  }
  public updateFeild = (type: string, value: any, id: any) => {
    this.setState({
      edit: {
        type: type,
        value: value,
        id: id,
      }, editField: true
    });

  }

  public openModal = (i: number) => {
    console.log(this.state.user);
    if (i === 1) {
      const modal = {
        title: 'Are you sure you want to permanently Delete your account?',
        sub: 'Once your account is deleted, all your settings & account information will be deleted',
        sub1: 'NOTE: There will be no refunds for remaining subscription time after deleting account',
        ok: 'YES, PLEASE DELETE MY ACCOUNT',
        cancle: 'NO, DO NOT DELETE MY ACCOUNT',
      }
      this.setState({ modal: modal, open: true, deleteType: i });
    }
    if (i === 2) {
      const modal = {
        title: 'Are you sure you want to hide your profile?',
        sub: 'Your profile will not be visible to other users until you off this setting.',
        sub1: '',
        ok: 'YES, PLEASE HIDE MY PROFILE',
        cancle: 'NO, DO NOT HIDE MY PROFILE',
      }
      this.setState({ modal: modal, open: true, deleteType: i });
    }

    if (i === 3) {
      if(this.state.user.payments.length > 0 && this.state.user.payments[0].transaction_id != null && this.state.user.payments[0].transaction_id != ''){
        const modal = {
          title: 'Are you sure you want to cancel your Subscription?',
          sub: 'Any paid plan may be cancelled, however, there will be no refunds. Your profile will stay intact until/unless you decide to delete your account.',
          sub1: '',
          ok: 'YES, PLEASE CANCEL MY SUBSCRIPTION',
          cancle: 'NO, DO NOT CANCEL MY SUBSCRIPTION',
        }
        this.setState({ modal: modal, open: true, deleteType: i });
      }else{
        Swal.fire({
          title: "You don't have any subscription!",
          text: "Please buy a plan",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then(async (result) => {

          this.setState({ paused_status: this.state.paused_status == 1 ? 0 : 1 })
        });
      }

    }
    if (i === 5) {
      const modal = {
        title: 'Are you sure you want to Freeze your subscription?',
        sub: 'Each paid plan may be frozen one time only per each pay period until the subscription is unfrozen.',
        sub1: 'However, there are no refunds for any period for which your subscription has been frozen.',
        ok: 'YES, PLEASE FREEZE MY SUBSCRIPTION',
        cancle: 'NO, DO NOT FREEZE MY SUBSCRIPTION',
      }
      this.setState({ modal: modal, open: true, deleteType: i });
    }
    if (i === 6) {
      const modal = {
        title: 'Are you sure you want to unfreeze your Subscription?',
        sub: 'Each paid plan may be frozen one time only per each pay period until the subscription is unfrozen.',
        sub1: 'However, there are no refunds during your frozen period',
        ok: 'YES, PLEASE UNFREEZE MY SUBSCRIPTION',
        cancle: 'NO, DO NOT UNFREEZE MY SUBSCRIPTION',
      }
      this.setState({ modal: modal, open: true, deleteType: i });
    }

    if (i === 4) {
      const modal = {
        title: 'Are you sure you want to show your profile?',
        sub: 'Your profile will  be visible to other users until you on this setting.',
        sub1: '',
        ok: 'YES, PLEASE SHOW MY PROFILE',
        cancle: 'NO, DO NOT SHOW MY PROFILE',
      }
      this.setState({ modal: modal, open: true, deleteType: i });
    }
  }

  public checksubscription() {

    const idx = Base64e.encode(this.state.user.id + '');
    axios.post(variables.url + 'UserAccountActions', { id: idx, type: 7 })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          if (res.data.id) {
            if (res.data.plan.id == 'price_1NNsAuI2tSAW9AOGh200G8s7' && this.state.paused_status == 0) {
              var start = res.data.current_period_start;
              var end = res.data.current_period_end;
              const date = new Date(start);
              var to = new Date(date.setMonth(date.getMonth() + 6));
              var from = start;

              var check = new Date(this.state.paused_on);
              if (check > from && check < to) {
                Swal.fire({
                  title: "You can't freeze the subscription!",
                  text: "You can't freeze the subscription more than one in MVP plan.",
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Ok'
                }).then(async (result) => {


                });
              } else
                if (to > from && check < end) {
                  Swal.fire({
                    title: "You can't freeze the subscription!",
                    text: "You can't freeze the subscription more than one in MVP plan.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                  }).then(async (result) => {


                  });
                } else {
                 // this.openModal(this.state.paused_status == 1 ? 5 : 6)
                  console.log('openModal', this.state.paused_status);
                }


            } else {
           ///   this.openModal(this.state.paused_status == 1 ? 5 : 6)
              console.log('openModal', this.state.paused_status);
            }



          } else {
            Swal.fire({
              title: "You don't have any subscription!",
              text: "Please buy a plan",
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok'
            }).then(async (result) => {

              this.setState({ paused_status: this.state.paused_status == 1 ? 0 : 1 })
            });
          }

        }
      });
  }

  public accountAction = () => {
    let modal: any = {};
    this.setState({ loading: true });
    const idx = Base64e.encode(this.state.user.id + '');
    const trans = Base64e.encode(this.state.user.payments[0].transaction_id);
    axios.post(variables.url + 'UserAccountActions', { id: idx,sub:trans, type: this.state.deleteType })
      .then((res) => {
        if (res.status === 200) {
          if (this.state.deleteType === 1) {
            modal = {
              title: 'Account Deleted',
              sub: 'Your account has been deleted and any active subscriptions canceled.',
              sub1: 'We are sorry to see you go and hope you will come back soon.',
            }
            localStorage.clear();
          } else if (this.state.deleteType === 2) {
            modal = {
              title: 'Thanks for using slammmm.com',
              sub: 'Your profile has been hidden.  We hope to see you back soon.',
              sub1: 'Any paid subscriptions are still active.  To make your profile visible again, simply slide the bar to the left on settings..',
            }
            this.setState({ hide_profile: 1 })
          } else if (this.state.deleteType === 3) {
            modal = {
              title:'Your paid subscription has been cancelled. ',
              sub:'You still have access to paid features until your next billing date.',
              sub1:'After next billing date, you will only be able to access free site features.',
            }
            

          } else if (this.state.deleteType === 4) {
            modal = {
              title: 'Thanks for using slammmm.com',
              sub: 'Your profile has been visible.',
              sub1: 'Any paid subscriptions are still active.',
            }
            this.setState({ hide_profile: 0 })
          } else if (this.state.deleteType === 5) {
            modal = {
              title: 'Thanks for using slammmm.com',
              sub: 'Your subscription has been freezed.',
              sub1: '',
            }

          } else if (this.state.deleteType === 6) {
            modal = {
              title: 'Thanks for using slammmm.com',
              sub: 'Your subscription has been resumed.',
              sub1: '',
            }

          }
          console.log(res);
          localStorage.setItem('_auth_state', JSON.stringify(res.data));
          this.setState({ success: 'Successfully Deleted', loading: false, modalAfterResponse: modal });


        }
      }).catch((e: any) => {
        console.log(e);
        this.setState({ msg: 'server error', loading: false });

      });

  }



}

export default withSignOut(Settings);