
import React from "react"

import { Navigate } from "react-router-dom";
import logo from '../assets/img/logo.png'
import logo1 from '../assets/img/logo-1.png'
import logo2 from '../assets/img/logo-2.png'
import img1 from '../assets/img/slider-1.png'
import img2 from '../assets/img/slider-2.png'
import img3 from '../assets/img/slider-3.png'
import pic1 from '../assets/img/Picture-8.png'
import pic2 from '../assets/img/Picture-7.png'
import pic3 from '../assets/img/Picture-6.jpg'
import pic4 from '../assets/img/Picture-5.jpg'
import banpic from '../assets/img/ban-pic.png'
import slicer from '../assets/img/slicer.png'
import logopitch from '../assets/img/logo-pitch.png'
import logoWithout from '../assets/img/logo-without.png'
import heromobile from '../assets/img/hero-mobile.png'
import backgroundimg from '../assets/img/background.png';
import Footer from "../components/footer/Footer";
import Sliderhome from "../components/Sliderhome";
import Typewriter from 'typewriter-effect';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
class WelcomeComponent extends React.Component<any> {
  public state = {
    go: false,
    page: '#',
    priority: 0,
    tmr: {},
    hide: ''

  }

  constructor(props: any) {
    super(props)
    console.log('as');


  }

  componentWillMount() {
    document.body.classList.add('bg-speckles');
    document.body.classList.remove('bg-smoke');

  }
  public goto = (page: any) => {
    this.setState({ go: true, page: page });

  }

  public removeText = (e: any) => {
    console.log('carousel', e);
    if (e > 0) {
      //this.setState({ hide: 'hide' });
    } else {
      this.setState({ hide: '' });
    }
  }

  public render() {


    return (
      <>
        <div className="main-container">
          {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={true} /> : ''}
          <section className="header pt-2 ">
            <div className="container-fluid">
              <div className="row show-md" data-aos="fade-in">
                <div className="col-2 col-lg-2 col-md-2 position-relative mt-2">
                  <a href="javascript::void();" className="logo1 align-items-center">
                    <img src={logo1} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                    <span className="header-tm-text logotm2">TM</span>
                  </a>
                </div>
                <div className="col-10 col-lg-10  col-md-10 position-relative d-flex align-items-center">
                  <a href="javascript::void();" className="logo align-items-center m-left ">
                    <img src={logo2} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                    <span className="header-tm-text">TM</span>
                  </a>
                  <hr className="logo-hr" />

                </div>
                <div className="gold-line"> &nbsp; </div>
              </div>
              <div className="row show-sm" data-aos="fade-in">

                <div className="col-lg-12  position-relative">
                  <a href="javascript::void();" className="logo align-items-center">
                    <img src={logo} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                    {/* <span className="header-tm-text-moblie header-tm-text-moblie2">TM</span> */}

                  </a>
                  <hr className="logo-hr" />

                </div>
                <div className="gold-line"> &nbsp; </div>
              </div>

            </div>

          </section>

          <section className="main">
            <div className="container-fluid cont position-relative pb-1 pt-2">
              <div className="row gy-5" data-aos="fade-in">
                <div className="col-lg-2 position-relative sm-show mt-4">
                  {/* <img className="baner-img" src={img1} /> */}
                  <Carousel className="carousel" onChange={(e) => this.removeText(e)} autoPlay={true} infiniteLoop={true} showThumbs={false}>
                    <div>
                      <img className="baner-img" src={pic1} />

                    </div>
                    <div>
                      <img src={pic2} />

                    </div>
                    <div>
                      <img src={img1} />

                    </div>
                    <div>
                      <img src={pic3} />

                    </div>
                    {/* <div>
                      <img src={pic4} />

                    </div> */}
                  </Carousel>

                  <div className={this.state.hide == 'hide' ? 'hide' : "main_area pt-0 d-flex flex-column right-bar"}>

                    <h3 className="mb-0">Where </h3>
                    <h2><span className="first change">S</span>ingle</h2>
                    <h2><span className="first change1">L</span>adies</h2>
                    <h2><span className="first change2">A</span>nd</h2>
                    <h2><span className="first change3">M</span>en</h2>
                    <h2><span className="first change4">M</span>eet</h2>
                    <h2><span className="first change5">M</span>ingle & </h2>
                    <h2><span className="first change6">M</span>arry  &nbsp;<span className="tm-mobile">TM</span></h2>

                  </div>
                </div>
                <div className="col-lg-12 text-center  order-lg-first position-relative mt-xs-0">

                  <div className="row gy-5 crous" data-aos="fade-in">
                    {/*  <div className="col-md-3 p-0 pic-1"> <img src={img4} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" /></div>
       <div className="col-md-3 p-0 pic-2"><img src={img2} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" /></div>
        <div className="col-md-2 p-0 pic-3"><img src={img3} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" /></div> */}
                    <div className="col-md-12 p-0 pic-4 mtop-3">
                      <img src={banpic} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" />
                      <div className="main_area pt-0 d-flex flex-column right-bar letters">
                        <h3 className="mb-4">Where </h3>
                        <h2><span className="first change">S</span>ingle</h2>
                        <h2><span className="first change1">L</span>adies</h2>
                        <h2><span className="first change2">A</span>nd</h2>
                        <h2><span className="first change3">M</span>en</h2>
                        <h2><span className="first change4">M</span>eet</h2>
                        <h2><span className="first change5">M</span>ingle & </h2>
                        <h2><span className="first change6">M</span>arry    &nbsp;<span className="tm">TM</span></h2>
                      </div>
                    </div>

                    {/* <div className="col d-flex position-relative height-337">
        <div className="div4 text-center">
          <img src={img4} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" />
          </div>
          <div className="div5 text-center">
          <img src={img5} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" />
          </div>
          <div className="div1 text-center">
          <img src={img2} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" />
          </div>
          <div className="div2  text-center">
          <img src={img3} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" />
          </div>
          <div className="div3 text-center">
          <img src={img1} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" />
          <div className="main_area pt-0 d-flex flex-column right-bar letters">
            <h3 className="mb-1">Where </h3>
            <h2><span className="first change">S</span>ingle</h2>
            <h2><span className="first change1">L</span>adies</h2>
            <h2><span className="first change2">A</span>nd</h2>
            <h2><span className="first change3">M</span>en</h2>
            <h2><span className="first change4">M</span>eet</h2>
            <h2><span className="first change5">M</span>ingle & </h2>
            <h2><span className="first change6">M</span>arry</h2>
            </div>
          </div>
          </div> */}
                  </div>


                  <div className="row gy-5  mt-0  yellarea" data-aos="fade-in">
                    <div className="col-12 col-md-7 m-auto text-center braces">
                      <h2>Introducing the <span>"Love Diamond"</span><span className="tm_love">TM</span> Feature</h2>
                      <h3>Show them your interest</h3>
                    </div>
                  </div>
                  <div className="row slicer">
                    <div className="col-3 btn-div text-center colum-center">
                      <a href="javascript::void();" onClick={() => this.goto('signup/questions')} className="btns red-btn ">Start</a>
                    </div>
                    <div className="col-6 text-center">
                      {/* <img src={slicer} alt="" className="img-fluid" data-aos="zoom-out" data-aos-delay="100" /> */}

                      <Sliderhome />
                    </div>
                    <div className="col-3 btn-div text-center colum-center">
                      <a href="javascript::void();" onClick={() => this.goto('signin')} className="btns purple-btn"><span>Sign In</span></a>
                    </div>
                  </div>

                </div>



              </div>
            </div>

          </section>









          {/* <div className="maink">
                     {this.state.go===true ? <Navigate state={this.state} to={"/"+this.state.page} replace={true}/> : ''}
                     <section className="main">
    <div className="container position-relative">
      <div className="row m_auto gy-5" data-aos="fade-in">
	  <div className="col-lg-6 img_col">
          <img src={logo} className="img-fluid hero-img" alt="" data-aos="zoom-out" data-aos-delay="100"/>
        </div>
        <div className="col-lg-6 main_area d-flex flex-column justify-content-center text-center text-lg-center">
		 <span  className="logo align-items-center">
       <img src={logo2} alt=""/>       
      </span>
          <h1>SLAMMMM.com</h1>
		  <img src={heromobile} className="img-fluid hero-img sml" alt="" data-aos="zoom-out" data-aos-delay="100"/>
		  <h2>Introducing the "Love Diamond" Feature</h2>		  
          <h3>Where <span className="first">S</span>ingle <span className="first">L</span>adies <span className="first">A</span>nd <span className="first">M</span>en go to
        
          <Typewriter
           options={{
            strings: ['<span className="first">M</span>eet','<span className="first">M</span>ingle','& <span className="first"> M</span>arry'],
            autoStart: true,
            loop: true,            
          }}
          
/>

</h3> */}
          {/* <div>
  <span className="change">g</span>ood
  <span className="change1">m</span>orning
  <span className="change2">m</span>orning
  <span className="change3">m</span>orning
  <span className="change4">m</span>orning
  <span className="change5">m</span>orning
  <span className="change6">m</span>orning
</div> */}
          {/* <div className="d-flex justify-content-center justify-content-lg-center pt-sm-12">  
            <button  className="btns red-btn me-4" onClick={()=>this.goto('demo-signup')}>Start</button>      
            <button  className="btns purple-btn me-4" onClick={()=>this.goto('signup/questions')}>Sign up</button>
            <button  className="btns purple-btn" onClick={()=>this.goto('signin')}><span>Sign In</span></button>
          </div>
        </div>
        
      </div>
    </div>
</section>   </div> */}

          <Footer />
        </div>
      </>
    )
  }

}


export default WelcomeComponent;