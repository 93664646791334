import React from "react"
import { Box, Chip, Button, ButtonGroup, CircularProgress, Stack, FormHelperText, FormControl, InputLabel, MenuItem, Checkbox, ListItemText, OutlinedInput } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import variables from '../../global/global';
import axios from 'axios';
import { withSignIn } from "react-auth-kit";
import Swal from 'sweetalert2';
import { a } from "@react-spring/web";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import { log } from "console";

class Question extends React.Component<any>{
    public state = {
        go: false,
        page: '',
        questions: [],
        current_q: this.props.sendprop.current_q,
        personalities: [],
        add_personalities: [],
        selected_per: this.props.sendprop.selected_per,
        selected_per_add: this.props.sendprop.selected_per_add,
        per_error: '',
        open: false,
    }

    // constructor(props:any) {
    //     super(props)
    // }

    public setactiveInstate = (val: any, id: any, q: any) => {
        console.log('ansss', val);

        if (this.props.sendprop.demo && (id == 116 || id == 117)) {
            let cer = {};
            if (id == 116) {
                cer = { email: "test_male@gmail.com", password: "Vv@123456" };
            } else {
                cer = { email: "test_female@gmail.com", password: "Vv@123456" };
            }

            this.setState({ loading: true });

            axios.post(variables.url + 'Userlogin', cer)
                .then((res) => {
                    this.setState({ load: false });
                    if (res.status === 200 && res.data.error === '') {

                        if (this.props.signIn({
                            token: res.data.token,
                            expiresIn: res.data.expiresIn,
                            tokenType: "Bearer",
                            authState: res.data.authUserState,
                            refreshToken: res.data.refreshToken,                    // Only if you are using refreshToken feature
                            refreshTokenExpireIn: res.data.refreshTokenExpireIn
                        })) { // Only if you are using refreshToken feature
                            this.props.returnHandle({ go: true, page: 'Dashboard' });
                        }
                    } else if (res.data.error !== '') {

                        Swal.fire({
                            icon: "error",
                            title: '',
                            text: res.data.error || 'server error.',
                            confirmButtonColor: '#7b1fa2',
                        }).then(() => {

                            this.props.returnHandle({ go: true, page: 'signin' });
                        });

                    } else {
                        alert('something goes wrong!');
                    }
                }).catch(() => {
                    this.setState({ load: false });
                    Swal.fire({
                        icon: "error",
                        title: '',
                        text: 'server error.',
                        confirmButtonColor: '#7b1fa2',
                    }).then(() => {

                        this.props.returnHandle({ go: true, page: 'signin' });
                    });


                });
        } else {
            setTimeout(function(){

                window.scrollTo({
                    top: 0,///document.body.scrollHeight,
                    left: 0,
                    behavior: 'smooth'
                  });
                  //alert('dfg');
            },1000);



            this.props.sendprop.questions.map((rw: any) => {
                if (q === rw.number) {
                    rw.selected = id;
                }
                return rw;
            });

            if (id !== '') {
                this.props.returnHandle({ questions: this.props.sendprop.questions })
            }

            setTimeout(() => {
                console.log('cccc', isNaN(val));

                if (isNaN(val)) {
                    console.log('ttttt', isNaN(val));
                    this.props.returnHandle({ page: 'verifypic' })
                } else {
                    console.log('HHHHHH', isNaN(val));
                    this.props.sendprop.questions.map((rw: any) => {
                         console.log(val);
                          console.log('this.props.sendprop.questiongroup.includes(val)',this.props.sendprop.questiongroup);
                          console.log('rw.number ',rw.number );
                        if (val === rw.number && this.props.sendprop.questiongroup.includes(val)) {
                            console.log('rw.idvvv ', rw.number);
                            console.log('current_q ', val);
                            this.setState({ questions: [rw], current_q: val });
                            this.props.returnHandle({ current_q: val })
                        } else if (this.props.sendprop.questiongroup.includes(val) === false) {
                            console.log('this.props.sendprop.prevNext[1]', this.props.sendprop.prevNext[1]);
                            this.props.returnHandle({
                                page: this.props.sendprop.prevNext[1]
                            })


                        }
                        return rw;
                    });
                }
            }, 200);

            console.log('questions',this.state.questions);


        }



    }

    public handleChange = (event: SelectChangeEvent<typeof this.state.selected_per>) => {
        const {
            target: { value },
        } = event;
        console.log('value', value);

        this.setState({ selected_per: value });
        this.props.returnHandle({ selected_per: value });
        if (this.state.selected_per.length > 0) {
            this.setState({ per_error: '' });
        }
        console.log('ssdfd', this.state.selected_per);
    };
    public handleChangeAdd = (event: SelectChangeEvent<typeof this.state.selected_per_add>) => {
        const {
            target: { value },
        } = event;
        console.log('value', value);

        this.setState({ selected_per_add: value });
        this.props.returnHandle({ selected_per_add: value });


    };


    componentDidMount(): void {
  
        
       
        console.log('ssdfd', this.state.selected_per);
        console.log(this.props.sendprop);

        this.setactiveInstate(this.state.current_q, '', '');
        const personalities: any = [];
        const add_personalities: any = [];
        const a: any = [];
        this.props.sendprop.questions.map((val: any) => {

            if (val.id == 9) {
                console.log('val.id', val.questions_options);
                val.questions_options.map((row: any) => {
                    personalities.push(row.option);
                    if (row.required == 1) {
                        // personalities.push(row.option);
                    } else {
                        add_personalities.push(row.option);
                    }


                })

            }

        })

        this.setState({ personalities: personalities, add_personalities: add_personalities });
       
    }

    public backpage = () => {
        console.log('backpage', this.state.current_q + '>' + this.props.sendprop.questiongroup[0]);
        console.log('backpage', this.props.sendprop.prevNext[0]);
        if (this.state.current_q > this.props.sendprop.questiongroup[0]) {
            this.setactiveInstate(this.state.current_q - 1, '', '');

        } else
            if (this.props.sendprop.prevNext[0] !== '') {
                this.props.returnHandle({ page: this.props.sendprop.prevNext[0] })
            }
    }


    render(): React.ReactNode {

        return (
            <div className="container main" >
                <div className={this.props.from === undefined ? 'd-block m_auto  col-md-5' : 'd-block m_auto  col-md-12'} >
                    {this.state.questions[0] !== undefined ? this.state.questions.map((val: any) => (
                        <>
                            {val.id == 9 ?
                                <div className="container signupform" key={val.id}>
                                    <h3 className="d-block text-center" >{val.question}</h3>

                                    <FormControl className="personalitytraits" sx={{ width: '100%' }}>
                                        <InputLabel id="demo-multiple-checkbox-label">Personality Traits</InputLabel>
                                        <Select
                                            error={this.state.per_error !== '' ? true : false}
                                            open={this.state.open}
                                            onOpen={() => this.setState({ open: true })}
                                            onClose={() => this.setState({ open: false })}

                                            multiple

                                            value={this.state.selected_per ?? []}
                                            onChange={this.handleChange}
                                            label="Personality Traits"
                                            input={<OutlinedInput id="select-multiple-chip" label="Personality Traits" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>

                                                    {selected.map((value: any) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}

                                        >
                                            <ClearIcon className='clear-ic staticy' onClick={() => this.setState({ open: false })} />
                                            <Button className="staticybutton" color="primary" variant="contained" onClick={() => this.setState({ open: false })}>Save</Button>
                                            {this.state.personalities.map((val: any) => (
                                                <MenuItem key={val} value={val}>
                                                    <Checkbox checked={this.state.selected_per != null && this.state.selected_per.includes(val)} />
                                                    <ListItemText className="personalitytext" primary={val} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText className="text-light" error={true}>{this.state.per_error}</FormHelperText>
                                    </FormControl>

                                    {/* <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox2-label">Additional Personality Traits</InputLabel>
                <Select
                 labelId="demo-multiple-checkbox2-label"
                id="demo-multiple-checkbox2"
                multiple
 
                value={this.state.selected_per_add ?? []}
                onChange={this.handleChangeAdd}
             
                input={<OutlinedInput id="select-multiple-chip2" label="Chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value:any) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
               
                >
                {this.state.add_personalities.map((val:any) => (
                    <MenuItem key={val} value={val}>
                    <Checkbox checked={this.state.selected_per_add != null && this.state.selected_per_add.includes(val)} />
                    <ListItemText primary={val} />
                    </MenuItem>
                ))}
                </Select>
               
            </FormControl> */}

                                    <ButtonGroup className="" style={{ width: '100%' }}>
                                        {this.props.from === undefined ?
                                            <LoadingButton
                                                style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                                                type="submit"
                                                size="large"
                                                disabled={false}
                                                className="btns login-next-button "
                                                loading={false}
                                                loadingPosition="center"
                                                onClick={() => {
                                                    if (this.state.current_q === 1) {
                                                        window.location.href = '/';
                                                    } else {
                                                        this.backpage()
                                                    }
                                                }}
                                                variant="contained">
                                                Back
                                            </LoadingButton>
                                            : ''}
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <LoadingButton
                                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                                            type="submit"
                                            size="large"
                                            disabled={false}
                                            className="btns  login-back-button"
                                            loading={false}
                                            loadingPosition="center"
                                            onClick={() => this.setProp()}
                                            variant="contained">
                                            {this.props.from === undefined ? 'Next' : 'Save'}
                                        </LoadingButton>
                                    </ButtonGroup>
                                </div>

                                :
                                <div className="container" key={val.number}>
                                    <h3 className="d-block text-center" >{val.question}</h3>
                                    <div className="d-grid justify-content-center justify-content-lg-center">
                                        {val.questions_options.map((row: any) => (<a key={row.id} className={val.selected === row.id ? 'btn-quest selected-btn mt-4' : 'btn-quest purple-btn mt-4'}
                                            onClick={() => this.setactiveInstate(parseInt(row.onclick_view_question), row.id, val.number)} >{row.option}</a>))}
                                    </div>
                                    <br></br>

                                    {this.props.from === undefined ? <ButtonGroup className="" style={{ width: '100%' }}>
                                        <LoadingButton
                                            style={{ display: 'initial', margin: 'auto', marginTop: '10px' }}
                                            type="submit"
                                            size="large"
                                            disabled={false}
                                            className="btns login-next-button"
                                            loading={false}
                                            loadingPosition="center"
                                            onClick={() => {
                                                if (this.state.current_q === 1) {
                                                    window.location.href = '/';
                                                } else {
                                                    this.backpage()
                                                }
                                            }}
                                            variant="contained">
                                            Back
                                        </LoadingButton>

                                    </ButtonGroup> : ''}


                                </div>
                            }
                        </>
                    )) :
                        <Stack sx={{ color: 'grey.500', textAlign: 'center', display: 'block' }} spacing={2} direction="row">
                            <CircularProgress color="secondary" />
                        </Stack>
                    }






                </div>
            </div>
        );
    }

    public nextQuestion = () => {

        if (this.state.current_q >= 32) {
            // this.props.returnHandle({page:'verifypic'})
        } else {
            this.setactiveInstate(this.state.current_q + 1, '', '')
        }

    }

    public setProp = () => {


        if (this.state.selected_per.length > 0) {

            this.props.returnHandle({ selected_per: this.state.selected_per, selected_per_add: this.state.selected_per_add, questions: this.props.sendprop.questions });
            this.setactiveInstate(this.state.current_q + 1, '', '')

        } else {
            this.setState({ per_error: 'Please choose personality traits.' });
        }

    }

}



export default withSignIn(Question);