import {FormControl, InputLabel, Select, MenuItem, Backdrop, Button, AppBar, Toolbar, Slide, Slider, IconButton, TextField,  CircularProgress,   Grid,  Typography , Chip } from '@mui/material';
import axios from 'axios';
import  React, { useContext, useEffect } from 'react';
import variables from '../../global/global';
import TopHeader from '../includes/header';
import Breadcrumb from '../includes/breadcrumb';
import logo from '../../assets/img/logo.png'
import logoWithout from '../../assets/img/logo-without.png'
import logopitch from '../../assets/img/logo-pitch.png'
import Sendpitch from '../../components/lovediamond/sendpitch';
import { LoadingButton } from '@mui/lab';
import Base64e from '../../components/encodedecode/encode';
import { Navigate, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import TinderCard from 'react-tinder-card';
import PlaceIcon from '@mui/icons-material/Place';
import TuneIcon from '@mui/icons-material/Tune';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import AdComponent from '../../components/AdComponent';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { log } from 'console';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TransitionProps } from '@mui/material/transitions';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle className='text-center' sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

class Match extends React.Component<any, any>{
public state = {
    genresall:[],
    genres:[],
    nodata:'',
    max:3,
    indicator:'',
    lat:this.props.user().rows[0].lat,
    lng:this.props.user().rows[0].lang,
    looking_min_age:this.props.user().rows[0].looking_min_age,
    looking_max_age:this.props.user().rows[0].looking_max_age,
    lookingminmax:{min:this.props.user().rows[0].looking_min_age,max:this.props.user().rows[0].looking_max_age},
    location_filter_status:this.props.user().rows[0].location_filter_status,
    distance:this.props.user().rows[0].distance,
    filter_status:0,
    city:this.props.user().rows[0].city,
    state:this.props.user().rows[0].state,
    old_city:this.props.user().rows[0].city,
    old_state:this.props.user().rows[0].state,
    zip:this.props.user().rows[0].zip,
    city_error:'',
    state_error:'',
    zip_error:'',
    open:false,
    genersopen:false,
    dialog:false,
    loginuser:this.props.user().rows[0],
    confirm:false,
    deleteId:'',
    priority:0,
    permission:true,
    online:false,
    showad:true,

    id:'',
    SendUId:'',
    user:null,
    rows:[],
    search:'',
    search_id:'',
    total:0,
    limit:6,
    totalPages:0,
    curentPage:0,
    loading:true,
    gencount:0,
    sendpitch:{
        open:false,
        value:'',
        loading:false,    
    },
    bool:false,
    path:'',
    viewUser:[],
    swal: {
      show: false,
      title: '',
      text: ''
  },
  breadcrumb:[{
    link:'/',
    link_name:'Love Pitches'
  }]
}

public stateAbbreviations = [
  { short: "AL", full: "Alabama" },
  { short: "AK", full: "Alaska" },
  { short: "AZ", full: "Arizona" },
  { short: "AR", full: "Arkansas" },
  { short: "CA", full: "California" },
  { short: "CO", full: "Colorado" },
  { short: "CT", full: "Connecticut" },
  { short: "DE", full: "Delaware" },
  { short: "FL", full: "Florida" },
  { short: "GA", full: "Georgia" },
  { short: "HI", full: "Hawaii" },
  { short: "ID", full: "Idaho" },
  { short: "IL", full: "Illinois" },
  { short: "IN", full: "Indiana" },
  { short: "IA", full: "Iowa" },
  { short: "KS", full: "Kansas" },
  { short: "KY", full: "Kentucky" },
  { short: "LA", full: "Louisiana" },
  { short: "ME", full: "Maine" },
  { short: "MD", full: "Maryland" },
  { short: "MA", full: "Massachusetts" },
  { short: "MI", full: "Michigan" },
  { short: "MN", full: "Minnesota" },
  { short: "MS", full: "Mississippi" },
  { short: "MO", full: "Missouri" },
  { short: "MT", full: "Montana" },
  { short: "NE", full: "Nebraska" },
  { short: "NV", full: "Nevada" },
  { short: "NH", full: "New Hampshire" },
  { short: "NJ", full: "New Jersey" },
  { short: "NM", full: "New Mexico" },
  { short: "NY", full: "New York" },
  { short: "NC", full: "North Carolina" },
  { short: "ND", full: "North Dakota" },
  { short: "OH", full: "Ohio" },
  { short: "OK", full: "Oklahoma" },
  { short: "OR", full: "Oregon" },
  { short: "PA", full: "Pennsylvania" },
  { short: "RI", full: "Rhode Island" },
  { short: "SC", full: "South Carolina" },
  { short: "SD", full: "South Dakota" },
  { short: "TN", full: "Tennessee" },
  { short: "TX", full: "Texas" },
  { short: "UT", full: "Utah" },
  { short: "VT", full: "Vermont" },
  { short: "VA", full: "Virginia" },
  { short: "WA", full: "Washington" },
  { short: "WV", full: "West Virginia" },
  { short: "WI", full: "Wisconsin" },
  { short: "WY", full: "Wyoming" }
];

public getMatch = (val:any)=>{ 
    const  Page =  val;
    let rows:any = val>0 ? this.state.rows : [];
    this.setState({
      loading:true,
  });
        axios.post(variables.url+'getSingleUser', {
          id:Base64e.encode(this.state.id+''),
          lat:this.state.lat,
          lang:this.state.lng, 
          Rid:'',
          uId:''
        }).then((res)=>{                
          if(res.status === 200 && res.data!==''){
              const row = res.data.rows.rows[0];
              this.setState({filter_status:row.filter_status,location_filter_status:row.location_filter_status});
             console.log('filter_status',row.filter_status);
  
          axios.post(variables.url+'getuserMatch', {
                      user:this.state.user,
                      id:Base64e.encode(this.state.id+''),
                      page:this.state.limit*Page,
                      limit:this.state.limit,
                      distance:this.state.distance,
                      search:this.state.search_id,
                      showAd:this.state.showad,
                      filter_status:row.filter_status,
                      location_filter_status:row.location_filter_status
          }).then((res)=>{                
                      if(res.status === 200 && res.data.rows[0]!==undefined && res.data.rows[0]!==null){  
                        this.getOnlinestatus(res.data.rows[0].id);   
                        
                         console.log('getSelectGenres',res.data.rows[0].genres);
                         
                        this.getSelectGenres(res.data.rows[0].genres)   
              res.data.rows.map((v:any)=>{
              v.loading=false;  
              v.from=parseInt(v.from); 
              v.to=parseInt(v.to);   
        
              rows.push(v); 
              return v;
                })
              console.log('dd',this.props.user().rows[0].payments[0].plan_id);
              console.log('dd',this.props.user().rows[0].payments.length);
              console.log('dd',res.data.swipe_count);
                if(this.props.user().rows[0].payments.length > 0 && this.props.user().rows[0].payments[0].plan_id==4 && res.data.swipe_count >= 2){
                 
                  //this.setState({showad:true}); //disable ad
                  this.setState({showad:false});
                }else{
                  // if((this.state.loginuser.email == 'test_male@gmail.com' || this.state.loginuser.email == 'test_female@gmail.com')){
                  //   this.setState({showad:true});
                  // }else{
                    this.setState({showad:false});
                  //}
                  
                }

               
                                            
          this.setState({
              rows:rows,
              total:res.data.count,
              totalPages:Math.ceil(res.data.count/this.state.limit),
              curentPage:Page,
              loading:false,
              
          });

          window.screenTop=300;
                        }else{

                          this.setState({
                            loading:false,
                        });

                        if(Page===0){
                          this.setState({
                            rows:[],
                            total:0,
                            totalPages:0,
                            curentPage:0,
                            loading:false,
                            nodata:'No match Found. Please change the search filters.'
                        });
                        }

                        }
               });

              }
            });
   } 

   componentDidMount(){       
    console.log(this.props);
   
   this.setState({id:this.props.user().rows[0].id,user:this.props.user().rows[0],search:this.props.search,search_id:this.props.search_id,loading:true});
    setTimeout(() => {
     this.getMatch(0); 
  }, 200);


   }

   public openDropdown=(val:any,lat:any,lng:any)=>{
   console.log('indicator',this.state.indicator);
   
    if(this.state.max >= 99){
      this.setState({max:3,indicator:'rotate(0deg)'})
    }else{
      this.setState({max:99,indicator:'rotate(180deg)'})
    }
    axios.post(variables.url+'getSingleUser', {
      id:Base64e.encode(val+''),
      lat:lat,
      lang:lng, 
      Rid:'',
      uId:''
    }).then((res)=>{                
      if(res.status === 200 && res.data!==''){
          const row = res.data.rows.rows[0];
          console.log('filter_statusvvv',row);
          this.getSelectGenres(row.genres) 
      }
    })
    
   }

   public  getSelectGenres(ids:any) {
    const dd:any=[];
    if(ids != '' && ids != null){
    axios.post(variables.url+'getSelectGenres',{ids:ids.split(',')}).then((response) => {
      this.setState({genresall:response.data});
      console.log('bbb',response.data);
      console.log('max',this.state.max);
      
      if(response.data.length >0){
        this.setState({gencount:response.data.length}); 
      }
      console.log('loginuser',this.props.user().rows[0]);
      var loginuser=this.props.user().rows[0].genres != null && this.props.user().rows[0].genres != '' ? this.props.user().rows[0].genres.split(',') : '';
      
      response.data.map((e:any,index:any)=>{
        e.matched = false;
        if(index <= this.state.max){
          if(loginuser !=''){
          loginuser.map((gen:any, index:any)=>{
            if(gen==e.id){
              console.log('matchgeneres',gen);
              e.matched = true;
            }
          });
        }else{
          e.matched = false;
        }
          
          dd.push(e)
           
        } 
      
        return e;
    })
    console.log('ben10',dd);
    this.setState({genres:dd});

    });
    }
   }
  
   public async getOnlinestatus(user_id:any){
    
  await axios.post(variables.url+'getOnlineStatus',{id:user_id})
  .then((res)=>{
      if(res.status === 200){
        if(res.data.id){
          this.setState({online:true});
        }else{
          this.setState({online:false});
        }
        
        
      }
  }).catch((e:any)=>{
    console.log(e);
  });
   }

componentWillMount(): void {
    
 
    
        // window.addEventListener('scroll', async() =>{
        //     const url= window.location.href.split('/')[3];
            
        //     if(url=== 'matches'){
        //     if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        //    if(this.state.curentPage<this.state.totalPages){
        //     this.setState({ loading:true});
        //       await this.getMatch(this.state.curentPage+1);
        //    }
        //     }
        //  }
        //  });
    

}




  public openIntrestBox=(value:any)=>{

    this.setState({sendpitch:value});

    //change functionlaity minor also send pitch
    this.setState({bool:true, path:'addinterest/'+Base64e.encode(value.value.id+'')+'/0'});

    return;

    if(this.state.loginuser.payments[0].plan_id==='4'){
     let trial_end= this.state.loginuser.payments[0].start_date;
     
      const date1 = new Date();
      const date2 = new Date(trial_end);
      console.log(trial_end);
      
      const diffTime = date2.valueOf() - date1.valueOf();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        console.log(diffTime + " milliseconds");
        console.log(diffDays + " days");

        if(diffTime <= 0){

          this.setState({permission:false}, () => {
            
              Swal.fire({  
                icon: "info",
                title: '',  
                text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
                confirmButtonColor: '#7b1fa2',  
              }).then((res) => {
                
                window.location.href = '/subscription?open=1';
                //window.location.reload();
              })
           
            
            
           })

        }else{
          this.setState({bool:true, path:'addinterest/'+Base64e.encode(value.value.id+'')+'/0'});
        }
    }else{
      let trial_end= this.state.loginuser.payments[0].start_date;
     
      const date1 = new Date();
      const date2 = new Date(trial_end);
      console.log(trial_end);
      
      const diffTime = date2.valueOf() - date1.valueOf();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        console.log(diffTime + " milliseconds");
        console.log(diffDays + " days");

      // if(diffTime <= 0){
      //   this.setState({permission:false}, () => {
            
      //     Swal.fire({  
      //       icon: "info",
      //       title: '',  
      //       text: ' Please upgrade your plan you have limited access in this subscription.',
      //       confirmButtonColor: '#7b1fa2',  
      //     });
       
        
        
      //  })

      // }else 
      if(this.state.loginuser.payments[0].status==0){
        this.setState({bool:true, path:'addinterest/'+Base64e.encode(value.value.id+'')+'/0'});
      }else{
        Swal.fire({  
          icon: "info",
          title: '',  
          text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
          confirmButtonColor: '#7b1fa2',  
        }).then((res) => {
                
          window.location.href = '/subscription?open=1';
          //window.location.reload();
        })
      }

      

      }
    
}
  
  // search creteria
  componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {  
                      console.log(nextProps);
                        
                        this.setState({
                                       search:nextProps.search,
                                       search_id:nextProps.search_id,
                                       loading:true
                                      });
                      setTimeout(() => {
                       this.getMatch(0); 
                    }, 200);  
  }
  public calculateage = (dob:any)=>{
    const birthDate = new Date(dob); 
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);

    return Math.abs(age.getUTCFullYear() - 1970);
  }
  public swiped = (direction:any, value:any) => {
    if(direction == 'left'){
        this.strikeout(this.state.id,value.id)
    }
    if(direction == 'right'){
        this.openIntrestBox({open:false, value:value}) 
    }
    console.log(direction)

  }
  public outOfFrame = (name:any) => {
    console.log(name + ' left the screen!')
  }
  public marks = [
    {
      value: 10,
      label: '10 miles',
     
    },
    {
      value: 250,
      label: '250 miles',
     
    },
    {
      value: 500,
      label: '500 miles',
     
    },
    {
      value: 750,
      label: '750 miles ',
    },
    {
      value: 1000,
      label: '1000 miles',
    },
  ];
  public setDistance(val:any){
    this.setState({distance:val});
  }
  public handleClickOpen = () => {
    this.setState({open:true});
  };
  public handleClose = () => {
    this.setState({open:false});
  };
  public setvalues = (r:any,type:any)=>{

    if(type==='min'){
      
      this.setState({looking_min_age:r.target.value});
   }
   if(type==='max'){

      this.setState({looking_max_age:r.target.value});
   }
    if(type==='city'){
        this.setState({city:r.target.value});
        
     }
     if(type==='state'){
        this.setState({state:r.target.value});
     }
     if(type==='zip'){
        this.setState({zip:r.target.value});
        this.hitMapApiByZip(r.target.value);
     }

  
}
public saveData = (e:any)=>{
  this.setState(e);
 
    this.setState({loading:true});


    axios.post(variables.url+'updateProfile',{data:e, id:this.props.user().rows[0].id})
    .then((res)=>{    
        if(res.status === 200){   
          console.log(res);
          this.handleClose();
                        this.setState({loading:false});    
                        localStorage.setItem('_auth_state',JSON.stringify(res.data)); 
                       setTimeout(() => {  window.location.reload(); }, 500);
             }
    }).catch((e:any)=>{
      this.setState({loading:false});    
      console.log(e);        
    });



}


public hitMapApiByCity = async (e:any)=>{

  await axios.get('https://maps.googleapis.com/maps/api/geocode/json?address='+e+'&components=country:US&location_type=GEOMETRIC_CENTER&key='+variables.MapApi+'&sensor=false')
            .then((res)=>{                                                    
                if(res.status === 200){
              if(res.data.status==='OK' && res.data.results[0]!==undefined){
                 console.log('cc',res.data.results);
                 let lat =res.data.results[0].geometry.location.lat;
                 let lng=res.data.results[0].geometry.location.lng;
                  axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+lng+'&key='+variables.MapApi+'&sensor=false')
                          .then((res)=>{                                                    
                              if(res.status === 200){
                            if(res.data.status==='OK' && res.data.results[0]!==undefined){
                              console.log('cc',res.data.results);  
                            
                              this.get_postcode(res.data);
                            }else{

                              
                            }
                            }else{
                                  alert('something goes wrong!');
                              }
                          })  
              
               
              }else{

                
              }
              
                
                }else{
                    alert('something goes wrong!');
                }
            })


}
public  get_postcode = async (results:any)=>{
var city:any=[];
 var city_data = results['results'][0]['address_components'];
  for(var i=0;i<city_data.length;i++){
      var cv = city_data[i];
      if(typeof cv['types'][0] != 'undefined'){
          if(cv['types'][0] === 'postal_code'){
              city['postcode'] = cv['long_name'];
          }else if(cv['types'][0] === 'postal_town'){
              city['place_name'] = cv['postal_town'];
          }
      }
  }

  if(city['postcode'] == undefined){
    var city:any=[];
      city_data = results['results'][1]['address_components'];
      for(i=0;i<city_data.length;i++){
          var cv = city_data[i];
          if(typeof cv['types'][0] != 'undefined'){
              if(cv['types'][0] === 'postal_code'){
                  city['postcode'] = cv['long_name'];
              }
          }
      }
  }
  if(city['postcode'] == undefined){
    var city:any=[];
      city_data = results['results'][2]['address_components'];
      for(i=0;i<city_data.length;i++){
          var cv = city_data[i];
          if(typeof cv['types'][0] != 'undefined'){
              if(cv['types'][0] === 'postal_code'){
                  city['postcode'] = cv['long_name'];
              }
          }
      }
  }
  if(city['postcode'] == undefined){
    var city:any=[];
      city_data = results['results'][3]['address_components'];
      for(i=0;i<city_data.length;i++){
          var cv = city_data[i];
          if(typeof cv['types'][0] != 'undefined'){
              if(cv['types'][0] === 'postal_code'){
                  city['postcode'] = cv['long_name'];
              }
          }
      }
  }
console.log('city',city);
console.log(city.length);

if(city['postcode'] != undefined){
  this.setState({zip:city['postcode']});
  this.hitMapApiByZip(city['postcode']);
}else{
  this.setState({zip:''});
}

 

}
public hitMapApiByZip = async (e:any)=>{

  await axios.get('https://maps.googleapis.com/maps/api/geocode/json?key='+variables.MapApi+'&components=postal_code:'+e)
            .then((res)=>{                                                    
                if(res.status === 200){
              if(res.data.status==='OK' && res.data.results[0]!==undefined){
                 // this.props.returnHandle({lat:res.data.results[0].geometry.location.lat,lang:res.data.results[0].geometry.location.lng});        
                  this.setState({zip_error:'',lat:res.data.results[0].geometry.location.lat,lng:res.data.results[0].geometry.location.lng});     
                
              }else{

                  this.setState({zip_error:'zip code is invalid Please check.',lat:''});
                  // if(e.toString().length < 5) {
                  //     this.setState({zip_error:'zip code is invalid Please check.',lat:''});
                  // }else{
                  //     this.setState({zip_error:'',lat:''}); 
                  // }
                
              }
              
                
                }else{
                    alert('something goes wrong!');
                }
            })


}
public checkCityChanges(r:any){
  console.log(this.state.old_city);
  
  if(r.target.value !== this.state.old_city){
    this.hitMapApiByCity(r.target.value);
     // this.setState({zip_error:'Please also update the zip code.'})
  }
 
}
public checkStateChanges(r:any){
  if(r.target.value !== this.state.old_state){
    //  this.setState({zip_error:'Please also update the zip code.'})
  }
 
}
public  render() {   

  let Age:any = [];
  for (let index =18; index <=100; index++) {
     Age.push(index); 
  }
 return (
  
  <div className='App'>

       {this.state.bool===true ? <Navigate  state={this.state} to={"/"+this.state.path} replace={true}/>  : ''}
       
      <TopHeader title="&nbsp;"  logo={''}/>
      <Breadcrumb breadcrumb={this.state.breadcrumb} />
      <div className='tune'>
      <Tooltip title="Search Filters" placement="top" arrow ><IconButton  onClick={this.handleClickOpen}> <TuneIcon /> </IconButton >
      </Tooltip>
      </div>
      <div className='help hhlp2'>
      <HtmlTooltip
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={90000}
      placement="left-start"
        title={
          <React.Fragment>
            Welcome to our dating site! Here’s how to use our Love Pitch feature:
            <ul>
              <li>Select Interested  if you’re interested in the person based on their photo, age, and location.</li>
              <li>Slide the bar to indicate your level of interest.  Level of interest ranges from “heading to first base” all the way to “home run”</li>
              <li>Type a message and send your pitch to the person you’re interested in. Keep it friendly and respectful!</li>
              <li>If the other person is interested in you too, they will send a hit back and you will match. A message thread will be started in Messages</li>
              <li>If you’re not interested in the person, simply Strikeout and move on to the next potential match.</li>
            </ul>
            Remember to be safe and respectful when using our site. Good luck!

          </React.Fragment>
        }
      >
        <span><HelpIcon /> Help </span>
      </HtmlTooltip>
     </div>
     
      


    <Grid container spacing={2} className={'matches'}>
    <Grid  xs={12}>

{this.props.search ? <h6>Search ({this.props.search}) <CloseIcon onClick={()=>window.location.reload()} /></h6> : ''}

{this.state.rows[0]!==undefined ?  

    this.state.rows.map((value:any,index) => (
    <> 
    <Grid container className="desktop-match-profile"  style={{padding: '10px',margin:'auto'}}>
 
    <Grid item xs={12}  md={3}  >
    {/* <div className='int-box'>
        <Typography variant='h5'>Not Interested</Typography>
        
        <LoadingButton loading={value.loading} variant='contained' onClick={()=>this.strikeout(this.state.id,value.id)} className='text-lowercasee mt-2'>Strikeout</LoadingButton>
       
        </div> */}
       </Grid>
    <Grid item xs={12} md={5} style={{display: 'flex', justifyContent:'center',height:'100vh'}}>
    {/* <SwipeableList> 
          <SwipeableListItem
            threshold={0.1}
            swipeLeft={{
              content: <div className="basic-swipeable-list__item-content-left"><span>Not interested</span></div>,
              action: () => this.strikeout(this.state.id,value.id)
            }}
            swipeRight={{
              content: <div className="basic-swipeable-list__item-content-right"><span>Interested</span></div>,
              action: () => this.openIntrestBox({open:false, value:value})
            }}
            onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}> */}
            { this.state.showad ? 
            <div className="match-wrapper">
              <div className="divimage">
              <div className="d-flex justify-content-end align-items-center w-100">
                      <div>
                      <HighlightOffIcon style={{fontSize:'40px'}} onClick={()=>this.strikeout(this.state.id,value.id)} />
                      
                      </div>
                      
                    </div>
              <AdComponent />
              
              </div>
             
            </div>
           
          
              :
              <div className="match-wrapper">
                  <div className="divimage" style={{height: '100%',background: 'rgb(122 122 122)', backgroundImage : `url('${value.pic}')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover',backgroundPosition:'center'}}></div>
                  <div className="match-title ">
                    <span className='line-ellipsis'>{value.first_name}, {value.age}</span> {value.confirm==1 ? <> 
                    <IconButton color="inherit" sx={{ flexDirection: 'row', alignItems:'end' }}>
                           <VerifiedOutlinedIcon  />
                        <Typography className="red-text" variant="caption">Photo Verified</Typography>
                      </IconButton></> : '' }  
                    <div className="match-name">
                    {value.city}, {value.state}<br/>
                    
                    {Math.round(value.distance) > 0 ? Math.round(value.distance)+' miles away':
                    
                    <Chip icon={<PlaceIcon />} style={{color:'#fff', marginTop:'10px'}} label={'Also lives in '+ value.city} variant="outlined" />
                    }
                    {this.state.online == true ? <> <br/><div id="status">
                      <div className="indicator online"></div>
                      <div className="message online">Online Now</div>
                    </div> <br/> </> : '' }
                    </div>
                    </div>
                  
                  <div className="divtext">
                    
                    <div className="d-flex justify-content-around align-items-center w-100">
                      <div>
                      <Typography className='sttext' variant='h5'>Strikeout</Typography>
                      <HighlightOffIcon style={{fontSize:'40px'}} onClick={()=>this.strikeout(this.state.id,value.id)} />
                   
                      </div>
                      <Button onClick={()=>this.viewProfile(value)} color='secondary' size="small" variant='contained' className='text-lowercasee'>Profile</Button>
                      <div style={{width:'100px'}}>
                      <Typography className='sttext'  variant='h5'>Interested</Typography>
                      {(this.state.loginuser.email != 'test_male@gmail.com' && this.state.loginuser.email != 'test_female@gmail.com') ?
                        <img  src={logoWithout} width={'60%'} alt="logo" onClick={()=>this.openIntrestBox({open:false, value:value})}/>
                        
                        : <img  src={logoWithout} width={'60%'} alt="logo" />
                        }
                     
                      
                      </div>
                    </div>
                    <div className='text-mm text-left'>
                    
                    <div className="match-name d-flex">
                        <div>
                        {this.state.genres ? 
                        this.state.genres.map((val:any) => (
                          
                        <Chip className={val.matched ? 'genres-chip MuiAutocomplete-tag green' : 'genres-chip MuiAutocomplete-tag'} style={{marginTop:'10px'}} label={val.name} variant="outlined" />
                        ))
                      
                        :''}
                        </div>
                    
                    {this.state.gencount > 4 ?
                    <ArrowDropDownIcon sx={{transform:this.state.indicator}} onClick={()=>this.openDropdown(value.id,value.lat,value.lng)}/>
                   : ''}
                    </div>
                    </div>
                  </div>
                </div>
              }
                

            {/* </SwipeableListItem>
        </SwipeableList> */}
        </Grid>
      <Grid item xs={12} md={3} style={{  marginLeft: '0px'}}>
        {/* <div className='int-box2'>
      <Typography variant='h5'>Interested</Typography>
      
      <img  src={logopitch} width={'50%'} alt="logo" onClick={()=>this.openIntrestBox({open:false, value:value})}/>
    
      </div> */}
      </Grid> 
    </Grid>
    </>
    )) : this.state.nodata}
    <Grid item xs={12}  md={4} className="mobile-match-profile" spacing={0}   >
    {this.state.rows[0]!==undefined ?  

            this.state.rows.map((value:any,index) => (
            <>
           {/* <SwipeableList> 
          <SwipeableListItem
            threshold={0.1}
            swipeLeft={{
              content: <div className="basic-swipeable-list__item-content-left"><span>Not interested</span></div>,
              action: () => this.strikeout(this.state.id,value.id)
            }}
            swipeRight={{
              content: <div className="basic-swipeable-list__item-content-right"><span>Interested</span></div>,
              action: () => this.openIntrestBox({open:false, value:value})
            }}
            onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
            
            > */}
            <div className='swipe'>
              <div className="match-wrapper">
                  <div className="divimage" style={{background: 'rgb(122 122 122)', backgroundImage : `url('${value.pic}')`, backgroundRepeat: 'no-repeat', backgroundSize:'cover',backgroundPosition:'center'}}></div>
                  <div className="match-title ">
                    <span className='line-ellipsis'>{value.first_name}, {value.age}</span> {value.confirm==1 ? <VerifiedOutlinedIcon  /> : '' }  
                   <div className="match-name">
                   {value.city}, {value.state}<br/>
                    
                    {Math.round(value.distance) > 0 ? Math.round(value.distance)+' miles away':
                    
                    <Chip icon={<PlaceIcon />} style={{color:'#fff', marginTop:'10px'}} label={'Also lives in '+ value.city} variant="outlined" />
                    }
                    {this.state.online == true ? <> <br/><div id="status">
                      <div className="indicator online"></div>
                      <div className="message online">Online Now</div>
                    </div> <br/> </> : '' }
                    </div>
                    </div>
                  
                  
                  
                  <div className="divtext">
                    
                    <div className="d-flex justify-content-around align-items-center w-100">
                    <div style={{height:'80px'}}>
                    <Typography variant='h6'>Strikeout</Typography>
                       <HighlightOffIcon style={{fontSize:'40px'}} onClick={()=>this.strikeout(this.state.id,value.id)} />
                      
                      </div>
                     
                      <Button onPointerEnter={()=>this.viewProfile(value)} color='secondary' size="small" variant='contained' className='text-lowercasee'>Profile</Button>
                     
                      <div style={{width:'100px'}}>
                      <Typography variant='h6'>Interested</Typography>
                      {(this.state.loginuser.email != 'test_male@gmail.com' && this.state.loginuser.email != 'test_female@gmail.com') ?
                        <img  src={logoWithout} width={'60%'} alt="logo" onClick={()=>this.openIntrestBox({open:false, value:value})}/>
                        
                        : <img  src={logoWithout} width={'60%'} alt="logo" />
                        }
                     
                      
                      </div>
                    </div>
                    <div className='text-mm text-left'>
                    
                    <div className="match-name d-flex">
                    <div>
                    {this.state.genres ? 
                        this.state.genres.map((val:any) => (
                          
                        <Chip className={val.matched ? 'genres-chip MuiAutocomplete-tag green' : 'genres-chip MuiAutocomplete-tag'} style={{marginTop:'10px'}} label={val.name} variant="outlined" />
                        ))
                      
                        :''}
                        </div>
                    
                    
                        {this.state.gencount > 2 ?
                        <Button onClick={() => this.setState({genersopen:true})} title='Save' className='w_40' color='secondary' size='small' variant='text'>See all</Button>
                    // <ArrowDropDownIcon sx={{transform:this.state.indicator}} onClick={()=>this.openDropdown(value.id,value.lat,value.lng)}/>
                   : ''}
                    </div>
                    </div>
                  </div>
                </div>
                </div>

                {/* </SwipeableListItem>
        </SwipeableList> */}
            </>
                )) : this.state.nodata}
     </Grid>











  </Grid>
    </Grid>

 

{/* {this.state.sendpitch.open ?  <Sendpitch from={'match'}  removeRequestedUser={(e:any)=>this.removeuser(e)} returnhandle={(e:any)=>this.setState(e)} data={this.state}/> : ''} */}


<Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.loading}>
  <CircularProgress color="inherit" />
</Backdrop>



<BootstrapDialog
        onClose={this.handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={this.handleClose}>
        Search Filters
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <div className='row d-slider'>
      <div className='col-md-11 m-auto'>
        <div className=''>
          <h6>Maximum Distance</h6>
        <Slider
            color="secondary"
            aria-label="Distance"
            defaultValue={this.state.distance}
            value={this.state.distance}
            valueLabelDisplay="auto"
            step={10}
            onChange={(_, newValue)=>this.setDistance(newValue)}
            marks={this.marks}
            min={10}  
            max={1000}
           />
        </div>
      </div>
      <div className='col-md-11 m-auto py-5'>
        
          <h6>Age Filter</h6>
          <div className='d-flex justify-content-space-between' style={{justifyContent:'space-between'}}>
          <FormControl fullWidth style={{marginTop:'10px'}}>
            <InputLabel id="demo-simple-select-label">Looking For Minimum Age</InputLabel> 
              <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                  defaultValue={this.state.looking_min_age}
                  label="Looking For Minimum Age"
                  onChange={(e)=>this.setvalues(e,'min')}
               
                >
                { Age.map((val:any)=>(<MenuItem key={val} value={val}>{val}</MenuItem>))}
                </Select>
             
            </FormControl>

            <FormControl fullWidth style={{marginTop:'10px'}}>
                <InputLabel id="demo-simple-select-label">Looking For Maximum Age</InputLabel>  
                  <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        defaultValue={this.state.looking_max_age}
                      label="Looking For Maximum Age"
                      onChange={(e)=>this.setvalues(e,'max')} 
                   
                    >
                    { Age.map((val:any)=>(<MenuItem key={val} value={val}>{val}</MenuItem>))}
                    </Select>
           </FormControl>
        </div>
      </div>
      <div className='col-md-11 m-auto px-5'>
        <div className='d-flex justify-content-between align-items-center'>
      <h6 className='my-3'>Your Location</h6> 
      <div className='d-flex align-items-center'>

      <h6 >Off</h6> 
      <Switch   onChange={(e)=>this.setState({location_filter_status:e.target.checked ? 0 : 1 })} defaultChecked={this.state.location_filter_status==0 ? true : false}  />
      <h6 >On</h6> 
      </div>
      

        </div>
      <TextField   required={true} className='d-flex mt-2' type={'text'} id="outlined-basic" label="City" onKeyUp={(e)=> this.checkCityChanges(e)} onChange={(e)=> this.setvalues(e,'city')} error={this.state.city_error!=='' ?  true : false} helperText={this.state.city_error} variant="outlined" value={this.state.city}/>

    {/* <TextField   required={true} className='d-flex mt-2' type={'text'} id="outlined-basic" label="State" onBlur={(e)=> this.checkStateChanges(e)}  onChange={(e)=> this.setvalues(e,'state')} error={this.state.state_error!=='' ?  true : false} helperText={this.state.state_error} variant="outlined" value={this.state.state}/> */}
    <FormControl fullWidth className='d-flex mt-2'>
         <InputLabel id="demo-simple-select-label">Select your state</InputLabel>
         <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={this.state.state}
                label="Select your state"
                error={this.state.state_error!=='' ?  true : false} 
                onChange={(e)=> this.setvalues(e,'state')} 
                required={true} 
            >
            { this.stateAbbreviations.map((val:any)=>(<MenuItem key={val} value={val.short}>{val.full}</MenuItem>))}
            </Select>
            <span className="text-light muierror" >{this.state.state_error}</span>
            </FormControl>

      <TextField   required={true} className='d-flex mt-2' type={'number'} id="outlined-basic" label="Zip Code" onChange={(e)=> this.setvalues(e,'zip')} error={this.state.zip_error!=='' ?  true : false} helperText={this.state.zip_error} variant="outlined" value={this.state.zip}/>
      </div>
     </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={()=>this.saveData({
            location:{city:this.state.city,state:this.state.state,zip:this.state.zip,distance:this.state.distance,lat:this.state.lat,lang:this.state.lng},
            lookingminmax:{min:this.state.looking_min_age,max:this.state.looking_max_age},
            filter_status:1,
            location_filter_status:this.state.location_filter_status
            })}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>



      <Dialog
        fullScreen
        hideBackdrop={true}
        disableAutoFocus
        open={this.state.genersopen}
        onClose={()=>this.setState({genersopen:false})}
        transitionDuration={200}
        TransitionComponent={Transition}
        PaperProps={{ style: { backgroundColor: 'rgb(255 175 13)' } }}
      >
        <AppBar sx={{ position: 'fixed', top: '0px', backgroundColor: '#7b1fa2' }}>
          <Toolbar>
            <IconButton
              className='back'
              edge="start"
              color="inherit"
              onClick={()=>this.setState({genersopen:false})}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Genres
            </Typography>
           
          </Toolbar>
        </AppBar>






        {this.state.genres ?



          <>

            <Grid container rowSpacing={2} direction="row"
              justifyContent="flex-start"
              alignItems="center" className="p-md-5 genres-container matchgeneres">
              {this.state.genres  ? this.state.genres.map((val: any) => (
                <>
                 
                    <Grid xs={6} md={3}>
                       <Chip className={val.matched ? 'genres-chip MuiAutocomplete-tag green' : 'genres-chip MuiAutocomplete-tag'} style={{marginTop:'10px'}} label={val.name} variant="outlined" />

                    </Grid>
                 
                </>
              )) : ''}
            </Grid>



           
          </>
          : ''}


      </Dialog>


</div>
 )

    }

public viewProfile=(v:any)=>{
  
    
const Eid =   Base64e.encode(v.id+'');

this.setState({path:'MatchesProfile/'+Eid+'/slammmm',bool:true});           

}


    public removeuser = (i:any)=>{
          const sendpitch={open:false};
          this.setState({sendpitch:sendpitch,rows:[],loading:true});
          setTimeout(() => { this.getMatch(0);  }, 100);
           
        }

public strikeout = (id:any,strikeout:any)=>{
        
          this.loading(strikeout,true);
            axios.post(variables.url+'strikeout',{
              strike:strikeout,
              id:Base64e.encode(id+''),
              rq_id:'',
            })
            .then((res)=>{
                if(res.status === 200){
                  this.loading(strikeout,false);
                  this.getMatch(0); 
                }
            }).catch((e:any)=>{
          console.log(e);
            });  

}


    
    public loading = (id:any,bool:boolean)=>{

        const final =  this.state.rows.map((v:any)=>{
        if(v.id===id){
        v.loading=bool;
        }
        return v;
          });
          this.setState({rows:final});
}

}

const mapStateToProps = (state:any) => {
  console.log('asa',state);
  
  return {
      search: state.search,
      search_id: state.search_id
  }
}

export default connect(mapStateToProps)(Match);