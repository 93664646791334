import React from "react"
import { ButtonGroup, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";


class Occupation extends React.Component<any>{
    public state = {
        occupation: this.props.sendprop.occupation,
        occupation_error: '',
        go: false,
        page: '',
    }

    // constructor(props:any) {
    //     super(props)
    // }


    public setProp = () => {


        if (this.state.occupation.length > 0) {
            this.props.returnHandle({
                occupation: this.state.occupation.trim(),
                page: 'questions',
                current_q: 13,
                questiongroup: [13, 14, 15, 16, 17, 18, 19],
                prevNext: ['occupation', 'verifypic'],
            });

        } else {
            this.setState({ occupation_error: 'Please enter a valid  occupation.' });
        }

    }

    public setvalues = (r: any) => {
        this.props.returnHandle({ occupation: r.target.value });
        this.setState({ occupation: r.target.value });
    }

    render(): React.ReactNode {

        return (
            <div className="container main py-2" >
                {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
                <div className={this.props.from === undefined ? 'd-block m_auto  col-md-5' : 'd-block m_auto  col-md-12'} >

                    {this.props.from !== undefined && this.props.from !== null ? '' : <h3 className="d-block text-center" >What is your occupation?</h3>}


                    <TextField autoComplete="off" required={true} className='d-flex' type={'occupation'} id="outlined-basic" label="What is your occupation ?" onChange={(e) => this.setvalues(e)} error={this.state.occupation_error !== '' ? true : false} helperText={this.state.occupation_error} variant="outlined" value={this.props.sendprop.occupation} />

                    <ButtonGroup style={{ width: '100%' }}>
                        {this.props.from === undefined ? <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns login-next-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.props.returnHandle({
                                page: 'questions',
                                current_q: 12,
                                questiongroup: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                                prevNext: ['lookingForMinMaxAge', 'occupation'],
                            })}
                            variant="contained">
                            Back
                        </LoadingButton> : ''}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns  login-back-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.setProp()}
                            variant="contained">
                            {this.props.from === undefined ? 'Next' : 'Save'}
                        </LoadingButton>

                    </ButtonGroup>

                </div>
            </div>
        );
    }


}

export default Occupation;