const Base64e = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZ"+
  "abcdefghijklmnopqrstuvwxyz0123456789",
    encode: function(e:any) {
        var t = "";
        var n, r, i, s, o, u, a;
        var f = 0;
        e = Base64e._utf8_encode(e);
        while (f < e.length) {
            n = e.charCodeAt(f++);
            r = e.charCodeAt(f++);
            i = e.charCodeAt(f++);
            s = n >> 2;
            o = ((n & 3) << 4) |( r >> 4);
            u = ((r & 15) << 2) | (i >> 6);
            a = i & 63;
            if (isNaN(r)) {
                u = a = 64
            } else if (isNaN(i)) {
                a = 64
            }
            t = t +
              this._keyStr.charAt(s) +
              this._keyStr.charAt(o) +
              this._keyStr.charAt(u) +
              this._keyStr.charAt(a)
        }
        return t
    },

    _utf8_encode: function(e: string) {
        e = e.replace(/\r\n/g, "\n");
        var t = "";
        for (var n = 0; n < e.length; n++) {
            var r = e.charCodeAt(n);
            if (r < 128) {
                t += String.fromCharCode(r)
            } else if (r > 127 && r < 2048) {
                t +=
                  String.fromCharCode((r >> 6) | 192);
                t +=
                  String.fromCharCode((r & 63) | 128)
            } else {
                t +=
                  String.fromCharCode((r >> 12) | 224);
                t +=
                  String.fromCharCode((r >> (6 & 63)) | 128);
                t +=
                  String.fromCharCode((r & 63 )| 128)
            }
        }
        return t
    },

}

export default Base64e;