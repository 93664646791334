import { Button, Grid, Divider, Breadcrumbs, Link, Typography, Badge, Modal, Fade, Box } from "@mui/material";
import TemporaryDrawer from "../../components/sidebar/sidebar";
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import searchaset from "../../store/reducers/searchset";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import axios from 'axios';
import variables from '../../global/global';
import { useAuthUser } from 'react-auth-kit';
import SportsBaseballOutlinedIcon from '@mui/icons-material/SportsBaseballOutlined';
import SportsCricketOutlinedIcon from '@mui/icons-material/SportsCricketOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import logo from '../../assets/img/logo.png';
import logo1 from '../../assets/img/logo-1.png'
import logo2 from '../../assets/img/logo-2.png'
import profileicon from '../../assets/img/profile-2.png'
import Base64e from '../../components/encodedecode/encode';
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp';
import { useSignOut } from 'react-auth-kit'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/material/styles';
import ball from '../../assets/img/pitch-white.png'
import hit from '../../assets/img/Hits-in-white.png'
import message from '../../assets/img/message-white.png'
import profile from '../../assets/img/profile-white.png'
import setting from '../../assets/img/setting-white.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const TopHeader = (val: any) => {

  const [smallScreen, setsmallScreen] = useState(false);
  const [valuex, setValue] = useState('');
  const [select, setSelect] = useState({ id: '', link: '', name: 'Home' });
  const navigate = useNavigate();
  const signOut = useSignOut();

  const user: any = useAuthUser();
  const user_id = user().rows[0].id;
  const usser = user().rows[0];
  const path = window.location.pathname;
  const page = path.split('/');
  const [request, setRequest] = React.useState({ request_count: '0' });
  const [msg, setMsg] = React.useState({ message_count: '0' });
  const [profileCompleted, setprofileCompleted] = React.useState(0);
  const [openmodal2, setModalopen2] = React.useState(false);
  const [title, settitle] = React.useState('Zodaic');
  const [sub, setsub] = React.useState('Zodiac signs have long been used to gain insite into various personality that individuals may have, based on times and dates in which they were born.');
  const [sub1, setsub1] = React.useState('Update your <span class="color-pink"> profile </span> to stand out or go to  <span class="color-pink"> send pitches </span> to meet new singles');
  const [allmsg, setallMsg] = React.useState(0);
  const [allrequest, setallRequest] = React.useState(0);
  const [content, setContent] = React.useState('');

  console.log('page', page[1]);
  const dispatch = useDispatch();
  const submit = () => {
    console.log(select.link);
    if (select.link !== undefined) {
      navigate(select.link);
    } else {

      const Eid = Base64e.encode(select.id + '');



      //    dispatch(searchaset('search',valuex,select.id))
      // navigate('/matches'); 
      navigate('/MatchesProfile/' + Eid + '/slammmm');

    }
    //dispatch(searchaset('search',valuex)) 




  }


  const setvvv = (e: any) => {
    setValue(e.target.value);
  }

  const [items, setItems] = useState([{ link: '/', name: 'Home' }]);

  const openModal = (i: number) => {
    console.log('fffffff', setallMsg);
    if (i === 1) {
      if (allmsg > 0) {
        navigate('/ChatList')
      } else {
        settitle('Messages');
        setsub('You have no messages');
        setsub1('Update your <a href="/profile"><span class="color-pink">profile</span></a> to stand out or go to  <a href="/matches"><span class="color-pink">send pitches</span></a> to meet new singles');
        setModalopen2(true);

      }

    }
    if (i === 2) {
      if (allrequest > 0) {
        navigate('/activepitches')
      } else {
        settitle('Active Hits');
        setsub('You have no new hits');
        setsub1('Update your <a href="/profile"><span class="color-pink">profile</span></a> to stand out or go to  <a href="/matches"><span class="color-pink">send pitches</span></a> to meet new singles');
        setModalopen2(true);

      }

    }



  }
  const getMassageList = () => {

    axios.post(variables.url + 'ChatList', {
      id: Base64e.encode(user().rows[0].id + ''),
      page: 1,
      limit: 1,
      search: '',
      status: 1,
    }).then((res) => {
      if (res.status === 200) {
        setallMsg(res.data.count)
      }
    });

    axios.post(variables.url + 'getuserRequests', {
      id: Base64e.encode(user().rows[0].id + ''),
      page: 1,
      limit: 1,
      search: '',
      status: 1,
    }).then((res) => {
      if (res.status === 200) {
        setallRequest(res.data.count)
      }
    });

  }
  useEffect(() => {
    getMassageList();


  }, []);
  const getData = async () => {

    // const arr=[
    //   {link:'/',name:'Home'},
    //   {link:'/Profile',name:'Profile'},
    //   {link:'/matches',name:'Love Pitches'},
    //   {link:'/activepitches',name:'Pitches'},
    //   {link:'/ChatList',name:'Messages'},
    //   {link:'/Blog',name:'Blog'},
    //   {link:'/settings',name:'Settings'},
    //   {link:'/subscription',name:'Subscription Details'},
    //   {link:'/subscription',name:'Change Subscription'},
    //   {link:'/support',name:'Support'},
    //   {link:'/settings',name:'Freeze Account'},
    //   {link:'/settings',name:'Delete Account'},
    //   {link:'/settings',name:'Mobile'},
    //   {link:'/settings',name:'Email'},
    // ];
    const arr: any = [];
    //  setItems(arr)
    console.log(arr);
    await axios.post(variables.url + 'getUsersdata', { user: usser })
      .then((res) => {
        if (res.status === 200) {

          var vv = arr.concat(res.data);
          setItems(vv)

        }
      }).catch((e: any) => {
        console.log(e);
      });
  }
  const updateOnlineStatus = async () => {

    await axios.post(variables.url + 'updateOnlineStatus', { id: user_id })
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data.row.id);
          if (res.data.row == undefined || res.data.row == null) {
            localStorage.setItem('_auth_state', '');
            setTimeout(() => { window.location.href = "/"; }, 500);
            setTimeout(() => { signOut(); }, 500);

          }


        }
      }).catch((e: any) => {
        console.log(e);
      });
  }

  const getUnreadMessage = async () => {

    axios.post(variables.url + 'getUnreadMessage', {
      uId: Base64e.encode(user().rows[0].id + ''),
    })
      .then((res) => {
        if (res.status === 200 && res.data !== null) {
          if (res.data.count == 1) {
            setMsg({ message_count: res.data.count })
          } else {
            setMsg({ message_count: res.data.count })
          }

        } else {
          setMsg({ message_count: '0' })
        }

      }).catch((e: any) => {
        console.log(e);
      });
  }

  const getnewRequests = async () => {

    axios.post(variables.url + 'getnewRequests', {
      uId: Base64e.encode(user().rows[0].id + ''),
    })
      .then((res) => {

        if (res.status === 200 && res.data !== null) {
          console.log('count', res.data.count);

          if (res.data.count > 0) {

            setRequest({ request_count: res.data.count })


          }
        }

      }).catch((e: any) => {
        console.log(e);
      });
  }


  const getSubscriptionStatus = async () => {
    const idx = Base64e.encode(user().rows[0].id + '');
    const trans = Base64e.encode(user().rows[0].payments[0].transaction_id);
    await axios.post(variables.url + 'getSubscriptionStatus', { id: idx, sub: trans })
      .then((res) => {
       
        if (res.status === 200) {
         
          console.log(res.data.status);

          if(res.data.response.status != 'active'){
            if(res.data.response.user != ''){
              localStorage.setItem('_auth_state', JSON.stringify(res.data.user));
             // clearInterval(myInterval);
              setTimeout(() => {window.location.reload();}, 2000);

            }
          }


        }

      }).catch((e: any) => {
        console.log(e);
      });
  }


  useEffect(() => {

    if (user().rows[0].payments.length > 0  && user().rows[0].payments[0].status !=1 && user().rows[0].payments[0].transaction_id != null && user().rows[0].payments[0].transaction_id != '') {
      getSubscriptionStatus();
       window.setInterval(function () {
        var date = new Date();
        //alert(date.getMinutes());
        if ((date.getMinutes() % 5) == 0) {
         // alert(date.getMinutes());
          getSubscriptionStatus();// your method
        }
    }, 60000);
     
     
      
    }

    getUnreadMessage();
    getnewRequests();
    getData();

    console.log(smallScreen);
    setInterval(updateOnlineStatus, 20000);




    const id = Base64e.encode(user().rows[0].id + "");

    axios.post(variables.url + 'getSingleUser', {
      id: id,
      Rid: '',
      lat: user().rows[0].lat,
      lang: user().rows[0].lang,
      uId: ''
    }).then((res) => {
      if (res.status === 200 && res.data !== '') {
        const row = res.data.rows.rows[0];

        console.log(row.user_photos);


        let profileCompleted = 0;
        if (row.first_name !== '') {
          profileCompleted = profileCompleted + 2;
        }
        if (row.height !== '') {
          profileCompleted = profileCompleted + 2;
        }
        if (row.dob !== '') {
          profileCompleted = profileCompleted + 2;
        }

        if (row.city !== '' && row.state !== '' && row.zip !== '') {
          profileCompleted = profileCompleted + 2;
        }
        if (row.looking_min_age !== '' && row.looking_max_age !== '') {
          profileCompleted = profileCompleted + 2;
        }

        if (row.occupation !== '') {
          profileCompleted = profileCompleted + 2;
        }
        if (row.about !== null && row.about !== '') {
          profileCompleted = profileCompleted + 22;
        }
        if (row.genres !== null && row.genres !== undefined && row.genres !== '') {
          profileCompleted = profileCompleted + 12;
        }
        // if(user().rows[0].genres_partner !== null && user().rows[0].genres_partner !== undefined && user().rows[0].genres_partner !== ''){
        //     profileCompleted= profileCompleted+6;
        // }
        console.log(row);
        var t = 0;
        row.user_answers.map(function (val: any, i: any) {

          if (val.answer_id !== '') {
            profileCompleted = profileCompleted + 2 - 0.55;
          }
        })
        profileCompleted = Math.floor(profileCompleted);
        if (row.user_photos.length >= 3) {
          profileCompleted = profileCompleted + 28;
        } else if (row.user_photos.length == 2) {
          profileCompleted = profileCompleted + 21;
        } else if (row.user_photos.length == 1) {
          profileCompleted = profileCompleted + 14;
        } else if (row.user_photos.length == 0) {
          profileCompleted = profileCompleted + 7;

        }

        setprofileCompleted(profileCompleted)



      }
    });



  }, []);
  const OpenPage = (page: string) => {
    navigate(page);
  }
  const handleOnSelect = (item: any) => {
    // the item selected
    console.log(item)
    setSelect(item)

    if (item.link !== undefined) {
      navigate(item.link);
    } else {
      console.log(item)
      const Eid = Base64e.encode(item.id + '');
      navigate('/MatchesProfile/' + Eid + '/slammmm');
      window.location.reload();

    }


  }

  const resize = (e: any) => {

    if (e !== null && e.getBoundingClientRect().width <= 820) {
      setsmallScreen(true);
    } else {
      setsmallScreen(false);
    }


  }
  const handleOnSearch = (string: any, results: any) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.

    setValue(string);
    console.log(string, results)
  }
  const formatResult = (item: any) => {
    return (
      <>
        <div className="d-flex">
          <img src={item.pic} width="40" alt="" />
          <div className="ml-3">
            <span style={{ display: 'block', textAlign: 'left' }}><b>{item.name}</b></span>
            <span style={{ display: 'block', textAlign: 'left', fontSize: '13px' }}>{item.city}</span>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Grid ref={resize} className="navv position-relative" container spacing={{ xs: 0, md: 0 }} >
        <Grid item xs={1} md={1} className="p-h-0" >
          <TemporaryDrawer />
        </Grid>

        <Grid item xs={11} md={11} className="p-h-0">
          <div className="d-flex searchdiv align-items-center">
            {profileCompleted > 0 && (page[1] == 'profile' || page[1] == 'Profile' || page[1] == 'MatchesProfile') ?
              <div className='profile-question' onClick={() => navigate('/profile')} >
                <img src={profileicon} />

                <span className="text-danger">{profileCompleted}%</span>   Compeleted


              </div>
              : ''}
            {page[1] == undefined || page[1] == null || page[1] == 'Dashboard' || page[1] == '' || page[1] == 'profile' || page[1] == 'Profile' || page[1] == 'ChatList' ?
              <div className='helpdash helpdash-md'>
                <HtmlTooltip
                  arrow
                  enterTouchDelay={0}
                  leaveTouchDelay={90000}
                  placement="left-start"
                  title={
                    <React.Fragment>
                      Welcome to our dating site! Here’s how to use our Love Pitch feature:
                      <ul>
                        <li>Select Interested  if you’re interested in the person based on their photo, age, and location.</li>
                        <li>Slide the bar to indicate your level of interest.  Level of interest ranges from “heading to first base” all the way to “home run”</li>
                        <li>Type a message and send your pitch to the person you’re interested in. Keep it friendly and respectful!</li>
                        <li>If the other person is interested in you too, they will send a hit back and you will match. A message thread will be started in Messages</li>
                        <li>If you’re not interested in the person, simply Strikeout and move on to the next potential match.</li>
                      </ul>
                      Remember to be safe and respectful when using our site. Good luck!

                    </React.Fragment>
                  }
                >
                  <span>Help &nbsp;<HelpIcon /> </span>
                </HtmlTooltip>
              </div>
              : ''}
            <div className="">
              <div className="d-flex align-items-center">
                <div className="searchwrapper">
                  <ReactSearchAutocomplete
                    placeholder='Search..'
                    items={items}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    styling={{ borderRadius: "24px 0px 0px 24px" }}
                    formatResult={formatResult}
                    fuseOptions={{
                      shouldSort: true,
                      location: 0,
                      threshold: 0.0,
                      distance: 0
                      //   findAllMatches: true,
                      //   includeMatches: true
                    }}



                  />

                </div>
                <Button className="searchbtn" onClick={submit} variant='outlined' color="primary"><ArrowForwardIcon /></Button>
              </div>

              {/* <div className="d-flex align-items-center m-2 vv-sm">
    { page[1] == 'MatchesProfile'  ?
    <div className='helpdash helpdash-sm'>
      <HtmlTooltip
      arrow
      enterTouchDelay={0}
      leaveTouchDelay={90000}
      placement="left-start"
        title={
          <React.Fragment>
            Welcome to our dating site! Here’s how to use our Love Pitch feature:
            <ul>
              <li>Select Interested  if you’re interested in the person based on their photo, age, and location.</li>
              <li>Slide the bar to indicate your level of interest.  Level of interest ranges from “heading to first base” all the way to “home run”</li>
              <li>Type a message and send your pitch to the person you’re interested in. Keep it friendly and respectful!</li>
              <li>If the other person is interested in you too, they will send a hit back and you will match. A message thread will be started in Messages</li>
              <li>If you’re not interested in the person, simply Strikeout and move on to the next potential match.</li>
            </ul>
            Remember to be safe and respectful when using our site. Good luck!

          </React.Fragment>
        }
      >
        <span>Help &nbsp;<HelpIcon /> </span>
      </HtmlTooltip>
     </div>
     : ''}
     {page[1] == 'matches'  ||  page[1] == 'MatchesProfile' ?
    <Button className={'back-btn back-btn-sm'} 
       onClick={() => navigate(-1)} size="small" variant="contained" color="primary" startIcon={<ArrowBackIcon />}>
      Back
    </Button>
   : ''}
    </div> */}

            </div>


          </div>

        </Grid>
        <Grid item xs={11} md={10} className=" log-col p-xs-0" style={{ top: (smallScreen == false && (page[1] == 'profile' || page[1] == 'Profile')) ? '33px' : '' }}>
          <div className="container-fluid">
            <div className="row show-md" data-aos="fade-in">
              <div className="col-lg-2 col-md-2 position-relative">
                <a href="javascript::void();" className="logo1 align-items-center">
                  <img src={logo1} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                  <span className="header-tm-text logotm">TM</span>
                </a>
              </div>
              <div className="col-lg-10  col-md-10 position-relative d-flex align-items-center">
                <a href="javascript::void();" className="logo align-items-center m-left ">
                  <img src={logo2} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                  <span className="header-tm-text">TM</span>
                </a>
                <hr className="logo-hr" />

              </div>
              <div className="gold-line"> &nbsp; </div>
            </div>
            <div className="row show-sm" data-aos="fade-in">

              <div className="col-lg-12  position-relative">
                <a href="javascript::void();" className="logo align-items-center">
                  <img src={logo} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                  {/* <span className="header-tm-text-moblie">TM</span> */}
                </a>
                <hr className="logo-hr" />

              </div>
              <div className="gold-line"> &nbsp; </div>
            </div>

          </div>
          {/* <a href="javascript::void();" className="logo-dash align-items-center"> 
                <img src={logo} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
            </a>	 */}
          {/* <hr className="logo-hr-dash" /> */}
          {/* <a href="#" className="noti" onClick={()=>OpenPage('/Notifications')} ><Badge badgeContent={msg.message_count+request.request_count} color="secondary"><img src={logo} className="header-logo" alt="" /></Badge></a> */}

        </Grid>
        {/* <div className="gold-line-dash gold-line-dash-new"> &nbsp; </div> */}

        <Grid item xs={11} md={2} >

        </Grid>
        {/* <Grid item xs={12} md={6} className={page[1] == 'Notifications' || page[1] == 'matches' || page[1]== 'Profile' || page[1]== 'MatchesProfile' || page[1]=='addinterest' ? "title-position" : ''}>
    <h1 className="mt-4">{val.title}</h1>
    </Grid> */}

      </Grid>
      {/* <Divider className="mb-3"/> */}



      <nav className="mobile-bottom-nav">
        <div className="mobile-bottom-nav__item mobile-bottom-nav__item">
          <div className={page[1] == 'matches' ? "mobile-bottom-nav__item-content active" : "mobile-bottom-nav__item-content"}>
            {/* <SportsBaseballOutlinedIcon  className="material-icons" onClick={()=>navigate('/matches')}/> */}
            <img src={ball} className="material-icons" onClick={() => navigate('/matches')} />
          </div>
        </div>
        <div className="mobile-bottom-nav__item">
          <div className={page[1] == 'activepitches' ? "mobile-bottom-nav__item-content active" : "mobile-bottom-nav__item-content"}>
            <Badge badgeContent={request.request_count} color="secondary"> <img src={hit} className="material-icons" onClick={() => openModal(2)} /></Badge>
            {/* <SportsCricketOutlinedIcon  className="material-icons"  onClick={()=>navigate('/activepitches')}/> */}
          </div>
        </div>
        <div className="mobile-bottom-nav__item">
          <div className={page[1] == 'ChatList' ? "mobile-bottom-nav__item-content active" : "mobile-bottom-nav__item-content"}>
            <Badge badgeContent={msg.message_count} color="secondary">  <img src={message} className="material-icons" onClick={() => openModal(1)} /></Badge>
            {/* <MessageOutlinedIcon  className="material-icons" onClick={()=>navigate('/ChatList')}/> */}
          </div>
        </div>

        <div className="mobile-bottom-nav__item">
          <div className={page[1] == 'profile' ? "mobile-bottom-nav__item-content active" : "mobile-bottom-nav__item-content"}>
            <img src={profile} className="material-icons" onClick={() => navigate('/profile')} />
            {/* <PersonOutlineOutlinedIcon className="material-icons"  onClick={()=>navigate('/profile')} /> */}
          </div>
        </div>
        {(user().rows[0].email != 'test_male@gmail.com' && user().rows[0].email != 'test_female@gmail.com') ?
          <div className="mobile-bottom-nav__item">
            <div className={page[1] == 'settings' ? "mobile-bottom-nav__item-content active" : "mobile-bottom-nav__item-content"}>
              <img src={setting} className="material-icons" onClick={() => navigate('/settings')} />
              {/* <SettingsOutlinedIcon className="material-icons" onClick={()=>navigate('/settings')} /> */}
            </div>
          </div>
          : ''}
      </nav>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openmodal2}
        onClose={() => setModalopen2(false)}
        closeAfterTransition
        style={{ pointerEvents: 'none' }}
      >
        <Fade in={openmodal2} >
          <Box className='msspopup' style={{ pointerEvents: 'all' }}>
            <div>
              <div className="row position-relative">
                <div className="col-md-12"> <Typography className='text-center color-pink' variant='h4'>{title}</Typography></div>
                <div className="col-md-12 iocdiv"> <ClearIcon onClick={() => setModalopen2(false)} className='clearicon' /></div>
              </div>

              <Typography className='text-center' variant='h5'>{sub}</Typography>
              <Typography className='text-center' variant='h6' ><div dangerouslySetInnerHTML={{
                __html: sub1
              }}></div></Typography>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );




}

export default TopHeader;