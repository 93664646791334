import React from 'react';
import { ButtonGroup, Dialog } from '@mui/material';
import axios from 'axios';
import variables from '../global/global';
import { LoadingButton } from '@mui/lab';
import DoneIcon from '@mui/icons-material/Done';
import successImg from '../assets/img/load.gif';
import { Navigate } from 'react-router-dom';
import { useSignIn } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import { withSignIn } from 'react-auth-kit';
import Swal from 'sweetalert2';
import PaymentForm from '../components/paymentform/PaymentForm';

class CheckoutForm extends React.Component<any> {

  public state = {
    load: false,
    dashboard: false,
    success: false,
    payerror: '',
    formData: { email: this.props.sendprop.email, password: this.props.sendprop.password },
    error: '',
    state_error: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    zipcode: '',
    address_line1_error: '',
    city_error: '',
    zipcode_error: '',
    name: '',
    name_error: ''

  }
  


  public autoLogin = (r:any) => {
    if(r){
      this.props.returnHandle(r);
    }else{

  

    this.setState({ load: true });

    axios.post(variables.url + 'Userlogin', this.state.formData)
      .then((res) => {
        this.setState({ load: false });
        if (res.status === 200 && res.data.error === '') {
          console.log(res.data);
          if (this.props.signIn({
            token: res.data.token,
            expiresIn: res.data.expiresIn,
            tokenType: "Bearer",
            authState: res.data.authUserState,
            refreshToken: res.data.refreshToken,                    // Only if you are using refreshToken feature
            refreshTokenExpireIn: res.data.refreshTokenExpireIn
          })) { // Only if you are using refreshToken feature
            this.props.returnHandle({ go: true, page: 'Dashboard' });
          }
        } else if (res.data.error !== '') {

          Swal.fire({
            icon: "error",
            title: '',
            text: res.data.error || 'server error.',
            confirmButtonColor: '#7b1fa2',
          }).then(() => {

            this.props.returnHandle({ go: true, page: 'signin' });
          });

        } else {
          alert('something goes wrong!');
        }
      }).catch(() => {
        this.setState({ load: false });
        Swal.fire({
          icon: "error",
          title: '',
          text: 'server error.',
          confirmButtonColor: '#7b1fa2',
        }).then(() => {

          this.props.returnHandle({ go: true, page: 'signin' });
        });


      });
    }
  }

  render() {
   

    return (






<>
{this.props.sendprop.register_res===null ?  <Navigate  to={"/"} replace={false}/>  : ''}
       {/* <PaymentForm from="signup" sendprop={this.props.sendprop}  returnHandle={(r:any)=>this.autoLogin()}/>  */}
       <PaymentForm from="signup" sendprop={this.props.sendprop}  returnHandle={(r:any)=> this.autoLogin(r)}/> 
</>
    );
  }
}





export default withSignIn(CheckoutForm);
