const titles: any = [
    'Wishing you a successful search for your perfect match today.',
    "We're here to help you find the one you've been looking for.",
    "Let's make today the day you find your soulmate",
    "May today be the day you find true love.",
    "Welcome back! Let's continue your journey to finding love.",
    "Ready to find your match? Let's get started.",
    "Keep searching, love is just around the corner.",
    "Let's work together to find the one that's right for you.",
    "We believe in your ability to find love today.",
    "Let's make today the day you find your happily ever after.",
    "Every day brings new opportunities for love. Let's seize them together.",
    "The journey to love starts with a single step. Keep walking.",
    "We're here to help you navigate the world of online dating and find your match.",
    "Love is waiting for you. Let's go find it.",
    "We're rooting for you! Keep searching for your perfect match.",
    "Your perfect match could be just a few clicks away. Keep searching.",
    "Welcome back to the search for your soulmate.",
    "Love is a journey, not a destination. Let's enjoy the ride.",
    "Let's make today the day you find someone who makes your heart skip a beat.",
    "Keep an open mind and an open heart as you search for love.",
    "Love is worth the wait. Keep searching.",
    "You deserve love, and we're here to help you find it.",
    "Let's make today the day you take a step closer to finding your forever.",
    "Welcome back to your search for love. Let's make it a successful one.",
    "Let's find the one who completes you.",
    "Don't give up on love. Your match could be just a few swipes away.",
    "Love is in the air, and we're here to help you catch it.",
    "Let's make today the day you find the person who makes your heart sing.",
    "Every journey starts with a single step. Let's take that step together.",
    "Keep searching for love, and we'll keep providing you with the tools to find it.",
    "Let's work together to find the love you deserve.",
    "We believe in your ability to find your soulmate.",
    "Your perfect match is out there, waiting for you to find them.",
    "Let's make today the day you find someone who makes your life complete.",
    "The search for love can be tough, but we're here to help you through it.",
    "We're here to help you find the one who makes your heart race.",
    "Let's make today the day you find someone who makes you feel alive.",
    "Keep searching, and you'll find the love you're looking for.",
    "We're here to support you on your journey to finding love.",
    "Let's make today the day you find someone who loves you for who you are.",
    "Love is a beautiful thing, and we're here to help you find it.",
    "Let's make today the day you find the one who makes your heart melt.",
    "Your perfect match is waiting for you. Let's find them together.",
    "Welcome back to your quest for love. Let's make it a successful one.",
    "We're here to help you find the love that's meant for you.",
    "Let's make today the day you find someone who makes you laugh and smile.",
    "Keep searching, and you'll find the love you've been dreaming of.",
    "Let's work together to make your search for love a successful one.",
    "Let’s get you closer to finding your soulmate today.",
    "Keep searching for love, you’re doing great!"
    
];
const random = titles[Math.floor(Math.random() * titles.length)];

  
  export default random;



