import React from "react"
import { ButtonGroup, Backdrop, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import test from '../../assets/test2.png'
class Verifypic extends React.Component<any>{
  public state = {
    verifypic: this.props.sendprop.verifypic,
    vpic: this.props.sendprop.vpic,
    verifypic_error: '',
    go: false,
    page: '',
    loading: true,
    camera_error: false,
    retake: false,
  }

  // constructor(props:any) {
  //     super(props)
  // }


  public skipProcess = () => {
    this.props.returnHandle({ verifypic: '', page: 'email' });
  }
  public setProp = () => {

    if (this.state.verifypic.type !== undefined) {
      const type = this.state.verifypic.type.split('/');

      if (this.state.verifypic.size <= 7340032 && type[0] === 'image') {
        this.props.returnHandle({ verifypic: this.state.verifypic, page: 'email' });
      } else {
        this.setState({ verifypic_error: 'Take a picture holding up a two finger peace sign to verify your account.' });
      }
    } else {

      this.props.returnHandle({ verifypic: '', page: 'email' });
      // this.setState({verifypic_error:'Take a picture holding up a two finger peace sign to verify your account.'});
    }

  }

  public setvalues = (r: any) => {

    fetch(r)
      .then(res => res.blob())
      .then(blob => {

        this.setState({ verifypic: blob, retake: true });
        this.props.returnHandle({ verifypic: blob, vpic: r });
      })


  }

  public onCameraStart() {
    this.setState({ loading: false });
  }

  public handleCameraError(error: any) {
    console.log('handleCameraError', error);
    this.setState({ loading: false, camera_error: true });
  }


  componentDidMount(): void {
    if (this.state.vpic !== '') {
      this.setState({ loading: false });
    }
  }

  render(): React.ReactNode {

    return (
      <div className="container main" >
        {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
        <div className="d-block m_auto col-md-5" >
          <h3 className="d-block text-center" >Please upload a photo holding two fingers up to verify your account</h3>

          {!this.state.retake && this.state.vpic == '' ?
            <Camera
              onCameraStart={() => { this.onCameraStart(); }}
              onTakePhoto={(dataUri) => { this.setvalues(dataUri); }}
              onCameraError={(error) => { this.handleCameraError(error); }}
            />
            : ''}

          <span className="text-danger">{this.state.verifypic_error}</span>
          {(this.state.vpic !== '' || (this.state.verifypic && this.state.retake)) ?
            <label><img alt="img" className="profilepic4" src={this.props.sendprop.vpic !== '' ? this.props.sendprop.vpic : test} style={{ display: this.props.sendprop.vpic !== '' ? 'block' : 'none' }} />
              <div id="container-circles" className="retake" onClick={() => this.setState({ retake: false, verifypic: false, vpic: '' })}><div id="outer-circle"><div id="inner-circle"></div></div></div>

            </label>
            : ''}
          <ButtonGroup style={{ width: '100%' }}>
            <LoadingButton
              style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
              type="submit"
              size="large"
              disabled={false}
              className="btns login-next-button"
              loading={false}
              loadingPosition="center"
              onClick={() => this.props.returnHandle({
                page: 'questions',
                current_q: 18,
                register: false,
                questiongroup: [18],
                prevNext: ['occupation', 'verifypic'],
              })}
              variant="contained">
              Back
            </LoadingButton>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {this.state.camera_error ?
              <LoadingButton
                style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                type="submit"
                size="large"
                disabled={false}
                className="btns  login-next-button"
                loading={false}
                loadingPosition="center"
                onClick={() => this.skipProcess()}
                variant="contained">
                Skip
              </LoadingButton>
              : ''}

            <LoadingButton
              style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
              type="submit"
              size="large"
              disabled={false}
              className="btns login-back-button"
              loading={false}
              loadingPosition="center"
              onClick={() => this.setProp()}
              variant="contained">
              Next
            </LoadingButton>

          </ButtonGroup>

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>

        </div>
      </div>
    );
  }



}

export default Verifypic;