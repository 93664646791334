import React from "react"
import { ButtonGroup, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";


class PhoneNumber extends React.Component<any>{
    public state = {
        number: this.props.sendprop.number,
        number_error: '',
        go: false,
        page: '',
    }

    // constructor(props:any) {
    //     super(props)
    // }


    public setProp = () => {


        if (this.state.number.length > 9 && this.state.number.length < 11) {
            this.props.returnHandle({ number: this.state.number.trim(), page: 'location' });

        } else {
            this.setState({ number_error: 'Please enter a valid mobile number.Maximum 10 numbers' });
        }

    }

    public setvalues = (r: any) => {
        const { value, maxLength } = r.target;
        if (String(value).length >= maxLength) {
            r.preventDefault();
            return;
        }
        this.props.returnHandle({ number: r.target.value });
        this.setState({ number: r.target.value });
    }

    render(): React.ReactNode {

        return (
            <div className="container main py-2" >
                {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
                <div className={this.props.from === undefined ? 'd-block m_auto  col-md-5' : 'd-block m_auto  col-md-12'} >
                    {this.props.from !== undefined && this.props.from !== null ? '' : <h3 className="d-block text-center" >What is your mobile number?</h3>}
                    <TextField autoComplete="one-time-code" required={true} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 11 }} className='d-flex' type={'number'} id="outlined-basic" label="Enter your mobile number" onChange={(e) => this.setvalues(e)} error={this.state.number_error !== '' ? true : false} helperText={this.state.number_error} variant="outlined" value={this.props.sendprop.number} />

                    <ButtonGroup style={{ width: '100%' }}>
                        {this.props.from === undefined ? <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns login-next-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.props.returnHandle({ page: 'profilepic' })}
                            variant="contained">
                            Back
                        </LoadingButton> : ''}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns  login-back-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.setProp()}
                            variant="contained">
                            {this.props.from === undefined ? 'Next' : 'Save'}
                        </LoadingButton>

                    </ButtonGroup>

                </div>
            </div>
        );
    }


}

export default PhoneNumber;