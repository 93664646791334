import React from "react"
import axios from 'axios'
import logo2 from '../assets/img/logo.png'

import FirstName from '../components/signup/firstName'
import Dob from '../components/signup/dob'
import Height from '../components/signup/height'
// import Uploadphotos from '../components/signup/uploadphotos'
import Email from '../components/signup/email'
import Location from '../components/signup/location'
import LookingMinMax from '../components/signup/lookingMinMax'
import Question from '../components/signup/quetions'
import TermConditions from '../components/signup/termConditions'
import PhoneNumber from '../components/signup/phoneNumber'
import { Navigate } from "react-router-dom"
import variables from "../global/global"
import Profilepic from "../components/signup/profilepic"
import Password from "../components/signup/password"
import Occupation from "../components/signup/occupation"
import Verifypic from "../components/signup/verifypic"
import Plans from "./plans"
import CheckoutForm from "./checkout"
import { loadStripe } from "@stripe/stripe-js"
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"
import DoneIcon from '@mui/icons-material/Done';

import {  useSignIn } from "react-auth-kit"
class demoSignup extends React.Component<any>{

        public state = {
            go:false,
            page:'questions',
            demo:true,
    id:'',
    page_c:'signup',
    name:'',
    dob:'',
    day:'',
    month:'',
    year:'',
    height:'',
    email:'',
    password:'',
    occupation:'',
    location:{city:'',state:'',zip:''},
    lat:'',
    lang:'',
    lookingminmax:{min:'',max:''},
    questions:[],
    current_q:1,
    questiongroup:[1,2],
    prevNext:['','dob'],
    profilepic:{},
    pic:'',
    confirm:1,
    verifypic:{},
    vpic:'',
    termcondition:false,
    number:'',
    photos:[],
    register:false,
    loading:false,
    register_res:null,
    selected_plan:{},
    clientSecret:'',
    title:'You will get all MVP / Major League features for the first 60 days. Pick your plan to start on day 61. No refunds:',
        }

    // constructor(props:any) {
    //     super(props)
    // }
 
    public getQuestions =()=>{
             
        axios.get(variables.url+'getRegisterQuestions')
                .then((res)=>{
                    
                    if(res.status === 200){

                     const rowx = res.data.rows.map((val:any)=>{
                            val.selected = '';
                            val.active = val.id===1 ? true : false;
                           val.questions_options.sort((a:any,b:any)=>{
                            if(a.option < b.option) { return -1; }
                            if(a.option > b.option) { return 1; }
                            return 0;
                        });
                        return val;
                        });
                                     
                   this.setState({questions:rowx,  loading:false,});

                         }
                });
    } 

    public goto = (page:any)=>{
        this.setState({go:true,page:page});
            }

    public setData=(r:any)=>{

this.setState(r);
window.history.pushState('test', 'Signup',r.page);
}

componentDidMount(): void {
    this.setState({  loading:true})
this.getQuestions();
}

componentDidUpdate(): void {
if(this.state.register){
this.finalRegister();
}
}

public finalRegister = ()=>{
     axios.post(variables.url+'registeruser',this.state,{
        headers: {
            'Content-Type': 'multipart/form-data'
          }
    })
    .then((res)=>{
        
        if(res.status === 200){
                    this.setState({register:false,register_res:res.data});
                    setTimeout(() => {
                     this.setState({page:'plans'});   
                    }, 100);
             }
    });
    
}



public render(){

    return (
        <div className="container" style={{display:'flex',height:'100vh',margin:'auto'}}>
        {this.state.go===true ?<Navigate state={this.state} to={"/"+this.state.page} replace={true}/> : ''}
              
         <div className="signupform" >    
<div className="form-group t-center">
<img alt='logo' src={logo2} width={'223px'}  onClick={()=>this.goto('')}/>
<h6 style={{color:'green'}}>{this.state.page==='plans' ? 'Account has been successfully created ' : ''} {this.state.page==='plans' ? <DoneIcon/> : ''} </h6>
</div>
{ 
this.state.page==='name' ? <FirstName returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> : 
this.state.page==='dob' ? <Dob returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='height' ? <Height returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='profilepic' ? <Profilepic returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='location' ? <Location returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='lookingForMinMaxAge' ? <LookingMinMax returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='number' ? <PhoneNumber returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='occupation' ? <Occupation returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='verifypic' ? <Verifypic returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='questions' && this.state.questions[0]!==undefined ? <Question   returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='email' ? <Email returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='password' ? <Password returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='termAndconditions' ? <TermConditions   returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :
this.state.page==='checkout' ? <Elements  stripe={loadStripe(variables.stripKey)} >
     <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm stripe={stripe} from='signup' sign={useSignIn()} elements={elements}  returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/>
      )}
    </ElementsConsumer>
</Elements> :
this.state.page==='plans' ? <Plans  from='signup' returnHandle={(r:any)=>this.setData(r)} sendprop={this.state}/> :''
}
         </div>
        </div>
    );
}


}

export default demoSignup;