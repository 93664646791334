import { Backdrop, Button, ButtonGroup, CircularProgress, Divider, Grid, Slider, Typography, Chip, Snackbar, Alert, IconButton } from '@mui/material';
import React from 'react';
import variables from '../../global/global';
import TopHeader from '../includes/header';
import Breadcrumb from '../includes/breadcrumb';
import { Navigate } from 'react-router-dom';
import logo from '../../assets/img/logo.png'
import logopitch from '../../assets/img/logo-pitch.png'
import logoWithout from '../../assets/img/logo-without.png'
import Genres from '../../components/profile/genres';
import About from '../../components/profile/about';
import axios from 'axios';
import Sendpitch from '../../components/lovediamond/sendpitch';
import { LoadingButton } from '@mui/lab';
import Base64e from '../../components/encodedecode/encode';
import Swal from 'sweetalert2';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import GenresPotentailPartner from '../../components/profile/genresPotentialPartner';
import CloseIcon from '@mui/icons-material/Close';
import Massages from '../Massages/Massages';
import searching_for from '../../assets/img/searching_for.png';
import Occupation from '../../assets/img/Occupation.png';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Personality from '../../components/profile/personality';
import profileline from '../../assets/img/Profile-line.png';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';



const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));



class MatchesProfile extends React.Component<any>{

  // constructor(props:any) {
  //     super(props)
  // }

  public state = {
    requestRow: [],
    chatPage: false,
    permission: false,
    activeField: false,
    msg: '',
    snack_open: false,
    snackmsg: '',
    dialog: false,
    confirm: false,
    pics: this.props.user().rows[0].user_photos,
    personalities: [],
    add_personalities: [],
    selected_per: [''],
    selected_per_add: [''],
    per_error: '',
    Otheruser: { id: '', first_name: '', dob: '', height: '', city: '', state: '', zip: '', confirm: 0, occupation: '', looking_min_age: '', looking_max_age: '', user_answers: [] },
    user: this.props.user().rows[0],
    activepic: 0,
    bool: false,
    loaded: false,
    id: this.props.user().rows[0].id,
    path: '',
    loading: true,
    priority: 0,
    showmsgbox: 'match_profile',
    insterst_msg: 'Slide the bar to the right or left to indicate your interest',
    opposite_request: 0,
    pending_request: { id: '', count: 0, to: '', from: 0, priority: 0, to_priority: 0, status: 999 },
    showSlider: true,
    sendpitch: {
      open: false,
      value: '',
      loading: false,
      id: '',
      column: '',
    },
    row_id: '',
    request: {
      to_priority: 0,
      priority: 0,
      from: '',
      id: '',
    },
    breadcrumb: [
      {
        link: '/matches',
        link_name: 'Love Pitches'
      },
      {
        link: '',
        link_name: 'Match Profile'
      }]
  }


  public marks = [
    {
      value: 0,
      label: '0%',

    },
    {
      value: 25,
      label: '25%',

    },
    {
      value: 50,
      label: '50%',

    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];


  public goto = (path: any) => {
    this.setState({
      bool: true,
      path: path,
    });
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log(errorInfo);

  }

  componentDidMount(): void {

    const id = window.location.href.split('/');

    const Eid = id[id.length - 2];
    const Rid = id[id.length - 1];

    const userId = Base64e.encode(this.props.user().rows[0].id + '');
    this.setState({ row_id: Rid, user: this.props.user().rows[0] })
    axios.post(variables.url + 'getSingleUser', {
      id: Eid,
      Rid: Rid,
      lat: this.props.user().rows[0].lat,
      lang: this.props.user().rows[0].lang,
      uId: userId
    })
      .then((res) => {
        if (res.status === 200 && res.data !== '') {
          const row = res.data.rows.rows[0];

          var a = [{
            id: 0,
            picture: row.pic,
            type: 'image',
            user_id: '0',
            createdAt: ''
          }];
          var otheruser_pics = a.concat(row.user_photos);

          console.log('personalities', res.data.request.count);
          if (res.data.request.count > 0) {
            const request = res.data.request.rows[0];

            this.setState({ Otheruser: row, pics: otheruser_pics, request: request }, () => {


              const personalities: any = [];
              const add_personalities: any = [];
              const a: any = [];
              let answer_id: any = [];
              this.state.Otheruser.user_answers.map(function (val: any, i: any) {





                if (val.question != null && val.question_id == 9 && val.answer_id != null && JSON.parse(val.answer_id).length > 0) {
                  answer_id = JSON.parse(val.answer_id);
                  console.log('val.id', val.question.questions_options);
                  val.question.questions_options.map((row: any) => {
                    personalities.push(row.option);
                    if (row.required == 1) {
                      // personalities.push(row.option);
                    } else {
                      add_personalities.push(row.option);
                    }


                  })

                }
              })
              this.setState({ selected_per: answer_id, personalities: personalities, add_personalities: add_personalities });



              // console.log(this.state.request, 'data');
            })
            this.setState({ showmsgbox: 'hit_back_from_profile' })

          } else {

            this.setState({ Otheruser: row, pics: otheruser_pics }, () => {


              const personalities: any = [];
              const add_personalities: any = [];
              const a: any = [];
              let answer_id: any = [];
              this.state.Otheruser.user_answers.map(function (val: any, i: any) {





                if (val.question != null && val.question_id == 9 && val.answer_id != null && JSON.parse(val.answer_id).length > 0) {
                  answer_id = JSON.parse(val.answer_id);
                  console.log('val.id', val.question.questions_options);
                  val.question.questions_options.map((row: any) => {
                    personalities.push(row.option);
                    if (row.required == 1) {
                      // personalities.push(row.option);
                    } else {
                      add_personalities.push(row.option);
                    }


                  })

                }
              })
              this.setState({ selected_per: answer_id, personalities: personalities, add_personalities: add_personalities });



              // console.log(this.state.request, 'data');
            })
            this.setState({ showmsgbox: 'match' })
          }







          this.setState({ opposite_request: res.data.opposite_request.count })
          if (res.data.opposite_request.count > 0) {
            console.log('matccccc', res.data.opposite_request);
          //  alert(res.data.opposite_request.count);
            this.setState({ pending_request: res.data.opposite_request.rows[0] }, () => {

              this.setState({ priority: this.state.user.id == this.state.pending_request.from ? this.state.pending_request.priority : this.state.pending_request.to_priority })

              console.log('srataus223244', this.state.pending_request.status);
              if (this.state.pending_request.status == 0 || this.state.pending_request.status == 1 || this.state.pending_request.status == 3 || this.state.pending_request.status == 2) {
                this.setState({ showSlider: false })
                this.setState({ showmsgbox: 'massages-list' })
              } else {
                this.setState({ showSlider: true })
              }
              console.log('opp', this.state.pending_request);
            })
          } else if (res.data.pending_request.count > 0) {
           
            this.setState({ pending_request: res.data.pending_request.rows[0] }, () => {

              this.setState({ priority: this.state.user.id == this.state.pending_request.from ? this.state.pending_request.priority : this.state.pending_request.to_priority })

              if (this.state.pending_request.status == 0 || this.state.pending_request.status == 1 || this.state.pending_request.status == 3 || this.state.pending_request.status == 2) {
                this.setState({ showSlider: false })
                this.setState({ showmsgbox: 'massages-list' })
              } else {
                this.setState({ showSlider: true })
              }
            })

            console.log('mat', this.state.pending_request);
          }

          console.log('matddf', this.state.pending_request);













          this.setState({ loading: false, loaded: true });
        } else {
          this.goto('matches')
        }
      }).catch((e: any) => {
        console.log(e);
        this.setState({ loading: false });
        this.goto('matches')
      });
  }

  public blockUser = () => {

    Swal.fire({
      title: 'Are you sure you want to block this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(variables.url + 'blockUser', {
          user_id: Base64e.encode(this.state.id + ''),
          blocked: Base64e.encode(this.state.Otheruser.id + ''),
          req_id: this.state.row_id,
        })
          .then((res) => {
            if (res.status === 200) {


              this.goto('');





            }
          }).catch((e: any) => {
            console.log(e);
          });


      }

    })
  }

  public strikeout = (id: any, strikeout: any, rq_id: any) => {

    this.setState({ loading: true });
    axios.post(variables.url + 'strikeout', {
      strike: strikeout,
      id: Base64e.encode(id + ''),
      rq_id: rq_id,
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ loading: false });
          this.goto('matches');

        }
      }).catch((e: any) => {
        console.log(e);
      });

  }



  public render() {

    let question = this.state.Otheruser.user_answers;

    function valuetext(value: number) {

      console.log('frommm', value);
      if (value >= 1 && value <= 24) {
        return "Headed  towards first base!";
      } else if (value >= 25 && value <= 49) {
        return "First base!";

      } else if (value >= 50 && value <= 74) {
        return "Second base!";
      } else if (value >= 75 && value <= 99) {
        return "Third base!";

      } else if (value == 100) {
        return "Home run!";

      }
    }
    function trans(id: any, from: any, priority: any, to_priority: any) {



      return id == from ? 100 - parseInt(priority) : 100 - parseInt(to_priority)
    }
    function trans1(priority: any) {



      return 100 - parseInt(priority)
    }


    question.sort((a: any, b: any) => a.question_id.localeCompare(b.question_id) || b.question_id - a.question_id);
    console.log('leeeeee', question);
    return (


      <div className='App' >
        {this.state.bool === true ? <Navigate state={this.state} to={"/" + this.state.path} replace={true} /> : ''}
        <TopHeader title="&nbsp;" logo={''} />
        <Breadcrumb breadcrumb={this.state.breadcrumb} />
        {!this.state.chatPage ?
          <>
            {this.state.Otheruser.id != '' ?
              <>
                <div className='container-fluid'>
                  <div className='d-flex justify-content-end blockbtn'>
                    <ButtonGroup>
                      {(this.state.pending_request !== undefined && this.state.pending_request.to !== '' && this.state.pending_request.to == this.state.user.id && this.state.pending_request.status == 0) ?
                        <> <LoadingButton className='back-btn  text-lowercasee' size='large' color='secondary' loading={this.state.loading} variant='contained' onClick={() => this.openIntrestBox({
                          priority: 0,
                          open: true,
                          value: this.state.Otheruser,
                          id: this.state.pending_request.id,
                          column: this.props.user().rows[0].id == this.state.request.from ? 'from' : 'to',
                        })}>Hit Back </LoadingButton>&nbsp;&nbsp;
                          <LoadingButton className='btns red-btn text-lowercasee ' size='large' color='secondary' loading={this.state.loading} onClick={() => this.strikeout(this.state.id, this.state.Otheruser.id, this.state.request.id)} variant='contained'>Strike Out</LoadingButton>
                        </> : ''}
                      {/* <LoadingButton style={{ marginLeft: '5px' }} className='btns red-btn text-lowercasee' size='large' color='secondary' loading={this.state.loading} onClick={() => this.blockUser()} variant='contained' >Block/Report</LoadingButton> */}

                    </ButtonGroup>
                  </div>
                </div>
                <div className="profile_  ">
                  <div className='container-fluid px-md-4 px-xs-2'>
                    <div className='row'>
                      <div className='col-md-4 position-relative  ing'>
                        <div className='profilegrid'>
                          <div className='location'>
                            <Typography className='m-1' variant='h6'><span className='firstname'>{this.state.Otheruser.first_name},  {this.getAge(this.state.Otheruser.dob.toString())}</span> {this.state.Otheruser.confirm==1 ? <> 
                    <IconButton className="verifiedicon" color="inherit" sx={{ flexDirection: 'row', alignItems:'center' }}>
                           <VerifiedOutlinedIcon  />
                        <Typography className="red-text p-0" variant="caption">Photo Verified</Typography>
                      </IconButton></> : '' }
                        <br /> <span className='address'>{this.state.Otheruser.city}, {this.state.Otheruser.state} ({this.state.Otheruser.zip})</span> </Typography>


                          </div>

                          {this.state.pics.length > 0 ? <img className='sliderImg' width={'100%'} alt={'img'} src={this.state.pics[this.state.activepic].picture} /> : <img className='sliderImg' width={'100%'} alt={'img'} src={variables.BaseUrl + 'thumbnails/img/' + 'nopic.jpg'} />}
                        </div>
                        <Grid container style={{ marginTop: '5px' }} className='tols'>
                          <Grid item xs={2} md={2} style={{ textAlign: 'initial', color: '#fff' }} color='black'>
                            {this.state.activepic + 1} of {this.state.pics.length}
                          </Grid>

                          <Grid item xs={10} md={10} className='text_right d-flex align-items-center justify-content-end'>
                            <ButtonGroup color="primary" className='gridOnSmall gridOnSmall11 ' aria-label="outlined primary button group">
                              <Button className='mr_2' size='small' onClick={() => this.setState({ activepic: this.state.activepic - 1 })} variant='contained' disabled={this.state.activepic <= 0 ? true : false}><ArrowLeftIcon /></Button>
                              <Button className='mr_2' size='small' onClick={() => this.setState({ activepic: this.state.activepic >= this.state.pics.length - 1 ? 0 : this.state.activepic + 1 })} variant='contained' disabled={this.state.activepic >= this.state.pics.length - 1 ? false : false}><ArrowRightIcon /></Button>

                            </ButtonGroup>

                          </Grid>
                        </Grid>
                        <Divider className='m-1' />
                      </div>
                      <div className='col-md-8'>
                        <div className='d-block text-left'>
                          <div className='profile-question'>
                            <img src={searching_for} />
                            {this.state.Otheruser.looking_min_age} - {this.state.Otheruser.looking_max_age}

                          </div>
                          <div className='profile-question'>
                            <img src={Occupation} />
                            {this.state.Otheruser.occupation}


                          </div>
                          <div className='profile-question'>
                            <img src={require(`./../../assets/img/Height.png`)} />
                            {this.state.Otheruser.height}


                          </div>
                          {
                            question.map((val: any, index: any) => (

                              <>
                                {val.question != null ?


                                  <>

                                    {val.question != null && val.question_id == 9 && val.answer_id != null && JSON.parse(val.answer_id).length > 0 ?
                                      <>
                                        {/* <hr className='hr' /> */}
                                        <img src={profileline} className='profileline' />
                                        <Personality data={this.state} questions={val.question} edit={false} returnhandle={(e: any) => this.setState(e)} />
                                      </>
                                      :
                                      <>
                                        {val.question != null && val.question_id != 1 ?
                                          <div className='profile-question'>
                                            <img src={require(`./../../assets/img/${val.question.sub_title}.png`)} />

                                            {val.question != null ? val.question.questions_options.map((ans: any) => (
                                              ans.id === parseInt(val.answer_id) ? ans.option : '')) : ''}


                                          </div>
                                          : ''}
                                      </>
                                    }
                                  </>
                                  : ''}
                              </>


                            ))
                          }

                        </div>

                      </div>
                    </div>












                    <div className='row'>
                      <div className='col-md-12 profile-fields'>
                        {this.state.loaded ? <Genres data={{ user: this.state.Otheruser }} edit={false} returnhandle={(e: any) => this.setState(e)} /> : ''}
                        <Divider className='m-1' />
                        {this.state.loaded ? <About state={{ user: this.state.Otheruser }} edit={false} /> : ''}
                        <Divider className='m-1' />
                      </div>

                    </div>





                    {(this.state.user.email != 'test_male@gmail.com' && this.state.user.email != 'test_female@gmail.com') ?

                      <>
                        {this.state.showSlider ?
                          <Grid xs={12} sm={10} md={10} className='slidd m-auto'>

                            <Grid container md={12}  >

                              <Grid item md={9} xs={11} className='m-auto'>
                                <div>
                                  <div className='d-flex align-items-center matchprofile'>
                                    <div className='percet' style={{ width: '60px' }}>{this.state.priority}%</div>
                                    <div className='pos-re-pro'>
                                      <img className='fade-img slider-logo' src={logoWithout} width={100} alt="logo" />
                                      <img className='heart-img heart-img-pro slider-logo' src={logopitch} alt="logo" style={{
                                        WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + trans1(this.state.priority) + '%, black 0%, black 90%, transparent 100%)',
                                        maskImage: 'linear-gradient(to bottom, transparent ' + trans1(this.state.priority) + '%, black 0%, black 90%, transparent 100%)'
                                      }} />
                                    </div>
                                    <ButtonGroup>
                                      <LoadingButton style={{ marginLeft: '5px' }} className='text-lowercasee btns red-btn' size='large' color='secondary' loading={this.state.loading} onClick={() => this.openChat(this.state.Otheruser)} variant='contained' >Send Message</LoadingButton>
                                    </ButtonGroup>
                                  </div>
                                  <>
                                    <Slider
                                      key={`slider-${valuetext}`}
                                      aria-label="Temperature"
                                      sx={{
                                        color: '#ffffff',
                                      }}
                                      defaultValue={
                                        this.state.request.from !== '' ?
                                          this.props.user().rows[0].id === parseInt(this.state.request.from) ? this.state.request.priority : this.state.request.to_priority

                                          : 0
                                      }
                                      //valueLabelFormat={valuetext}
                                      valueLabelDisplay='auto'
                                      marks={this.marks}
                                      step={1}
                                      onChangeCommitted={(_, newValue) => this.handleSubmit(newValue)}
                                      onChange={(_, newValue) => this.setState({ priority: newValue })}

                                      min={0}
                                      max={100}
                                    />
                                    <span className='slider-tt'>Slide the bar to the right or left to indicate your interest </span>
                                  </>
                                </div>
                              </Grid>

                            </Grid>



                            <div className='d-flex  align-items-center'>



                            </div>
                          </Grid>
                          :


                          <>
                            {this.state.pending_request.status == 1 || this.state.id == this.state.pending_request.from ?
                              <Grid xs={12} sm={12} md={12} className='slidd  m-auto'>

                                <Grid container spacing={4} className=' py-4' style={{ justifyContent: 'center' }} >
                                  {/* <Grid  item xs={12}  md={2}><div className='percet'>{this.state.priority}%</div></Grid>  */}
                                  <Grid item xs={12} className={'font-40 yourins'} md={4}>
                                    <Typography sx={{ fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} ><span className='yellow'>Your</span> Interest</Typography>

                                    <div className='pos-re-pro'>
                                      <img className='fade-img slider-logo' src={logoWithout} width={100} alt="logo" />
                                      <img className='heart-img heart-img-pro slider-logo' src={logopitch} alt="logo" style={{
                                        WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + trans1(this.state.priority) + '%, black 0%, black 90%, transparent 100%)',
                                        maskImage: 'linear-gradient(to bottom, transparent ' + trans1(this.state.priority) + '%, black 0%, black 90%, transparent 100%)'
                                      }} />
                                    </div>


                                    <Typography sx={{ fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} >{this.state.priority}%</Typography>



                                    <LoadingButton className='text-lowercasee btns red-btn' size='large' color='secondary' loading={this.state.loading}
                                      onClick={() => this.openIntrestBox({
                                        priority: this.state.priority,
                                        open: true,
                                        value: this.state.id == this.state.pending_request.from ? this.state.Otheruser : this.state.user,
                                        id: this.state.pending_request.id,
                                        column: this.state.id == this.state.pending_request.from ? 'from' : 'to',
                                        buttontext:this.state.pending_request.status == 2 ? 'Send Pitch' : 'Update Interest'
                                      })} variant='contained' >
                                        {this.state.pending_request.status == 2 ? 'Send Pitch' : 'Update Interest'}
                                        
                                        
                                        </LoadingButton>



                                  </Grid>

                                  {this.state.pending_request.status ?
                                    <>
                                      <Grid item xs={12} className={'font-40 yourins'} md={4} spacing={5}>
                                        <Typography sx={{ fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} ><span className='yellow'>Their</span>  Interest</Typography>

                                        <div className='pos-re-pro'>
                                          <img className='fade-img slider-logo' src={logoWithout} width={100} alt="logo" />
                                          <img className='heart-img heart-img-pro slider-logo' src={logopitch} width={100} alt="logo" style={{
                                            WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, this.state.pending_request.from, this.state.pending_request.to_priority, this.state.pending_request.priority) + '%, black 10%, black 90%, transparent 100%)',
                                            maskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, this.state.pending_request.from, this.state.pending_request.to_priority, this.state.pending_request.priority) + '%, black 10%, black 90%, transparent 100%)'
                                          }} />
                                        </div>

                                        <Typography sx={{ fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} >{this.state.id == this.state.pending_request.from ? this.state.pending_request.to_priority ?? 0 : this.state.pending_request.priority ?? 0}%</Typography>
                                        {/* <LoadingButton style={{fontSize:'12px'}} className=''size='large'  color='secondary' loading={this.state.loading} 
                                                          onClick={()=>this.setState({bool:true, path:'ChatList'})} variant='contained' >Message</LoadingButton> */}
                                        <div className='d-flex flex-column align-items-center '>
                                          <ButtonGroup>
                                            <LoadingButton className='text-lowercasee btns red-btn' size='large' color='secondary' loading={this.state.loading} onClick={() => this.openChatIfRequestAlreadysent(this.state.Otheruser)} variant='contained' >Send Message</LoadingButton>
                                          </ButtonGroup>
                                        </div>
                                      </Grid>
                                      {/* <Grid  item xs={12}  md={2}><div className='percet'>{this.state.id== this.state.pending_request.from ? this.state.pending_request.to_priority ?? 0 : this.state.pending_request.priority ?? 0}%</div></Grid>  */}
                                    </>
                                    : ''}

                                </Grid>
                              </Grid>
                              : ''}

                          </>

                        }
                      </>


                      : ''}


<LoadingButton style={{ marginTop: '50px' }} className='btns red-btn text-lowercasee' size='large' color='secondary' loading={this.state.loading} onClick={() => this.blockUser()} variant='contained' >Block/Report</LoadingButton>
                  </div>


                </div>
                {this.state.sendpitch.open ? <Sendpitch from={this.state.showmsgbox} removeRequestedUser={(e: any) => this.setState({ showSlider: false })} returnhandle={(e: any) => this.setState(e)} data={this.state} /> : ''}
                <br /><br />
              </>
              : ''}
          </>
          : <Massages user={this.props.user} state={this.state} setpriority={(e: any) => this.setpriority(e)} returnHandler={(e: any) => this.setState(e)} />}

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.snack_open}
          autoHideDuration={3000}
          onClose={() => this.setState({ snack_open: false })}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {this.state.snackmsg}
          </Alert>
        </Snackbar>

      </div>

    );



  }

  public setpriority = (i: any) => {
    console.log('iiiiii', i);
    this.setState({ priority: i })

  }
  public openChatIfRequestAlreadysent = (val: any) => {
    console.log('vvvvv', this.state.pending_request);

    this.setState({ chatPage: true, requestRow: this.state.pending_request });
  }

  public openChat = (val: any) => {


    // if (this.state.user.payments[0].plan_id == '4') {
    //   let trial_end = this.state.user.payments[0].start_date;

    //   const date1 = new Date();
    //   const date2 = new Date(trial_end);
    //   console.log(trial_end);

    //   const diffTime = date2.valueOf() - date1.valueOf();
    //   const diffDays = diffTime / (1000 * 60 * 60 * 24);
    //   console.log(diffTime);
    //   if (diffTime <= 0) {

    //     this.setState({ permission: false }, () => {

    //       Swal.fire({
    //         icon: "info",
    //         title: '',
    //         text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
    //         confirmButtonColor: '#7b1fa2',
    //       }).then((res) => {
                
    //         window.location.href = '/subscription?open=1';
    //         //window.location.reload();
    //       })



    //     })

    //   } else {

    //     console.log('openchat');

    //     let data: any = [];
    //     this.setState({ loading: true });
    //     data = {
    //       from: Base64e.encode(this.state.user.id + ''),
    //       to: Base64e.encode(this.state.Otheruser.id + ''),
    //       priority: 0,
    //       msg: '',
    //       id: '',
    //       status: 2
    //     };
    //     axios.post(variables.url + 'sendUserRequests', data).then((res) => {
    //       if (res.status === 200) {
    //         this.setState({ loading: false });
    //         console.log('dttaatata', res.data);

    //         this.setState({ chatPage: true, requestRow: res.data });

    //       }
    //     }).catch((e: any) => {
    //       console.log(e);

    //     });

    //   }
    // } else {


     // if (this.state.user.payments[0].status == 0) {


        console.log('paid openchat');
        //paid users
        let data: any = [];
        this.setState({ loading: true });
        data = {
          from: Base64e.encode(this.state.user.id + ''),
          to: Base64e.encode(this.state.Otheruser.id + ''),
          priority: 0,
          msg: '',
          id: '',
          status: 2
        };
        axios.post(variables.url + 'sendUserRequests', data).then((res) => {
          if (res.status === 200) {
            this.setState({ loading: false });
            console.log('dttaatata', res.data);

            this.setState({ chatPage: true, requestRow: res.data });

          }
        }).catch((e: any) => {
          console.log(e);

        });

      // } else {
      //   Swal.fire({
      //     icon: "info",
      //     title: '',
      //     text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
      //     confirmButtonColor: '#7b1fa2',
      //   }).then((res) => {
                
      //     window.location.href = '/subscription?open=1';
      //     //window.location.reload();
      //   })
      // }
   // }




  }

  public openIntrestBox = (value: any) => {




    if (value.priority >= 1 && value.priority <= 24) {

      this.setState({ insterst_msg: "Your interest is heading towards first base!" });

    } else if (value.priority >= 25 && value.priority <= 49) {
      this.setState({ insterst_msg: "Your interest is first base!" });



    } else if (value.priority >= 50 && value.priority <= 74) {
      this.setState({ insterst_msg: "Your interest is second base!" });



    } else if (value.priority >= 75 && value.priority <= 99) {
      this.setState({ insterst_msg: "Your interest is third base!" });



    } else if (value.priority == 100) {
      this.setState({ insterst_msg: "Your interest is a home run!" });




    }



    if (this.state.user.payments[0].plan_id === '4') {
      let trial_end = this.state.user.payments[0].start_date;

      const date1 = new Date();
      const date2 = new Date(trial_end);
      console.log(trial_end);

      const diffTime = date2.valueOf() - date1.valueOf();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffTime + " milliseconds");
      console.log(diffDays + " days");

      if (diffTime <= 0) {

        this.setState({ permission: false }, () => {

          Swal.fire({
            icon: "info",
            title: '',
            text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
            confirmButtonColor: '#7b1fa2',
          }).then((res) => {
                
            window.location.href = '/subscription?open=1';
            //window.location.reload();
          })



        })

      } else {

        // this.setState({bool:true, path:'addinterest/'+Base64e.encode(value.value.id+'')+'/'+value.priority});
        this.setState({ priority: value.priority, sendpitch: value });
      }
    } else {

      if (this.state.user.payments[0].paused_status == 0) {

        this.setState({ priority: value.priority, sendpitch: value });

      } else {
        Swal.fire({
          icon: "info",
          title: '',
          text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
          confirmButtonColor: '#7b1fa2',
        }).then((res) => {
                
          window.location.href = '/subscription?open=1';
          //window.location.reload();
        })
      }
      // this.setState({bool:true, path:'addinterest/'+Base64e.encode(value.value.id+'')+'/'+value.priority});
    }

  }


  public handleSubmit = (priority: any) => {


    let data: any = [];

    data = {
      from: Base64e.encode(this.state.user.id + ''),
      to: Base64e.encode(this.state.Otheruser.id + ''),
      priority: priority,
      msg: '',
      id: '',
    };

    if (this.state.user.payments[0].plan_id === '4') {
      let trial_end = this.state.user.payments[0].start_date;

      const date1 = new Date();
      const date2 = new Date(trial_end);
      console.log(trial_end);

      const diffTime = date2.valueOf() - date1.valueOf();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(diffTime + " milliseconds");
      console.log(diffDays + " days");

      if (diffTime <= 0) {

        this.setState({ permission: false }, () => {

          Swal.fire({
            icon: "info",
            title: '',
            text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
            confirmButtonColor: '#7b1fa2',
          }).then((res) => {
                
            window.location.href = '/subscription?open=1';
            //window.location.reload();
          })



        })

      } else {
        this.setState({ loading: true });
        axios.post(variables.url + 'sendUserRequests', data).then((res) => {
          if (res.status === 200) {
            this.setState({ loading: false });
            ///this.props.removeRequestedUser(id)
            // this.setState(
            //   {loading:false,
            //   snack_open:true,
            //   snackmsg:'Request sent successfully!'
            //   });
            window.location.reload();
            // setTimeout(()=>( this.setState({bool:true, path:'matches'}) ), 3000)
          }
        }).catch((e: any) => {
          console.log(e);

        });
      }
    } else {
      this.setState({ loading: true });
      axios.post(variables.url + 'sendUserRequests', data).then((res) => {
        if (res.status === 200) {
          this.setState({ loading: false });
          ///this.props.removeRequestedUser(id)
          // this.setState(
          //   {loading:false,
          //   snack_open:true,
          //   snackmsg:'Request sent successfully!'
          //   });
          window.location.reload();
          ///    this.setState({bool:true, path:'matches'})
          // setTimeout(()=>( this.setState({bool:true, path:'matches'}) ), 3000)
        }
      }).catch((e: any) => {
        console.log(e);

      });

    }
  }

  public getAge = (dateString: any) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

}

export default MatchesProfile;