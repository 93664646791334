
export default (state: any, action: any) => {  

    switch (action.type) {
      case "search":
        return {
          search: action.search,
          search_id: action.id
        };
      default:
        return state;
    }
  };