import React from "react"
import axios from 'axios'
import { ButtonGroup, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";
import variables from "../../global/global";
import Autocomplete from '@mui/material/Autocomplete';


class Location extends React.Component<any>{
    public state = {
        city: this.props.sendprop.location.city,
        old_city: this.props.sendprop.location.city,
        old_state: this.props.sendprop.location.state,
        city_error: '',
        state: this.props.sendprop.location.state,
        statefull: this.props.sendprop.location.statefull,
        state_error: '',
        zip: this.props.sendprop.location.zip,
        distance: this.props.sendprop.location.distance,
        zip_error: '',
        go: false,
        page: '',
        lat: this.props.sendprop.location.lat,
        lng: this.props.sendprop.location.lang,
    }

    // constructor(props:any) {
    //     super(props)
    // }
    public stateAbbreviations = [
        { short: "AL", full: "Alabama" },
        { short: "AK", full: "Alaska" },
        { short: "AZ", full: "Arizona" },
        { short: "AR", full: "Arkansas" },
        { short: "CA", full: "California" },
        { short: "CO", full: "Colorado" },
        { short: "CT", full: "Connecticut" },
        { short: "DE", full: "Delaware" },
        { short: "FL", full: "Florida" },
        { short: "GA", full: "Georgia" },
        { short: "HI", full: "Hawaii" },
        { short: "ID", full: "Idaho" },
        { short: "IL", full: "Illinois" },
        { short: "IN", full: "Indiana" },
        { short: "IA", full: "Iowa" },
        { short: "KS", full: "Kansas" },
        { short: "KY", full: "Kentucky" },
        { short: "LA", full: "Louisiana" },
        { short: "ME", full: "Maine" },
        { short: "MD", full: "Maryland" },
        { short: "MA", full: "Massachusetts" },
        { short: "MI", full: "Michigan" },
        { short: "MN", full: "Minnesota" },
        { short: "MS", full: "Mississippi" },
        { short: "MO", full: "Missouri" },
        { short: "MT", full: "Montana" },
        { short: "NE", full: "Nebraska" },
        { short: "NV", full: "Nevada" },
        { short: "NH", full: "New Hampshire" },
        { short: "NJ", full: "New Jersey" },
        { short: "NM", full: "New Mexico" },
        { short: "NY", full: "New York" },
        { short: "NC", full: "North Carolina" },
        { short: "ND", full: "North Dakota" },
        { short: "OH", full: "Ohio" },
        { short: "OK", full: "Oklahoma" },
        { short: "OR", full: "Oregon" },
        { short: "PA", full: "Pennsylvania" },
        { short: "RI", full: "Rhode Island" },
        { short: "SC", full: "South Carolina" },
        { short: "SD", full: "South Dakota" },
        { short: "TN", full: "Tennessee" },
        { short: "TX", full: "Texas" },
        { short: "UT", full: "Utah" },
        { short: "VT", full: "Vermont" },
        { short: "VA", full: "Virginia" },
        { short: "WA", full: "Washington" },
        { short: "WV", full: "West Virginia" },
        { short: "WI", full: "Wisconsin" },
        { short: "WY", full: "Wyoming" }
    ];

    // You can now use the stateArray to access both the state abbreviation and full name for each state.


    public setProp = () => {


        if (this.state.city.length <= 0) {
            this.setState({ city_error: 'City name field is required.' });

        } else if (this.state.city.length > 25) {
            this.setState({ city_error: 'City name is invalid.' });
        } else {
            this.setState({ city_error: '' });
        }
        if (this.state.state.length <= 0) {
            this.setState({ state_error: 'State name field is required.' });
        } else {
            this.setState({ state_error: '' });
        }

        if (this.state.zip.length < 4 || this.state.zip.trim().length < 4 || this.state.zip.length >= 8
            // &&   this.state.lat===''
        ) {
            this.setState({ zip_error: 'zip code is invalid Please check.' });
        } else {
            this.setState({ zip_error: '' });
        }

        setTimeout(() => {
            if (this.state.city_error === '' && this.state.state_error === '' && this.state.zip_error === '') {
                this.props.returnHandle({ location: { city: this.state.city, state: this.state.state, statefull: this.state.statefull, zip: this.state.zip, distance: this.state.distance, lat: this.state.lat, lang: this.state.lng }, page: 'lookingForMinMaxAge' });
            }
        }, 100);

    }
    public debounce = (func: any, wait: any) => {
        let timeout: any;

        return function executedFunction(...args: any) {
            const later = () => {
                timeout = null;

                func(...args);
            };
            clearTimeout(timeout);

            timeout = setTimeout(later, wait);
        };
    }
    public setvalues = (r: any, type: any) => {
        if (type === 'city') {
            //debounce(props.valueOut(event.target.value), 500);
            this.setState({ city: r.target.value });
            if (r.target.value.length <= 0) {
                this.setState({ city_error: 'City name field is required.' });
            } else if (r.target.value.length > 25) {
                this.setState({ city_error: 'City name is invalid.' });
            } else {
                this.setState({ city_error: '' });
            }
        }
        if (type === 'state') {
            console.log('r.target.value', r.short);

            this.setState({ state: r.short, statefull: r });
            if (r.short.length <= 0) {
                this.setState({ state_error: 'State name field is required.' });
            } else {
                this.setState({ state_error: '' });
            }
        }
        if (type === 'zip') {
            this.setState({ zip: r.target.value });
            this.hitMapApiByZip(r.target.value);
        }

        setTimeout(() => {
            this.props.returnHandle({ location: { city: this.state.city, state: this.state.state, zip: this.state.zip, distance: this.state.distance, lat: this.state.lat, lang: this.state.lng } });
        }, 100);

    }
    public checkCityChanges(r: any) {

        console.log(this.state.old_city);
        if (r.target.value !== '') {

            this.hitMapApiByCity(r.target.value);
            //  this.setState({zip_error:'Please also update the zip code.'})
        }

    }
    public checkStateChanges(r: any) {
        if (this.state.old_state !== '' && r.target.value !== this.state.old_state) {
            //   this.setState({zip_error:'Please also update the zip code.'})
        }

    }
    public handleInputChange = (inputValue: any) => {
        console.log('value', inputValue);
        var value = inputValue != undefined && inputValue != null ? inputValue.target.value.toLowerCase() : '';
        const option = this.stateAbbreviations.find(option => option.full.toLowerCase() == value);

        console.log('option', option);
        if (option) {
            console.log('option', option);
            this.setState({ state: option.short, statefull: option });
            this.hitMapApiByCity(this.state.city+' '+option.short);
        } else {
            //setSelectedOption(null);
        }
        return inputValue;
    };
    public handleChange = (selectedOption: any) => {
        this.setState({ statefull: selectedOption });
    };

    render(): React.ReactNode {

        return (
            <div className="container main" >
                {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
                <div className={this.props.from === undefined ? 'd-block m_auto  col-md-5' : 'd-block m_auto  col-md-12'} >
                    {this.props.from !== undefined && this.props.from !== null ?
                        ''
                        : <h3 className="d-block text-center">Where are you located?</h3>}
                    <TextField required={true} className='d-flex mt-2' type={'text'} inputProps={{ maxLength: 25 }} id="outlined-basic" label="City" onBlur={(e) => this.checkCityChanges(e)} onChange={(e: any) => this.setvalues(e, 'city')} error={this.state.city_error !== '' ? true : false} helperText={this.state.city_error} autoComplete="one-time-code" variant="outlined" value={this.state.city ?? this.props.sendprop.location.city} />
                    <FormControl fullWidth className='d-flex mt-2'>
                        <InputLabel id="demo-simple-select-label"></InputLabel>
                        <Autocomplete
                            value={this.state.statefull}
                            disablePortal
                            id="combo-box-demo"
                            options={this.stateAbbreviations}
                            getOptionLabel={option => option.full}
                            //     renderOption={(props, option) => {
                            //         return (
                            //           <li {...props} key={option.short}>
                            //             {option.full}
                            //           </li>
                            //         );
                            //     }
                            //    }
                            sx={{ width: 300 }}
                            // onChange={(e,option)=> this.setvalues(option,'state')} 
                            onInputChange={this.handleInputChange}
                            renderInput={(params) => <TextField autoComplete="one-time-code" error={this.state.state_error !== '' ? true : false}  {...params} label="State*" />}
                        />
                        {/* <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={this.props.sendprop.location.state}
                label="Select your state"
                error={this.state.state_error!=='' ?  true : false} 
                onChange={(e)=> this.setvalues(e,'state')} 
                required={true} 
            >
            { this.stateAbbreviations.map((val:any)=>(<MenuItem key={val} value={val.short}>{val.full}</MenuItem>))}
            </Select> */}
                        <span className="text-light muierror" >{this.state.state_error}</span>
                    </FormControl>

                    {/* <TextField   required={true} className='d-flex mt-2' type={'text'} id="outlined-basic" label="State" onBlur={(e)=> this.checkStateChanges(e)} onChange={(e)=> this.setvalues(e,'state')} error={this.state.state_error!=='' ?  true : false} helperText={this.state.state_error} variant="outlined" value={this.props.sendprop.location.state}/> */}

                    <TextField required={true} className='d-flex mt-2' type={'number'} id="outlined-basic" label="Zip Code" onChange={(e) => this.setvalues(e, 'zip')} error={this.state.zip_error !== '' ? true : false} helperText={this.state.zip_error} variant="outlined" value={this.state.zip} />

                    <ButtonGroup style={{ width: '100%' }}>
                        {this.props.from === undefined ? <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns login-next-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.props.returnHandle({ page: 'number' })}
                            variant="contained">
                            Back
                        </LoadingButton> : ''}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns  login-back-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.setProp()}
                            variant="contained">
                            {this.props.from === undefined ? 'Next' : 'Save'}
                        </LoadingButton>

                    </ButtonGroup>

                </div>
            </div>
        );
    }





    public hitMapApiByCity = async (e: any) => {

        await axios.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + e + '&components=country:US&location_type=GEOMETRIC_CENTER&key=' + variables.MapApi + '&sensor=false')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.status === 'OK' && res.data.results[0] !== undefined) {
                        console.log('cc', res.data.results);
                        let lat = res.data.results[0].geometry.location.lat;
                        let lng = res.data.results[0].geometry.location.lng;
                        axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + variables.MapApi + '&sensor=false')
                            .then((res) => {
                                if (res.status === 200) {
                                    if (res.data.status === 'OK' && res.data.results[0] !== undefined) {
                                        console.log('cc', res.data.results);

                                        this.get_postcode(res.data);
                                    } else {


                                    }
                                } else {
                                    alert('something goes wrong!');
                                }
                            })


                    } else {


                    }


                } else {
                    alert('something goes wrong!');
                }
            })


    }
    public get_postcode = async (results: any) => {
        var city: any = [];
        var city_data = results['results'][0]['address_components'];
        for (var i = 0; i < city_data.length; i++) {
            var cv = city_data[i];
            if (typeof cv['types'][0] != 'undefined') {
                if (cv['types'][0] === 'postal_code') {
                    city['postcode'] = cv['long_name'];
                } else if (cv['types'][0] === 'postal_town') {
                    city['place_name'] = cv['postal_town'];
                }
            }
        }

        if (city['postcode'] == undefined) {
            var city: any = [];
            city_data = results['results'][1]['address_components'];
            for (i = 0; i < city_data.length; i++) {
                var cv = city_data[i];
                if (typeof cv['types'][0] != 'undefined') {
                    if (cv['types'][0] === 'postal_code') {
                        city['postcode'] = cv['long_name'];
                    }
                }
            }
        }
        if (city['postcode'] == undefined) {
            var city: any = [];
            city_data = results['results'][2]['address_components'];
            for (i = 0; i < city_data.length; i++) {
                var cv = city_data[i];
                if (typeof cv['types'][0] != 'undefined') {
                    if (cv['types'][0] === 'postal_code') {
                        city['postcode'] = cv['long_name'];
                    }
                }
            }
        }
        if (city['postcode'] == undefined) {
            var city: any = [];
            city_data = results['results'][3]['address_components'];
            for (i = 0; i < city_data.length; i++) {
                var cv = city_data[i];
                if (typeof cv['types'][0] != 'undefined') {
                    if (cv['types'][0] === 'postal_code') {
                        city['postcode'] = cv['long_name'];
                    }
                }
            }
        }
        console.log('city', city);
        console.log(city.length);

        if (city['postcode'] != undefined) {

            this.setState({ zip: city['postcode'] });
            console.log(this.state.zip);
            this.hitMapApiByZip(city['postcode']);
        } else {
            this.setState({ zip: '' });
        }



    }
    public hitMapApiByZip = async (e: any) => {

        await axios.get('https://maps.googleapis.com/maps/api/geocode/json?key=' + variables.MapApi + '&components=postal_code:' + e)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.status === 'OK' && res.data.results[0] !== undefined) {
                        this.props.returnHandle({ lat: res.data.results[0].geometry.location.lat, lang: res.data.results[0].geometry.location.lng });
                        this.setState({ zip_error: '', lat: res.data.results[0].geometry.location.lat, lng: res.data.results[0].geometry.location.lng });

                    } else {

                        this.setState({ zip_error: 'zip code is invalid Please check.', lat: '' });
                        // if(e.toString().length < 5) {
                        //     this.setState({zip_error:'zip code is invalid Please check.',lat:''});
                        // }else{
                        //     this.setState({zip_error:'',lat:''}); 
                        // }

                    }


                } else {
                    alert('something goes wrong!');
                }
            })

        //// using react-geocode package I was able to successfully capture lat lng of Pincode.
        // GeoCode.setApiKey("YOUR API KEY");
        // GeoCode.setLanguage("en");
        // GeoCode.setRegion("in");
        //   var lat = '';
        //     var lng = '';

        //     // depends on whether you really only have the zip code
        //     var address = {zipcode} or {city and state};

        //     geocoder.geocode({ 'address': address}, function(results, status) {
        //     if (status == google.maps.GeocoderStatus.OK) {
        //         lat = results[0].geometry.location.lat();
        //         lng = results[0].geometry.location.lng();
        //         });
        //     } else {
        //         console.log("Geocode was not successful for the following reason: " + status);
        //     }
        //     });
        //     console.log('Latitude: ' + lat + ' Longitude: ' + lng);



    }

}

export default Location;