import React from 'react';
import variables from '../../global/global';
import { Backdrop, Box, CircularProgress, Fade, Modal } from '@mui/material';
import FirstName from '../signup/firstName';
import Dob from '../signup/dob';
import Location from '../signup/location';
import LookingMinMax from '../signup/lookingMinMax';
import Height from '../signup/height';
import PhoneNumber from '../signup/phoneNumber';
import Occupation from '../signup/occupation';
import Question from '../signup/quetions';
import axios from 'axios';
import Email from '../signup/email';
import Plans from '../../pages/plans';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import CheckoutForm from '../../pages/checkout';
import { loadStripe } from '@stripe/stripe-js';
import { useSignIn } from 'react-auth-kit';
class ProfileUpdate extends React.Component<any,any>{
    // constructor(props:any) {
    //     super(props)
    // }

    public final = this.props.data.edit.value;
    public Input = this.props.data.edit.type;
    public User = this.props.data.user;

    
    public dbb= this.User.dob.split("-");
public state={
    name:this.final,
    dob:this.final,
    day:this.dbb[2],
    month:this.dbb[1],
    year:this.dbb[0],
    height:this.final,
    email:this.final,
    password:this.final,
    occupation:this.final,
    location:{city:this.final.city,state:this.final.state,zip:this.final.zip},
    lat:this.final,
    lang:this.final,
    lookingminmax:{min:this.final.min,max:this.final.max},
    questions:[this.final],
    questiongroup:[this.final.id!==undefined ? this.final.number : ''],
    prevNext:['','dob'],
    current_q:this.final.id!==undefined ? this.final.number : '',
    selected_per:this.final.selected_per!==undefined ? this.final.selected_per : [],
    selected_per_add:this.final.selected_per_add!==undefined ? this.final.selected_per_add : [],
    profilepic:{},
    pic:'',
    confirm:1,
    verifypic:{},
    vpic:'',
    termcondition:false,
    number:this.final,
    photos:[],
    loading:false,
    selected_plan:{},
    page:this.props.data.fresh,
    open:this.props.data.editField,
    title:'Change Subscription',
    register_res:this.User!==undefined ? this.User  : '',
      
}


componentDidMount(){   
  console.log('dsd',this.state);

}
   

    public goto = (path:any)=>{
this.setState({
bool:true,
path:path,
});
    }

public render(){


  
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius:'5px!important',
    p: 4,
  };

  const styleplan = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius:'5px!important',
    p: 2,
  };

  const stylecheckout = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius:'5px!important',
  };

  const options = {
    // passing the client secret obtained from the server
    clientSecret: '{{CLIENT_SECRET}}',
  };

    return(
      <>
    <Modal 
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={this.state.open}
        onClose={this.close}  
      
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
      
        <Fade in={this.state.open}>
          <Box className='updateModal' sx={this.Input==='plan' ? styleplan : this.Input==='checkout' ? stylecheckout :style}>
              
        {
        this.Input==='name' ? <FirstName from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)}/> : 
        this.Input==='dob' ? <Dob from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)}/> : 
        this.Input==='location'  ? <Location from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)} /> :
        this.Input==='height'  ? <Height from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)} /> :
        this.Input==='height'  ? <Height from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)} /> :
        this.Input==='number'  ? <PhoneNumber from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)} /> :
        this.Input==='occupation'  ? <Occupation from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)} /> :
        this.Input==='question'  ?<div style={{ maxHeight: '550px', overflowY: 'auto' }}> <Question from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)} /></div> :
        this.Input==='email'  ? <Email from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)} /> :
        this.Input==='plan'  ? <Plans from='edit' returnHandle={(r:any)=>this.planSet(r)} sendprop={this.state}/> :
        this.Input==='checkout' ? <Elements options={options} stripe={loadStripe(variables.stripKey)}>
     <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm />
      )}
    </ElementsConsumer>
</Elements> :
        this.Input==='looking-min-max'  ? <LookingMinMax from='edit' sendprop={this.state} returnHandle={(e:any)=>this.saveData(e)} /> :'' 
        
    }

<Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.loading}

>
  <CircularProgress color="inherit" />
</Backdrop>

          </Box>
        </Fade>

      </Modal>

      </>
    )
}


public close = ()=>{
    this.props.returnhandle({editField:false})
}

public saveData = (e:any)=>{
 
    this.setState(e);
    if(e.page!==undefined && e.page!=='' ){
      this.setState({loading:true});
      axios.post(variables.url+'updateProfile',{data:e, id:this.props.data.edit.id})
      .then((res)=>{    
          if(res.status === 200){   
            console.log(res);
            
                          this.setState({loading:false});    
                          this.props.returnhandle({editField:false});  
                          localStorage.setItem('_auth_state',JSON.stringify(res.data)); 
                        setTimeout(() => {  window.location.reload(); }, 500);
               }
      }).catch((e:any)=>{
        this.setState({loading:false});    
        console.log(e);        
      });
  }

  if(e.questions!==undefined){
    console.log('eeeeeee',e);
    this.setState({loading:true});
    axios.post(variables.url+'updateAnswer',{data:e, id:this.props.data.edit.id})
                .then((res)=>{    
                    if(res.status === 200){
                      this.setState({loading:false});    
                      this.props.returnhandle({editField:false});   
                      localStorage.setItem('_auth_state',JSON.stringify(res.data)); 
                    setTimeout(() => {  window.location.reload(); }, 500);
                         }
                }).catch((e:any)=>{
                  this.setState({loading:false});    
                  console.log(e);        
                });
}
}


public planSet = (r:any)=>{
  this.setState(r);
  this.props.returnhandle(r)
this.Input=r.page;
  
}

}

export default ProfileUpdate;

