import React from "react"

import { ButtonGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";
import ImageCropper from '../../components/imagecropper/imagecropper';

class ProfilePic extends React.Component<any>{
    public state = {
        profilepic: this.props.sendprop.profilepic,
        profilepic_error: '',
        pic: this.props.sendprop.pic,
        go: false,
        page: '',
    }

    // constructor(props:any) {
    //     super(props)
    // }


    public setProp = () => {
        console.log(this.state.profilepic);
        const type = this.state.profilepic.type != undefined ? this.state.profilepic.type.split('/') : [];
        console.log(this.state.profilepic, type[0]);

        if (this.state.profilepic.size <= 7340032 && type[0] === 'image') {
            this.props.returnHandle({ profilepic: this.state.profilepic, page: 'number' });
        } else {
            this.setState({ profilepic_error: 'Profile picture feild is required. And size should be less than 7Mb.' });
        }

    }

    public setvalues = (r: any) => {
        const file = r.file;
        const dataURL = r.dataURL;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (E: any) => {
            //this.props.returnHandle({pic:E.srcElement.result});
            this.props.returnHandle({ profilepic: file, pic: dataURL });
            this.setState({ profilepic: file, pic: dataURL });
        }


    }

    render(): React.ReactNode {

        return (
            <div className="container main" >
                {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
                <div style={{ textAlign: 'center' }} className={this.props.from === undefined ? 'd-block m_auto   col-md-5' : 'd-block m_auto  col-md-12'} >
                    <h3 className="d-block text-center" >Upload your profile picture</h3>
                    <ImageCropper pic={this.state.pic} images={[]} page="profile_pic" returnHandler={(e: any) => { this.setvalues(e) }} />


                    <span className="text-light muierror">{this.state.profilepic_error}</span>
                    <ButtonGroup style={{ width: '100%' }}>
                        <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns login-next-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.props.returnHandle({ page: 'height' })}
                            variant="contained">
                            Back
                        </LoadingButton>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns login-back-button "
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.setProp()}
                            variant="contained">
                            Next
                        </LoadingButton>

                    </ButtonGroup>

                </div>
            </div>
        );
    }


}

export default ProfilePic;