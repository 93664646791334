import axios from "axios";
import React from "react";
import { Navigate } from 'react-router-dom';
import variables from "../global/global";
import parse from 'html-react-parser'

class  StaticPages extends React.Component<any, any>{
    public state = {
        content:'',
    }
componentDidMount(): void {
    const l = window.location.pathname.split('/').length;
    const id = window.location.pathname.split('/')[l-1];
    axios.post(variables.url+'getPage',{id:id})
    .then((res)=>{    
    if(res.status === 200){     
this.setState({content:res.data.content});
            }
    }).catch((e:any)=>{  
         
    });
}


render(): React.ReactNode {

return (
    <>
    {parse(this.state.content)}
    </>
)
}

}

export default StaticPages;