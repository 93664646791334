import React from 'react';
declare const window: any;
export default class AdComponent extends React.Component {

  componentDidMount () {


  (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <ins className='adsbygoogle'
          style={{ display: 'block',background: '#d0caca', width: '100%' }}
          data-ad-client="ca-pub-6449470840279198"
            data-ad-slot="6149752321"
            data-ad-format="auto"
            data-full-width-responsive="true"/>
    );
  }
}