
import { Box, Button, Card, CardMedia, CircularProgress, CircularProgressProps, Grid, Snackbar, Typography, ButtonGroup } from '@mui/material';
import * as React from 'react';
import axios from 'axios';
import { LoadingButton } from "@mui/lab";
import variables from '../../global/global';
import { Navigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumb from '../../pages/includes/breadcrumb';
import TopHeader from '../../pages/includes/header';
import Dialogs from '../dialog/dialog';
import Base64e from '../../components/encodedecode/encode';
import ImageCropper from '../../components/imagecropper/imagecropper';
import Swal from 'sweetalert2';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import test from '../../assets/test2.png'


function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

class Images extends React.Component<any, any> {
  public pics: any = this.props.user().rows[0].user_photos;
  public cropper: any;
  public state = {
    camera_error: false,
    verifypic: this.props.user().rows[0].vpic,
    verifypic_error: '',
    vpic: { type: '', size: 10000000 },
    change_vpic: false,
    progress: 0,
    dialog: false,
    confirm: false,
    cropImage: '',
    deleteId: '',
    id: this.props.user().rows[0].id,
    pagetitle: 'Add Images',
    pic: '',
    file: null,
    photos: this.pics,
    redirect: {
      path: '',
      bool: false,
    },
    edit: false,
    snack_open: false,
    snackmsg: '',
    bool: false,
    path: '',
    breadcrumb: [
      {
        link: '/profile',
        link_name: 'Profile'
      },
      {
        link: '',
        link_name: 'Images'
      }]
  }

  //     constructor(props:any){
  // super(props);

  //     }

  componentDidMount() {
    const id = Base64e.encode(this.props.user().rows[0].id + "");

    axios.post(variables.url + 'getSingleUser', {
      id: id,
      Rid: '',
      lat: this.props.user().rows[0].lat,
      lang: this.props.user().rows[0].lang,
      uId: ''
    }).then((res) => {
      if (res.status === 200 && res.data !== '') {
        const row = res.data.rows.rows[0];

        this.setState({
          pagetitle: 'Edit Images',
          id: this.props.user().rows[0].id,
          pic: '',
          file: null,
          photos: row.user_photos
        }, () => {
          console.log(row.user_photos);
          this.addProfilepic();

        });
      }
    });







  }

  public addProfilepic = async () => {
    console.log('concat', this.state.photos);
    var a = [{
      id: 0,
      picture: this.props.user().rows[0].pic,
      type: 'image',
      user_id: '0',
      createdAt: ''
    }];


    await this.setState({ photos: a.concat(this.state.photos) });
    console.log('concat', this.state.photos);
  }


  public imageHandle = (e: any) => {
    console.log(e);

    this.Sentoserver(e);
    this.setState({
      loading: true,
      file: e,
    });


  }


  public setProp = () => {

    if (this.state.vpic.type !== undefined && this.state.vpic.type !== '') {
      const type = this.state.vpic.type.split('/');

      if (this.state.vpic.size <= 7340032 && type[0] === 'image') {
        this.uploadpic();
      } else {
        this.setState({ verifypic_error: 'Take a picture holding up a two finger peace sign to verify your account.' });
      }
    } else {
      this.setState({ verifypic_error: 'Take a picture holding up a two finger peace sign to verify your account.' });
    }

  }
  public uploadpic = () => {
    axios.post(variables.url + 'uploadVerificationPic', this.state, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {

        if (res.status === 200) {
          this.setState(
            {
              loading: false,
              snack_open: true,
              snackmsg: 'Successfully Saved',
            }

          );

          localStorage.setItem('_auth_state', JSON.stringify(res.data));
          setTimeout(() => { window.location.reload(); }, 2000);

        } else {
          alert('something goes wrong!');
        }
      });

  }

  public setvalues = (r: any) => {

    fetch(r)
      .then(res => res.blob())
      .then(blob => {
        this.setState({ verifypic: r, vpic: blob });
        console.log();

      })


  }

  public onCameraStart() {
    this.setState({ loading: false });
  }

  public handleCameraError(error: any) {
    console.log('handleCameraError', error);
    this.setState({ loading: false, camera_error: true });
  }


  public openCamera() {

    this.setState({ change_vpic: true });

  }
  public closeCamera() {

    this.setState({ change_vpic: false });
    this.setState({ verifypic: this.props.user().rows[0].vpic });

  }
  // public beforeUpload(file) {
  //   const cropper = this.cropper;
  //   console.log('>> cropper', this.cropper);
  //   return cropper.selectImage(file).then(image => {
  //     console.log('>> selecTImage', image);
  //     return image;
  //   });
  // }

  render(): React.ReactNode {


    return (
      <>
        <div className='App' >
          {this.state.bool === true ? <Navigate state={this.state} to={"/" + this.state.path} replace={true} /> : ''}

          <TopHeader title="&nbsp;" logo={''} />
          <Breadcrumb breadcrumb={this.state.breadcrumb} />


          <div className='container-fluid'>
            <Grid container style={{ display: 'flex', margin: 'auto', marginBottom: '70px' }} className="allimggrid">

              <Grid md={2} xs={12} color={'purple'} style={{ borderRadius: '4px', margin: 'auto 0px' }}>
                <h5>Add New Photo</h5>
                {this.state.progress <= 0 || this.state.progress >= 100 ?
                  //  <Button  variant="outlined" color='secondary' component="label">
                  //     <img width={'100%'} src={'/images/addpiclogo.gif'}  alt="img" />
                  //     <input type="file" onChange={(e)=>this.imageHandle(e)}  hidden  />
                  // </Button>
                  <ImageCropper images={this.state.photos} page="user_photos" returnHandler={(e: any) => { this.imageHandle(e) }} /> : <CircularProgressWithLabel value={this.state.progress} />}
              </Grid>



              {this.state.photos.map((pi: any) => (
                <Grid className='fl-23' md={3} xs={12} key={pi.picture} color={'purple'} style={{ margin: '2px', position: 'relative' }}  >
                  <Card >
                    <CardMedia
                      component="img"

                      image={pi.picture != '' ? pi.picture : variables.BaseUrl + 'thumbnails/nopic.jpg'}
                      alt="green iguana"
                    />
                    {pi.id == 0 ?
                      <>
                        <Button className='small-btn' style={{ position: 'absolute', bottom: '1px', left: '1px', fontSize: '0.6rem' }} size='small' color='success' variant='contained'  >MAIN PROFILE PICTURE</Button>
                        {this.state.photos.length >= 2 ?
                          <Button className='small-btn' style={{ position: 'absolute', bottom: '1px', right: '1px', fontSize: '0.6rem' }} size='small' color='secondary' variant='contained' onClick={() => this.DeleteAction(pi.id, this.props.user().rows[0].id)}><DeleteIcon style={{ fontSize: '1rem' }} /> Delete</Button>
                          : ''}
                      </>
                      :
                      <>
                        <Button className='small-btn' style={{ position: 'absolute', bottom: '1px', left: '1px', fontSize: '0.6rem' }} size='small' color='secondary' variant='contained' onClick={() => this.setMainProfile(pi.id)}>Set As Main Profile Photo</Button>
                        <Button className='small-btn' style={{ position: 'absolute', bottom: '1px', right: '1px', fontSize: '0.6rem' }} size='small' color='secondary' variant='contained' onClick={() => this.DeleteAction(pi.id, null)}><DeleteIcon style={{ fontSize: '1rem' }} /> Delete</Button>
                      </>
                    }

                  </Card></Grid>))}
            </Grid>
          </div>









          {/* <Grid container style={{ display: 'flex', margin: 'auto', marginBottom: '70px' }} className="allimggrid">


            {this.state.change_vpic == false ?
              <>
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-3 ml-1'>
                    <h4 >Verification Image</h4>
                    <Card >
                      <CardMedia
                        component="img"
                        image={this.state.verifypic !== null ?  this.state.verifypic : test}
                        alt="green iguana"
                      />
                    </Card>
                    <ButtonGroup style={{ width: '100%' }}>
                      <LoadingButton
                        style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                        type="submit"
                        size="large"
                        disabled={false}
                        className="btns purple-btn change"
                        loading={false}
                        loadingPosition="center"
                        onClick={() => this.openCamera()}
                        variant="contained">
                        Change
                      </LoadingButton>
                    </ButtonGroup>
                  </div>
                </div>
                </div>
              </>
              :
              <div className="d-block m_auto col-md-5" >
                <h3 className="d-block text-center" >Please upload a photo holding two fingers up to verify your account</h3>
                <Camera
                  onCameraStart={() => { this.onCameraStart(); }}
                  onTakePhoto={(dataUri) => { this.setvalues(dataUri); }}
                  onCameraError={(error) => { this.handleCameraError(error); }}
                />

                <span className="text-danger">{this.state.verifypic_error}</span>
                {(this.state.vpic.type !== '') ?
                  <label><img alt="img" className="profilepic4" src={this.state.verifypic} style={{ display: this.state.verifypic !== '' ? 'block' : 'none' }} /></label>
                  : ''}
                <ButtonGroup style={{ width: '100%' }}>
                  <LoadingButton
                    style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                    type="submit"
                    size="large"
                    disabled={false}
                    className="btns purple-btn"
                    loading={false}
                    loadingPosition="center"
                    onClick={() => this.setProp()}
                    variant="contained">
                    Update
                  </LoadingButton>
                  <LoadingButton
                    style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                    color='error'
                    type="submit"
                    size="large"
                    disabled={false}
                    className="btns red-btn"
                    loading={false}
                    loadingPosition="center"
                    onClick={() => this.closeCamera()}
                    variant="contained">
                    Cancel
                  </LoadingButton>
                </ButtonGroup>
              </div>
            }

          </Grid> */}

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.snack_open}
            autoHideDuration={1}
            message={this.state.snackmsg}
            key={'snackbar'}
          />


        </div>
      </>
    )
  }




  public Sentoserver = async (file: any) => {
    console.log('file', file);
    await axios.post(variables.url + 'user_Addimage', { file: file.file, id: this.state.id }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        this.setState({ progress: percentCompleted });
      }
    })
      .then((res) => {
        if (res.status === 200) {
          this.setState(
            {
              loading: false,
              snack_open: true,
              snackmsg: 'Successfully Saved',
              photos: res.data.rows[0].user_photos
            }

          );
          console.log(res.data.rows[0].user_photos);

          //  this.state.photos.push(res.data.rows[0].user_photos);
          localStorage.setItem('_auth_state', JSON.stringify(res.data));
          setTimeout(() => { window.location.reload(); }, 2000);
          // setTimeout(() => {
          //     this.setState({edit:false,redirect:{path:'users',bool:true}}); 
          // }, 1000);
        } else {
          alert('something goes wrong!');
        }
      })
  }

  public DeleteAction = (id: any, user_id: any) => {

    Swal.fire({
      title: 'Are you sure you want to delete this photo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const picId = id;
        axios.post(variables.url + 'user_deleteImage', { id: picId, user_id: user_id })
          .then((res) => {
            if (res.status === 200) {
              if (user_id !== null) {
                localStorage.setItem('_auth_state', JSON.stringify(res.data));
              }


              if (user_id !== null) {
                setTimeout(() => { window.location.reload(); }, 1000);
              } else {
                setTimeout(() => {
                  setTimeout(() => { window.location.reload(); }, 1000);
                  this.setState({ edit: false, redirect: { path: '/images', bool: true } });

                  this.setState((re: any) => {
                    re.photos.map((v: any, index: number) => {
                      if (v.id === picId) {
                        re.photos.splice(index, 1);
                      }
                    });
                    return re;
                  });
                }, 500);
              }








            } else {
              alert('something goes wrong');
            }
          });





      }
    })


  }




  public setMainProfile = (id: any) => {


    axios.post(variables.url + 'setMainProfile', { id: id })
      .then((res) => {
        if (res.status === 200) {
          this.setState(
            {
              loading: false,
              snack_open: true,
              snackmsg: 'Successfully Saved',
              photos: res.data.rows[0].user_photos
            }

          );
          console.log(res.data.rows[0]);
          localStorage.setItem('_auth_state', JSON.stringify(res.data));
          setTimeout(() => { window.location.reload(); }, 2000);

        } else {
          alert('something goes wrong');
        }
      });


  }




}
export default Images;