import * as React from 'react';
import { useState, useEffect } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
  Backdrop, Button, Grid, Typography, Link, Slide,
  AppBar, Toolbar, IconButton, Divider
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import variables from '../../global/global';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';
import Swal from 'sweetalert2';
import CreateIcon from '@mui/icons-material/Create';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function Genres(dat: any) {
  let selected: any = [];
  let selected_ids: any = [];
  const navigate = useNavigate();
  const [smallScreen, setsmallScreen] = useState(false);
  const [editgenres, setEditgenres] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [max, setMax] = React.useState({ val: 18, indicator: '' });
  const [load, setload] = React.useState(false);
  const [genres, setGenres]: any = React.useState([]);
  const [addgenres, setAddgenres]: any = React.useState('');


  const handleClickOpen = () => {
    console.log('dsfs');

    setOpen(true);
    setMax({ val: 99, indicator: 'rotate(180deg)' })
  };

  const handleClose = () => {
    console.log('dfs');
    setOpen(false);
    if (window.innerWidth <= 820) {
      setMax({ val: 5, indicator: '' });
    } else {
      setMax({ val: 18, indicator: '' })
    }
  };

  const handleResize = () => {
    console.log('width', window.innerWidth);
    if (window.innerWidth <= 820) {
      setsmallScreen(true);
      setMax({ val: 5, indicator: '' });
    } else {
      setsmallScreen(false);

    }
  }

  React.useEffect(() => {
    axios.get(variables.url + 'getGenres').then((response) => {
      setGenres(response.data.rows);
      console.log(dat.data.user);
      let data = (dat.data.user.genres !== null && dat.data.user.genres !== undefined && dat.data.user.genres !== '') ? dat.data.user.genres.split(',') : [];
      setAddgenres(data.map(Number));
    });

    window.addEventListener("resize", handleResize)
    handleResize()
  }, []);


  const addOrRemove = (event: any, name: any) => {

    selected_ids = addgenres;


    const index = selected_ids.indexOf(name);

    console.log(selected_ids)
    if (index === -1) {
      selected_ids.push(name);
    } else {
      selected_ids.splice(index, 1);
    }

    setAddgenres(selected_ids);
    console.log(selected_ids)
  }


  const setva = () => {
    setload(true);
    // let ids:any = [];
    // e.map((v:any)=>{
    //     ids.push(v.id);
    //     return v;
    // });
    axios.post(variables.url + 'updateGenres', { id: dat.data.user.id, genres: addgenres })
      .then((res) => {
        if (res.status === 200) {
          setload(false);
          setOpen(false);
          localStorage.setItem('_auth_state', JSON.stringify(res.data));
          window.location.reload();
          // Swal.fire({  
          //   icon: "success",
          //   title: 'Successfully Updated!',  
          //   confirmButtonColor: '#7b1fa2',  
          // }).then(()=>{

          //   window.location.reload();
          // });

        }
      }).catch((e: any) => {
        setload(false);
        console.log(e);
      });
  }

  const selectedData = () => {
    if (dat.data.user.genres !== null) {
      let data = dat.data.user.genres.split(',');


      genres.map((e: any) => {
        data.map((v: any, index: any) => {
          if (parseInt(v) === e.id && index <= max.val) {

            selected.push({ id: e.id, name: e.name })
            selected_ids.push(e.id)
          }
          return v;
        });
        return e;
      })
      console.log(selected);
    }
  }

  selectedData();
  return (
    <Grid item xs={12} md={12} style={{ textAlign: 'left', position: 'relative' }}>
      <Typography className='m-1 gen-tt' variant='h4'>Genres that Apply to & interest you ...
        {!editgenres && dat.edit === true ? <Button onClick={() => handleClickOpen()} title='Edit' className='w_40' color='secondary' size='small' variant='text'><CreateIcon />{(dat.data.user.genres !== null && dat.data.user.genres !== undefined && dat.data.user.genres !== '') ? '' : <sup>+12%</sup>}</Button>
          : dat.edit === true ?
            <Button onClick={() => handleClickOpen()} title='Save' className='w_40' color='secondary' size='small' variant='text'><CreateIcon />{(dat.data.user.genres !== null && dat.data.user.genres !== undefined && dat.data.user.genres !== '') ? '' : <sup>+12%</sup>}</Button>
            : ''}
        &nbsp;  &nbsp;
        {/* <Link
  component="button"
  variant="body2"
  onClick={()=>navigate("/Genres")}
>
   See Genres
</Link> */}
      </Typography>
      <Autocomplete

        onOpen={() => {
          setMax({ val: 99, indicator: 'rotate(180deg)' })
        }}
        onClose={handleClose}
        sx={{
          width: "100%",
          "& .MuiAutocomplete-popupIndicator": { transform: max.indicator },
        }}
        multiple
        id="size-small-standard-multi"
        size='medium'
        readOnly={true}
        options={genres}
        getOptionLabel={(option) => option.name}
        onChange={(e: any, detail: any) => setva()}
        value={selected}
        defaultValue={selected}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label=""
            placeholder={editgenres ? 'Type...' : ''}
          />
        )}
      />

      <Dialog
        fullScreen
        hideBackdrop={true}
        disableAutoFocus
        open={open}
        onClose={handleClose}
        transitionDuration={200}
        TransitionComponent={Transition}
        PaperProps={{ style: { backgroundColor: 'rgb(255 175 13)' } }}
      >
        <AppBar sx={{ position: 'fixed', top: '0px', backgroundColor: '#7b1fa2' }}>
          <Toolbar>
            <IconButton
              className='back'
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Genres - Choose as many as you would like
            </Typography>
            <Button autoFocus color="inherit" onClick={setva}>
              Save
            </Button>
          </Toolbar>
        </AppBar>






        {genres !== '' ?



          <>

            <Grid container rowSpacing={2} direction="row"
              justifyContent="flex-start"
              alignItems="center" className="p-md-5 genres-container">
              {genres !== '' ? genres.map((value: any) => (
                <>
                  {value.title == 'Normal' &&
                    <Grid xs={6} md={3}>
                      <label> <input type="checkbox" name="generes" onClick={(e) => addOrRemove(e, value.id)} defaultChecked={selected_ids.includes(value.id)} />  {value.name}</label>
                    </Grid>
                  }
                </>
              )) : ''}
            </Grid>



            {/* <Grid container  rowSpacing={2}  direction="row"
              justifyContent="flex-start"
              alignItems="center" className="p-5">
                
                 <Grid  xs={12}><Typography variant="h4" component="h5">
                 ETHNITICITY </Typography><Divider className='m-3'/></Grid>
              {genres !== '' ?    genres.map((value:any) => (
                  <>
                  { value.title == 'ETHNITICITY' &&
                <Grid  xs={6} md={3}>
                <label> <input type="checkbox" name="generes"  onClick={(e) => addOrRemove(e, value.id)} defaultChecked={selected_ids.includes(value.id)}/>  {value.name}</label>
                </Grid>
                  }
                </>
                )) : '' }
              </Grid>   

       
         <Grid container  rowSpacing={2}  direction="row"
         justifyContent="flex-start"
         alignItems="center" className="p-5">
          
          <Grid  xs={12}><Typography variant="h4" component="h5">
          RELIGIONS </Typography><Divider className='m-3'/></Grid>
         {genres !== '' ?    genres.map((value:any
         ) => (
             <>
             { value.title == 'RELIGIONS' &&
           <Grid  xs={6} md={3}>
           <label> <input type="checkbox" name="generes"  onClick={(e) => addOrRemove(e, value.id)} defaultChecked={selected_ids.includes(value.id)}/>  {value.name}</label>
           </Grid>
             }
           </>
           )) : '' }
         </Grid> 

  
        <Grid container  rowSpacing={2}  direction="row"
         justifyContent="flex-start"
         alignItems="center" className="p-5">
            <Grid  xs={12}><Typography variant="h4" component="h5">
          BUILD </Typography><Divider className='m-3'/></Grid>
         {genres !== '' ?    genres.map((value:any) => (
             <>
             { value.title == 'BUILD' &&
           <Grid  xs={6} md={3}>
           <label> <input type="checkbox" name="generes"  onClick={(e) => addOrRemove(e, value.id)} defaultChecked={selected_ids.includes(value.id)}/>  {value.name}</label>
           </Grid>
             }
           </>
           )) : '' }
         </Grid> 
       
            <Grid container  rowSpacing={2}  direction="row"
            justifyContent="flex-start"
            alignItems="center" className="p-5">
              <Grid  xs={12}><Typography variant="h4" component="h5">
              EYES </Typography><Divider className='m-3'/></Grid>
            
            {genres !== '' ?    genres.map((value:any) => (
                <>
                { value.title == 'EYES' &&
              <Grid  xs={6} md={3}>
              <label> <input type="checkbox" name="generes"  onClick={(e) => addOrRemove(e, value.id)} defaultChecked={selected_ids.includes(value.id)}/>  {value.name}</label>
              </Grid>
                }
              </>
              )) : '' }
            </Grid> 
  
            <Grid container  rowSpacing={2}  direction="row"
            justifyContent="flex-start"
            alignItems="center" className="p-5">
            
               <Grid  xs={12}><Typography variant="h4" component="h5">
               HAIR </Typography><Divider className='m-3'/></Grid>
            {genres !== '' ?    genres.map((value:any) => (
                <>
                { value.title == 'HAIR' &&
              <Grid  xs={6} md={3}>
              <label> <input type="checkbox" name="generes"  onClick={(e) => addOrRemove(e, value.id)} defaultChecked={selected_ids.includes(value.id)}/>  {value.name}</label>
              </Grid>
                }
              </>
              )) : '' }
            </Grid>  */}
          </>
          : ''}


      </Dialog>




      {/* <Backdrop  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 ,position:'absolute'}}  open={load}>
     <CircularProgress color="inherit" />
</Backdrop> */}
    </Grid>
  );
}



