import { Button, Grid, Divider, Breadcrumbs, Link, Typography, Chip } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import variables from '../../global/global';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { emphasize, styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuthUser } from 'react-auth-kit';
import profileicon from '../../assets/img/profile-2.png';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Base64e from '../../components/encodedecode/encode';
import axios from 'axios';



const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const StyledBreadcrumb = styled(Chip)(({ theme }) => {

  const backgroundColor =
    theme.palette.mode === 'light'
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;
const Breadcrumb = (props: any) => {
  const [valuex, setValue] = useState('');
  const navigate = useNavigate();
  const user: any = useAuthUser();
  const [profileCompleted, setprofileCompleted] = React.useState(0);
  console.log(props.breadcrumb)

  const path = window.location.pathname;
  const page = path.split('/');

  const backdromChat = (e:any)=>{
    
    if(props.chatPage!=undefined ){
      if(e=='/ChatList'){
        window.location.href = e;
      
       // navigate(e);
      }else{
        props.returnHandler(false);
      }
    
    }

    if(props.chatPage==undefined || props.chatPage==false){
      console.log('page',e);
      
      navigate(e);
    }
    
  }

  useEffect(() => {


    const id = Base64e.encode(user().rows[0].id + "");

       axios.post(variables.url + 'getSingleUser', {
         id: id,
         Rid: '',
         lat:user().rows[0].lat,
         lang:user().rows[0].lang,
         uId: ''
       }).then((res) => {
         if (res.status === 200 && res.data !== '') {
           const row = res.data.rows.rows[0];
   
             console.log(row.user_photos);


             let profileCompleted=0;
                if(row.first_name !== ''){
                  profileCompleted= profileCompleted+2;
              }
              if(row.height !== ''){
                  profileCompleted= profileCompleted+2;
              }
              if(row.dob !== ''){
                  profileCompleted= profileCompleted+2;
              }
            
              if(row.city !== '' && row.state !== '' && row.zip !== ''){
                  profileCompleted= profileCompleted+2;
              }
              if(row.looking_min_age !== '' && row.looking_max_age !== ''){
                  profileCompleted= profileCompleted+2;
              }
            
              if(row.occupation !== ''){
                  profileCompleted= profileCompleted+2;
              }
              if(row.about !== null && row.about !== ''){
                  profileCompleted= profileCompleted+22;
              }
              if(row.genres !== null && row.genres !== undefined && row.genres !== ''){
                  profileCompleted= profileCompleted+12;
              }
              // if(user().rows[0].genres_partner !== null && user().rows[0].genres_partner !== undefined && user().rows[0].genres_partner !== ''){
              //     profileCompleted= profileCompleted+6;
              // }
          console.log(row);
          var t=0;
          row.user_answers.map(function(val:any,i:any){
            
                if(val.answer_id !== ''){
                  profileCompleted= profileCompleted+2-0.55;
                }
                  })
                  profileCompleted=  Math.floor(profileCompleted);
              if(row.user_photos.length >= 3 ){
                  profileCompleted= profileCompleted+28;
              }else if(row.user_photos.length ==2 ){
                  profileCompleted= profileCompleted+21;
              }else if(row.user_photos.length ==1 ){ 
                  profileCompleted= profileCompleted+14;   
                }else if(row.user_photos.length ==0 ){
                  profileCompleted= profileCompleted+7;    
                  
              }

              setprofileCompleted(profileCompleted)
                    
          
                  
                }
       });



  }, []);


  return (
    <>
      <Grid container spacing={{ xs: 0, md: 0 }} className="pb-1 pt-4 justify-content-center breadcrumb">
        {page[1] == 'profile' || page[1] == 'Profile' || page[1] == 'matches' || page[1] == 'MatchesProfile' ?
          <Grid item xs={12} md={10} >
            <div className="d-flex align-items-center m-2 vv-sm">
              {page[1] == 'profile' || page[1] == 'Profile' || page[1] == 'matches' || page[1] == 'MatchesProfile' ?
                <div className='helpdash helpdash-sm'>
                  <HtmlTooltip
                    arrow
                    enterTouchDelay={0}
                    leaveTouchDelay={90000}
                    placement="bottom"
                    title={
                      <React.Fragment>
                        Welcome to our dating site! Here’s how to use our Love Pitch feature:
                        <ul>
                          <li>Select Interested  if you’re interested in the person based on their photo, age, and location.</li>
                          <li>Slide the bar to indicate your level of interest.  Level of interest ranges from “heading to first base” all the way to “home run”</li>
                          <li>Type a message and send your pitch to the person you’re interested in. Keep it friendly and respectful!</li>
                          <li>If the other person is interested in you too, they will send a hit back and you will match. A message thread will be started in Messages</li>
                          <li>If you’re not interested in the person, simply Strikeout and move on to the next potential match.</li>
                        </ul>
                        Remember to be safe and respectful when using our site. Good luck!

                      </React.Fragment>
                    }
                  >
                    <span>Help &nbsp;<HelpIcon /> </span>
                  </HtmlTooltip>
                </div>
                : ''}
              {page[1] == 'profile' || page[1] == 'Profile' || page[1] == 'matches' || page[1] == 'MatchesProfile' || page[1] == 'images' ?
                <Button className={'back-btn back-btn-sm  v1'}
                  onClick={() => navigate(-1)} size="small" variant="contained" color="primary" startIcon={<ArrowBackIcon />}>
                  Back
                </Button>
                : ''}
            </div>
          </Grid>
          : ''}


        <Grid item xs={page[1] == 'MatchesProfile' ? 10 : 6} md={10} >
          <Breadcrumbs maxItems={3} aria-label="breadcrumb">
            <StyledBreadcrumb
              component="a"
              href="#"
              onClick={() => navigate('/')}
              label="Home"
              className="homebread"
              icon={<HomeIcon fontSize="small" />}
            />

            {props !== undefined ?

              props.breadcrumb.map((value: any) => (
                value.link !== '' ?
                  <Link underline="hover" color="inherit" href="#" onClick={() => navigate(value.link)}>
                    {value.link_name}
                  </Link>
                  : <Typography color="text.primary">{value.link_name}</Typography>

              )) : ''}


          </Breadcrumbs>
        </Grid>





        <Grid item xs={page[1] == 'MatchesProfile' ? 1 : 4} md={1} className="back-div pl-xs-0 lg-mr-0" >
          {props !== undefined ?

            props.breadcrumb.map((value: any, index: any) => (
              index == 0 ?
                value.link !== '' ?
                  <Button className={page[1] == 'ChatList' || page[1] == 'images' || page[1] == 'activepitches' ? 'back-btn ve' : 'back-btn back-btn-md v2'}

                    onClick={() => backdromChat(value.link)} size="small" variant="contained" color="primary" startIcon={<ArrowBackIcon />}>
                    Back
                  </Button>
                  : <Button className={page[1] == 'ChatList' || page[1] == 'images' || page[1] == 'activepitches' ? 'back-btn ' : 'back-btn back-btn-md v3'}

                    onClick={() => backdromChat(-1)} size="small" variant="contained" color="primary" startIcon={<ArrowBackIcon />}>
                    Back
                  </Button>
                : ''

            )) :
            <Button className={page[1] == 'images' ? 'back-btn ' : 'back-btn back-btn-md v4'}

              onClick={() => navigate(-1)} size="small" variant="contained" color="primary" startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          }
          {page[1] == 'profile' || page[1] == 'Profile' ?
            <div className='profile-question mt-1' onClick={() => navigate('/profile')} >
              <img src={profileicon} />
              <span className="text-danger">{profileCompleted}% </span>   Compeleted
            </div>
            : ''}
        </Grid>
      </Grid>
      {/* <Grid container spacing={{ xs: 2, md: 0 }}>
  <Grid item xs={11} className="text-right py-2">
  <Button className={
    page[1] == 'Notifications' || 
    page[1] == 'ChatList' || 
    page[1]== 'activepitches' || 
    page[1]== 'Blog' || 
    page[1]== 'post' || 
    page[1]== 'settings' || 
    page[1]== 'subscription' ||
    page[1]== 'support' ||
    page[1]== 'Aboutus' ||
    page[1]== 'Missionstatement' ||
    page[1]=='addinterest' ? "pruple-btn back-btn" : 'pruple-btn'} 
  
  onClick={() => navigate(-1)} size="small" variant="contained" color="primary" startIcon={<ArrowBackIcon />}>
  Back
</Button>
</Grid>
</Grid> */}
    </>
  );




}

export default Breadcrumb;