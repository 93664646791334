import React from "react"
import { ButtonGroup, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";


class Height extends React.Component<any>{
    public state = {
        height: this.props.sendprop.height,
        height_error: '',
        go: false,
        page: '',
    }

    // constructor(props:any) {
    //     super(props)
    // }


    public setProp = () => {
        if (this.state.height.length > 2 && this.state.height.trim().length > 2) {
            this.props.returnHandle({ height: this.state.height.trim(), page: 'profilepic' });

        } else {
            this.setState({ height_error: 'Height feild is required.' });
        }

    }


    public setvalues = (r: any) => {
        this.props.returnHandle({ height: r.target.value });
        this.setState({ height: r.target.value });
    }

    render(): React.ReactNode {

        const heightSet = ['4’ 1”', '4’ 2”', '4’ 3”', '4’ 4”', '4’ 5”', '4’ 6”', '4’ 7”', '4’ 8”',
            '4’ 9”', '4’ 10”', '4’ 11”', '5’ 0”', '5’ 1”', '5’ 2”', '5’ 3”', '5’ 4”', '5’ 5”', '5’ 6”', '5’ 7”', '5’ 8”', '5’ 9”', '5’ 10”',
            '5’ 11”', '6’ 0”', '6’ 1”', '6’ 2”', '6’ 3”', '6’ 4”', '6’ 5”', '6’ 6”', '6’ 7”', '6’ 8”', '6’ 9”', '6’ 10”', '6’ 11”', '7’ 0”'];
        let i = 10;
        return (
            <div className="container main py-2" >
                {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
                <div className={this.props.from === undefined ? 'd-block m_auto  col-md-5' : 'd-block m_auto  col-md-12'} >
                    {this.props.from !== undefined && this.props.from !== null ? '' : <h3 className="d-block text-center" >What is your height?</h3>}
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select your height</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={this.props.sendprop.height}
                            label="Select your height"
                            error={this.state.height_error !== '' ? true : false}
                            onChange={(e) => this.setvalues(e)}
                        >
                            {heightSet.map((val: any) => {
                                // Declare the variable inside the loop
                                i++;
                                return (
                                    <MenuItem style={{ fontSize: '17px'}} key={val} value={val}>{val}</MenuItem>
                                );
                            })}
                        </Select>
                        <span className="text-light muierror">{this.state.height_error}</span>
                    </FormControl>

                    <ButtonGroup style={{ width: '100%' }}>
                        {this.props.from === undefined ? <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns login-next-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.props.returnHandle({ page: 'name' })}
                            variant="contained">
                            Back
                        </LoadingButton> : ''}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            disabled={false}
                            className="btns  login-back-button"
                            loading={false}
                            loadingPosition="center"
                            onClick={() => this.setProp()}
                            variant="contained">
                            {this.props.from === undefined ? 'Next' : 'Save'}
                        </LoadingButton>

                    </ButtonGroup>

                </div>
            </div>
        );
    }


}

export default Height;