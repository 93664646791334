import { useAcceptJs } from 'react-acceptjs';
import { ButtonGroup, Dialog, Backdrop, CircularProgress } from '@mui/material';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import DoneIcon from '@mui/icons-material/Done';
import successImg from '../../assets/img/load.gif';
import { Navigate } from 'react-router-dom';
import variables from '../../global/global';
import React from 'react';
const authData = {
  apiLoginID: '538PmNJ6dFAU',
  clientKey: '4ftQ4aN9nz3nB6TNtxg63W8XkF53BHm9K3r5XSekruu9DKSGC6367a73N8Ew4HfU',

  // apiLoginID: '7v6VyH2J',
  // clientKey: '7dsdk6j495Ty2MuFRFjh4UDeKzg7Sp4YLuKNMmCH36Jky4pbCfpFbZLQn94Q5kyW',
};
interface BasicCardInfo {
  cardNumber: string;
  cardCode: string;
  month: string;
  year: string;
  name: string;
  addressline1: string;
  addressline2: string;
  city: string;
  state: string;
  zipcode: string;
}
//PRODUCTION //SANDBOX
const PaymentForm = (props: any) => {
  const { dispatchData, loading, error } = useAcceptJs({ environment: 'PRODUCTION', authData });
  const [cardData, setCardData] = React.useState<BasicCardInfo>({
    cardNumber: '',
    month: '',
    year: '',
    cardCode: '',
    name: '',
    addressline1: '',
    addressline2: '',
    city: '',
    state: '',
    zipcode: '',

  });
  const [load, setload] = React.useState(false)
  const [success, setsuccess] = React.useState(false);
  const [name_error, setNameError] = React.useState('');
  const [addressline1_error, setAdressline1Error] = React.useState('');
  const [state_error, setStateError] = React.useState('');
  const [city_error, setCityError] = React.useState('');
  const [zipcode_error, setZipcodeError] = React.useState('');
  const [card_error, setCardError] = React.useState('');
  const [month_error, setMonthError] = React.useState('');
  const [year_error, setYearError] = React.useState('');
  const [cvc_error, setCVCError] = React.useState('');
  const [payerror, setpayerror] = React.useState('');


  const stateAbbreviations = [
    { short: "AL", full: "Alabama" },
    { short: "AK", full: "Alaska" },
    { short: "AZ", full: "Arizona" },
    { short: "AR", full: "Arkansas" },
    { short: "CA", full: "California" },
    { short: "CO", full: "Colorado" },
    { short: "CT", full: "Connecticut" },
    { short: "DE", full: "Delaware" },
    { short: "FL", full: "Florida" },
    { short: "GA", full: "Georgia" },
    { short: "HI", full: "Hawaii" },
    { short: "ID", full: "Idaho" },
    { short: "IL", full: "Illinois" },
    { short: "IN", full: "Indiana" },
    { short: "IA", full: "Iowa" },
    { short: "KS", full: "Kansas" },
    { short: "KY", full: "Kentucky" },
    { short: "LA", full: "Louisiana" },
    { short: "ME", full: "Maine" },
    { short: "MD", full: "Maryland" },
    { short: "MA", full: "Massachusetts" },
    { short: "MI", full: "Michigan" },
    { short: "MN", full: "Minnesota" },
    { short: "MS", full: "Mississippi" },
    { short: "MO", full: "Missouri" },
    { short: "MT", full: "Montana" },
    { short: "NE", full: "Nebraska" },
    { short: "NV", full: "Nevada" },
    { short: "NH", full: "New Hampshire" },
    { short: "NJ", full: "New Jersey" },
    { short: "NM", full: "New Mexico" },
    { short: "NY", full: "New York" },
    { short: "NC", full: "North Carolina" },
    { short: "ND", full: "North Dakota" },
    { short: "OH", full: "Ohio" },
    { short: "OK", full: "Oklahoma" },
    { short: "OR", full: "Oregon" },
    { short: "PA", full: "Pennsylvania" },
    { short: "RI", full: "Rhode Island" },
    { short: "SC", full: "South Carolina" },
    { short: "SD", full: "South Dakota" },
    { short: "TN", full: "Tennessee" },
    { short: "TX", full: "Texas" },
    { short: "UT", full: "Utah" },
    { short: "VT", full: "Vermont" },
    { short: "VA", full: "Virginia" },
    { short: "WA", full: "Washington" },
    { short: "WV", full: "West Virginia" },
    { short: "WI", full: "Wisconsin" },
    { short: "WY", full: "Wyoming" }
  ];

  const validatefields = (e: any) => {
    e.preventDefault();
    const isValid: any = [];
    if (cardData.name == '') {
      setNameError('This field is required!')
      console.log(cardData.name);
      isValid.push(1);
    } else {
      setNameError('')
    }
    if (cardData.addressline1 == '') {


      setAdressline1Error('This field is required')
      isValid.push(1);
    } else {
      setAdressline1Error('')
    }

    if (cardData.city == '') {
      setCityError('This field is required')
      isValid.push(1);
    } else {
      setCityError('')
    }
    if (cardData.state == '') {
      setStateError('This field is required')
      isValid.push(1);
    } else {
      setStateError('')
    }
    if (cardData.zipcode == '') {
      setZipcodeError('This field is required')
      isValid.push(1);
    } else {
      setZipcodeError('')
    }
    if (cardData.cardNumber == '') {
      setCardError('This field is required')
      isValid.push(1);
    } else {
      setCardError('')
    }
    if (cardData.month == '') {
      setMonthError('This field is required')
      isValid.push(1);
    } else {
      setMonthError('')
    }
    if (cardData.year == '') {
      setYearError('This field is required')
      isValid.push(1);
    } else {
      setYearError('')
    }
    if (cardData.cardCode == '') {
      setCVCError('This field is required')
      isValid.push(1);
    } else {
      setCVCError('')
    }

    if (isValid.length == 0) {
      handleSubmit(e)
    }
  }




  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setCardError('')
    setMonthError('')
    setYearError('')
    setCVCError('')
    console.log('dispatchData:');
    setload(true);
    // Dispatch CC data to Authorize.net and receive payment nonce for use on your server
    await dispatchData({ cardData }).then(async (response) => {
      console.log('Received response:', response);
      const token = response.opaqueData;
      console.log('Received response:', token);
      console.log('Received response:', props);
      if (props.from == 'signup') {
        const data = {
          name: props.sendprop.name === 'ok' ? props.sendprop.register_res.name : props.sendprop.name,
          email: props.sendprop.email === 'ok' ? props.sendprop.register_res.email : props.sendprop.email,
          phone: props.sendprop.number === 'ok' ? props.sendprop.register_res.number : props.sendprop.number,
          user_id: props.sendprop.register_res.id,
          selected_plan: props.sendprop.selected_plan,
        }




        await axios.post(variables.url + 'authrizepayment', { from: 'signup', creditCard: token, plan: data, cardData: cardData })
          .then((res: any) => {
            console.log('Received response:', res);
            if (res.status === 200 && res.data.statusCode === undefined) {

              setload(false);
              setsuccess(true);
              setTimeout(() => {

                if (props.sendprop.page_c == 'signup') {
                  //     this.autoLogin();
                  props.returnHandle();
                } else {
                  const currentUrl = window.location.href;
                  window.location.href = currentUrl.replace('?open=1', "");
                  //window.location.reload();
                }

                // this.props.returnHandle({go:true,page:'signin'});  
              }, 2000);

            } else {
              // this.setState({ load: false, success: false, payerror: res });
              setpayerror(res.data);
            }
          }).catch((e: any) => {
            console.log(e);
            setpayerror('Error -> ' + e.response.data);
            alert('Error -> ' + e.response.data);
            setload(false);
          });


      } else {


        const pm = props.sendprop.user.payments[0].cardId ?? '';
        const c = props.sendprop.user.payments[0].s_customer_id;
        const customer_name = props.sendprop.user.first_name;
        const customer_email = props.sendprop.user.email;
        const customer_phone = props.sendprop.user.number;
        const Uid = props.sendprop.user.id;
        await axios.post(variables.url + 'updateCard', {
          creditCard: token,
          cardData: cardData,
          pm: pm,
          customer_name: customer_name,
          c: c,
          Uid: Uid,
          customer_email: customer_email,
          customer_phone: customer_phone,
          selected_plan: props.sendprop.selected_plan
        })
          .then((res) => {

            if (res.status === 200) {
              setload(false);
              setsuccess(true);
              localStorage.setItem('_auth_state', JSON.stringify(res.data));

              setTimeout(() => {
                props.returnHandle();
                //localStorage.setItem("open_planpopup", 'true');
                window.location.reload();
              }, 2000);

            } else {
              setpayerror('Error -> ' + res.data);
              //  this.setState({ error: res.data.code, load: false, er: true });
            }
          }).catch((e: any) => {
            console.log(e);
            setpayerror('Error -> ' + e.response.data);
            alert('Error -> ' + e.response.data);
            setload(false);
          });


      }







    }).catch(error => {
      setload(false);
      console.log('Received error:', error);

      if (error.messages.message.length > 0) {
        error.messages.message.map(function (item: any, i: any) {
          console.log('test', item.code);
          if (item.code == 'E_WC_05') {
            console.log('test', item.text);
            setCardError(item.text)
          }
          if (item.code == 'E_WC_06') {
            setMonthError(item.text)
          }
          if (item.code == 'E_WC_07' || item.code == 'E_WC_08') {
            setYearError(item.text)
          }
          if (item.code == 'E_WC_15') {
            setCVCError(item.text)
          }

        })
      }
    });



  };

  return (
    <>
      <form id="form_payment" className='card' style={{ background: 'none' }} onSubmit={validatefields} autoComplete='off'>
        {props.from == 'signup' ?
          <> <h5>60 Day Free Trial. Please enter your card details so we can have it on file for after your free trial is completed.</h5><br /></>
          : ''}
        <div className="row">
          <div className="col-sm-12 m-auto">
            <div className="card" style={{ background: 'none' }}>

              <div className="card-body payment-form">

                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="name">Name <span className='text-danger'>*</span></label>
                      <input autoComplete="new-password" className="form-control" onChange={(event) =>
                        setCardData({ ...cardData, name: event.target.value })
                      } id="name" type="text" placeholder="Enter your name" />
                      <span className='text-danger muierror'>{name_error}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="ccnumber">Credit Card Number <span className='text-danger'>*</span></label>
                      <div className="input-group">
                        <input
                          autoComplete="one-time-code"
                          type="number"
                          name="cardNumber"

                          className="form-control"
                          placeholder="Enter card Number"
                          value={cardData.cardNumber}
                          onChange={(event) =>
                            setCardData({ ...cardData, cardNumber: event.target.value })
                          }
                        />

                      </div>
                      <span className='text-danger muierror'>{card_error}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-sm-4">
                    <label htmlFor="ccyear">Month <span className='text-danger'>*</span></label>
                    <input
                      autoComplete="one-time-code"
                      type="number"
                      name="expMonth"
                      className="form-control"
                      placeholder="Enter Month"
                      value={cardData.month}
                      onChange={(event) =>
                        setCardData({ ...cardData, month: event.target.value })
                      }
                    />
                    <span className='text-danger muierror'>{month_error}</span>
                  </div>
                  <div className="form-group col-sm-4">
                    <label htmlFor="ccyear">Year <span className='text-danger'>*</span></label>
                    <input
                      autoComplete="one-time-code"
                      type="number"
                      name="expMonth"
                      className="form-control"
                      placeholder="Enter Year"
                      value={cardData.year}
                      onChange={(event) =>
                        setCardData({ ...cardData, year: event.target.value })
                      }
                    />
                    <span className='text-danger muierror'>{year_error}</span>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="cvv">CVV/CVC <span className='text-danger'>*</span></label>
                      <input
                        autoComplete="one-time-code"
                        type="number"
                        className="form-control"
                        placeholder="Enter CVV/CVC"
                        name="cardCode"
                        value={cardData.cardCode}
                        onChange={(event) =>
                          setCardData({ ...cardData, cardCode: event.target.value })
                        }
                      />
                      <span className='text-danger muierror'>{cvc_error}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label htmlFor="name">Billing Adresss <span className='text-danger'>*</span></label>
                    <div className="row">
                      <div className="col-sm-12 mb-2">
                        <input autoComplete="new-password" className="form-control" onChange={(event) =>
                          setCardData({ ...cardData, addressline1: event.target.value })
                        } id="address_line1" type="text" placeholder="Address line 1" />
                        <span className='text-danger muierror'>{addressline1_error}</span>
                      </div>
                      <div className="col-sm-12 mb-2">
                        <input autoComplete="new-password" className="form-control" onChange={(event) =>
                          setCardData({ ...cardData, addressline2: event.target.value })} id="address_line_2" type="text" placeholder="Address line 2" />

                      </div>
                      <div className="col-sm-4 mb-2">
                        <input autoComplete="new-password" className="form-control" onChange={(event) =>
                          setCardData({ ...cardData, city: event.target.value })} id="City" type="text" placeholder="City" />
                        <span className='text-danger muierror'>{city_error}</span>
                      </div>
                      <div className="col-sm-4 mb-2">
                        <select autoComplete="new-password" className='form-control' onChange={(event) =>
                          setCardData({ ...cardData, state: event.target.value })}>
                          <option>Select State</option>
                          {stateAbbreviations.map((val: any) => (
                            <option value={val.short}>{val.full}</option>
                          ))}
                        </select>
                        <span className='text-danger muierror'>{state_error}</span>
                      </div>
                      <div className="col-sm-4 mb-2">
                        <input autoComplete="new-password" className="form-control" onChange={(event) =>
                          setCardData({ ...cardData, zipcode: event.target.value })} id="zipcode" type="number" placeholder="zip code" />
                        <span className='text-danger muierror'>{zipcode_error}</span>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


        <div className='form-group mt-3 text-center'>

          {props.from == 'signup' ?


            <ButtonGroup style={{ width: '100%' }}>
              {props.from === 'signup' ?
                <LoadingButton
                  style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                  type="button"
                  size="large"
                  disabled={false}
                  className="login-button"
                  loading={false}
                  loadingPosition="center"
                  onClick={() => props.returnHandle({ page: 'plans' })}
                  variant="contained">
                  Back
                </LoadingButton> : ''}
              &nbsp;&nbsp;&nbsp;&nbsp;
              <LoadingButton
                style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                type="submit"
                size="large"
                disabled={success}
                className="login-button"
                loading={load}
                loadingPosition="center"
                color='primary'
                variant="contained">
                {props.sendprop.selected_plan.amount !== '' && !success ? 'Save card ($' + props.sendprop.selected_plan.amount + ' DUE 60 DAYS)' : !success ? 'Add' : ''}  {success ? <DoneIcon /> : ''}

              </LoadingButton>

            </ButtonGroup>

            :
            <ButtonGroup style={{ width: '100%' }}>
              <LoadingButton
                style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                type="submit"
                size="large"
                disabled={success}
                className="login-button"
                loading={load}
                loadingPosition="center"
                color={!success ? 'primary' : 'success'}
                variant="contained">
                {!success ? 'Save' : 'Saved'}  {success ? <DoneIcon /> : ''}
              </LoadingButton>

            </ButtonGroup>
          }


          <span className="mt-2" color={'error'}>{error}{payerror}</span>
        </div>


        <Dialog open={success}>
          <img className="img success pt-3" alt='img success' src={successImg} />
          <p className="px-3">Card saved successfully. Welcome to SLAMMMM.com.</p>
        </Dialog>


      </form>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}>
        <CircularProgress color="inherit" />

      </Backdrop>
    </>

  );
};
export default PaymentForm;