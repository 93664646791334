import axios from "axios";
import TopHeader from './includes/header';
import Breadcrumb from './includes/breadcrumb';
import React from "react";
import { Navigate } from 'react-router-dom';
import variables from "../global/global";
import {Grid } from '@mui/material';
import parse from 'html-react-parser';
import Base64e from "../components/encodedecode/encode";

class  Aboutus extends React.Component<any, any>{
    public state = {
        
        go:false,
        page:'#',
        loading:false,
        content:'',
        breadcrumb:[{
            link:'',
            link_name:'About Us'
          }
        ]
        
    }
    componentDidMount(): void {

        axios.post(variables.url+'getPage',{id:Base64e.encode(9+'')})
        .then((res)=>{    
        if(res.status === 200){     
    this.setState({content:res.data.content});
                }
        }).catch((e:any)=>{  
             
        });
    }
public goto = (page:any)=>{
        
    this.setState({go:true,page:page});

}

render(): React.ReactNode {

return (

  <div className='App'>

{this.state.go===true ? <Navigate  state={this.state} to={"/"+this.state.page} replace={true}/>  : ''}

<TopHeader title="&nbsp;"  logo={''}/>
<Breadcrumb breadcrumb={this.state.breadcrumb} />
<div className="container blog">
<div className="row">
<div className="col-md-10 m-auto text-left">
{parse(this.state.content)}


 </div>
 </div>
 </div>
 </div>

 
)
}

}



export default Aboutus;