// const variables = {

//     url:process.env.NODE_ENV!=='development' ?  'https://api.slammmm.com/' : 'https://api.slammmm.com/',
//     BaseUrl: process.env.NODE_ENV!=='development' ?  'https://api.slammmm.com/' :'https://api.slammmm.com/',
//     MapApi:'AIzaSyBKaucg-64N7qeS39AAKLznjILeMi7TSSU',
//    current_app_url:process.env.NODE_ENV==='development' ?  'http://localhost:3000' : 'https://www.slammmm.com',
//    stripKey: process.env.NODE_ENV==='development' ?  'pk_test_51MNJEbI2tSAW9AOGbRxZIHGI83mAZm0kESSlwXyPsDWZ3XnStyzK7nZmZjApMhj2ph2Zr2KGUmUmrturrGWyilEe00Zuc0Yu2N' : 'pk_test_51MNJEbI2tSAW9AOGbRxZIHGI83mAZm0kESSlwXyPsDWZ3XnStyzK7nZmZjApMhj2ph2Zr2KGUmUmrturrGWyilEe00Zuc0Yu2N',
//   wsUrl: process.env.NODE_ENV==='development' ? 'wss://api.slammmm.com/' : 'wss://api.slammmm.com/',
// }

///dev sites
const variables = {

  url:process.env.NODE_ENV!=='development' ?  'https://api.slammmmmmm.com/' : 'https://api.slammmmmmm.com/',
  BaseUrl: process.env.NODE_ENV!=='development' ?  'https://api.slammmmmmm.com/' :'https://api.slammmmmmm.com/',
  MapApi:'AIzaSyBKaucg-64N7qeS39AAKLznjILeMi7TSSU',
 current_app_url:process.env.NODE_ENV==='development' ?  'http://localhost:3000' : 'https://www.slammmmmmm.com',
 stripKey: process.env.NODE_ENV==='development' ?  'pk_test_51MNJEbI2tSAW9AOGbRxZIHGI83mAZm0kESSlwXyPsDWZ3XnStyzK7nZmZjApMhj2ph2Zr2KGUmUmrturrGWyilEe00Zuc0Yu2N' : 'pk_test_51MNJEbI2tSAW9AOGbRxZIHGI83mAZm0kESSlwXyPsDWZ3XnStyzK7nZmZjApMhj2ph2Zr2KGUmUmrturrGWyilEe00Zuc0Yu2N',
wsUrl: process.env.NODE_ENV==='development' ? 'wss://api.slammmmmmm.com/' : 'wss://api.slammmmmmm.com/',
}

//http://192.168.1.12:2222/
// const variables = {

//   url: process.env.NODE_ENV === 'development' ? 'http://localhost:2222/' : 'https://api.slammmm.com/',
//   BaseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:2222/' : 'https://api.slammmm.com/',
//   MapApi: 'AIzaSyBKaucg-64N7qeS39AAKLznjILeMi7TSSU',
//   current_app_url: process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://www.slammmm.com',
//   stripKey: process.env.NODE_ENV === 'development' ? 'pk_test_51MNJEbI2tSAW9AOGbRxZIHGI83mAZm0kESSlwXyPsDWZ3XnStyzK7nZmZjApMhj2ph2Zr2KGUmUmrturrGWyilEe00Zuc0Yu2N' : 'pk_test_51MNJEbI2tSAW9AOGbRxZIHGI83mAZm0kESSlwXyPsDWZ3XnStyzK7nZmZjApMhj2ph2Zr2KGUmUmrturrGWyilEe00Zuc0Yu2N',
//   wsUrl: process.env.NODE_ENV === 'development' ? 'ws://localhost:2222/' : 'wss://api.slammmm.com/',
// }
console.log(process.env.NODE_ENV);

export default variables;


