import axios from "axios";
import React from "react";
import { Navigate } from 'react-router-dom';
import variables from "../global/global";
import parse from 'html-react-parser';
import Base64e from "../components/encodedecode/encode";

class  TermConditions extends React.Component<any, any>{
    public state = {
        content:'',
    }
componentDidMount(): void {

    axios.post(variables.url+'getPage',{id:Base64e.encode(2+'')})
    .then((res)=>{    
    if(res.status === 200){     
this.setState({content:res.data.content});
            }
    }).catch((e:any)=>{  
         
    });
}


render(): React.ReactNode {

return (
    <>
    <div className="terms-conditions">
    {parse(this.state.content)}</div>
    </>
)
}

}

export default TermConditions;