import React from "react";
import { Navigate } from 'react-router-dom';
import Base64e from "../encodedecode/encode";


class  Footer extends React.Component<any, any>{
    public state = {
        bool:false,
        path:'',
    }

public  openurl=(num:any)=>{
    
        window.open('https://www.slammmm.com/'+num);
}


render(): React.ReactNode {

return (
  
     <footer>
        <div className="container position-relative py-0 pt-0">
        {/* <hr /> */}
        <div className="row" data-aos="fade-in">
            <div className="col-md-12 text-center links-footer">
                <a href="javascript::void();" onClick={()=>this.openurl('privacy-policy')}>Privacy Policy</a> 
                <a href="javascript::void();" onClick={()=>this.openurl('terms-and-conditions')}>Terms & Conditions</a>
                <a href="javascript::void();" onClick={()=>this.openurl('genre-disclaimer')}>Genres Disclaimer
                </a>
        </div>
        </div>
        </div>
    </footer>

   
)
}

}

export default Footer;