import { createStore } from "redux";
import searchreducer from "./reducers/searchreducer";

const configureStore =(state = { search:'' }) =>{
 console.log(state);

  return createStore(searchreducer,state);

}

export default configureStore;




export  const store =()=>{ return  configureStore(); }
