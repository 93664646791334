import {  Avatar, Backdrop, Button,  ButtonGroup,  CircularProgress,  Divider, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import TopHeader from '../includes/header';
import Breadcrumb from '../includes/breadcrumb';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import variables from '../../global/global';
import { LoadingButton } from '@mui/lab';
import Base64e from '../../components/encodedecode/encode';
class BlockedUsers extends React.Component<any>{

    // constructor(props:any) {
    //     super(props)
    // }
       
    public state = {
        user:this.props.user().rows[0],
        activepic:0,
        bool:false,
      path:'',
      edit:{
        type:'',
        value:'',
        id:'',
      },
      editField:false,
      loading:false,
    //   paginations
    dialog:false,
    confirm:false,
    deleteId:'',

    id:'',
    rows:[],
    search:'',
    total:0,
    limit:10,
    totalPages:0,
    curentPage:0,
    breadcrumb:[{
      link:'/settings',
      link_name:'Settings'
    },
    {
      link:'',
      link_name:'Blocked Users'
    },
  ]
        }
    
        public getBlocked = (val:any)=>{
            const  Page =  val;
               axios.post(variables.url+'blockedUsers', {id:this.state.user.id,page:this.state.limit*Page,limit:this.state.limit,search:this.state.search})
                       .then((res)=>{
                           if(res.status === 200 && res.data.rows[0]!==null){ 
                      const row = res.data.rows.map((v:any)=>{
                                v.load=false;
                                v.disable=false;
                                return v;
                            });                  
                                        this.setState({
                                           rows:row,
                                           total:res.data.count,
                                           totalPages:Math.ceil(res.data.count/this.state.limit),
                                           curentPage:Page
                                       });

                                       

                                       this.setState({loading:false});
                                }
                       }).catch((e:any)=>{
                        console.log(e);                        
                        this.setState({loading:false});
                       });
           } 

           componentDidMount(){       
        setTimeout(() => {
            this.getBlocked(0);  
        }, 100);
        
           }

           componentWillUnmount(): void {
               this.setState({loading:true});
           }

           public unblocke = (id:any)=>{
const row = this.state.rows.map((v:any)=>{
if(v.id===id){
    v.load=true;
}else{
    v.disable=true;
}
return v
});
this.setState({rows:row});
const idx = Base64e.encode(id+'');
axios.post(variables.url+'unblockUser', {id:idx})
.then((res)=>{
    if(res.status === 200){ 
        this.getBlocked(0); 
        
        setTimeout(() => { 
                                    
          window.location.reload();
         }, 1000);
         }
}).catch((e:any)=>{
console.log(e);

});
           }

public render(){
    const pages:any = [];
    for(let i=0; i<this.state.totalPages; i++){
    pages.push(i);
    }  
    return(
        <div  className='App' >
          {this.state.bool===true ? <Navigate state={this.state} to={"/"+this.state.path} replace={true}/> : ''}

          <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.loading}
>
  <CircularProgress color="inherit" />
</Backdrop>

    <TopHeader title="Blocked Users"  logo={''}/>
    <Breadcrumb breadcrumb={this.state.breadcrumb} />

            <div className='setting_ m-4' >
            <Grid container className='container_settings' spacing={2}>               

<List dense sx={{ width: '100%'}}>
{this.state.rows[0]  ? this.state.rows.map((value:any,index) => {
        const labelId = `checkbox-list-secondary-label-${index}`;

        return (
          <ListItem
            key={index}
            secondaryAction={                
        <LoadingButton
          style={{display:'flex',margin:'auto',marginTop:'10px'}}
color='secondary'
          size='medium'
          disabled={value.disable} 
          className="login-button "
  loading={value.load}
  loadingPosition="center"
  onClick={()=>this.unblocke(value.id)}
  variant="contained">
Unblock
</LoadingButton> 
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar className='box avatarimage' 
                  alt={`uvatar n°${index + 1}`}
                  src={value.user!==null ? value.user.pic : ''}
                />
              </ListItemAvatar>
              <ListItemText  className='avatartext'  id={labelId} primary={`${value.user.first_name}`} />
            </ListItemButton>
          </ListItem>
        );
      }) : this.state.loading ? '' : <h4><b>No Blocked Users Found</b></h4>}

      <ListItem>
      <ButtonGroup variant="contained" style={{margin:'auto'}} aria-label="outlined primary button group">
{ pages.map((valu:any)=>(
  <>
  { valu > 0  ? 
    <Button key={valu} color={this.state.curentPage===valu ? 'info' : 'inherit'}  onClick={()=>this.getBlocked(valu)} >{valu+1}</Button>
  : ''
  }
  </>
))} 
</ButtonGroup>
</ListItem>
    </List>

</Grid>


        </div>
        </div>

    )
}





}

export default BlockedUsers;