import { Avatar, Button, ButtonGroup, Grid, Slider, Typography, Chip} from '@mui/material';
import  React from 'react';
import Base64e from '../../components/encodedecode/encode';
import logo from '../../assets/img/logo-pitch.png'
import logoWithout from '../../assets/img/logo-without.png'
import ChatBox from '../../components/chatbox/ChatBox';
import { Navigate } from 'react-router-dom';
import variables from '../../global/global';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PersonIcon from '@mui/icons-material/Person';
import ClearIcon from '@mui/icons-material/Clear';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import axios from 'axios';
import Dialogs from '../../components/dialog/dialog';
import Sendpitch from '../../components/lovediamond/sendpitch';
import Updateinterest from '../../components/lovediamond/updateinterest';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import Swal from 'sweetalert2';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import PlaceIcon from '@mui/icons-material/Place';
class Massages extends React.Component<any, any>{
  
public state:any = {
  permission:true,
  dialog:false,
  user:{},
  profileMatch:'0%',
  refreshChat:false,
  dialog2:false,
  bool:false,
  path:'',
  toUser:'',
  smallScreen:false,
  priority: this.props.state.user.id==this.props.state.requestRow.from ? this.props.state.requestRow.priority : this.props.state.requestRow.to_priority,
  theirInterest: this.props.state.user.id ==  this.props.state.requestRow.from ? this.props.state.requestRow.to_priority : this.props.state.requestRow.priority,
  sendpitch:{
    open:false,
    updateopen:false,
    value:'',
    loading:false,   
    id:'', 
    column:'',
},
}
public marks = [
  {
    value: 0,
    label: '0%',
   
  },
  {
    value: 25,
    label: '25%',
   
  },
  {
    value: 50,
    label: '50%',
   
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
];
// constructor(prop:any){
//     super(prop);
// }

public resize = (e:any)=>{
  
  if(e!==null && e.getBoundingClientRect().width<=820){
this.setState({smallScreen:true});
  }else{
    this.setState({smallScreen:false}); 
  }
}

componentDidMount(){

  console.log(this.props.state.requestRow);
  

  if(this.props.state.requestRow.from == this.props.state.user.id){
    this.setState({toUser:this.props.state.requestRow.user_to,user:this.props.user().rows[0] });
  }else{
    this.setState({toUser:this.props.state.requestRow.user,user:this.props.user().rows[0]});  
  }

  setTimeout(() => {
    if(this.state.user.ansquery!==undefined && this.state.user.ansquery!=='' && this.state.user.ansquery!==null && this.state.toUser.ansquery!==undefined && this.state.toUser.ansquery!=='' && this.state.toUser.ansquery!==null){
     const matchedAns = this.state.user.ansquery.split(',').filter((val:any) => {
    return this.state.toUser.ansquery.split(',').includes(val)
  });
  const percentage = (matchedAns.length*100/13).toFixed(0)
  this.setState({profileMatch:percentage})
}
  }, 100);

/////check payment status and trial period/////
  // if(this.props.user().rows[0].payments[0].plan_id==='4'){
  //   let trial_end= this.props.user().rows[0].payments[0].start_date;

  //   const date1 = new Date();
  //   const date2 = new Date(trial_end);
  //   console.log(trial_end);
    
  //   const diffTime = date2.valueOf() - date1.valueOf();
  //   const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  //     console.log(diffTime + " milliseconds");
  //     console.log(diffDays + " days");

  //     if(diffTime <= 0){

  //       this.setState({permission:false}, () => {
          
  //           Swal.fire({  
  //             icon: "info",
  //             title: '', 
  //             allowOutsideClick: false, 
  //             text: ' To communicate with other users on Slammmm.com, please upgrade your subscription to either Major League or MVP.',
  //             confirmButtonColor: '#7b1fa2',  
  //           }).then(() => {
  //              window.location.reload();
  //           })
         
          
          
  //        })

  //     }
  // }
  ///end here///////



}
public viewProfile=(v:any)=>{
  const Uid =   Base64e.encode(this.state.toUser.id+'');
  //this.props.returnHandler({chatPage:false});
  //this.setState({path:'MatchesProfile/'+Uid+'/slammmm',bool:true}); 
  window.location.href='/MatchesProfile/'+Uid+'/slammmm';          
  
  }
  public handleSubmit = (priority:any)=>{


  if (this.state.permission) {

          this.props.returnHandler({breadcrumb:[{
            link:'/ChatList',
          }]})
          let data:any = [];

          data = {
            from:Base64e.encode(this.state.user.id+''),
            to:Base64e.encode(this.state.toUser.id+''),
            priority:this.state.priority,
            msg:'',
            id:this.props.state.requestRow.id,
            column: this.props.state.user.id == this.props.state.requestRow.from ? 'from' : 'to',
            status:this.props.state.requestRow.status
          };
        
      
        
          
            this.setState({loading:true});
          axios.post(variables.url+'sendUserRequests',data).then((res)=>{
                if(res.status === 200){
                  this.setState({loading:false});
                  ///this.props.removeRequestedUser(id)
                  // this.setState(
                  //   {loading:false,
                  //   snack_open:true,
                  //   snackmsg:'Request sent successfully!'
                  //   });
                  //window.location.reload();
              ///    this.setState({bool:true, path:'matches'})
                  // setTimeout(()=>( this.setState({bool:true, path:'matches'}) ), 3000)
                }
            }).catch((e:any)=>{
              console.log(e);
          
            }); 
   
    }
    }
    public calculateage = (dob:any)=>{
      const birthDate = new Date(dob); 
      const difference = Date.now() - birthDate.getTime();
      const age = new Date(difference);

      return Math.abs(age.getUTCFullYear() - 1970);
    }
public  render() {

  function valuetext(value: number) { 
    if(value>= 1 && value <= 24){
      return "Headed  towards first base!";
    }else if(value>= 25 && value <= 49){
      return "First base!";
  
    }else if(value>= 50 && value <= 74){
      return "Second base!";
    }else if(value>= 75 && value <= 99){
      return "Third base!";
  
    }else if(value == 100){
      return "Home run!";
  
    }   
  }     
  
  function trans(id:any,from:any,to_priority:any,priority:any){
    return id==from ? 100-to_priority : 100-priority
  }
  function trans1(priority:any){
   
    
    return 100-priority;
  }
          
                                          return (

<Grid container  style={{width:this.state.smallScreen ? '100%' : '95%',margin:'auto', paddingBottom:'100px'}}  ref={this.resize}>
{this.state.bool===true ? <Navigate state={this.state} to={"/"+this.state.path} replace={true}/> : ''}
<Grid item className='' xs={12} md={7} >
<div className='main-chat'>
<Grid container>


<Grid item  xs={12} md={12} >
<ButtonGroup variant='contained' className={this.state.smallScreen ? 'flexdisplay-n justify-content-around' : 'flexdisplay'} style={{width:this.state.smallScreen ? '100%' : '100%'}}>

<Button  className='text-lowercasee color-none '   variant={'text' }>
  <Avatar  sx={{ width: 50, height: 50 ,marginLeft:2}} alt="v" src={this.state.toUser!=='' ? this.state.toUser.pic : ''}/>  &nbsp;
  <Button  className='text-lowercasee  color-none pl-0'    variant={'text'}>
  <Typography  variant={this.state.smallScreen ? 'h6' :'h6'} style={{lineHeight:this.state.smallScreen ? '2.3' : '2.3'}}> {this.state.toUser!=='' ? this.state.toUser.first_name :''} &nbsp;
  {this.state.smallScreen ?
  <span>{this.state.toUser!=='' ? <>{this.calculateage(this.state.toUser.dob)} {this.state.toUser.confirm==1 ? <VerifiedOutlinedIcon  /> : ''}</> :''}
<span className='cit'> {this.state.toUser.city}, &nbsp; {this.state.toUser.state}
                    </span></span>:''}
  </Typography>
</Button></Button>
{/* {this.state.smallScreen ?
<Button  className='text-lowercasee'  variant={'text'} onClick={()=>this.openIntrestBox({
                                                      priority: this.state.priority,
                                                      theirInterest: this.state.theirInterest,
                                                      updateopen:true,
                                                      value:this.state.toUser,
                                                      id:this.props.state.requestRow.id,
                                                      column:this.props.state.user.id == this.props.state.requestRow.from ? 'from' : 'to',
                                                      status:this.props.state.requestRow.status
                                                    })} >
<img src={require(`./../../assets/img/logo-without.png`)} width="30" /></Button>
:''} */}
{!this.state.smallScreen ?
<>
{/* <Button  className='text-lowercasee' color='secondary'  variant={this.state.smallScreen ? 'text' : 'contained'} onClick={()=>this.props.returnHandler({chatPage:false})}>{this.state.smallScreen ? <ArrowBackIosIcon/> : 'Back'}</Button>&nbsp;&nbsp; */}
<Button  className='btns purple-btn text-lowercasee' color='secondary' variant={this.state.smallScreen ? 'text' :'contained'} onClick={this.viewProfile} >{this.state.smallScreen ? <PersonIcon/> : 'View Profile'}</Button>&nbsp;&nbsp;
{this.props.state.requestRow.status == 1 ? 
 <><Button  className='btns purple-btn text-lowercasee' color='secondary' variant={this.state.smallScreen ? 'text' :'contained'} onClick={this.unmatchUser}>{this.state.smallScreen ? <ClearIcon/> : 'Unmatch'}</Button>&nbsp;&nbsp; </>
 : ''}
<Button  className='btns red-btn text-lowercasee' color='secondary' variant={this.state.smallScreen ? 'text' :'contained'} onClick={this.blockUser}>{this.state.smallScreen ? <NotInterestedIcon/> : 'Block'}</Button>&nbsp;&nbsp;
</>
:
''

}
<Button className='icon-btn' color='secondary'  variant="text" onClick={()=>this.setState({refreshChat:true})}><RotateRightIcon/> </Button>
</ButtonGroup>
</Grid>

{/* <Grid item  xs={12} md={4} style={{marginBottom:'5px'}}>
<ButtonGroup variant='contained' className='' style={{width:this.state.smallScreen ? '100%' : '100%'}}>

</ButtonGroup>
</Grid> */}

</Grid>





{this.state.toUser!=='' ? <ChatBox profileMatch={this.state.profileMatch} refresh={this.state.refreshChat} toUser={this.state.toUser} data={this.props} returnHandle={(e:any)=>this.setState(e)} breadcrumbHandle={(e:any)=>this.props.returnHandler(e)} /> : ''}
</div>
</Grid>

{this.state.smallScreen ?
<Grid className='pt-3'  xs={12} md={12} >

<ButtonGroup variant='contained' className='flexdisplay paddingflex mb-5' style={{width:this.state.smallScreen ? '100%' : '100%'}}>
<>{/* <Button  className='text-lowercasee'  variant={'text'} onClick={()=>this.openIntrestBox({
                                                      priority: this.state.priority ?? 0,
                                                      theirInterest: this.state.theirInterest,
                                                      updateopen:true,
                                                      value:this.state.toUser,
                                                      id:this.props.state.requestRow.id,
                                                      column:this.props.state.user.id == this.props.state.requestRow.from ? 'from' : 'to',
                                                      status:this.props.state.requestRow.status
                                                    })} >
<img src={require(`./../../assets/img/logo-without.png`)} width="100" /></Button> */}</>
{/* <Button  className='text-lowercasee' color='secondary'  variant={this.state.smallScreen ? 'text' : 'contained'} onClick={()=>this.props.returnHandler({chatPage:false})}>{this.state.smallScreen ? <ArrowBackIosIcon/> : 'Back'}</Button>&nbsp;&nbsp; */}
<Button  className='btns purple-btn text-lowercasee' color='secondary' variant={'contained'} onClick={this.viewProfile} >{'View Profile'}</Button>&nbsp;&nbsp;
{this.props.state.requestRow.status == 1 ? 
 <><Button  className='btns purple-btn text-lowercasee' color='secondary' variant={'contained'} onClick={this.unmatchUser}>{'Unmatch'}</Button>&nbsp;&nbsp; </>
 : ''}
<Button  className='btns red-btn text-lowercasee' color='secondary' variant={'contained'} onClick={this.blockUser}>{'Block'}</Button>&nbsp;&nbsp;

</ButtonGroup>




{this.state.smallScreen ?

<Grid  xs={12} md={5} className='p-3 messagebbox'>
  <Grid container>
 <Grid item xs={12} className={'font-40 yourins'} md={12}>
                                              <div className='d-flex justify-content-end align-items-center'>
                                                <div className="messageboxslide" >
                                              <Typography sx={{  fontSize: { xs: "14px", md: "22px" }}}   variant={'h6'} ><span className='yellow'>Your</span> Interest</Typography> 
                                              
                                              
                                              <div className='pos-re-mess'>
                                                  <img className='fade-img' src={logoWithout} style={{width: '100%'}} alt="logo"/>
                                                  <img className='heart-img heart-img-mssg heart-img-chat'  src={logo}  alt="logo" style={{width:'66%',
                                                    WebkitMaskImage : 'linear-gradient(to bottom, transparent '+trans1(this.state.priority)+'%, black 0%, black 90%, transparent 100%)',
                                                    maskImage : 'linear-gradient(to bottom, transparent '+trans1(this.state.priority)+'%, black 0%, black 90%, transparent 100%)'}}/>
                                                  </div>
                                                

                                                  {/* <Typography sx={{  fontSize: { xs: "14px", md: "22px" }}}   variant={'h6'} >{valuetext(this.state.priority)}&nbsp;</Typography>  */}
                                                    {/*<>
                                                   <Slider
                                                      key={`slider-${valuetext}`}
                                                      aria-label="Temperature"
                                                      sx={{
                                                        color: '#ffffff',
                                                        }}
                                                      defaultValue={
                                                        this.props.state.requestRow.from !== '' ? 
                                                        this.props.user().rows[0].id===parseInt(this.props.state.requestRow.from) ? this.props.state.requestRow.priority : this.props.state.requestRow.to_priority

                                                        : 0
                                                    }
                                                    //valueLabelFormat={valuetext}
                                                    valueLabelDisplay='auto'
                                                    marks={this.marks}
                                                      step={1}
                                                    onChangeCommitted={(_, newValue)=>this.handleSubmit(newValue)}
                                                    onChange={(_, newValue)=>this.setState({priority:newValue})}
                                                      
                                                      min={0}
                                                      max={100}
                                                    />
                                                    <span className='slider-ttt'>Slide the bar to the right or left to indicate your interest </span>
                                                    </> */}
                                                  <LoadingButton style={{fontSize:'17px',borderRadius:'28px'}} className=''size='large'  color='primary' loading={this.state.loading}  onClick={()=>this.openIntrestBox({
                                                      priority: this.state.priority,
                                                      open:true,
                                                      value:this.state.toUser,
                                                      id:this.props.state.requestRow.id,
                                                      column:this.props.state.user.id == this.props.state.requestRow.from ? 'from' : 'to',
                                                      status:this.props.state.requestRow.status
                                                    })} variant='contained' >{this.state.priority <= 0 && this.props.state.requestRow.status==2 ?  'Send Pitch' : 'Update Interest'}</LoadingButton>
                                             </div>
                                             <div className='percet mb-5'>{this.state.priority ?? 0}%</div>
                                             </div>
     </Grid>
    <Grid item xs={12} md={12} className='h-250 h-235-mssg yourins '>
                                      <div className='d-flex justify-content-end align-items-center mt-5'>
                                            <div  className="messageboxslide" >
                                              <Typography sx={{  fontSize: { xs: "14px", md: "22px" }}} variant={'h6'} > <span className='yellow'>Their</span> Interest</Typography>
                                          
                                              <div className='pos-re-mess'>
                                                  <img className='fade-img' src={logoWithout} style={{width: '100%'}} alt="logo"/>
                                                  <img className='heart-img heart-img-mssg heart-img-chat'  src={logo}  alt="logo" style={{width:'66%',
                                                    WebkitMaskImage : 'linear-gradient(to bottom, transparent '+trans(this.props.state.user.id,this.props.state.requestRow.from, this.props.state.requestRow.to_priority, this.props.state.requestRow.priority)+'%, black 0%, black 90%, transparent 100%)',
                                                    maskImage : 'linear-gradient(to bottom, transparent '+trans(this.props.state.user.id,this.props.state.requestRow.from, this.props.state.requestRow.to_priority, this.props.state.requestRow.priority)+'%, black 0%, black 90%, transparent 100%)'}}/>
                                                  </div>
                                                  
                                                  </div>
                                                  <div className='percet'>{this.props.state.user.id== this.props.state.requestRow.from ? this.props.state.requestRow.to_priority ?? 0 : this.props.state.requestRow.priority ?? 0}%</div>
                                             </div>
                                                  {/* <Typography sx={{  fontSize: { xs: "14px", md: "22px" }}}   variant={'h6'} >{this.props.state.user.id==this.props.state.requestRow.from ? valuetext(this.props.state.requestRow.to_priority) : valuetext(this.props.state.requestRow.priority)}</Typography>  */}
                                           
    </Grid>
    </Grid> 
</Grid>


:''}

</Grid>
:''}


{this.state.smallScreen ? '' : 
<Grid  xs={12} md={5}>
  <Grid container>
 <Grid item xs={12} className={'font-40 yourins'} md={12}>
                                              <div className='d-flex justify-content-end align-items-center'>
                                                <div className="messageboxslide" >
                                              <Typography sx={{  fontSize: { xs: "14px", md: "22px" }}}   variant={'h6'} ><span className='yellow'>Your</span> Interest</Typography> 
                                              
                                              
                                              <div className='pos-re-mess'>
                                                  <img className='fade-img' src={logoWithout} style={{width: '100%'}} alt="logo"/>
                                                  <img className='heart-img heart-img-mssg heart-img-chat'  src={logo}  alt="logo" style={{width:'66%',
                                                    WebkitMaskImage : 'linear-gradient(to bottom, transparent '+trans1(this.state.priority)+'%, black 0%, black 90%, transparent 100%)',
                                                    maskImage : 'linear-gradient(to bottom, transparent '+trans1(this.state.priority)+'%, black 0%, black 90%, transparent 100%)'}}/>
                                                  </div>
                                                

                                                  {/* <Typography sx={{  fontSize: { xs: "14px", md: "22px" }}}   variant={'h6'} >{valuetext(this.state.priority)}&nbsp;</Typography>  */}
                                                  <>
                                                    <Slider
                                                      key={`slider-${valuetext}`}
                                                      aria-label="Temperature"
                                                      sx={{
                                                        color: '#ffffff',
                                                        }}
                                                      defaultValue={
                                                        this.props.state.requestRow.from !== '' ? 
                                                        this.props.user().rows[0].id===parseInt(this.props.state.requestRow.from) ? this.props.state.requestRow.priority : this.props.state.requestRow.to_priority

                                                        : 0
                                                    }
                                                    //valueLabelFormat={valuetext}
                                                    valueLabelDisplay='auto'
                                                    marks={this.marks}
                                                      step={1}
                                                    onChangeCommitted={(_, newValue)=>this.handleSubmit(newValue)}
                                                    onChange={(_, newValue)=>this.setState({priority:newValue})}
                                                      
                                                      min={0}
                                                      max={100}
                                                    />
                                                    <span className='slider-ttt'>Slide the bar to the right or left to indicate your interest </span>
                                                    </>
                                                  {/* <LoadingButton style={{fontSize:'12px'}} className=''size='large'  color='secondary' loading={this.state.loading}  onClick={()=>this.openIntrestBox({
                                                      priority: this.state.priority,
                                                      open:true,
                                                      value:this.state.toUser,
                                                      id:this.props.state.requestRow.id,
                                                      column:this.props.state.user.id == this.props.state.requestRow.from ? 'from' : 'to',
                                                      status:this.props.state.requestRow.status
                                                    })} variant='contained' >{this.state.priority <= 0 && this.props.state.requestRow.status==2 ?  'Send Pitch' : 'Update Interest'}</LoadingButton> */}
                                             </div>
                                             <div className='percet mb-5'>{this.state.priority ?? 0}%</div>
                                             </div>
     </Grid>
    <Grid item xs={12} md={12} className='h-250 h-235-mssg yourins '>
                                      <div className='d-flex justify-content-end align-items-center mt-5'>
                                            <div  className="messageboxslide" >
                                              <Typography sx={{  fontSize: { xs: "14px", md: "22px" }}} variant={'h6'} > <span className='yellow'>Their</span> Interest</Typography>
                                          
                                              <div className='pos-re-mess'>
                                                  <img className='fade-img' src={logoWithout} style={{width: '100%'}} alt="logo"/>
                                                  <img className='heart-img heart-img-mssg heart-img-chat'  src={logo}  alt="logo" style={{width:'66%',
                                                    WebkitMaskImage : 'linear-gradient(to bottom, transparent '+trans(this.props.state.user.id,this.props.state.requestRow.from, this.props.state.requestRow.to_priority, this.props.state.requestRow.priority)+'%, black 0%, black 90%, transparent 100%)',
                                                    maskImage : 'linear-gradient(to bottom, transparent '+trans(this.props.state.user.id,this.props.state.requestRow.from, this.props.state.requestRow.to_priority, this.props.state.requestRow.priority)+'%, black 0%, black 90%, transparent 100%)'}}/>
                                                  </div>
                                                  
                                                  </div>
                                                  <div className='percet'>{this.props.state.user.id== this.props.state.requestRow.from ? this.props.state.requestRow.to_priority ?? 0 : this.props.state.requestRow.priority ?? 0}%</div>
                                             </div>
                                                  {/* <Typography sx={{  fontSize: { xs: "14px", md: "22px" }}}   variant={'h6'} >{this.props.state.user.id==this.props.state.requestRow.from ? valuetext(this.props.state.requestRow.to_priority) : valuetext(this.props.state.requestRow.priority)}</Typography>  */}
                                           
    </Grid>
    </Grid> 
</Grid>}

{this.state.dialog ? <Dialogs open={this.state.dialog} onreturn={(e:any)=>this.blockUser(e)} title='Block' subtitle='Are you sure to block this user.'/> :''}
{this.state.dialog2 ? <Dialogs open={this.state.dialog2} onreturn={(e:any)=>this.unmatchUser(e)} title='Unmatch' subtitle='Are you sure to Unmatch this user.'/> :''}
{this.state.sendpitch.open ?  <Sendpitch from={'massages'} setpriority={(e:any)=>this.setpriority(e)} returnhandle={(e:any)=>this.setState(e)} data={this.state}/> : ''}
{this.state.sendpitch.updateopen ?  <Updateinterest from={'massages'} setpriority={(e:any)=>this.setpriority(e)} returnhandle={(e:any)=>this.setState(e)} data={this.state}/> : ''}
</Grid>
                                      )

    }




    public openIntrestBox=(value:any)=>{
      this.setState({priority:value.priority,sendpitch:value});
      
        }

        public setpriority = (i:any)=>{
          console.log('iiiiii',i);
         this.setState({priority:i})
          const sendpitch={open:false};
          this.setState({sendpitch:sendpitch,refreshChat:true});
          this.props.setpriority(i);
        }

    
    public unmatchUser = (v:any)=>{


    if (this.state.permission) {

      this.setState(v);
      Swal.fire({
        title: 'Are you sure you want to unmatch this user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async (result) => {
        if (result.isConfirmed) {
          axios.post(variables.url+'unmatchUser',{
            req_id:Base64e.encode(this.props.state.requestRow.id+''),
          })
          .then((res)=>{
              if(res.status === 200){
              
                  window.location.reload();
              
                
   
              }
          }).catch((e:any)=>{
         console.log(e);
          });
        }

      });
    }

     }

 


      public blockUser = (v:any)=>{


        if (this.state.permission) {
        this.setState(v);
        Swal.fire({
          title: 'Are you sure?',
          text: "You want to block this user!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post(variables.url+'blockUser',{

              user_id:Base64e.encode(this.props.user().rows[0].id+''),
              blocked:Base64e.encode(this.state.toUser.id+''),
              req_id:Base64e.encode(this.props.state.requestRow.id+''),
            })
          .then((res)=>{
              if(res.status === 200){
             
                    window.location.reload();
                
                
        
              
          
              }
          }).catch((e:any)=>{
        console.log(e);
          }); 
    
    
        }
    
        })

      }
      }






}

export default Massages;