import React from "react"
import { ButtonGroup, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Navigate } from "react-router-dom";
import { differenceInYears, format } from "date-fns";

const getDays = (monthString: string, yearString: string) => {
  const month = parseInt(monthString, 10);
  const year = parseInt(yearString, 10);
  const lastDay = new Date(year, month, 0).getDate();
  const days: any[] = [];

  for (let day = 1; day <= lastDay; day++) {
    days.push(day);
  }
  // days.sort((a,b)=>b-a);
  return days;
};
class Dob extends React.Component<any>{
  public state = {
    day: this.props.sendprop.day,
    month: this.props.sendprop.month,
    year: this.props.sendprop.year,
    dateOfBirthIsValid: true,
    dob: this.props.sendprop.dob,
    dob_error: '',
    go: false,
    page: '',
  }

  // constructor(props:any) {
  //     super(props)
  // }


  public setProp = () => {


    if (this.state.dob.length > 9 && this.state.dob.trim().length > 9 && this.state.dob_error == '') {
      this.props.returnHandle({ dob: this.state.dob.trim(), page: 'name', day: this.state.day, month: this.state.month, year: this.state.year });
    } else {
      this.setState({ dob_error: 'Date of birth feild is required. And must be at least 18 years old to create an account.' });
    }

  }

  public setvalues = (r: any) => {
    if (r.target.value > this.isDate18orMoreYearsOld()) {
      this.setState({ dob_error: 'Must be at least 18 years old to create an account.' });
    } else {
      this.props.returnHandle({ dob: r.target.value });
      this.setState({ dob: r.target.value });
      this.setState({ dob_error: '' });
    }
  }


  public handleMonth = (r: any) => {
    this.setState({ month: r.target.value }, this.processDateOfBirth);

  }
  public handleDay = (r: any) => {
    this.setState({ day: r.target.value }, this.processDateOfBirth);
  }
  public handleYear = (r: any) => {
    this.setState({ year: r.target.value }, this.processDateOfBirth);
  }

  private validateDateOfBirth = ({ day, month, year }: { day: string; month: string; year: string }) => {
    if (!day || !month || day === "null" || month === "null") {
      return false;
    }

    const dateOfBirth = new Date(`${month}/${day}/${year}`);
    const today = new Date();
    const difference = differenceInYears(today, dateOfBirth);
    let minAge = 18;
    console.log('difff', difference);
    if (difference >= minAge) {
      return true;
    } else {
      return false;
    }
  };



  private generateDays = () => {
    const { month, year } = this.state;

    if (month && year) {
      const days = getDays(month, year).map((day, index) => (
        <option key={index} value={day}>
          {day}
        </option>
      ));
      this.setState({ days });
    }
  };
  private processDateOfBirth = () => {
    const { day, month, year } = this.state;

    this.generateDays();

    const dateOfBirthIsValid = this.validateDateOfBirth({ day, month, year });

    this.validateDateOfBirth({ day, month, year });

    if (dateOfBirthIsValid) {

      const dateofbirth: any = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
      console.log(dateofbirth);
      if (dateofbirth != 'Invalid Date') {
        //  window.G_dateofbirth = dateofbirth;
        this.setState({ dateOfBirthIsValid: true, dob: format(dateofbirth, 'yyyy-MM-dd') });
        this.setState({ dob_error: '' });
      }

    } else {
      this.setState({ dateOfBirthIsValid: false });
      this.setState({ dob_error: 'Must be at least 18 years old to create an account.' });
    }
  };

  public isDate18orMoreYearsOld = () => {

    const d = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

    const y = d.getFullYear();
    const m = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const dd = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
    return '' + y + '-' + m + '-' + dd + '';
  }

  componentDidMount(): void {
    console.log('sendprop', this.props.sendprop);

  }

  render(): React.ReactNode {
    // alert(this.isDate18orMoreYearsOld());
    const values = {
      someDate: this.isDate18orMoreYearsOld()
    };

    return (
      <div className="container main py-2" >
        {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={false} /> : ''}
        <div className={this.props.from === undefined ? 'd-block m_auto  col-md-5' : 'd-block m_auto  col-md-12'} >
          {/* <TextField focused type={'date'}  required={true} className='d-flex' id="outlined-basic" label="What is your date of birth" 
         onChange={(e)=> this.setvalues(e)} error={this.state.dob_error!=='' ?  true : false} helperText={this.state.dob_error} variant="outlined"
           value={this.props.sendprop.dob!=='' ? this.props.sendprop.dob : ''}
          /> */}
          {this.props.from !== undefined && this.props.from !== null ? '' : <h3 className="d-block text-center" >What is your date of birth?</h3>}

          {/* <label className="mb-2">What is your date of birth</label> */}
          <div className="d-flex">
            <FormControl fullWidth>
              <InputLabel id="dob-month">Month</InputLabel>
              <Select name="month" label="Month" onChange={(e) => this.handleMonth(e)} defaultValue={this.props.sendprop.month}>

                {(() => {
                  let option = [];
                  for (let i = 1; i <= 12; i++) {
                    option.push(<MenuItem value={i}>{i}</MenuItem>);
                  }
                  return option;
                })()}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="dob-date">Date</InputLabel>
              <Select name="date" label="Date" onChange={(e) => this.handleDay(e)} defaultValue={this.props.sendprop.day}>

                {(() => {
                  let option = [];
                  for (let i = 1; i <= 31; i++) {
                    option.push(<MenuItem value={i}>{i}</MenuItem>);
                  }
                  return option;
                })()}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="dob-year">Year</InputLabel>
              <Select name="year" label="Year" onChange={(e) => this.handleYear(e)} defaultValue={this.props.sendprop.year}>
                
                {(() => {
                  let option = [];
                  for (let i = 1950; i <= 2010; i++) {
                    option.push(<MenuItem value={i}>{i}</MenuItem>);
                  }
                  return option;
                })()}
              </Select>
            </FormControl>
          </div>
          {this.state.dob_error !== '' ? <span className=" muierror">{this.state.dob_error}</span> : ''}




          <ButtonGroup style={{ width: '100%' }}>
            {this.props.from === undefined ? <LoadingButton
              style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
              type="submit"
              size="large"
              disabled={false}
              className="btns login-next-button"
              loading={false}
              loadingPosition="center"
              onClick={() => this.props.returnHandle({ page: 'questions' })}
              variant="contained">
              Back
            </LoadingButton> : ''}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <LoadingButton
              style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
              type="submit"
              size="large"
              disabled={false}
              className="btns  login-back-button"
              loading={false}
              loadingPosition="center"
              onClick={() => this.setProp()}
              variant="contained">
              {this.props.from === undefined ? 'Next' : 'Save'}
            </LoadingButton>

          </ButtonGroup>

        </div>
      </div>
    );
  }


}

export default Dob;