import * as React from 'react';
import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import {Typography, Badge, Modal, Fade } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuthUser } from 'react-auth-kit';
import { useSignOut } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import logo from '../../assets/img/logo-1.png'
import ArticleIcon from '@mui/icons-material/Article';
import InfoIcon from '@mui/icons-material/Info';
import FlagIcon from '@mui/icons-material/Flag';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Collapse from '@mui/material/Collapse';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import variables from '../../global/global';
import Base64e from '../../components/encodedecode/encode';
import parse from 'html-react-parser';
import MoreVertIcon from '@mui/icons-material/MoreVert';




type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [smallScreen, setsmallScreen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openmodal, setModalopen] = React.useState(false);
  const [openmodal2, setModalopen2] = React.useState(false);
  const [title, settitle] = React.useState('Zodaic');
  const [sub, setsub] = React.useState('Zodiac signs have long been used to gain insite into various personality that individuals may have, based on times and dates in which they were born.');
  const [sub1, setsub1] = React.useState('Update your <span class="color-pink">profile</span> to stand out or go to  <span class="color-pink">send pitches</span> to meet new singles');
  const [msg, setMsg] = React.useState(0);
  const [request, setRequest] = React.useState(0);
  const [content, setContent] = React.useState('');

const openModal = (i:number)=>{
  console.log('fffffff', msg);
  if(i===1){
    if(msg > 0) {
      window.location.href='/ChatList';
      navigate('/ChatList')
    }else{
      settitle('Messages');
      setsub('You have no messages');
      setsub1('Update your <a href="/profile"><span class="color-pink"> profile</span></a> to stand out or go to  <a href="/matches"><span class="color-pink">send pitches</span></a> to meet new singles');
      setModalopen2(true);
        setOpen(false);
    }   
 
    }
    if(i===2){
      if(request > 0) {
        navigate('/activepitches')
      }else{
        settitle('Active Hits');
        setsub('You have no new hits');
        setsub1('Update your <a href="/profile"><span class="color-pink">profile</span></a> to stand out or go to  <a href="/matches"><span class="color-pink">send pitches</span></a> to meet new singles');
        setModalopen2(true);
        setOpen(false);
      }  
      
      }

      if(i===3){
     
        settitle('Zodaic');
        setsub('Zodiac signs have long been used to gain insite into various personality that individuals may have, based on times and dates in which they were born.');
        setsub1('Update your <span class="color-pink">profile</span> to stand out or go to  <span class="color-pink">send pitches</span> to meet new singles');
        setModalopen(true);
        setOpen(false);
         
   
      }

}


const getMassageList = ()=>{

     axios.post(variables.url+'ChatList', {
      id:Base64e.encode(user().rows[0].id+''),
      page:1,
      limit:1,
      search:'',
      status:1,
    }).then((res)=>{                
                 if(res.status === 200){         
                  setMsg(res.data.count)
                }
             });

             axios.post(variables.url+'getuserRequests', {
              id:Base64e.encode(user().rows[0].id+''),
              page:1,
              limit:1,
              search:'',
              status:1,
            }).then((res)=>{                
                         if(res.status === 200){         
                          setRequest(res.data.count)
                        }
            });

 } 
 const handleResize = ()=>{
  console.log('width',window.innerWidth);
  if(window.innerWidth<=820){
  setsmallScreen(true);

  }else{
  setsmallScreen(false); 

  }
}
 useEffect(() => {
   getMassageList();

   window.addEventListener("resize", handleResize)
   handleResize()

   axios.post(variables.url+'getPage',{id:Base64e.encode(12+'')})
   .then((res)=>{    
   if(res.status === 200){     

    setContent(res.data.content)
    }
   }).catch((e:any)=>{  
        
   });
 },[]);

  const handleClick = () => {
    setOpen(!open);
    toggleDrawer('left', true)
    
  };

  
  const closedrawer =()=>{
   toggleDrawer('left', false)
  }
  const navigate = useNavigate();
  const signOut = useSignOut()
  const user:any = useAuthUser();
  const handleSignout = () => {
    signOut(); // Call signout without any arguments
    // Redirect the user to the desired page after signout
    navigate('/');
  };
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

    const OpenPage = (page:string)=>{
        navigate(page);
    }

  const list = (anchor: Anchor) => (
    <Box
    
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, true)}
    >
      <List>
      <ListItem  onClick={()=>OpenPage('/')} key={'Home'} disablePadding>
      <img style={{margin:'auto'}} src={logo} width={'50%'} alt=""/>
      <span className="header-tm-text logotm3">TM</span>
                </ListItem>
      
      <ListItem onClick={()=>OpenPage('/')} key={'Home1'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/Home.png`)} />
                </ListItemIcon>
                <ListItemText primary={'Home'} />
                </ListItemButton>
                </ListItem>


                <ListItem onClick={()=>OpenPage('/Profile')} key={'profile'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/Profile-icon.png`)} />
                </ListItemIcon>
                <ListItemText primary={'Profile'} />
                </ListItemButton>
                </ListItem>

               


                <ListItem onClick={()=>OpenPage('/matches') } key={'activepitches'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/Baseball.png`)} />
                </ListItemIcon>
                <ListItemText primary={'Pitches'} />
                </ListItemButton>
                </ListItem>
                <ListItem onClick={()=>openModal(2)} key={'matches'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/Hits-in-white.png`)} />
                </ListItemIcon>
                <ListItemText primary={'Hits'} />
                </ListItemButton>
                </ListItem>

                <ListItem onClick={()=> openModal(1)} key={'ChatList'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/Message-bubble.png`)} />
                </ListItemIcon>
                <ListItemText primary={'Messages'} />
                </ListItemButton>
                </ListItem>
                <ListItem onClick={()=> openModal(3)} key={'Zodiac'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/Horoscope.png`)} />
                </ListItemIcon>
                <ListItemText primary={'Zodiac'} />
                </ListItemButton>
                </ListItem>
               
                

                <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                    <img className="sidebar-icon" src={require(`./../../assets/img/other-pages-icon.png`)} />
                    </ListItemIcon>
                    <ListItemText primary="Other pages" />
                    {open ? <ExpandLess className='text-black' /> : <ExpandMore className='text-black'/>}
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                     
                      <ListItem onClick={()=>OpenPage('/Aboutus')} key={'Aboutus'} disablePadding>
                          <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                          <img className="sidebar-icon" src={require(`./../../assets/img/about-icon.png`)} />
                          </ListItemIcon>
                          <ListItemText primary={'About Us'} />
                          </ListItemButton>
                          </ListItem>
                          <ListItem onClick={()=>OpenPage('/Relationshipcoaching')} key={'Relationshipcoaching'} disablePadding>
                          <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                          <img className="sidebar-icon" src={require(`./../../assets/img/relationship-coaching-icon-1.png`)} />
                          </ListItemIcon>
                          <ListItemText primary={'Coaching'} />
                          </ListItemButton>
                          </ListItem>
                          <ListItem onClick={()=>OpenPage('/Blog')} key={'Blog'} disablePadding>
                          <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                          <img className="sidebar-icon" src={require(`./../../assets/img/dating.png`)} />
                          </ListItemIcon>
                          <ListItemText primary={'Dating Suggestions'} />
                          </ListItemButton>
                          </ListItem>
                          <ListItem onClick={()=>OpenPage('/Missionstatement')} key={'Missionstatement'} disablePadding>
                          <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                          <img className="sidebar-icon" src={require(`./../../assets/img/Mission-statement.png`)} />
                          </ListItemIcon>
                          <ListItemText primary={'Mission Statement'} />
                          </ListItemButton>
                          </ListItem>
                         
                    </List>
                  </Collapse>
                {(user().rows[0].email != 'test_male@gmail.com' && user().rows[0].email != 'test_female@gmail.com') ?
                <ListItem onClick={()=>OpenPage('/settings')} key={'settings'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/settings-icon.png`)} />
                </ListItemIcon>
                <ListItemText primary={'Settings'} />
                </ListItemButton>
                </ListItem>

                   
                : ''}

                <ListItem onClick={()=>OpenPage('/contactus')} key={'contactus'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/contact-icon.png`)} />
                </ListItemIcon>
                <ListItemText primary={'Contact Us'} />
                </ListItemButton>
                </ListItem>

                <ListItem onClick={()=>OpenPage('/help')} key={'help'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/help-icon-menu.png`)} />
                </ListItemIcon>
                <ListItemText primary={'Help'} />
                </ListItemButton>
                </ListItem>



                <ListItem key={'logout'} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <img className="sidebar-icon" src={require(`./../../assets/img/logout-icon.png`)} />
                </ListItemIcon>
                <ListItemText onClick={()=>handleSignout()} primary={'Logout'} />
                </ListItemButton>
                </ListItem>
                 
      </List>    
    </Box>
  );

  return (
    <div  style={{textAlign:'left'}}>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor} >
          
            <Button className="drawer" variant='contained' size='large' color='secondary' onClick={toggleDrawer(anchor, true)}><FormatListBulletedIcon /></Button>
          

          {/* <a href='#' onClick={toggleDrawer(anchor, true)}><img src={require(`./../../assets/img/burger.png`)} /></a> */}
          {/* <Button className="drawer" variant='contained' size='large' color='secondary' ></Button> */}
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
             <ClearIcon className='clear-ic staticy2' onClick={toggleDrawer(anchor, false)}/>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

<Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openmodal}
        onClose={()=>setModalopen(false)}       
        closeAfterTransition
        style={{pointerEvents: 'none'}}
      >
        <Fade in={openmodal} >
          <Box className='msspopup zodiac' style={{pointerEvents: 'all'}}>
            <div>
            <div className="row position-relative">
            <div className="col-md-12 text-center mb-3"> <img className="zodiac-title" src={require(`./../../assets/img/Zodiac-title.png`)} /></div>
            <div className="col-md-12 iocdiv"> 
            <img className="zodiac-icon" src={require(`./../../assets/img/Horoscope-1.png`)} />
            <ClearIcon onClick={()=>setModalopen(false)} className='clearicon'/>
            </div>
            </div>
            <div className='zodiac-text'>
                <p>{parse(content)}</p>
            </div>
             
            
          </div>
        </Box>
      </Fade>
    </Modal>

    <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openmodal2}
        onClose={()=>setModalopen2(false)}       
        closeAfterTransition
        style={{pointerEvents: 'none'}}
      >
        <Fade in={openmodal2} >
          <Box className='msspopup' style={{pointerEvents: 'all'}}>
            <div>
            <div className="row position-relative">
            <div className="col-md-12"> <Typography className='text-center color-pink' variant='h4'>{title}</Typography></div>
            <div className="col-md-12 iocdiv"> <ClearIcon onClick={()=>setModalopen2(false)} className='clearicon'/></div>
            </div>
           
            <Typography className='text-center' variant='h5'>{sub}</Typography>
            <Typography className='text-center' variant='h6' ><div dangerouslySetInnerHTML={{
                      __html: sub1
              }}></div></Typography>
          </div>
        </Box>
      </Fade>
    </Modal>

    </div>
  );
}
