import {    Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import TopHeader from './includes/header';
import Breadcrumb from './includes/breadcrumb';
import { Navigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Base64e from './../components/encodedecode/encode';
import variables from './../global/global';
import axios from 'axios';
class Contactus extends React.Component<any>{

    // constructor(props:any) {
    //     super(props)
    // }
       
    public state = {
        user:this.props.user().rows[0],
        bool:false,
      path:'',
      loading:false,
      text:'',
      msg:'',
      success:'',
      breadcrumb:[
      {
        link:'',
        link_name:'Contact us'
      },
    ]
        }
    
        public submitSupport=()=>{
if(this.state.text==='' && this.state.text.trim()===''){
    this.setState({msg:'Message Field is required.'});
}else{
    this.setState({msg:'',loading:true});
    const idx = Base64e.encode(this.state.user.id+'');
    axios.post(variables.url+'supportSubmit', {id:idx,email:this.state.user.email,text:this.state.text})
    .then((res)=>{
        if(res.status === 200){ 
            this.setState({success:'Successfully Submited',loading:false,text:''}); 
            setTimeout(() => {   
              
                this.setState({bool:true});
             }, 2000);   
             }
    }).catch((e:any)=>{
    console.log(e);
    this.setState({msg:'server error',loading:false});   
    
    });

}

        }
public set =(e:any)=>{
this.setState({text:e.target.value});
}

public render(){

    return(
        <div  className='App' >
          {this.state.bool===true ? <Navigate state={this.state} to={"/"+this.state.path} replace={true}/> : ''}
<TopHeader title="Support"  logo={''}/>
<Breadcrumb breadcrumb={this.state.breadcrumb} />
<br/>
            <div className='Support_ mb-5'>
            <Grid container spacing={2}>               
<Grid item xs={12} md={12}>
<Typography  variant='h5'  >Please let us know how we can help and we will respond to your email.</Typography><br/>
{this.state.success!=='' ? <Typography  variant='h5' style={{color: '#07ff07'}} >{this.state.success} &#10004;</Typography>:''}
<textarea placeholder='' rows={6}  onChange={(e)=>this.set(e)}  value={this.state.text} />
<Typography  variant='subtitle1' color='red' >{this.state.msg}</Typography>
<Typography  variant='h5'  >
        <LoadingButton
          style={{display:'flex',margin:'13px auto'}}
          type="submit"
          size="large"
          disabled={false}
          className="btns blue-btn"
          color='primary'
  loading={this.state.loading}
  loadingPosition="center"
  onClick={()=>this.submitSupport()} 
  variant="contained">
Submit
</LoadingButton>
        </Typography>
</Grid> 
</Grid>

            </div>
           </div>

    )
}


public updateFeild = (type: string,value: any,id: any)=>{

    this.setState({edit:{
                    type:type,
                    value:value,
                    id:id,
                },editField:true});
    
    }



}

export default Contactus;