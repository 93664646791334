import axios from 'axios';
import {Box, Button,  Fade, Grid, Modal, Slider,  Typography, Snackbar, Alert,Stack, Chip } from "@mui/material";
import { Navigate, useLocation  } from "react-router-dom";

import  React, { useContext, useEffect, Component } from 'react';
import variables from '../../global/global';
import TopHeader from '../includes/header';
import Breadcrumb from '../includes/breadcrumb';
import logo from '../../assets/img/logo-pitch.png'
import logoWithout from '../../assets/img/logo-without.png'
import { LoadingButton } from '@mui/lab';
import Base64e from '../../components/encodedecode/encode';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import PlaceIcon from '@mui/icons-material/Place';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';


import Swal from 'sweetalert2';





class Addinterest extends React.Component<any, any>{



    public state = {
        id:'',
        user:this.props.user().rows[0],
        dob_error:'',
        go:false,
        page:'',
        text:'',
        priority:0,
        transparent:0,
        priority_txt:'',
        msg:'',
        backUrl:'matches',
        loading:false,
        permission:true,
        snack_open:false,
        snackmsg:'',
        Otheruser:{
            pic:'',
            id:'',
            first_name:'',
            dob:'',
            city:'',
            state:'',
            distance:0,
            confirm:0
        },
        bool: false,
        path:'',
        insterst_msg:'Slide the bar to the right or left to indicate your interest',
        breadcrumb:[{
          link:'/matches',
          link_name:'Love Pitches'
        },
        {
          link:'',
          link_name:'Send Pitches'
        }
      ]
       
    }
    public marks = [
      
      {
        value: 25,
        label: '25%',
       
      },
      {
        value: 50,
        label: '50%',
       
      },
      {
        value: 75,
        label: '75%',
      },
      {
        value: 100,
        label: '100%',
      },
    ];

  
    public msgSet = (e:any)=>{
        this.setState({msg:e.target.value})
      }

      public calculateage = (dob:any)=>{
        const birthDate = new Date(dob); 
        const difference = Date.now() - birthDate.getTime();
        const age = new Date(difference);

        return Math.abs(age.getUTCFullYear() - 1970);
      }


      public setInterest = (value:any)=>{
        this.setState({priority:value});
        const vv=100-value;
        console.log('trans',vv);
        
        this.setState({transparent:vv});
        
        console.log(value);
        
        if(value>= 1 && value <= 24){
          
         // this.setState({insterst_msg: "Your interest is heading towards first base!"});
          this.setState({priority_txt: "towards first base!"});
        }else if(value>= 25 && value <= 49){
       //   this.setState({insterst_msg: "Your interest is first base!"});
          this.setState({priority_txt: "first base!"});

         
        }else if(value>= 50 && value <= 74){
       //   this.setState({insterst_msg: "Your interest is second base!"});
          this.setState({priority_txt: "second base!"});

       
        }else if(value>= 75 && value <= 99){
        //  this.setState({insterst_msg: "Your interest is third base!"});
          this.setState({priority_txt: "third base!"});


        }else if(value == 100){
        //  this.setState({insterst_msg: "Your interest is a home run!"});
          this.setState({priority_txt: "home run!"});

          

        }
      }
      componentDidMount(){ 
        console.log('props',this.props);
        
          

            const id= window.location.href.split('/')[4];

            const priority:number= parseInt(window.location.href.split('/')[5]);

            this.setState({transparent:100-priority});

            this.setState({priority:priority});

            if(priority > 0){
              this.setState({backUrl:'MatchesProfile/'+id+'/slammmm'});
            }else{
              this.setState({backUrl:'matches'});
            }
            
            
            axios.post(variables.url+'getSingleUser', {
                id:id,
                lat:this.state.user.lat,
                lang:this.state.user.lang,
                Rid:'',
                uId:''
            }).then((res)=>{                
                if(res.status === 200 && res.data!==''){
                    const row = res.data.rows.rows[0];
                    this.setState({Otheruser:row})
                    
                    

                    if(this.state.user.payments[0].plan_id==='4'){
                      let trial_end= this.state.user.payments[0].start_date;
                      
                       const date1 = new Date();
                       const date2 = new Date(trial_end);
                       console.log(trial_end);
                       
                       const diffTime = date2.valueOf() - date1.valueOf();
                       const diffDays = diffTime / (1000 * 60 * 60 * 24); 
                        if(diffTime <= 0){
                 
                           this.setState({permission:false}, () => {
                             
                               Swal.fire({  
                                 icon: "info",
                                 title: '',  
                                 text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
                                 confirmButtonColor: '#7b1fa2',  
                                }).then((res) => {
                
                                  window.location.href = '/subscription?open=1';
                                  //window.location.reload();
                                })
                            
                             
                             
                            })
                 
                         }else{
                          this.setState({permission:true});
                         }
                     }
                }
            });
    
       }

 
    public  render() { 
        
        
      
        function valuetext(value: number) { 
          if(value>= 1 && value <= 24){
            return "Headed  towards first base!";
          }else if(value>= 25 && value <= 49){
            return "First base!";

          }else if(value>= 50 && value <= 74){
            return "second base!";
          }else if(value>= 75 && value <= 99){
            return "Third base!";

          }else if(value == 100){
            return "Home run!";

          }   
        }
       
          
      
        return (

           
        <div className='App'>

             {this.state.bool===true ? <Navigate  state={this.state} to={"/"+this.state.path} replace={true}/>  : ''}

                <TopHeader title="&nbsp;"  logo={''}/>
                <Breadcrumb breadcrumb={this.state.breadcrumb} />
           <div>
           
           </div>


            <div className="container mt-3 mb-6" >
              <Box className='updateModal interestbox main' >  
              <Typography variant="h5">Rate your interest in {this.state.Otheruser.first_name}</Typography>
                <Grid  container md={12}  >


                 
                <Grid item md={12} xs={12}>
                  <div className='d-flex justify-content-end'>
                    <a href='#' style={{color:'#fff'}} title="Back" onClick={()=>   this.setState({bool:true, path:this.state.backUrl})}><ClearIcon  className='backarrow'/></a>
                       <br/>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={6}>
                    <img  src={this.state.Otheruser !==null ? this.state.Otheruser.pic : ''} width={'50%'} alt="logo"/>
                    </Grid>
                    <Grid item md={1} xs={1}>
                      <div className='percet'>{this.state.priority}%</div>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <div className='pos-re'>
                    <img className='fade-img' src={logoWithout} width={'93%'} alt="logo"/>
                    <img className='heart-img'  src={logo} width={'60%'} alt="logo" style={{
                      WebkitMaskImage : 'linear-gradient(to bottom, transparent '+this.state.transparent+'%, black 0%, black 90%, transparent 100%)',
                      maskImage : 'linear-gradient(to bottom, transparent '+this.state.transparent+'%, black 0%, black 90%, transparent 100%)'}}/>
                    </div>
                    </Grid>

                    <Grid item md={12} xs={12} ><br/>
                    <div className='px-3'>
                    <Slider
                    sx={{
                    color: '#ffffff',
                    }}
            aria-label="Interest"
            defaultValue={this.state.priority}
            value={this.state.priority} 
            valueLabelDisplay="auto"
            step={1}
            onChange={(_, newValue)=>this.setInterest(newValue)}
            marks={this.marks}
            min={0}  
            max={100}
           // valueLabelFormat={valuetext}
           
            />
            </div>
            <div> 
              
              
              <Typography variant="h6" className="mb-1">{this.state.insterst_msg}</Typography>
            <Typography variant="h6"  className="mb-1">Add a message</Typography>
            <textarea style={{width:'100%'}} placeholder='Write your message here..........' rows={3} onKeyUp={(e)=>this.msgSet(e)} defaultValue={this.state.text}  /></div>
            <Stack
              direction="row"
               className='sendpicht '     
              alignItems="center"
              spacing={2}
            >
      
            <LoadingButton
                    type="submit"
                    size="large"
                    color='info'
                    disabled={false}
                    className="btns "
            loading={this.state.loading}
            onClick={()=>this.handleSubmit(this.state.Otheruser.id)}
            loadingPosition="center"
            variant="contained">
            Send pitch
            </LoadingButton>
            </Stack>
                    </Grid>

                </Grid>
              </Box>

              {this.state.Otheruser.first_name != '' ?
               <div  className='cdiv' >
              <Typography variant="h4" className='pt-2 pb-5 usname' >
              {this.state.Otheruser.first_name},  {this.calculateage(this.state.Otheruser.dob)} {this.state.Otheruser.confirm==1 ? <VerifiedOutlinedIcon  /> : ''}<br/>
                    <span className='cit'> {this.state.Otheruser.city}, &nbsp; {this.state.Otheruser.state} <br/>
                    {Math.round(this.state.Otheruser.distance) > 0 ? Math.round(this.state.Otheruser.distance)+' miles away':
                    
                    <Chip icon={<PlaceIcon />} style={{color:'#fff', marginTop:'0px'}} label={'Also lives in '+ this.state.Otheruser.city} variant="outlined" />
                    }
                    </span>
             
              </Typography>
              </div>

              : ''}
                 
                
                </div>

                
                <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={this.state.snack_open}
                autoHideDuration={3000}
                onClose={() => this.setState({snack_open: false})}
            >
                <Alert  severity="success" sx={{ width: '100%' }}>
                {this.state.snackmsg}
                </Alert>
            </Snackbar>
        </div>
        )
    }
  
    public handleSubmit = (id:any)=>{
  
        if(this.state.permission){
          this.setState({loading:true});
        let data:any = [];
       
        data = {
          from:Base64e.encode(this.state.user.id+''),
          to:Base64e.encode(this.state.Otheruser.id+''),
          priority:this.state.priority,
          msg:this.state.msg,
          id:'',
        };
        
     
        
        
        
        
          axios.post(variables.url+'sendUserRequests',data).then((res)=>{
              if(res.status === 200){
                this.setState({loading:false});
                ///this.props.removeRequestedUser(id)
                // this.setState(
                //   {loading:false,
                //   snack_open:true,
                //   snackmsg:'Request sent successfully!'
                //   });
                this.setState({bool:true, path:'matches'})
                 // setTimeout(()=>( this.setState({bool:true, path:'matches'}) ), 3000)
              }
          }).catch((e:any)=>{
            console.log(e);
        
          }); 
        }else{
          alert('access denied')
        } 
        }
}

export default Addinterest;