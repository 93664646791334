
import { LoadingButton } from '@mui/lab';
import {  Avatar, Grid, TextField, Typography } from '@mui/material';
import  React from 'react';
import variables from '../../global/global';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from 'axios';
import Base64e from '../encodedecode/encode';
import UpgradeplaneModal from '../upgradetopremium/modal';
import { format } from "date-fns";
import Swal from 'sweetalert2';

class ChatBox extends React.Component<any,any> {
 
    public state:any={
        rowId:'',
        userId:'',
        from:'',
        to:'',
        message_type:'text',
        message:'',
        message_error:'',
        messages:[],
        client:[],
        toUser:'',
        search:'',
        total:0,
        limit:15,
        totalPages:0,
        curentPage:0,
        loading:true,
        rowsLoad:false,
        permission:true,
        focus:false,
    }

    // constructor(prop:any){
    // super(prop);

    // }


componentDidMount(): void {
 const row = this.props.data.state.requestRow;
 const CLIENT=new W3CWebSocket(variables.wsUrl+this.props.data.state.requestRow.id+this.props.data.user().rows[0].id+"?type=message")
    this.setState({
        client:CLIENT,
        toUser:this.props.toUser,
            });

          
            
            
        if(this.props.data.user().rows[0].id== row.from){
      
        this.setState({from:row.from,to:row.to,userId:this.props.data.user().rows[0].id,rowId:row.id})
        }else{
         
        this.setState({from:row.to,to:row.from,userId:this.props.data.user().rows[0].id,rowId:row.id})
        }
        //alert(this.props.data.user().rows[0].payments[0].plan_id);


  CLIENT.onopen = () => {
    console.log('WebSocket Client Connected');
  };
  CLIENT.onclose = () =>{
 
    console.log('echo-protocol Client Closed');
  };

  CLIENT.onmessage = (message:any) => {
    const dataFromServer = JSON.parse(message.data);
    console.log('got reply! ', dataFromServer);
      this.setState((state:any) =>{
        state.messages.push(dataFromServer);
        return state;
      } ); 
      setTimeout(() => { this.scrolltoBottom(100000); }, 200);
  };


setTimeout(() => { this.getChat(0) }, 200);
}


componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
  
if(nextProps.refresh===true){
  this.setState({messages:[],curentPage:0});
  
  setTimeout(() => { this.getChat(0);  }, 200);
  this.props.returnHandle({refreshChat:false});
}
}


public scrolltoBottom = (v:number)=>{
  const chatDiv = document.getElementById('chatbox')!;
  chatDiv.scrollTop=v;
}


public sendMessage=()=>{

console.log(this.state.permission);

  if(this.state.permission){

    this.props.breadcrumbHandle({breadcrumb:[{
      link:'/ChatList',
    }]})


    const CLIENT:any =  this.state.client;
    const data ={
      to:this.state.to,
      from:this.state.from,
      userId:this.state.userId,
      rowId:this.state.rowId,
      message:this.state.message,
      message_type:this.state.message_type,
      type:'msg',
    }

    console.log(data);
    
        CLIENT.send(JSON.stringify(data));
        
    this.setState({
      message:'',
    message_type:'text',
    message_error:'',
    });
    setTimeout(() => {this.setState({focus:true}); this.scrolltoBottom(100000); }, 200);
  }else{
     Swal.fire({  
                icon: "info",
                title: '', 
                allowOutsideClick: false, 
                text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
                confirmButtonColor: '#7b1fa2',  
              }).then((res) => {
            
                window.location.href = '/subscription?open=1';
                //window.location.reload();
              })
  }
}




public setMessage=(e:any)=>{
this.setState({ 
  message:e.target.value,
    message_type:'text',
    message_error:''});
}

public scroll =(e:any)=>{
if(e.target.scrollTop<=0 && this.state.curentPage<=this.state.totalPages){
this.getChat(this.state.curentPage+1)
}

}

public bgcolor(ans:any){
  var aa= (ans!== undefined && ans!== null ) ? ans.split(',') : [];
  if(aa.length > 0){
    if(aa.indexOf("92") > -1){
      return '#f9b2c2';
    }
  }
return '#84d0f4';
}
   public render(){
    console.log(this.props.data.state.requestRow.updatedAt);

    

return(
<div className='box'>


  
  <div id="chatbox" onScroll={(e)=>this.scroll(e)}>
  {this.props.data.state.requestRow.status==1 ? 
<Typography variant='h6' className='' style={{textAlign:'left'}}>   {this.props.data.state.requestRow.status==1 ?  'You matched with '+this.state.toUser.first_name+' on '+ format(new Date(this.props.data.state.requestRow.updatedAt), 'MM/dd/yyyy') : <>&nbsp;</> }</Typography>
  :""}
    <p>{this.state.rowsLoad ? 'loading......' : ''}</p>
{this.state.messages.map((v:any,index:any)=>{
 
  if(this.props.data.user().rows[0].id== v.to && this.state.permission ==true){
   
    /////check payment status and trial period/////
    if(this.props.data.user().rows[0].payments[0].plan_id== '4'){
      let trial_end= this.props.data.user().rows[0].payments[0].start_date;
 
      const date1 = new Date();
      const date2 = new Date(trial_end);
      //console.log(trial_end);
      
      const diffTime = date2.valueOf() - date1.valueOf();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        //console.log(diffTime + " milliseconds");
        //console.log(diffDays + " days");

        if(diffTime <= 0){

          this.setState({permission:false}, () => {
            //alert('false');
              // Swal.fire({  
              //   icon: "info",
              //   title: '', 
              //   allowOutsideClick: false, 
              //   text: ' Please upgrade your plan to enjoy full access, as the free subscription offers limited features.',
              //   confirmButtonColor: '#7b1fa2',  
              // }).then((res) => {
            
              //   window.location.href = '/subscription?open=1';
              //   //window.location.reload();
              // })
           
            
            
           })

        }
    }
    ///end here////
  }

 return ( v.from== this.state.userId ? 
 <Grid  key={index} container className='mb-1' >  <Grid xs={1.5} md={1}><Avatar alt="Remy Sharp" src={this.props.data.user().rows[0].pic}/></Grid> 
 <Grid xs={10.5} md={11} className='text-center'>
   <Typography style={{color:'black', backgroundColor : this.bgcolor(this.props.data.user().rows[0].ansquery)}} variant='h5' className='d-flex p-1 overflow-auto'> &nbsp;{v.message}</Typography> 
   </Grid></Grid>

: <Grid  key={index} container className='mb-1'>  <Grid xs={1.5} md={1}> <Avatar alt="Remy Sharp" src={this.state.toUser!==null ? this.state.toUser.pic : ''}/></Grid> 
<Grid className='text-center ' xs={10.5} md={11}> <Typography style={{color:'black', backgroundColor : this.bgcolor(this.state.toUser.ansquery)}} variant='h5' className='d-flex overflow-auto p-1'> &nbsp;{v.message}</Typography> </Grid></Grid>
)})}
</div>
<div className='msg-input-btn mt-1'>
  <form style={{display: 'flex',
    width: '100%', justifyContent: 'center'}}>
<TextField autoComplete="one-time-code" autoFocus={this.state.focus}  onBlur={(e)=> e.target.focus()}  className='d-flex ' style={{background:'#fff'}} type={'text'} id="outlined-basic" label="Message" onChange={(e:any)=> this.setMessage(e)} error={this.state.message_error!=='' ?  true : false} helperText={this.state.message_error} variant="outlined" value={this.state.message}/>
<LoadingButton type='submit' onClick={(e)=>{e.preventDefault(); this.sendMessage()}} variant='contained'>Send</LoadingButton>
</form>
</div>

</div>

)
    }

public getChat = async (val:any)=>{
  const  Page =  val;
  const msg = val>0 ? this.state.messages : [];

  this.setState({rowsLoad:true});
 await axios.post(variables.url+'getChat',{
    rowId:Base64e.encode(this.state.rowId+''),
    page:this.state.limit*Page,
    limit:this.state.limit,
    search:this.state.search,
    status:0,
  })
  .then((res)=>{
      if(res.status === 200){
        
    const rw =  res.data.rows.reverse().map((x:any)=>{
          x.from =  parseInt(x.from,10);
          x.to =  parseInt(x.to,10);
       return x;
      })
const msg1 = rw.concat(msg);
this.setState({
  messages:msg1,
total:res.data.count,
totalPages:Math.ceil(res.data.count/this.state.limit),
curentPage:Page,
rowsLoad:false,
})
setTimeout(() => { this.scrolltoBottom(500) }, 200);

      }
  }).catch((e:any)=>{
 console.log(e);
  }); 

}


}


export default ChatBox;