import axios from "axios";
import TopHeader from './includes/header';
import Breadcrumb from './includes/breadcrumb';
import React from "react";
import { Navigate } from 'react-router-dom';
import variables from "../global/global";
import {Grid } from '@mui/material';
import { format } from "date-fns";
import Base64e from '../components/encodedecode/encode';
import Massages from './Massages/Massages';

class  Notifications extends React.Component<any, any>{
    public state = {
        user:this.props.user().rows[0],
        go:false,
        page:'#',
        loading:false,
        blogs:[],
        rows:[],
        requests:[],
        chatPage:false,
        requestRow:[],
        breadcrumb:[{
            link:'',
            link_name:'Notifications'
          }
        ]
        
    }
componentDidMount(): void {
    this.setState({chatPage:false});
    this.getUnreadMessage();
    this.getnewRequests();
    axios.get(variables.url+'getBlogs')
    .then((res)=>{    
    if(res.status === 200){     
        this.setState({blogs:res.data.rows});
            }
    }).catch((e:any)=>{  
         
    });

}
public goto = (page:any)=>{
        
    this.setState({go:true,page:page});

}
public openChat =(val:any)=>{
this.setState({chatPage:true,requestRow:val});
 }
public getUnreadMessage= async()=>{

    axios.post(variables.url+'getUnreadMessage',{
      uId:Base64e.encode(this.state.user.id+''),
    })
    .then((res)=>{
        if(res.status === 200 && res.data!==null){  
         console.log('unread',res.data);
         this.setState({rows:res.data.rows})
         
          
        } 

    }).catch((e:any)=>{
    console.log(e);
    }); 
  }

  public getnewRequests= async()=>{

    axios.post(variables.url+'getnewRequests',{
      uId:Base64e.encode(this.state.user.id+''),
    })
    .then((res)=>{
     
        if(res.status === 200 && res.data!==null){  
          console.log('count',res.data.count);
         if(res.data.rows.length >0){
            this.setState({requests:res.data.rows})
         }
         
          }


    }).catch((e:any)=>{
    console.log(e);
    }); 
  }

render(): React.ReactNode {

return (

  <div className='App'>

{this.state.go===true ? <Navigate  state={this.state} to={"/"+this.state.page} replace={true}/>  : ''}

<TopHeader title="&nbsp;"  logo={''}/>
<Breadcrumb breadcrumb={this.state.breadcrumb} />
{ !this.state.chatPage ? 

<div className="container notification">
<div className="row">
<div className="col-md-10 m-auto">
    <h3>All Notifications</h3>

    
{ this.state.rows.length > 0 ? 

this.state.rows.map((value:any,index) => (
    <>
   <div className="card" >
                                    <a className="d-flex justify-content-between" href="javascript:void(0);" onClick={()=>this.setState({go:true,page:'ChatList'})}>
                                        <div className="media d-flex align-items-center">
                                            <div className="media-left pr-0">
                                                <div className="avatar mr-1 ">
                                                    <img alt={`uvatar n°${index + 1}`} src={value.user.pic}  height="39" width="39" /></div>
                                            </div>
                                            <div className="media-body">
                                                <h6 className="media-heading"><span className="text-bold-500">{value.user.first_name}</span>  sent a message.</h6><small className="notification-text">{format(new Date(value.updatedAt), 'H:i:s a')}</small>
                                            </div>
                                        </div>
                                    </a>
  </div>
  </>
    )) : '' }

{ this.state.requests.length > 0 ? 

this.state.requests.map((value:any,index) => (
    <>
   <div className="card" >
                                    <a className="d-flex justify-content-between" href="javascript:void(0);" onClick={()=>this.setState({go:true,page:'activepitches'})}>
                                        <div className="media d-flex align-items-center">
                                            <div className="media-left pr-0">
                                                <div className="avatar mr-1 ">
                                                    <img alt={`uvatar n°${index + 1}`} src={value.user.pic}  height="39" width="39" /></div>
                                            </div>
                                            <div className="media-body">
                                                <h6 className="media-heading"><span className="text-bold-500">{value.user.first_name}</span>  sent a pitch.</h6><small className="notification-text">{format(new Date(value.updatedAt), 'dd/MM/yy HH:mm:ss a')}</small>
                                            </div>
                                        </div>
                                    </a>
  </div>
  </>
    )) : '' }
{ this.state.rows.length == 0 && this.state.requests.length == 0  ? <div className="card" >Notifications not Found.</div> : ''}

 </div>
 </div>
 </div>
 : <Massages user={this.props.user} state={this.state} returnHandler={(e:any)=>this.setState(e)}/>}

 </div>
 
 
)
}

}



export default Notifications;