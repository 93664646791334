import { Backdrop, ButtonGroup, CircularProgress, Grid, Typography, Button, Stack, Chip, Box } from '@mui/material';
import axios from 'axios';
import React from 'react';
import variables from '../../global/global';
import TopHeader from '../includes/header';
import logo from '../../assets/img/logo-pitch.png'
import logoWithout from '../../assets/img/logo-without.png'
import Breadcrumb from '../includes/breadcrumb';
import Sendpitch from '../../components/lovediamond/sendpitch';
import { LoadingButton } from '@mui/lab';
import Base64e from '../../components/encodedecode/encode';
import { Navigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SportsBaseballOutlinedIcon from '@mui/icons-material/SportsBaseballOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Massages from '../Massages/Massages';
class Activepitches extends React.Component<any, any>{
  public state = {
    dialog: false,
    confirm: false,
    deleteId: '',
    priority: 0,
    smallScreen: false,
    chatPage: false,
    requestRow: [],
    id: '',
    SendUId: '',
    user: null,
    rows: [],
    search: '',
    total: 0,
    limit: 15,
    totalPages: 0,
    curentPage: 0,
    loading: true,
    sendpitch: {
      open: false,
      value: '',
      column: '',
      loading: false,
    },
    bool: false,
    path: '',
    viewUser: [],
    breadcrumb: [{
      link: '',
      link_name: 'Active Pitches'
    }]
  }
  // constructor(prop:any){
  //     super(prop);

  // }
  public openChat = (val: any) => {
    console.log('vvvv', val);

    this.setState({ chatPage: true, requestRow: val });
  }
  public goto = (path: any) => {
    this.setState({
      bool: true,
      path: path,
    });
  }
  public getPitches = (val: any) => {
    const Page = val;

    let rows: any = this.state.rows;
    axios.post(variables.url + 'getuserRequests', {
      id: Base64e.encode(this.state.id + ''),
      page: this.state.limit * Page,
      limit: this.state.limit,
      search: this.state.search,
      status: 0,
    }).then((res) => {
      if (res.status === 200) {
        res.data.rows.map((v: any) => {
          v.loading = false;
          v.from = parseInt(v.from);
          v.to = parseInt(v.to);
          rows.push(v);
          return v;
        })

        this.setState({
          rows: rows,
          total: res.data.count,
          totalPages: Math.ceil(res.data.count / this.state.limit),
          curentPage: Page,
          loading: false,
        });

        window.screenTop = 300;
      }
    });
  }

  componentDidMount() {
    this.setState({ id: this.props.user().rows[0].id, user: this.props.user().rows[0] });
    setTimeout(() => {
      this.getPitches(0);
    }, 100);
  }

  componentWillMount(): void {
    window.addEventListener('scroll', () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        if (this.state.curentPage <= this.state.totalPages) {
          this.setState({ loading: true });
          this.getPitches(this.state.curentPage + 1);
        }
      }
    });
  }

  public valuetext(value: number) {
    if (value >= 1 && value <= 24) {
      return "headed  towards first base!";
    } else if (value >= 25 && value <= 49) {
      return "first base!";

    } else if (value >= 50 && value <= 74) {
      return "second base!";
    } else if (value >= 75 && value <= 99) {
      return "third base!";

    } else if (value == 100) {
      return "home run!";

    }
  }

  public resize = (e: any) => {

    if (e !== null && e.getBoundingClientRect().width <= 820) {
      this.setState({ smallScreen: true });
    } else {
      this.setState({ smallScreen: false });
    }
  }
  public openIntrestBox = (value: any) => {

    this.setState({ sendpitch: value });

  }

  public render() {
    function trans(id: any, from: any, priority: any, to_priority: any) {
      return id === from ? 100 - priority : 100 - to_priority
    }

    return (
      <div className='App' ref={this.resize}>
        {this.state.bool === true ? <Navigate state={this.state} to={"/" + this.state.path} replace={true} /> : ''}
        <TopHeader title="Active Pitches" logo={''} />
        <Breadcrumb breadcrumb={this.state.breadcrumb}  chatPage={this.state.chatPage} returnHandler={(e: any) => this.setState({ chatPage: e })}/>
        <h5 className='page-title'>{this.state.chatPage ? 'Messages' : 'New Hits'}</h5>
        <div className='mb-6'>

          {!this.state.chatPage ?
            <div style={{ width: this.state.smallScreen ? '100%' : '80%', margin: 'auto', padding: '10px 0px' }} >
              {this.state.rows[0] !== undefined ? this.state.rows.map((value: any, index) => (
                <>
                  <div className='row' style={{ width:'100%', margin:'auto'}}>
                    {this.state.smallScreen ?

                      <div className='col-md-12 msg-list-box hits mb-2' style={{ background: (index % 2) == 0 ? '#e39c14' : '#555452' }}>
                        <div className='row smallscreen'>
                          <div className='col-12 col-md-12 '>
                            <div className="row">
                              <div className='col-7 impadd' onClick={() => this.viewProfile(this.state.id === value.from ? value.user_to : value.user, value.id)}>
                                <div className='row'>
                                  <div className='col-4 p-0'>
                                    <img className='border-rounded' width={'100%'} alt={`uvatar n°${index + 1}`} src={value !== null ? value.user.pic : ''} />

                                  </div>
                                  <div className='col-8   name-box pl-1'>
                                  <Typography className='h4-sm' variant={'h5'} style={{ textAlign: 'left' }}><b>{value.user.first_name + ', ' + this.getAge(value.user.dob.toString()) + ', ' + value.user.city + ' ' + value.user.state}</b></Typography>
                                  <p className='ms-tx '><b>{value.messages[0] != undefined && value.messages[0].message != '' ? <>{this.state.id == value.messages[0].from && value.user.id==this.state.id ?  value.user.first_name+': '  :  value.user_to.first_name+': '}</>:'No messages in chat'}</b>
                                  
                                  {value.messages[0] !== undefined ? value.messages[0].message.substr(0, 80) : ''}</p>
                                  </div>
                                </div>
                              
                              </div>
                              <div className='col-5 name-box pl-1'>
                              <div className='d-flex justify-cotent-center align-items-center mt-3'>
                                  <div>


                                    <div className='pos-re-mess'>
                                      <img className='fade-img' src={logoWithout} width={90} alt="logo" />
                                      <img className='heart-img heart-img-mssg' src={logo} width={100} alt="logo" style={{
                                        width: '58px',
                                        WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.to_priority, value.priority) + '%, black 0%, black 90%, transparent 100%)',
                                        maskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.to_priority, value.priority) + '%, black 0%, black 90%, transparent 100%)'
                                      }} />
                                    </div>
                                    <Typography sx={{textAlign:'center', fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} className='nowrap'> Their Interest</Typography>
                                  </div>
                                  <div className='percet'>{this.state.id == value.from ? value.to_priority ?? 0 : value.priority ?? 0}%</div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-3">
                            <div className='col-5 d-flex align-items-end justify-content-center'>
                            <Chip onClick={() => this.openChat(value)} label="View Messages" className='graybtn-sm' />
                            </div>
                            <div className='col-7 position-relative'>
                   
                            <Grid container className='align-items-end'>

                              <Grid xs={6} sx={{ display: { xs: "block" } }} title="View Profile" className={'font-60'} md={3} onClick={() => this.openIntrestBox({
                                open: true,
                                value: value.user,
                                id:value.id,
                                column: this.state.id === value.from ? 'from' : 'to',
                              })}> <img src={require(`./../../assets/img/Thumbs-up.png`)} width="45" /><br /><Chip label="Hit Back" className='graybtn-sm mt-2 bac-blue' /></Grid>
                              <Grid xs={6} sx={{ display: { xs: "block", md: "block" } }} title="Block" className={'font-60'} md={3} onClick={() => this.strikeout(this.state.id, value.user.id, value.id)}><img src={require(`./../../assets/img/Thumbs-down.png`)} width="45" /><br /><Chip label="Strike Out" color="primary" className='graybtn-sm mt-2 bac-red' /></Grid>

                              

                            </Grid>

                         
                            </div>
                            
                            </div>

                            
                          </div>
                          {/* <div className='col-2 col-md-3 d-flex'>
                            <Grid container onClick={()=>this.viewProfile(this.state.id===value.from ? value.user_to : value.user,value.id)}>
                                  <Grid item className='p-0 text-center' xs={12} md={3}>
                                  <Typography   variant={'h5'}><b>{value.from !==undefined && value.from == this.state.id ? value.user_to.first_name : value.user.first_name}</b></Typography>
                              <img className='border-rounded' width={'100%'} alt={`uvatar n°${index + 1}`} src={
                                    value!==null && this.state.id===value.from  ?
                                    variables.BaseUrl+'files/'+value.user_to.pic  : 
                                    value!==null && this.state.id===value.to   ? 
                                    variables.BaseUrl+'files/'+value.user.pic  : ''
                                    }/> 
                                  </Grid>

                              </Grid>
      </div> */}
                     

                        </div>
                      </div>

                      :
                      <div className='col-md-12 msg-list-box hits mb-2' style={{ background: (index % 2) == 0 ? '#e39c14' : '#555452' }}>
                        <div className='row'>
                          <div className='col-md-3 d-flex'>
                            <Grid container>
                              <Grid item className='pl-0' xs={2} md={3}>
                                <img className='border-rounded' width={'100%'} alt={`uvatar n°${index + 1}`} src={value !== null ? value.user.pic : ''} />
                              </Grid>

                              <Grid item xs={10} md={9} className='name-box' title="open chat"  >
                                <Typography variant={'h5'} style={{ textAlign: 'left' }}><b>{value.user.first_name + ', ' + this.getAge(value.user.dob.toString()) + ', ' + value.user.city + ' ' + value.user.state}</b></Typography>
                                <p className='ms-tx mt-2'><b>{value.messages[0] != undefined && value.messages[0].message != '' ? <>{this.state.id == value.messages[0].from && value.user.id==this.state.id ?  value.user.first_name+': '  :  value.user_to.first_name+': '}</>:'No messages in chat'}</b>
                                  
                                  {value.messages[0] !== undefined ? value.messages[0].message.substr(0, 80) : ''}</p>
                                <Chip onClick={() => this.openChat(value)} label="View Messages" className='graybtn' />
                              </Grid>
                            </Grid>
                          </div>
                          <div className='col-md-9 position-relative'>
                            <Grid container className='align-items-end'>
                              <Grid xs={6} sx={{ display: { xs: "none", md: "block" } }} title="View Profile" className={'font-60'} md={2} onClick={() => this.viewProfile(value.user, value.id)}><AccountCircleOutlinedIcon /><br /><Chip label="View Profile" className='graybtn mt-2' /></Grid>
                              <Grid xs={6} sx={{ display: { xs: "none", md: "block" } }} title="View Profile" className={'font-60'} md={3} onClick={() => this.openIntrestBox({
                                open: true,
                                id:value.id,
                                value: value.user,
                                column: this.state.id === value.from ? 'from' : 'to',
                              })}> <img src={require(`./../../assets/img/Thumbs-up.png`)} width="85" /><br /><Chip label="Hit Back/Accept" className='graybtn mt-2 bac-blue' /></Grid>
                              <Grid xs={6} sx={{ display: { xs: "none", md: "block" } }} title="Block" className={'font-60'} md={3} onClick={() => this.strikeout(this.state.id, value.user.id, value.id)}><img src={require(`./../../assets/img/Thumbs-down.png`)} width="85" /><br /><Chip label="Strike Out/Decline" color="primary" className='graybtn mt-2 bac-red' /></Grid>

                              <Grid item xs={4} md={4} className='h-235 h-235-pitch'>
                                <div className='d-flex justify-cotent-center align-items-center'>
                                  <div>


                                    <div className='pos-re-mess'>
                                      <img className='fade-img' src={logoWithout} width={130} alt="logo" />
                                      <img className='heart-img heart-img-mssg' src={logo} width={100} alt="logo" style={{
                                        width: '83px',
                                        WebkitMaskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.to_priority, value.priority) + '%, black 0%, black 90%, transparent 100%)',
                                        maskImage: 'linear-gradient(to bottom, transparent ' + trans(this.state.id, value.from, value.to_priority, value.priority) + '%, black 0%, black 90%, transparent 100%)'
                                      }} />
                                    </div>
                                    <Typography sx={{ fontSize: { xs: "14px", md: "22px" } }} variant={'h6'} > Their Interest</Typography>
                                  </div>
                                  <div className='percet'>{this.state.id == value.from ? value.to_priority ?? 0 : value.priority ?? 0}%</div>
                                </div>
                              </Grid>

                            </Grid>

                          </div>

                        </div>

                      </div>
                    }

                  </div>


                </>

              )) : !this.state.loading ?

                <>
                  <div className='py-5 px-2'>
                    <Box className='msspopup msspopup2' style={{ pointerEvents: 'all' }}>
                      <div>
                        <div className="row position-relative">
                          <div className="col-md-12"> <Typography className='text-center color-pink' variant='h4'>Active Hits</Typography></div>
                          <div className="col-md-12 iocdiv"> </div>
                        </div>

                        <Typography className='text-center' variant='h5'>You have no new hits</Typography>
                        <Typography className='text-center' variant='h6' >Update your <a href="/profile"><span className="color-pink">profile</span></a> to stand out or go to  <a href="/matches"><span className="color-pink">send pitches</span></a> to meet new singles</Typography>
                      </div>
                    </Box>
                  </div>

                </>
                : ''}
            </div>
            : <Massages user={this.props.user} state={this.state} returnHandler={(e: any) => this.setState(e)} />}



          {this.state.sendpitch.open ? <Sendpitch from={'hitRequest'} openChat={(e: any) => this.openChat(e)} removeRequestedUser={(e: any) => this.removeuser(e)} returnhandle={(e: any) => this.setState(e)} data={this.state} /> : ''}


          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>

        </div>
      </div>
    )

  }



  public getAge = (dateString: any) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  public viewProfile = (v: any, Rd: any) => {
    const Eid = Base64e.encode(v.id + '');
    const Rid = Base64e.encode(Rd + '');

    this.setState({ path: 'MatchesProfile/' + Eid + '/' + Rid, bool: true });

  }


  public removeuser = (i: any) => {
    const sendpitch = { open: false };
    this.setState({ sendpitch: sendpitch, rows: [] });
    setTimeout(() => { this.getPitches(0); }, 100);
  }

  public strikeout = (id: any, strikeout: any, rq_id: any) => {

    this.loading(strikeout, true);
    axios.post(variables.url + 'strikeout', {
      strike: strikeout,
      id: Base64e.encode(id + ''),
      rq_id: rq_id,
    })
      .then((res) => {
        if (res.status === 200) {
          this.loading(strikeout, false);
          this.setState({ rows: [], loading: true });
          setTimeout(() => { this.getPitches(0); }, 100);
        }
      }).catch((e: any) => {
        console.log(e);
      });

  }


  public loading = (id: any, bool: boolean) => {

    const final = this.state.rows.map((v: any) => {
      if (v.id === id) {
        v.loading = bool;
      }
      return v;
    });
    this.setState({ rows: final });
  }

}

export default Activepitches;