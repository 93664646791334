import React from "react";
import {BrowserRouter,  Route,Routes} from "react-router-dom";
import { RequireAuth, useAuthUser } from 'react-auth-kit'
import SignInComponent from '../pages/signIn';
import App from "../App";
import WelcomeComponent from "../pages/welcome";
import ForgotPassword from "../pages/forgotPassword";
import ResetPassword from "../pages/resetPassword";
import Signup from "../pages/signup";
import DemoSignup from "../pages/demoSignup";
import {useIsAuthenticated} from 'react-auth-kit'
import Profile from "../pages/Profile/Profile";
import Images from "../components/profile/images";
import Settings from "../pages/Profile/Settings";
import BlockedUsers from "../pages/settings/BlockedUsers";
import Support from "../pages/Profile/Support";
import Subscription from "../pages/settings/subscription";
import Match from "../pages/matches/match";
import Addinterest from "../pages/matches/addinterest";
import MatchesProfile from "../pages/matches/MatchesProfile";
import Activepitches from "../pages/pitches/activepitches";
import ChatList from "../pages/Massages/list";
import addNotification from 'react-push-notification';
import variables from '../global/global';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Base64e from "../components/encodedecode/encode";
import Footer from "../components/footer/Footer";
import StaticPages from "../pages/StaticPages";
import Genres from "../pages/genres";
import TermConditions from "../pages/termsConditions";
import PrivacyPolicy from "../pages/privacyPolicy";
import Blog from "../pages/blog";
import BlogSingle from "../pages/blogSingle";
import Notifications from "../pages/notifications";
import Aboutus from "../pages/Aboutus";
import Missionstatement from "../pages/Missionstatement";
import Relationshipcoaching from "../pages/Relationshipcoaching";
import Help from "../pages/Help";
import Contactus from "../pages/contactus";

const Routesx=(data:any)=>{



const auth = useIsAuthenticated();
const user:any = useAuthUser();

const privatePages = [
  {name:'Dashboard',component:  <App  user={user}  />},
  {name:'Profile',component:  <Profile  user={user}  />},
  {name:'images',component:  <Images  user={user}  />},
  {name:'settings',component:  <Settings  user={user}  />},
  {name:'blockedUsers',component:  <BlockedUsers  user={user}  />},
  {name:'support',component:  <Support  user={user}  />},
  {name:'subscription',component:  <Subscription  user={user}  />},
  {name:'matches',component:  <Match  user={user}  />},
  {name:'addinterest/:id/:priority',component:  <Addinterest  user={user}  />},
  {name:'MatchesProfile/:id/:req_id',component:  <MatchesProfile  user={user}  />},
  {name:'activepitches',component:  <Activepitches  user={user}  />},
  // {name:'massages',component:  <Massages  user={user}  />},
  {name:'ChatList',component:  <ChatList  user={user}  />},
  {name:'Blog',component:  <Blog  user={user}  />},
  {name:'post/:id',component:  <BlogSingle  user={user}  />},
  {name:'Notifications',component:  <Notifications  user={user}  />},
  {name:'Aboutus',component:  <Aboutus  user={user}  />},
  {name:'Missionstatement',component:  <Missionstatement  user={user}  />},
  {name:'Relationshipcoaching',component:  <Relationshipcoaching  user={user}  />},
  {name:'help',component:  <Help  user={user}  />},
  {name:'Contactus',component:  <Contactus  user={user}  />},

]

const init =()=>{

  const row =  user().rows[0].id;
  const CLIENT=new W3CWebSocket(variables.wsUrl+row+"?type=notification")
  
   CLIENT.onopen = () => {
     console.log('notification WebSocket Client Connected');
   };

   CLIENT.onclose = () =>{
     console.log('notification echo-protocol Client Closed');
   };
 
   CLIENT.onmessage = (message:any) => {
     const dataFromServer = JSON.parse(message.data);

  addNotification({
    title: dataFromServer.first_name,
    subtitle: dataFromServer.first_name, //optional
    message: dataFromServer.message, //optional

    onClick: (e: Event | Notification) => {    window.close(); window.open('/ChatList?chat='+Base64e.encode(dataFromServer.rowId+''));
     }, //optional, onClick callback.
    theme: 'red', //optional, default: undefined
    duration: 5000, //optional, default: 5000,
    backgroundTop: 'green', //optional, background color of top container.
    backgroundBottom: 'darkgreen', //optional, background color of bottom container.
    colorTop: '#fff', //optional, font color of top container.
    colorBottom: '#fff', //optional, font color of bottom container.
    closeButton: 'Go away', //optional, text or html/jsx element for close text. Default: Close,
    native: true, //optional, makes the push notification a native OS notification
    icon: '<img src="'+dataFromServer.pic+'"/>', // optional, Native only. Sets an icon for the notification.
    vibrate: 10 , // optional, Native only. Sets a vibration for the notification.
    silent: false // optional, Native only. Makes the notification silent.

});
     console.log('got notification! ', dataFromServer);
   };     
}

if(auth()){
init();
}


  return (<BrowserRouter>
  <Routes>

 <Route path={'/'} element={!auth() ? <WelcomeComponent/> : <App />}/>
    <Route path={'/signin'} element={!auth() ? <SignInComponent/> : <App />}/>
   <Route path={'/forgotpassword'} element={!auth() ? <ForgotPassword/> : <App />}/>
   <Route path={'/resetpassword/:token'} element={!auth() ? <ResetPassword/> : <App />}/>
   <Route path={'/signup/:page'} element={!auth() ? <Signup/> : <App />}/>
   <Route path={'/demo-signup'} element={!auth() ? <DemoSignup/> : <App />}/>
   <Route path={'/page/:id'} element={<StaticPages/>}/>

   <Route path={'genre-disclaimer'} element={<Genres/>}/>
   <Route path={'terms-and-conditions'} element={<TermConditions/>}/>
   <Route path={'privacy-policy'} element={<PrivacyPolicy/>}/>

   
  

{privatePages.map((w:any,index:number)=>(
<Route key={index} path={'/'+w.name}  element={
   <RequireAuth loginPath={'/'}>
         {w.component}</RequireAuth>}/>
         ))}

  </Routes>
</BrowserRouter>)


}


export default Routesx;