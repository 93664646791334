import React from 'react';
import variables from '../../global/global';
import {  Backdrop, Button,   CircularProgress,  Grid,  Typography } from '@mui/material';
import axios from 'axios';
import CreateIcon from '@mui/icons-material/Create';
import SaveIcon from '@mui/icons-material/Save';
class About extends React.Component<any,any>{
    // constructor(props:any) {
    //     super(props)
       
    // }
    
public state ={
    user:this.props.state.user,
    editAbout:false,
    loading:false,

}
   

    public goto = (path:any)=>{
this.setState({
bool:true,
path:path,
});
    }


    public updateAbout =()=>{
        this.setState({editAbout:false});
        this.setState({loading:true});  
        axios.post(variables.url+'updateAbout',{id:this.state.user.id,about:this.state.user.about})
        .then((res)=>{    
            if(res.status === 200){
              this.setState({loading:false});      
              localStorage.setItem('_auth_state',JSON.stringify(res.data)); 
              setTimeout(() => {  window.location.reload(); }, 1000);
                 }
        }).catch((e:any)=>{
          this.setState({loading:false});    
          console.log(e);        
        });
        }

public updatetextAbout = (e:any)=>{
this.state.user.about=  e.target.value;
}

public render(){


 


    return(
        <Grid item xs={12} md={12} style={{textAlign:'left',position:'relative'}}>

        <Typography className='m-1 gen-tt d-flex' variant='h4'>About you ... 
        {!this.state.editAbout && this.props.edit===true ? <Button  onClick={()=>this.setState({editAbout:true})} title='Edit' className='w_40' color='secondary' size='small'variant='text'><CreateIcon  />{(this.state.user.about == null || this.state.user.about =='') ? <sup>+22%</sup> : ''}</Button>
        : this.props.edit===true ?
        <div className='saveblue'><Button  onClick={()=>this.updateAbout()} title='Save' className='w_40' color="primary" variant="contained" size='small'>Save</Button><>{(this.state.user.about ==null || this.state.user.about =='') ? <sup>+22%</sup> : ''}</></div>
       :'' }
        </Typography>
        {!this.state.editAbout ? <Typography className='m-1 abouttext'  style={{whiteSpace:'break-spaces'}}>{this.state.user.about}&nbsp;&nbsp;</Typography> : ''}
        {this.state.editAbout ? <textarea onChange={(e)=>this.updatetextAbout(e)} className='textareaView' rows={5} spellCheck={true} lang="en">{this.state.user.about}</textarea> : '' }
        <Backdrop  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 ,position:'absolute'}}  open={this.state.loading}>
     <CircularProgress color="inherit" />
</Backdrop>
        </Grid>
    )
}





}

export default About;

