import React from "react"
import axios from 'axios'
import { useState, useEffect } from "react";
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import variables from '../global/global';
import logo from '../assets/img/logo.png'
import logo1 from '../assets/img/logo-1.png'
import logo2 from '../assets/img/logo-2.png'
import { Grid, TextField, Typography, Button, InputAdornment, IconButton, OutlinedInput, InputLabel, FormControl } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const SignInComponent = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const signIn = useSignIn()
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({ email: '', password: '' })
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [minimum, setMinimum] = React.useState('Passwords must be a minimum of 8 characters long.');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const checkPassword = (r: any) => {


    if (r.target.value.length >= 8) {

      setMinimum('');

    } else {
      setMinimum('Passwords must be a minimum of 8 characters long.');
    }

  }
  const onSubmit = (e: any) => {
    e.preventDefault()
    setLoading(true);

    axios.post(variables.url + 'Userlogin', formData)
      .then((res) => {
        setLoading(false);
        if (res.status === 200 && res.data.error === '') {
          console.log(res.data);
          if (signIn({
            token: res.data.token,
            expiresIn: res.data.expiresIn,
            tokenType: "Bearer",
            authState: res.data.authUserState,
            refreshToken: res.data.refreshToken,                    // Only if you are using refreshToken feature
            refreshTokenExpireIn: res.data.refreshTokenExpireIn
          })) { // Only if you are using refreshToken feature
            navigate('/Dashboard')
          }
        } else if (res.data.error !== '') {
          setError(res.data.error);
        } else {
          alert('something goes wrong!');
        }
      }).catch(() => {
        setLoading(false);
        setError('server error.');
      });
  }
  useEffect(() => {
    document.body.classList.add('bg-smoke');

    return () => {
      document.body.classList.remove('bg-without-smoke');
    };
  });
  return (
    <>
      <section className="header pt-2 ">
        <div className="container-fluid">
          <div className="row show-md" data-aos="fade-in">
            <div className="col-lg-2 col-md-2 position-relative">
              <a href="javascript::void();" onClick={() => navigate('/')} className="logo1 align-items-center">
                <img src={logo1} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                <span className="header-tm-text logotm2">TM</span>
              </a>
            </div>
            <div className="col-lg-10  col-md-10 position-relative d-flex align-items-center">
              <a href="javascript::void();" onClick={() => navigate('/')} className="logo align-items-center m-left ">
                <img src={logo2} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                <span className="header-tm-text">TM</span>
              </a>
              <hr className="logo-hr" />

            </div>
            <div className="gold-line"> &nbsp; </div>
          </div>
          <div className="row show-sm" data-aos="fade-in">

            <div className="col-lg-12  position-relative">
              <a href="javascript::void();" onClick={() => navigate('/')} className="logo align-items-center">
                <img src={logo} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                {/* <span className="header-tm-text-moblie">TM</span> */}
              </a>
              <hr className="logo-hr" />

            </div>
            <div className="gold-line"> &nbsp; </div>
          </div>

        </div>

      </section>
      <div className="container_ main">
        <form className="lginform t-center" onSubmit={onSubmit}>
          {/* <div className="form-group t-center">
                   <a href="#" className="logo align-items-center"> 
                   <img alt="ads" src={logo}  style={{width:'100%'}}  onClick={()=> navigate('/')}/></a></div>               */}
          <Typography variant='h3' gutterBottom color='red' style={{ textAlign: 'center' }}>
            <h3 className="tt-l">Login</h3>
          </Typography>
          <Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <TextField required={true} className='d-flex' id="outlined-basic" label="Email" onChange={(e) => setFormData({ ...formData, email: e.target.value })} variant="outlined" value={formData.email} />
            </Grid>
            <br />
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <FormControl className={error != '' ? 'yellow-border' : ''} sx={{ width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput required={true}

                  type={showPassword ? 'text' : 'password'}
                  onKeyUp={(e) => checkPassword(e)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        className="eyeicon"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }

                  className='d-flex' id="outlined-adornment-password" label="Password" onChange={(e) => setFormData({ ...formData, password: e.target.value })} value={formData.password} />
              </FormControl>
            </Grid>
            <span className="p-text">{minimum}</span>
          </Grid>
          <div className="form-group">
            <span style={{ color: '#000' }}><b>{error}</b></span>
          </div>

          <LoadingButton
            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
            type="submit"
            size="large"
            className="btns login-button blue-btn"
            loading={loading}
            loadingPosition="center"
          >
            Login
          </LoadingButton>

          <Typography variant='h3' gutterBottom color='red' onClick={() => navigate('/forgotpassword')} style={{ marginTop: '10px', textAlign: 'center', cursor: 'pointer' }}>
            <h3 className="forgot">Forgot Password?</h3>
          </Typography>
          <div className="create-account">
            <a href="javascript:void()" onClick={() => navigate('/signup/questions')} >Create Account</a>
          </div>

        </form>
      </div>
    </>

  )
}


export default SignInComponent;