import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import React from "react"
import { Navigate } from "react-router-dom";
import variables from "../global/global";
import logo from '../assets/img/logo.png'
import logo1 from '../assets/img/logo-1.png'
import logo2 from '../assets/img/logo-2.png'


class ForgotPassword extends React.Component<any> {
    public state = {
        go: false,
        page: '#',
        email: '',
        loading: false,
        error: '',
        color: 'text-danger',
        host: variables.current_app_url
    }
    // constructor(props:any) {
    //     super(props)
    // }

    public goto = (page: any) => {
        this.setState({ go: true, page: page });

    }

    public sendlink = () => {
        this.setState({ loading: true });
        axios.post(variables.url + 'forgotPassword', this.state)
            .then((res) => {
                this.setState({
                    error: res.data.msg,
                    color: 'text-danger',
                    loading: false
                })

            });
    }

    public render() {
        return (
            <>
                <section className="header pt-2 ">
                    <div className="container-fluid">
                        <div className="row show-md" data-aos="fade-in">
                            <div className="col-lg-2 col-md-2 position-relative">
                                <a href="javascript::void();" onClick={() => this.goto('')} className="logo1 align-items-center">
                                    <img src={logo1} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                                    <span className="header-tm-text logotm2">TM</span>
                                </a>
                            </div>
                            <div className="col-lg-10  col-md-10 position-relative d-flex align-items-center">
                                <a href="javascript::void();" onClick={() => this.goto('')} className="logo align-items-center m-left ">
                                    <img src={logo2} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                                    <span className="header-tm-text">TM</span>
                                </a>
                                <hr className="logo-hr" />

                            </div>
                            <div className="gold-line"> &nbsp; </div>
                        </div>
                        <div className="row show-sm" data-aos="fade-in">

                            <div className="col-lg-12  position-relative">
                                <a href="javascript::void();" className="logo align-items-center">
                                    <img src={logo} className="logo" alt="" data-aos="zoom-out" data-aos-delay="100" />
                                    {/* <span className="header-tm-text-moblie">TM</span> */}
                                </a>
                                <hr className="logo-hr" />

                            </div>
                            <div className="gold-line"> &nbsp; </div>
                        </div>

                    </div>

                </section>
                <div className="container_ main">
                    {this.state.go === true ? <Navigate state={this.state} to={"/" + this.state.page} replace={true} /> : ''}
                    <div className="lginform" >

                        <Typography variant='h3' gutterBottom color='red' style={{ textAlign: 'center' }}><h3>
                            Forgot Password</h3>
                        </Typography>
                        <Grid  >
                            <Grid item xs={12} style={{ marginTop: '10px' }}>

                                <TextField required={true} className='d-flex' id="outlined-basic" label="Email" onChange={(e) => this.setState({ email: e.target.value })} variant="outlined" value={this.state.email} />
                            </Grid>
                        </Grid>
                        <div className={this.state.color}>
                            <span className="d-block t-center">{this.state.error}</span>
                        </div>
                        <LoadingButton
                            style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}
                            type="submit"
                            size="large"
                            className="login-button"
                            loading={this.state.loading}
                            onClick={() => this.sendlink()}
                            loadingPosition="center"
                            variant="contained">
                            Send
                        </LoadingButton>
                        <Typography variant='h3' gutterBottom color='red' onClick={() => this.goto('signin')} style={{ marginTop: '10px', textAlign: 'center', cursor: 'pointer' }}>
                            <h3 >Back to Login?</h3>
                        </Typography>
                    </div>
                </div>
            </>
        )
    }

}


export default ForgotPassword;